import { extend, localize } from "vee-validate";
import { email, required, min, max, numeric } from "vee-validate/dist/rules";
import { date_format } from "@shared/util/ValidateDate";
import { color_hex } from "@shared/util/ValidateColorHexCode";

localize({
  en: {
    messages: {
      required: "This field is required",
      acceptTerms:
        "You must accept the terms and conditions in order to proceed.",
      email: "Must be a valid email format",
      us_phone: "Must match the format 000-000-0000",
      min: "Minimum {length} characters",
      max: "Maximum {length} characters",
      numeric: "Must only contain numbers",
      date_format: "Must match format mm/dd/yyyy",
    },
  },
  es: {
    messages: {
      required: "Este campo es requerido",
      acceptTerms: "Debes aceptar los términos y condiciones para continuar",
      email: "Debe ser una dirección de correo electrónico válida",
      us_phone: "Debe coincidir con el formato 000-000-0000",
      min: "Mínimo de {length} carácteres",
      max: "Máximo de {length} carácteres",
      numeric: "Debe incluir números",
      date_format: "Debe coincidir con el formato mm/dd/yyyy",
    },
  },
  ["es-US"]: {
    messages: {
      required: "Este campo es requerido",
      acceptTerms: "Debes aceptar los términos y condiciones para continuar",
      email: "Debe tener un formato de correo electrónico válido",
      us_phone: "Debe coincidir con el formato 000-000-0000",
      min: "Mínimo de {length} carácteres",
      max: "Máximo de {length} carácteres",
      numeric: "Debe contener números",
      date_format: "Debe coincidir con el formato mm/dd/yyyy",
    },
  },
});

// Establish validation rule messages that will be used for vee-validate validation throughout the particpant portal
extend("required", { ...required });

extend("acceptTerms", { ...required });

extend("email", { ...email });

extend("min", { ...min });

extend("max", { ...max });

extend("numeric", { ...numeric });

extend("date_format", { ...date_format });

extend("color_hex", { ...color_hex });

extend("us_phone", {
  validate(value) {
    const phoneRegex = /^[0-9]{3}-[0-9]{3}-[0-9]{4}$/;

    return phoneRegex.test(value);
  },
});
