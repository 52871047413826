import Vue from "vue";
import { createHelpers, getField, updateField } from "vuex-map-fields";
import i18n from "@utils/plugins/i18n";

import {
  SET_PREVIEW_PAGE,
  SET_SURVEY_VERSION,
  SET_SURVEY,
  SET_SURVEY_MESSAGES,
  SET_SURVEY_SUBMISSION,
  SET_SURVEY_SUBMISSION_ACCESS_TOKEN,
  SET_SURVEY_MEDIA_CHANNEL,
  SET_SURVEY_PAGE,
  SET_SURVEY_PAGE_ENTRY,
  SET_SURVEY_PAGE_QUESTIONS,
  SNAPSHOT_SURVEY_PAGE_HISTORY,
  POP_SURVEY_PAGE_HISTORY,
  SANITIZE_SURVEY_PAGE_HISTORY,
  SET_SURVEY_TEMP_METRIC_DATA,
  SET_PROGRESS_BAR_MAX,
  RESET_PROGRESS_BAR,
  DECREMENT_PROGRESS_BAR_VALUE,
  INCREMENT_PROGRESS_BAR_VALUE,
} from "@shared/store/mutation-types";
import { resolveWithErrors } from "@utils/http/parseErrors";

const survey = () => {
  return {
    id: null,
    // If dealing with a survey that should be savable (i.e. we aren't in preview mode),
    // we should have the concept of a survey submission as the saved instance of the survey for
    // the current session
    submissionId: null,
    submissionAccessToken: null,
    identity: null,
    isActive: false,
    // When managing a survey, we can be managing it in the scope of a media channel (a governing body
    // for managing surveys). Account for necessary information about a related media channel for it to
    // be populated when applicable
    targetMediaChannelId: null,
    targetMediaChannelIsActive: 0,
    // When a submission id has not been created yet but we are sending metric data, lets temporarily
    // hold that metric data until submission id is retrieved
    tempMetricData: undefined,
    // see if survey has a followup survey if submission wasn't qualified (ex: 406/407 surveys)
    transitions: [],
  };
};

const surveyPage = () => {
  return {
    id: null,
    // Track the parent ID of a survey page since this is a necessary detail to have whenever
    // createSurveyPageEntry needs to be used since we need to ensure an entry for the parent is
    // created before we can create an instance of the current page
    parentId: null,
    identity: null,
    // If dealing with a "saved" page for a survey submission, the saved
    // page should come with an entryId
    entryId: null,
    title: null,
    startTime: null,
  };
};

const {
  getSurveyPageQuestionEntriesField,
  updateSurveyPageQuestionEntriesField,
} = createHelpers({
  getterType: "getSurveyPageQuestionEntriesField",
  mutationType: "updateSurveyPageQuestionEntriesField",
});

const defaultState = () => {
  return {
    isPreview: false,
    contactId: false,
    versionId: null,
    progressBarMax: 0,
    progressBarValue: 0,
    survey: survey(),
    surveyMessages: {},
    surveyPage: surveyPage(),
    surveyPageQuestions: {},
    surveyPageQuestionEntries: {},
    // Save a history of which pages the user has been to during this session. This will be used to provide users the
    // ability to go back to what pages they've already been to for the session (i.e. a back button). This is meant to
    // operate like javascript history state where there is only a concept of where the user has been for the current session.
    // If the session is lost, there will be no concept of where the user has been and they'll be starting fresh
    surveyPageHistory: [],
    // Save a history of the questions asked and answers that have been given for survey pages that have been encountered by the user.
    // Like surveyPageHistory, this data will need to be sanitized to only consist of the pages that the user has encountered in his/her current
    // path. We maintain this data so it can be referenced as the completed survey once the survey submission process has completed.
    // IMPORTANT: If post processing is ever applied to question entry data, the data maintained for history should be recorded from the response data
    // provided when a submission page is saved. This way we can ensure any post processing that may happen on the server to entry data is reflected.
    // This is not done by default since it introduces additional concerns that are unnecessary to account for unless post processing is introduced
    surveyPageHistoryQuestionEntryData: {},
  };
};

export default {
  namespaced: true,
  state: defaultState(),
  getters: {
    getField,
    getSurveyPageQuestionEntriesField,
    progressBarData: (state) => {
      return {
        value: Math.floor(
          (state.progressBarValue / state.progressBarMax) * 100
        ),
      };
    },
    surveyIsPreview(state) {
      return state.isPreview;
    },
    isSurveyAccessible(state, getters, rootState) {
      if (rootState.mediaChannel) {
        // If there is a media channel defined on root state, we expect the current OForm to be related to that media channel, and for the media channel
        // to be active. Otherwise we can't consider the media channel active
        if (
          rootState.mediaChannel !== state.survey.targetMediaChannelId ||
          !state.survey.targetMediaChannelIsActive
        ) {
          return false;
        }
      }
      return state.survey.isActive;
    },
    survey(state) {
      return state.survey;
    },
    surveyPage(state) {
      return state.surveyPage;
    },
    surveyPageQuestions(state) {
      return state.surveyPageQuestions;
    },
    hasSurveySubmission(state) {
      return (
        Object.hasOwn(state.survey, "submissionId") &&
        state.survey.submissionId !== null
      );
    },
    hasSurveyPageEntry(state) {
      return (
        Object.hasOwn(state.surveyPage, "entryId") &&
        state.surveyPage.entryId !== null
      );
    },
    hasSurveyPageHistory(state) {
      return state.surveyPageHistory.length > 0;
    },
  },
  mutations: {
    updateField,
    updateSurveyPageQuestionEntriesField,
    RESET_STATE(state) {
      Object.assign(state, defaultState());
    },
    RESET_PAGE_STATE(state) {
      state.surveyPage = surveyPage();
      state.surveyPageQuestions = {};
      state.surveyPageQuestionEntries = {};
    },
    [SET_PREVIEW_PAGE](state, isPreview) {
      state.isPreview = isPreview;
    },
    [SET_PROGRESS_BAR_MAX](state, max) {
      state.progressBarMax = max;
    },
    [RESET_PROGRESS_BAR](state) {
      state.progressBarValue = 0;
    },
    [DECREMENT_PROGRESS_BAR_VALUE](state) {
      state.progressBarValue--;
    },
    [INCREMENT_PROGRESS_BAR_VALUE](state) {
      state.progressBarValue++;
    },
    [SET_SURVEY_VERSION](state, oFormVersionId) {
      state.versionId = oFormVersionId;
    },

    [SET_SURVEY](state, survey) {
      state.survey = {
        id: survey.id,
        surveyStartTime: 0,
        identity: survey.attributes.identity,
        isActive: survey.attributes.isActive,
        transitions: survey.attributes.transitions ?? [],
      };
    },
    [SET_SURVEY_MESSAGES](state, { languageTargets = [], messages = [] } = {}) {
      const messageTypes = [
        "completed_contact_form",
        "no_trial_sites_consent",
        "no_trial_sites_contact_form",
        "not_active_consent",
        "not_active_contact_form",
        "not_qualified_consent",
        "not_qualified_contact_form",
        "trial_site_selected_consent",
        "trial_site_selected_contact_form",
      ];
      const messageMap = new Map();
      const interpretedMessages = {};

      for (const message of messages) {
        messageMap.set(
          message.attributes.messageLanguage +
            "_" +
            message.relationships.oFormMessageType.data.id,
          {
            message: message.attributes.message,
            messageLanguage: message.attributes.messageLanguage,
            oFormMessageTypeId: message.relationships.oFormMessageType.data.id,
            id: message.id,
          }
        );
      }

      for (const languageTarget of languageTargets) {
        for (const messageType of messageTypes) {
          const mapIdentifier = languageTarget.value + "_" + messageType;

          interpretedMessages[messageType] = Object.assign(
            interpretedMessages[messageType] ?? {},
            {
              [languageTarget.value]: messageMap.has(mapIdentifier)
                ? messageMap.get(mapIdentifier).message
                : "",
            }
          );
        }
      }

      state.surveyMessages = interpretedMessages;
    },
    // Update survey details to override survey submission based details with the given survey submission
    [SET_SURVEY_SUBMISSION](state, surveySubmission) {
      const survey = state.survey;

      survey.submissionId = surveySubmission.id;

      survey.surveyStartTime = Date.now();

      state.survey = { ...survey };
    },
    // Update survey details to override related media channel based details with the given media channel
    [SET_SURVEY_MEDIA_CHANNEL](state, mediaChannel) {
      const survey = state.survey;

      survey.targetMediaChannelId = mediaChannel.id;
      survey.targetMediaChannelIsActive = mediaChannel.attributes.isActive;

      state.survey = { ...survey };
    },
    // Update survey details to override the stored access token for being able to manage the current in scope submission
    [SET_SURVEY_SUBMISSION_ACCESS_TOKEN](state, submissionAccessToken) {
      const survey = state.survey;

      survey.submissionAccessToken = submissionAccessToken;

      state.survey = { ...survey };
    },
    [SET_SURVEY_PAGE](state, surveyPage) {
      state.surveyPage = {
        id: surveyPage.id,
        parentId: surveyPage.relationships.parentOFormContainer.data.id,
        identity: surveyPage.attributes.identity,
        title: surveyPage.attributes.title,
        startTime: Date.now(),
      };
    },
    // Update survey page details to override survey page entry based details with the given
    // survey page entry
    [SET_SURVEY_PAGE_ENTRY](state, surveyPageEntry) {
      const surveyPage = state.surveyPage;

      surveyPage.entryId = surveyPageEntry.id;

      state.surveyPage = { ...surveyPage };
    },
    [SET_SURVEY_PAGE_QUESTIONS](
      state,
      { surveyPageQuestions = [], surveyPageQuestionEntries = [] } = {}
    ) {
      const newSurveyPageQuestions = {};
      const newSurveyPageQuestionEntries = {};

      // For ease and speediness of lookup for question entries, establish a map where each entry is key'd by its corresponding
      // question/field (there can only be one entry per question/field per page).
      const questionEntriesMap = new Map();

      for (const surveyPageQuestionEntry of surveyPageQuestionEntries) {
        questionEntriesMap.set(
          surveyPageQuestionEntry.relationships.oFormField.data.id,
          surveyPageQuestionEntry
        );
      }

      for (const surveyPageQuestion of surveyPageQuestions) {
        // For each given question, as long as a "type" of question can be resolved, and
        // the type isn't placeholder (we don't want to allow placeholder types since they can't
        // actually be answered/completed for a submission, so we don't even want to consider them)
        if (
          surveyPageQuestion.relationships &&
          surveyPageQuestion.relationships.oFormFieldType &&
          surveyPageQuestion.relationships.oFormFieldType.data.id &&
          surveyPageQuestion.relationships.oFormFieldType.data.id !==
            "placeholder"
        ) {
          // Index based on survey page question identity since we have to use this identifier to represent a field on the form to be compatible
          // with the API
          const surveyPageQuestionIdentity =
            surveyPageQuestion.attributes.identity;

          newSurveyPageQuestions[surveyPageQuestionIdentity] = {
            id: surveyPageQuestion.id,
            ...surveyPageQuestion.attributes,
          };

          newSurveyPageQuestions[surveyPageQuestionIdentity].oFormFieldTypeId =
            surveyPageQuestion.relationships.oFormFieldType.data.id;

          // Ensure that, if no configuration was given, that the configuration defaults to an empty
          // object. This will ensure that all question configurations will be treated as an object when
          // rendering questions.
          if (
            !Object.hasOwn(
              newSurveyPageQuestions[surveyPageQuestionIdentity],
              "configuration"
            ) ||
            newSurveyPageQuestions[surveyPageQuestionIdentity].configuration ===
              null
          ) {
            newSurveyPageQuestions[surveyPageQuestionIdentity].configuration =
              {};
          }

          newSurveyPageQuestions[surveyPageQuestionIdentity].startTime =
            Date.now();
          // Review for a potentially already saved value for the question based on the entry representation of questions for the current page.
          // If one isn't found, simply indicate null until a value is eventually provided
          newSurveyPageQuestionEntries[surveyPageQuestionIdentity] =
            questionEntriesMap.has(surveyPageQuestion.id)
              ? questionEntriesMap.get(surveyPageQuestion.id).attributes
                  .fieldValue
              : null;
        }
      }

      state.surveyPageQuestions = newSurveyPageQuestions;
      state.surveyPageQuestionEntries = newSurveyPageQuestionEntries;
    },
    // For the current survey page and current question/entry data, record details about
    // the history of these items. This should be utilized before directing to a different survey page.
    [SNAPSHOT_SURVEY_PAGE_HISTORY](state) {
      state.surveyPageHistory = state.surveyPageHistory.concat([
        { ...state.surveyPage },
      ]);

      const surveyPageHistoryQuestionEntryData = [];

      for (const [key, question] of Object.entries(state.surveyPageQuestions)) {
        surveyPageHistoryQuestionEntryData.push({
          question_id: key,
          question_text: question.title,
          question_answer: state.surveyPageQuestionEntries[key] ?? null,
        });
      }

      state.surveyPageHistoryQuestionEntryData = Object.assign(
        {},
        state.surveyPageHistoryQuestionEntryData,
        { [state.surveyPage.identity]: surveyPageHistoryQuestionEntryData }
      );
    },
    // Remove the last survey page from survey page history. This is useful for the concept of going "back"
    // to a previous survey page as the last page that was added will be the one a user goes "back" to, and
    // it should no longer be in history as a result
    [POP_SURVEY_PAGE_HISTORY](state) {
      const targetPageHistoryIndex = state.surveyPageHistory.length - 1;

      // If going back, we also need remove the saved history question data for the history item we are popping from
      // history
      if (
        Object.hasOwn(
          state.surveyPageHistoryQuestionEntryData,
          state.surveyPageHistory[targetPageHistoryIndex].identity
        )
      ) {
        Vue.delete(
          state.surveyPageHistoryQuestionEntryData,
          state.surveyPageHistory[targetPageHistoryIndex].identity
        );
      }

      state.surveyPageHistory.splice(targetPageHistoryIndex, 1);
    },
    // Ensure that the current state of page history can be considered "valid"
    [SANITIZE_SURVEY_PAGE_HISTORY](state) {
      if (state.surveyPage.identity !== null) {
        // We want to ensure that if a survey directs a user to a current survey page that has already existed in
        // the history of survey pages, that we clear out all pages that are "future" of that page. We don't want to
        // persist data that would indicate a user reached a page that we can't guarantee that he/she actually made it to.
        const currentPageIndex = state.surveyPageHistory.findIndex(
          (element) => element.identity == state.surveyPage.identity
        );

        if (currentPageIndex !== -1) {
          // If the current page exists in history, we want to consider all page elements that are indexed before that page to be the only ones still in history.
          // We don't keep the current page in history, so that should be removed, and all "future" pages that came after it aren't guaranteed to be in the current
          // path anymore, so we want to consider them irrelevant until a user makes them relevant again

          // Identify all pages that will be removed by slicing history, and remove their related history question entry data
          for (
            let pageIndex = currentPageIndex;
            pageIndex < state.surveyPageHistory.length;
            pageIndex++
          ) {
            if (
              Object.hasOwn(
                state.surveyPageHistoryQuestionEntryData,
                state.surveyPageHistory[pageIndex].identity
              )
            ) {
              Vue.delete(
                state.surveyPageHistoryQuestionEntryData,
                state.surveyPageHistory[pageIndex].identity
              );
            }
          }

          state.surveyPageHistory = state.surveyPageHistory.slice(
            0,
            currentPageIndex
          );
        }
      } else {
        // If sanitizing history, and there is no valid way to consider the current page, purge all history since we can't determine
        // if saved data should be considered valid or not, and we want to err on the side of not keeping data if we can't guarantee it should
        // be there. This shouldn't happen, and this is just a logical precaution
        state.surveyPageHistory = [];
      }
    },
    [SET_SURVEY_TEMP_METRIC_DATA](state, tempMetricData) {
      state.survey.tempMetricData = tempMetricData;
    },
  },
  actions: {
    decrementProgressBar({ commit }) {
      commit("DECREMENT_PROGRESS_BAR_VALUE");
    },
    incrementProgressBar({ commit }) {
      commit("INCREMENT_PROGRESS_BAR_VALUE");
    },
    refreshState({ commit }) {
      commit("RESET_STATE");
    },
    refreshSurveyPageState({ commit }) {
      commit("RESET_PAGE_STATE");
    },
    togglePreviewPage({ commit }, isPreview = false) {
      commit(SET_PREVIEW_PAGE, Boolean(isPreview));
    },
    getSurveyByIdentity(
      { commit, rootState },
      {
        identity = null,
        // NOTE: while we allow for toggling approvedOnly, this is only effective when managing a survey submission's survey outside
        // of public scope. The expected API behavior is that public scope will always be restricted to loading approved surveys
        approvedOnly = 1,
      } = {}
    ) {
      if (identity === null) {
        return Promise.resolve([false]);
      }

      const url = "/api/o-forms/o-forms";

      const params = { identity, approvedOnly };

      if (rootState.mediaChannel) {
        // If the current application scope has a media channel defined, this means the scope of the application
        // must be specific to the media channel. Identify which media channel the survey is related to so that decisions
        // on survey provision can be made based on this relationship
        params.include = "IdentityMediaChannels,OFormContainers";
        params.media_channel_id = rootState.mediaChannel;
      }

      return Vue.axios
        .get(url, { params })
        .then((response) => {
          const isSuccess = response.status === 200;

          if (isSuccess) {
            // We have to query for surveys from the index method since we need to find results based on "identity",
            // but only a singular record should be returned (e.g. the current version of the form with the given identity)
            // we'll want to set focus to only deal with a singular identity
            const survey =
              response.data.data.length > 0 ? response.data.data[0] : null;

            if (survey === null) {
              // Even if we have a successful response, we will consider getting the survey
              // a failure if a survey cannot be derived
              return Promise.resolve([false]);
            }
            commit(
              SET_SURVEY_VERSION,
              survey !== null ? survey.relationships.oFormVersion.data.id : null
            );
            commit(SET_SURVEY, survey !== null ? survey : survey());
            commit(SET_PROGRESS_BAR_MAX, survey.attributes.containerCount);

            //lets reset progress bar as it could be possible user transition from one survey to another
            commit(RESET_PROGRESS_BAR);
          }

          if (Object.hasOwn(response.data, "included")) {
            for (const included of response.data.included) {
              // Since we are filtering by mediaChannel ID, we can expect at most one media channel to be provided
              // in the include
              if (included.type === "oFormIdentityMediaChannels") {
                commit(SET_SURVEY_MEDIA_CHANNEL, included);
              }
            }
          }

          return Promise.resolve([isSuccess]);
        })
        .catch(() => {
          return Promise.resolve([false]);
        });
    },
    // lets get the media channel from the transitioned 'to' survey
    // that has the same domain as the 'from' survey
    getMediaChannelForTransitionSurvey(
      { commit },
      { toOFormIdentity, fromMediaChannelId }
    ) {
      return Vue.axios
        .get(
          "/api/get-media-channel-for-transition-survey/:to_o_form_identity".replace(
            ":to_o_form_identity",
            toOFormIdentity
          ) +
            "/:from_media_channel_id".replace(
              ":from_media_channel_id",
              fromMediaChannelId
            )
        )
        .then((response) => {
          commit(SET_SURVEY_MEDIA_CHANNEL, response.data);

          return Promise.resolve([true]);
        })
        .catch(() => {
          Promise.resolve([false]);
        });
    },
    getMessages({ state, commit, rootGetters }) {
      if (!state.survey.id) {
        // If a survey hasn't been loaded yet, we can't get messages for the survey
        return Promise.resolve([false]);
      }

      return Vue.axios
        .get("/api/o-forms/o-form-messages", {
          params: {
            o_form_identity: state.survey.identity,
            o_form_version_id: state.versionId,
          },
        })
        .then((response) => {
          commit(SET_SURVEY_MESSAGES, {
            languageTargets: rootGetters["systemLanguages/languageTargets"],
            messages: response.data.data,
          });

          return Promise.resolve([true]);
        })
        .catch(() => {
          Promise.resolve([false]);
        });
    },
    // For the current contextual survey, create a submission record for it
    async createSurveySubmission({
      commit,
      state,
      rootState,
      getters,
      dispatch,
    }) {
      if (!state.survey.id) {
        // If a survey isn't currently loaded, we can't create a submission for it
        return Promise.resolve([false]);
      }

      if (getters.hasSurveySubmission) {
        // If a survey submission is already loaded, we don't want to create a new one. Consider
        // already having a survey meaning that one has successfully been created so that already existing
        // survey can just be used
        return Promise.resolve([true]);
      }

      //get all languages from system_langugages module
      await dispatch(
        "systemLanguages/getLanguages",
        {},
        {
          root: true,
        }
      );
      //get language list from system_languages store module
      let languages = rootState.systemLanguages.languages;
      let language_id = null;
      let default_language_id = null;

      // search in languages to see if i18n.locale matches one in our language list
      languages.forEach(function (language) {
        if (language.iso_code === i18n.locale) {
          language_id = language.id;
        }
        //set default language id in case one is not found
        if (language.iso_code === "en") {
          default_language_id = language.id;
        }
      });

      //if language id not found, use default language id
      if (language_id === null) {
        language_id = default_language_id;
      }

      const url = "/api/o-forms/o-form-submissions";

      return Vue.axios
        .post(url, {
          data: {
            type: "oFormSubmission",
            attributes: {
              o_form_id: state.survey.id,
              o_form_submission_status_id: "in_progress",
              media_channel_id: state.survey.targetMediaChannelId
                ? state.survey.targetMediaChannelId
                : rootState.mediaChannel,
              language_id: language_id,
              // If the root state has a concept of a media channel URL (meaning the current app scope can identify the URL
              // the submission is being completed for), add the media channel URL to the submission
              media_channel_url:
                rootState.mediaChannelUrl &&
                rootState.mediaChannelUrl.length > 0
                  ? rootState.mediaChannelUrl
                  : null,
            },
          },
        })
        .then((response) => {
          // When a record is successfully created, a 201 response is given
          const isSuccess = response.status === 201;

          if (isSuccess) {
            if (!state.contactId) {
              dispatch("addContactForId", "", { root: true });
            }
            commit(SET_SURVEY_SUBMISSION, response.data.data);
            commit(
              SET_SURVEY_SUBMISSION_ACCESS_TOKEN,
              response.headers["survey-submission-token"] ?? null
            );
          }

          return Promise.resolve([isSuccess]);
        });
    },

    // Determine if the current contextual survey submission is considered "qualified" based on all data that
    // is currently saved for the survey on the server
    getSurveySubmissionQualified({ state, getters }) {
      if (getters.surveyIsPreview) {
        // If the survey is in preview mode, there is no data that will have been saved (i.e. no submission) to
        // determine if a user qualified. Always return that the user qualified in its stead
        return Promise.resolve([true, [], { isQualified: true }]);
      }

      if (!state.survey.submissionId) {
        return Promise.resolve([false]);
      }

      return Vue.axios
        .get(
          "/api/o-forms/o-form-submission-qualification/:id".replace(
            ":id",
            state.survey.submissionId
          )
        )
        .then((response) => {
          const isSuccess = response.status === 200;

          if (isSuccess && Object.hasOwn(response.data, "meta")) {
            // When saving a survey page for the submission, if the page is successfully saved, a next container target
            // will be provided within meta data. The inclusion of meta data thus indicates a successful save of data
            if (Object.hasOwn(response.data.meta, "isQualified")) {
              return Promise.resolve([
                true,
                [],
                {
                  isQualified: Boolean(response.data.meta.isQualified),
                },
              ]);
            }
          }

          return Promise.resolve([false]);
        });
    },
    snapshotSurveyPageHistory({ commit }) {
      commit(SNAPSHOT_SURVEY_PAGE_HISTORY);
    },
    getSurveyPage(
      { state, commit, dispatch },
      {
        // Provide the identity of which page container should be loaded next. If one isn't included, the "first" container
        // for the current survey will be loaded
        targetOFormContainerIdentity = null,
        targetOFormContainerEntryId = null,
      } = {}
    ) {
      // Before loading a new survey page, clear all saved survey page state data. If the user is currently on a page,
      // store the details of that page in history first
      if (state.surveyPage.identity !== null) {
        dispatch("snapshotSurveyPageHistory");
      }

      dispatch("refreshSurveyPageState");
      if (state.survey.identity && state.versionId) {
        const containerParams = {
          o_form_identity: state.survey.identity,
          o_form_version_id: state.versionId,
        };

        const promises = [];

        if (targetOFormContainerIdentity !== null) {
          // If we'd like to get a specific survey page (based on identity and o_form_version_id combination),
          // request a survey page based on it
          containerParams.identity = targetOFormContainerIdentity;
        } else {
          // If no specific target identity is provided, get the first container for the given o_form_identity
          containerParams.type = "firstContainerPerForm";
        }

        promises.push(
          Vue.axios.get(
            "/api/o-forms/o-form-containers?include=OFormFields,ParentOFormContainers",
            {
              params: containerParams,
            }
          )
        );

        if (targetOFormContainerEntryId) {
          // If we expect to target an already saved container entry, get the container entry and all saved field entries that are related
          // to it. This is necessary to be able to represent already saved data

          // IMPORTANT: if we are meant to get container entry data, but we can't because there isn't a stored submission access token,
          // reject getting a sruvey page
          if (!state.survey.submissionAccessToken) {
            return Promise.reject(
              new Error(
                "Cannot get page entry data without a submission access token"
              )
            );
          }

          promises.push(
            Vue.axios.get(
              "/api/o-forms/o-form-container-entries/:id?include=OFormFieldEntries".replace(
                ":id",
                targetOFormContainerEntryId
              ),
              {
                headers: {
                  "Survey-Submission-Token": state.survey.submissionAccessToken,
                },
              }
            )
          );
        }

        return Promise.all(promises).then((responses) => {
          const containerResponse = responses.shift();
          // If a request was made to get a container entry because one is expected to exist, identify the response for that request
          const containerEntryResponse =
            responses.length > 0 ? responses.shift() : null;

          // We have to query for survey pages from the index method since we need to find results based on "identity",
          // but only a singular record should be returned (e.g. the current version of the container with the given identity)
          // we'll want to set focus to only deal with a singular identity
          const surveyPage =
            containerResponse.data.data.length > 0
              ? containerResponse.data.data[0]
              : null;
          const surveyPageIncluded = Object.hasOwn(
            containerResponse.data,
            "included"
          )
            ? containerResponse.data.included
            : null;
          const surveyPageEntry =
            containerEntryResponse !== null
              ? containerEntryResponse.data.data
              : null;
          const surveyPageEntryIncluded =
            containerEntryResponse !== null &&
            Object.hasOwn(containerEntryResponse.data, "included")
              ? containerEntryResponse.data.included
              : null;

          commit(SET_SURVEY_PAGE, surveyPage);

          if (surveyPageEntry !== null) {
            commit(SET_SURVEY_PAGE_ENTRY, surveyPageEntry);
          }

          const surveyPageQuestions = [];
          const surveyPageQuestionEntries = [];

          if (surveyPageIncluded !== null && surveyPageIncluded.length > 0) {
            for (const included of surveyPageIncluded) {
              if (included.type === "oFormFields") {
                surveyPageQuestions.push(included);
              }
            }
          }

          if (
            surveyPageEntryIncluded !== null &&
            surveyPageEntryIncluded.length > 0
          ) {
            for (const included of surveyPageEntryIncluded) {
              if (included.type === "oFormFieldEntries") {
                surveyPageQuestionEntries.push(included);
              }
            }
          }

          commit(SET_SURVEY_PAGE_QUESTIONS, {
            surveyPageQuestions,
            surveyPageQuestionEntries,
          });

          return Promise.resolve([true]);
        });
      }

      return Promise.resolve([false]);
    },

    /*
    When managing the survey experience where a user goes between multiple pages, it is possible for him/her to
    experience a combination of going back to previous pages and answering questions differently where their selections
    direct them to a different set of future pages than he/she was originally on. This will mean that page history and data
    saved to the API for pages no longer in scope will exist unless something is done. This method allows for sanitizing current
    page transition state based on the current page that is focused
    */
    sanitizeSurveyPageHistory({ commit, getters, state }) {
      // When a user utilizes something like getPreviousSurveyPage to go to a previous page, the page history item the user is currently on will be
      // popped from history. While this can satisfy almost all sanitization needed of local page history representation, there are still cases where a user
      // can be directed to a page he/she has already been to via page target transition logic. In these cases, it has been decided that if a user is transitioned
      // to a page he/she has already been to, we treat that as going "back" too and all future pages should be cleared out.
      // E.g. if a user made it to a tenth page he/she will work on, and that page directs him/her back to the first page he/she worked on, pages two through ten should
      // be considered invalid and need to be sanitized out
      commit(SANITIZE_SURVEY_PAGE_HISTORY);

      if (!getters.surveyIsPreview && state.survey.submissionId) {
        const attributes = { pageIds: [] };

        // If there is currently history stored, utilize the identities for these history items to indicate what data we'll want to preserve
        if (state.surveyPageHistory.length > 0) {
          attributes.pageIds = [];
          for (const history of state.surveyPageHistory) {
            attributes.pageIds.push(history.entryId);
          }
        }

        // The current page won't exist in history (since it is still the current page), but it should be perceived as in history from a state perspective so we include it
        // in the sanitization check
        attributes.pageIds.push(state.surveyPage.entryId);

        // Once page history is successfully managed, if working in non preview mode (meaning there should be a current submission), instruct the API to clear out any saved data for the current
        // submission that isn't based on the pages we desire to keep in scope
        return Vue.axios
          .patch(
            "/api/o-forms/o-form-submission-sanitization/:id".replace(
              ":id",
              state.survey.submissionId
            ),
            {
              data: {
                type: "oFormSubmissionSanitization",
                attributes,
              },
            }
          )
          .then((response) => {
            const isSuccess = response.status === 200;

            if (isSuccess) {
              return Promise.resolve([
                Object.hasOwn(response.data.meta, "isSanitizeSuccess") &&
                  response.data.meta.isSanitizeSuccess,
              ]);
            }

            return Promise.resolve([false]);
          });
      }

      return Promise.resolve([true]);
    },

    // Create an entry for a survey page (and whatever parent container contains it) based on what survey page is currently in state.
    // IMPORTANT: this scope doesn't check if entries already exist, so, if it is possible that entries could already exist in the scope
    // this action is invoked, such records should be checked for before invoking this action. It is best to avoid creating duplicate page
    // entries (and their parents) for a submission because that can lead to bad data
    async createSurveyPageEntry({ state, getters, commit }) {
      if (!state.survey.submissionAccessToken) {
        return Promise.reject(
          new Error(
            "Cannot create a page entry without a submission access token"
          )
        );
      }

      if (
        !state.surveyPage.id ||
        !state.surveyPage.parentId ||
        !getters.hasSurveySubmission
      ) {
        // Can't create a survey page entry for the current survey page (and its parent) if there isn't a current page with a parent.
        // Also, can't create a survey page entry if there isn't a submission that the entries would be based on
        return Promise.resolve([false]);
      }
      if (getters.hasSurveyPageEntry) {
        // If there is already a set entry ID, consider this successful
        return Promise.resolve([true]);
      }

      // Create the expected parent entry
      const parentEntryId = await Vue.axios
        .post(
          "/api/o-forms/o-form-container-entries",
          {
            data: {
              type: "oFormContainerEntry",
              attributes: {
                o_form_container_id: state.surveyPage.parentId,
                o_form_submission_id: state.survey.submissionId,
              },
            },
          },
          {
            headers: {
              "Survey-Submission-Token": state.survey.submissionAccessToken,
            },
          }
        )
        .then((response) => {
          const isSuccess = response.status === 201;

          if (isSuccess) {
            return Promise.resolve(response.data.data.id);
          }

          return Promise.resolve(false);
        });

      if (parentEntryId !== false) {
        // If a parent entry was successfully created, create the actual page
        // entry for that parent
        const entry = await Vue.axios
          .post(
            "/api/o-forms/o-form-container-entries",
            {
              data: {
                type: "oFormContainerEntry",
                attributes: {
                  o_form_container_id: state.surveyPage.id,
                  parent_id: parentEntryId,
                  o_form_submission_id: state.survey.submissionId,
                },
              },
            },
            {
              headers: {
                "Survey-Submission-Token": state.survey.submissionAccessToken,
              },
            }
          )
          .then((response) => {
            const isSuccess = response.status === 201;

            if (isSuccess) {
              return Promise.resolve(response.data.data);
            }

            return Promise.resolve(false);
          });

        // TODO need to rollback the parentEntryId if entryId is false
        if (entry !== false) {
          commit(SET_SURVEY_PAGE_ENTRY, entry);

          // If a page entry was successfully saved based on the parent, we can consider the action successful
          return Promise.resolve([true]);
        }
      }

      // If a page entry was not successfully saved based on the parent, the action was not successful
      return Promise.resolve([false]);
    },

    getPreviousSurveyPage({ commit, state, dispatch }) {
      if (state.surveyPageHistory.length > 0) {
        // Identify what the previous page was in history and then remove it from history
        const previousPage =
          state.surveyPageHistory[state.surveyPageHistory.length - 1];
        commit(POP_SURVEY_PAGE_HISTORY);
        // Refresh survey page state to remove any current considered page from being focused. This MUST be done
        // to ensure any current considered page isn't itself added to history when transitioning to a different page
        dispatch("refreshSurveyPageState");

        // Transition to the previous page
        return dispatch("getSurveyPage", {
          targetOFormContainerIdentity: previousPage.identity,
          targetOFormContainerEntryId: previousPage.entryId ?? null,
        });
      }

      return Promise.resolve([false]);
    },

    editSurveyPageSubmission({ state, getters, commit, dispatch }) {
      let url;
      const requestData = {};
      const requestConfig = {};

      if (getters.surveyIsPreview) {
        // If saving a page preview, we don't have a survey submission that we are working on. We want to base operations off of the
        // container as a result
        url = "/api/o-forms/o-form-submission-page-preview/:pageId".replace(
          ":pageId",
          state.surveyPage.id
        );

        requestData.type = "oFormSubmissionPagePreview";
        requestData.id = state.surveyPage.id;
      } else {
        // If dealing with saving an actual survey submission (i.e. not in preview mode)
        // ensure a submission access token can be given to save the data
        if (!state.survey.submissionAccessToken) {
          return Promise.reject(
            new Error(
              "Cannot get page entry data without a submission access token"
            )
          );
        }
        dispatch("sendPageMetrics");

        // If saving a page, we have a survey submission that we are working on, so we want to base operations on a saved version of the container
        // as a result. A saved version of the container should always be created ahead of time since we want to know if the user reached that page and left
        // without saving, so we'll always have an entry ID to work with.
        url = "/api/o-forms/o-form-submission-page/:pageId".replace(
          ":pageId",
          state.surveyPage.entryId
        );

        requestData.type = "oFormSubmissionPage";
        requestData.id = state.surveyPage.entryId;

        requestConfig.headers = Object.assign({}, requestConfig.headers ?? {}, {
          "Survey-Submission-Token": state.survey.submissionAccessToken,
        });
      }

      requestData.attributes = state.surveyPageQuestionEntries;

      return Vue.axios
        .patch(
          url,
          {
            data: requestData,
          },
          requestConfig
        )
        .then((response) => {
          if (Object.hasOwn(response.headers, "survey-submission-token")) {
            // If a survey-submission-token was provided, override the one currently stored with the new one.
            // This will extend the amount of time the user has to complete the survey everytime they interact with it
            commit(
              SET_SURVEY_SUBMISSION_ACCESS_TOKEN,
              response.headers["survey-submission-token"]
            );
          }
          if (Object.hasOwn(response.data, "meta")) {
            // When saving a survey page for the submission, if the page is successfully saved, a next container target
            // will be provided within meta data. The inclusion of meta data thus indicates a successful save of data
            if (Object.hasOwn(response.data.meta, "nextContainerIdentity")) {
              // When in a non preview mode:
              // If a next container is identified, it should come with an already saved entry instance for the container.
              // If one wasn't provided, this system is not equiped to attempt to create one so we shouldn't even try to load the page
              if (
                !getters.surveyIsPreview &&
                response.data.meta.nextContainerIdentity &&
                (!Object.hasOwn(response.data.meta, "nextContainerEntryId") ||
                  !response.data.meta.nextContainerEntryId)
              ) {
                return Promise.resolve([false]);
              }

              return Promise.resolve([
                true,
                [],
                {
                  targetOFormContainerIdentity:
                    response.data.meta.nextContainerIdentity,
                  targetOFormContainerEntryId:
                    response.data.meta.nextContainerEntryId ?? null,
                },
              ]);
            }
          } else if (Object.hasOwn(response.data, "errors")) {
            return resolveWithErrors(response.data.errors);
          }

          return Promise.resolve([false]);
        });
    },
    updateSurveyTempMetricData({ commit }, tempMetricData) {
      commit(SET_SURVEY_TEMP_METRIC_DATA, tempMetricData);
    },
    sendPageMetrics({ state, dispatch }) {
      // ensure a submission access token can be given to send page metric data with a submission id
      if (!state.survey.submissionAccessToken) {
        return Promise.reject(
          new Error("Cannot send page metrics without a submission id")
        );
      }

      let metricData = {
        start: state.surveyPage.startTime,
        stop: Date.now(),
        type: "page",
        identity: state.surveyPage.identity,
        o_form_submission_id: state.survey.submissionId,
      };

      dispatch("addMetric", metricData, { root: true });
    },
  },
};
