<template>
  <v-container>
    <v-card color="grey lighten-3">
      <router-view></router-view>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "GenericLayoutWithCard",
  mounted() {
    this.$root.$on("toggleSectionBody", (data) => {
      if (data.showValue) {
        data.target.classList.remove("d-none");
      } else {
        data.target.classList.add("d-none");
      }
    });
  },
};
</script>

<style scoped></style>
