<template>
  <v-btn
    type="submit"
    color="blue"
    :loading="isSubmitting"
    class="mx-1"
    @click="submit"
  >
    Save
    <v-icon aria-label="Save Icon" class="ml-1">mdi-floppy</v-icon>
  </v-btn>
</template>

<script>
export default {
  name: "SaveButton",
  data() {
    return {
      isSubmitting: false,
    };
  },
  methods: {
    async submit() {
      this.isSubmitting = true;
      await this.attributes.onSubmit().then(() => {
        this.isSubmitting = false;
      });
    },
  },
  props: {
    /**
     * Attributes for the button
     * onSubmit: {
     *   type: function,
     *   required: true,
     *}
     */
    attributes: {},
  },
};
</script>

<style scoped>
.v-btn {
  background-color: #146e98 !important;
}
</style>
