import {
  CLEAR_SPONSOR,
  REMOVE_SPONSOR,
  SET_ERROR,
  SET_SPONSORS_COUNT,
  SET_SPONSOR,
  SET_SPONSORS,
  SET_SPONSORS_LIST,
  SET_INCLUDED,
} from "@admin/store/mutation-types";
import { getField, updateField } from "vuex-map-fields";
import Vue from "vue";
import { getBelongsToManyByType } from "@shared/util/Relationships";
const emptySponsor = function () {
  return {
    id: "",
    name: "",
    identification: "",
    rms_sponsor_identifier: "",
    description: "",
    is_active: true,
    business_practice: "",
    country: "",
    country_name: "",
  };
};

export default {
  namespaced: true,
  state: {
    engagementsCount: 0,
    error: "",
    included: [],
    sponsor: emptySponsor(),
    sponsorRelationships: {},
    sponsors: [],
    sponsorsList: [],
    sponsorsCount: 0,
  },
  getters: {
    getField,
    sponsorEngagements(state) {
      if (state.sponsorRelationships === undefined) {
        return [];
      }
      const engagements = getBelongsToManyByType(
        state.sponsorRelationships,
        "engagements",
        state.included
      );
      state.engagementsCount = engagements.length;
      return engagements;
    },
  },
  mutations: {
    updateField,
    [CLEAR_SPONSOR](state) {
      state.sponsor = emptySponsor();
    },
    [SET_SPONSORS_COUNT](state, meta) {
      state.sponsorsCount = meta.record_count;
    },
    [SET_SPONSOR](state, sponsor) {
      Object.assign(state.sponsor, sponsor.attributes);
      state.sponsor.business_practice = sponsor.attributes.businessPractice;
      state.sponsor.country = sponsor.relationships.country.data.id;
      state.sponsor.country_name = sponsor.attributes.countryName;
      state.sponsor.is_active = sponsor.attributes.isActive;
      state.sponsor.rms_sponsor_identifier =
        sponsor.attributes.rmsSponsorIdentifier;
      state.sponsorRelationships = sponsor.relationships;
    },
    [SET_SPONSORS](state, sponsors) {
      state.sponsors = sponsors;
    },
    [SET_SPONSORS_LIST](state, sponsors) {
      // This is used for select elements in forms.
      state.sponsorsList = [];
      sponsors.forEach(function (sponsor) {
        state.sponsorsList.push({
          value: sponsor.id,
          text: sponsor.attributes.name,
        });
      });
    },
    [SET_INCLUDED](state, included) {
      state.included = included;
    },
    [SET_ERROR](state, errors) {
      if (Array.isArray(errors)) {
        errors.forEach(function (error) {
          state.error = state.error + error.detail;
        });
      } else {
        state.error = errors;
      }
    },
    [REMOVE_SPONSOR](state, id) {
      const index = state.sponsors.findIndex((sponsor) => sponsor.id === id);
      Vue.delete(state.sponsors, index);
    },
  },
  actions: {
    addSponsor({ commit, state }) {
      commit(SET_ERROR, "");
      let data = {
        data: {
          type: "sponsors",
          attributes: {
            name: state.sponsor.name,
            identification: state.sponsor.identification,
            rms_sponsor_identifier: state.sponsor.rms_sponsor_identifier,
            description: state.sponsor.description,
            business_practice: state.sponsor.business_practice,
            country_id: state.sponsor.country,
            is_active: state.sponsor.is_active,
          },
        },
      };
      return Vue.axios
        .post("/api/sponsors", data)
        .then(({ data }) => {
          if (data.data.id) {
            commit(SET_SPONSOR, data.data);
          }
          if (data.errors) {
            commit(SET_ERROR, data.errors);
            return false;
          }
          return data.data.id;
        })
        .catch(({ response }) => {
          if (response.data.errors) {
            commit(SET_ERROR, response.data.errors);
          }
          return false;
        });
    },
    clearSponsor({ commit }) {
      commit(CLEAR_SPONSOR);
      commit(SET_INCLUDED, []);
    },
    clearSponsors({ commit }) {
      commit(SET_SPONSORS, []);
      commit(SET_INCLUDED, []);
    },
    deleteSponsor({ commit }, id) {
      return Vue.axios
        .delete("/api/sponsors/:id".replace(":id", id))
        .then((response) => {
          // A delete request will return a 204 on success from the API
          const success = response.status === 204;

          if (success) {
            commit(REMOVE_SPONSOR, id);
          }

          return Promise.resolve([success]);
        });
    },
    editSponsor({ commit, state }, id) {
      commit(SET_ERROR, "");
      let data = {
        data: {
          id: id,
          type: "sponsors",
          attributes: {
            name: state.sponsor.name,
            identification: state.sponsor.identification,
            rms_sponsor_identifier: state.sponsor.rms_sponsor_identifier,
            description: state.sponsor.description,
            business_practice: state.sponsor.business_practice,
            country: state.sponsor.country,
            is_active: state.sponsor.is_active,
          },
        },
      };
      return Vue.axios
        .patch("/api/sponsors/:id".replace(":id", id), data)
        .then(({ data }) => {
          if (data.errors) {
            commit(SET_ERROR, data.errors);
            return false;
          }
          return id;
        })
        .catch(({ response }) => {
          if (response.data.errors) {
            commit(SET_ERROR, response.data.errors);
          }
          return false;
        });
    },
    getSponsor({ commit }, id) {
      commit(CLEAR_SPONSOR);
      commit(SET_INCLUDED, []);
      commit(SET_ERROR, "");
      return Vue.axios
        .get(`/api/sponsors/${id}`, {
          params: {
            include: [
              "country",
              "engagements",
              "engagements.therapeuticAreas",
              "engagements.indications",
              "engagements.targetGroups",
              "engagements.campaignTypes",
              "engagements.sponsors",
            ].join(","),
            "fields[country]": "id,name",
            "fields[targetGroups]": "id,name",
            "fields[campaignTypes]": "id,name",
          },
        })
        .then(({ data }) => {
          if (data.data.id) {
            commit(SET_SPONSOR, data.data);
            commit(SET_INCLUDED, data.included);
          } else if (data.errors) {
            commit(SET_ERROR, data.errors[0].title);
          }
          return true;
        })
        .catch(({ data }) => {
          if (data.errors) {
            commit(SET_ERROR, data.errors[0].title);
          }
          return false;
        });
    },
    getSponsors(
      { commit },
      { sortBy = ["-isActive", "name"], page = 1, limit = 20 } = {}
    ) {
      commit(SET_SPONSORS, []);
      commit(SET_INCLUDED, []);

      const include = ["engagements"];
      const fields = [
        "id",
        "name",
        "isActive",
        "activeEngagements",
        "totalEngagements",
        "totalSites",
        "totalReferrals",
        "totalScreenedReferrals",
        "totalRandomizedReferrals",
      ];
      return Vue.axios
        .get("/api/sponsors", {
          params: {
            include: include.join(","),
            sort: sortBy.join(","),
            page,
            limit,
            "fields[sponsors]": fields.join(","),
          },
        })
        .then(({ data }) => {
          commit(SET_SPONSORS_COUNT, data.meta);
          commit(SET_SPONSORS, data.data);
          return true;
        })
        .catch((response) => {
          commit(SET_ERROR, response);
          return false;
        });
    },
    getSponsorsList({ commit }) {
      commit(SET_SPONSORS_LIST, []);
      return Vue.axios
        .get("/api/sponsors?fields[sponsors]=id,name")
        .then(({ data }) => {
          commit(SET_SPONSORS_LIST, data.data);
          return true;
        })
        .catch((response) => {
          commit(SET_ERROR, response);
          return false;
        });
    },
    async identificationIsUnique(state, identification) {
      let isUnique = false;
      await Vue.axios
        .get(`/api/sponsors?uniqueIdentification=${identification}`)
        .then(({ data }) => {
          if (data.data.length === 0) {
            isUnique = true;
          }
        });
      return isUnique;
    },
  },
};
