var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"vue-diagrams"},[_c('SvgPanZoom',{ref:"svgpanzoom",style:({
      width: '100%',
      height: _vm.height + 'px',
      border: '1px solid black',
    }),attrs:{"zoomEnabled":_vm.zoomEnabled,"panEnabled":true,"controlIconsEnabled":true,"fit":false,"center":true,"viewportSelector":"#svg-root","preventMouseEventsDefault":true}},[_c('svg',{ref:"diagramRoot",staticClass:"svg-content",attrs:{"id":"svg-root","xmlns":"http://www.w3.org/2000/svg","viewBox":'0 0 ' + _vm.width + ' ' + _vm.height,"width":"100%","height":_vm.height,"preserveAspectRatio":"xMinYMin meet"}},[_c('defs',[_c('pattern',{attrs:{"id":"smallGrid","width":"16","height":"16","patternUnits":"userSpaceOnUse"}},[_c('path',{attrs:{"d":"M 16 0 L 0 0 0 16","fill":"none","stroke":"#ccc","stroke-width":"1"}})]),_c('pattern',{attrs:{"id":"grid","width":"80","height":"80","patternUnits":"userSpaceOnUse"}},[_c('rect',{attrs:{"width":"80","height":"80","fill":"url(#smallGrid)"}}),_c('path',{attrs:{"d":"M 80 0 L 0 0 0 80","fill":"none","stroke":"gray","stroke-width":"2"}})])]),_c('rect',{ref:"grid",staticClass:"svg-pan-zoom_viewport",attrs:{"x":"-5000px","y":"-5000px","width":"10000px","height":"10000px","fill":"url(#grid)"}}),_c('g',{ref:"viewPort",attrs:{"id":"viewport","x":"50","y":"50"}},[_vm._l((_vm.model._model.links),function(link,linkIndex){return _c('Link',{key:'link-' + linkIndex,ref:'link-' + link.id,refInFor:true,attrs:{"id":link.id,"options":link.options,"positionFrom":link.positionFrom,"positionTo":link.positionTo}})}),_vm._l((_vm.model._model.nodes),function(node){return _c('Node',{key:'node-' + node.id,ref:'node-' + node.id,refInFor:true,attrs:{"height":node.height,"id":node.id,"nodeModel":node,"title":node.title,"width":node.width,"x":node.x,"y":node.y}},_vm._l((node.questions),function(question,questionIndex){return _c('Question',{key:questionIndex,attrs:{"index":questionIndex,"title":question.title}},_vm._l((_vm.questionPorts(
                node,
                question.id,
                questionIndex
              )),function(port,portIndex){return _c('Port',{key:portIndex,ref:'port-' + port.id,refInFor:true,attrs:{"id":port.id,"name":port.name,"node":node,"type":port.type,"y":portIndex * 30 + questionIndex * 130}})}),1)}),1)})],2)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }