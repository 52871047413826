import {
  CLEAR_SURVEY,
  CLEAR_PREVIOUS_APPROVED_SURVEY,
  CLEAR_SURVEY_VERSIONS,
  CLEAR_SURVEY_MESSAGES,
  SET_ERROR,
  SET_IS_EDITING_SURVEY,
  SET_SURVEY,
  SET_PREVIOUS_APPROVED_SURVEY,
  SET_SURVEY_MESSAGES,
  SET_SURVEYS_COUNT,
  APPEND_SURVEY_VERSIONS,
  APPEND_SURVEY_PAGES,
  REMOVE_SURVEY_PAGE,
  REMOVE_SURVEY,
  APPEND_SURVEY_PAGE_QUESTIONS,
  REMOVE_SURVEY_PAGE_QUESTION,
  APPEND_SURVEY_PAGE_QUESTION_MAP,
  APPEND_SURVEY_PAGE_QUESTION_TAG_MAP,
  SET_SURVEY_ADDITIONAL_LANGUAGES,
  REMOVE_SURVEY_PAGE_QUESTION_MAP_PAGE,
  REMOVE_SURVEY_PAGE_QUESTION_MAP_QUESTION,
  SET_SURVEYS,
  SET_SURVEY_LIST,
} from "@admin/store/mutation-types";

import { getField, updateField } from "vuex-map-fields";
import { enUS } from "date-fns/locale";
import Vue from "vue";

const emptySurvey = function () {
  return {
    id: "",
    identity: "",
    oFormVersionId: "",
    title: "",
    engagementId: "",
    isActive: true,
    isApproved: null,
    engagementName: "",
  };
};

const patchSurvey = function (commit, state) {
  commit(SET_ERROR, "");
  commit(SET_IS_EDITING_SURVEY, true);
  // In order to save the data for a "survey", we need to save
  // multiple data items that are related to the survey. JSON API doesn't
  // support saving related data in a single request, so we must make multiple
  // requests to save all data. Since all data is created real time, we can patch
  // it concurrently
  const requests = [];

  const surveyData = setSurveyData(state.survey);

  // Establish request to save the survey
  requests.push(
    Vue.axios
      .patch(
        "/api/o-forms/o-forms/:id".replace(":id", state.survey.id),
        surveyData
      )
      .catch(() => {
        // Indicate false to show that we couldn't save the data, but omit interpreting
        // any errors and attempting to apply them. Data is too complex to worry about for now
        return Promise.resolve(false);
      })
  );

  // Establish requests to save survey pages
  for (const [, surveyPage] of Object.entries(state.surveyPages)) {
    const pageData = setSurveyPageData(surveyPage);

    requests.push(
      Vue.axios
        .patch(
          "/api/o-forms/o-form-containers/:id".replace(":id", surveyPage.id),
          pageData
        )
        .catch(() => {
          // Indicate false to show that we couldn't save the data, but omit interpreting
          // any errors and attempting to apply them. Data is too complex to worry about for now
          return Promise.resolve(false);
        })
    );
  }

  // Establish requests to save survey questions
  for (const [, surveyPageQuestion] of Object.entries(
    state.surveyPageQuestions
  )) {
    const questionData = setSurveyPageQuestionData(surveyPageQuestion);
    const relationshipId = questionData.data.id;
    const relationships = {};
    relationships["tags"] = { data: [] };
    if (state.surveyPageQuestionTagMap.has(relationshipId)) {
      state.surveyPageQuestionTagMap
        .get(relationshipId)
        .forEach(function (tagId) {
          relationships["tags"].data.push({
            type: "tags",
            id: tagId,
          });
        });
    }
    questionData.data.relationships = relationships;
    requests.push(
      Vue.axios
        .patch(
          "/api/o-forms/o-form-fields/:id".replace(
            ":id",
            surveyPageQuestion.id
          ),
          questionData
        )
        .catch(() => {
          // Indicate false to show that we couldn't save the data, but omit interpreting
          // any errors and attempting to apply them. Data is too complex to worry about for now
          return Promise.resolve(false);
        })
    );
  }

  // NOTE: we don't catch errors on the requests level because we'd only get the error that fails first
  // (if there are many) when in reality we can errors for each datum we are trying to save.
  // We need to catch on every individual request to handle this properly
  return Promise.all(requests).then((responses) => {
    let success = true;

    for (const response of responses) {
      switch (typeof response) {
        case "object":
          if (response.status !== 200) {
            success = false;

            // Log whatever error occurred for the first response that was found to fail.
            // We can only handle these errors one at a time
            commit(
              SET_ERROR,
              Object.prototype.hasOwnProperty.call(response.data, "message") &&
                response.data.message.length > 0
                ? response.data.message
                : "Could not save data"
            );
          }
          break;
        case "boolean":
          if (!response) {
            success = false;
            commit(SET_ERROR, "Could not save data");
          }
          break;
      }

      if (!success) {
        break;
      }
    }

    commit(SET_IS_EDITING_SURVEY, false);

    return Promise.resolve([success]);
  });
};

const extractSurveyData = (survey) => {
  const surveyData = {
    id: survey.id,
    identity: survey.attributes.identity,
    title: survey.attributes.title,
    isActive: survey.attributes.isActive,
    engagementName: survey.attributes.engagementName,
    sponsorName: survey.attributes.sponsorName,
    sponsorId: survey.attributes.sponsorId,
  };

  if (survey.relationships) {
    if (survey.relationships.oFormVersion) {
      surveyData.oFormVersionId = survey.relationships.oFormVersion.data.id;
    }

    if (survey.relationships.engagement) {
      surveyData.engagementId = survey.relationships.engagement.data.id;
    }
  }

  return surveyData;
};

const setSurveyData = function (survey) {
  return {
    data: {
      type: "oForms",
      id: survey.id,
      attributes: {
        title: survey.title,
        // Note: We don't account for setting isActive in this context since activeness
        // is managed not when editing a survey, but rather when managing one
      },
    },
  };
};

const setSurveyPageData = (surveyPage) => {
  return {
    data: {
      type: "oFormFields",
      id: surveyPage.id,
      attributes: {
        display_order: surveyPage.displayOrder,
        title: surveyPage.title,
        is_active: surveyPage.isActive,
        is_deleted: surveyPage.isDeleted,
      },
    },
  };
};

const setSurveyPageQuestionData = (surveyPageQuestion) => {
  return {
    data: {
      type: "oFormFields",
      id: surveyPageQuestion.id,
      attributes: {
        configuration: surveyPageQuestion.configuration,
        o_form_field_type_id: surveyPageQuestion.oFormFieldTypeId,
        o_form_field_category_id: surveyPageQuestion.oFormFieldCategoryId,
        title: surveyPageQuestion.title,
        is_deleted: surveyPageQuestion.isDeleted,
      },
    },
  };
};

export default {
  namespaced: true,
  state: {
    // Provide an indication of if the survey is being edited in the context of the API. Editing a survey is a complex process where all loaded questions
    // and pages are saved. We want to be able to indicate to all areas that allow for editing these items that saving is happening
    // to allow for those areas to prevent edits during the save. Additionally, when new parts of the survey are being created (e.g.
    // adding pages or questions), we want to prevent the editing of the survey since we want all data to load locally so this application can provide
    // validation rules for that new data before allowing a final save to the server
    isEditingSurvey: false,
    survey: emptySurvey(),
    // If the current survey in scope is not yet approved, there is a chance that a previous version of the survey
    // exists that has been approved and would thus be accessible to public scope. This version may need to be managed
    // (e.g. for toggling its activeness), so we want to allow for loading its information
    previousApprovedSurvey: emptySurvey(),
    // Identify which secondary language option the admin can currently configure.
    // We only allow for configuring a single secondary language option at a time to limit the amount of vertical growth
    // needed to provide fields to configure multiple languages. We want to always show English so the configurer always has
    // context on what is being edited, so we'll need additional inputs to adjust config for other languages.
    // IMPORTANT: We don't allow for switching the secondary language when there are validation errors since we don't want to allow for
    // errors with the secondary language inputs when the user switches away since, we have to actually switch inputs to account for the change.
    // Since these inputs would be brought out of scope, we wouldn't be able to validate them
    surveyAdditionalLanguages: [],
    surveyMessages: {},
    surveyPages: {},
    surveyPageQuestions: {},
    surveyPageQuestionMap: new Map(),
    surveyPageQuestionTagMap: new Map(),
    surveys: [],
    surveyVersions: {},
    surveyList: [],
    included: [],
    error: "",
    surveyPageCounter: 1,
    surveysCount: 0,
  },
  getters: {
    getField,
    isEditingSurvey(state) {
      return state.isEditingSurvey;
    },
    sortedSurveys(state) {
      return state.surveys.sort((a, b) => a.name.localeCompare(b.name));
    },
    secondaryLanguageTarget(state, getters, rootState) {
      return rootState.systemLanguages.languages.find(
        (element) => element.value === state.secondaryLanguageTarget
      );
    },
    languages(state) {
      return state.languages;
    },
    surveyAdditionalLanguagesList(state) {
      return state.surveyAdditionalLanguages;
    },
    // Identify the identities for all pages belonging to a survey in their
    // given display order. Add all pages that don't have a display order to
    // the end of the set in whatever order they are encountered
    surveyPageIdentities(state) {
      const orderIndexedIdentities = {};
      const unorderedIdentities = [];

      for (const [identity, page] of Object.entries(state.surveyPages)) {
        if (Number.isInteger(page.displayOrder)) {
          orderIndexedIdentities[page.displayOrder] = identity;
        } else {
          // Include when the unordered item was created so that we can still sort
          // all unordered items by when they were created and give a consistent expectation
          // to the user
          unorderedIdentities.push({ identity, created: page.created });
        }
      }

      return Object.values(orderIndexedIdentities).concat(
        unorderedIdentities
          .sort((a, b) => {
            // Sort by created date ascending
            if (a.created < b.created) {
              return -1;
            }

            return 1;
          })
          // Get the actual identities of the unordered items
          .map((page) => page.identity)
      );
    },
    // Identify a list of survey pages based on the structure of [identity => page title]
    surveyPagesList(state) {
      const pages = [];

      for (const [identity, page] of Object.entries(state.surveyPages)) {
        pages.push({ value: identity, text: page.title });
      }

      return pages.sort((a, b) => {
        // Sort by text i.e. order by the title of a page
        if (a.text < b.text) {
          return -1;
        }

        return 1;
      });
    },
    // Identify a survey page based on the given identity
    surveyPage: (state) => (identity) => {
      return state.surveyPages[identity] ?? null;
    },
    // Identify a survey version based on the given id
    surveyVersion: (state) => (id) => {
      return state.surveyVersions[id] ?? null;
    },
    // For a page identity, identify all question Ids that are associated to that identity
    surveyPageQuestionIdsPerPage: (state) => (pageIdentity) => {
      if (state.surveyPageQuestionMap.has(pageIdentity)) {
        return state.surveyPageQuestionMap.get(pageIdentity);
      }

      return [];
    },
    tagsPerQuestion: (state, getters, rootState) => (questionId) => {
      const tags = [];
      if (state.surveyPageQuestionTagMap.has(questionId)) {
        const tagIds = state.surveyPageQuestionTagMap.get(questionId);
        tagIds.forEach((tagId) => {
          const record = rootState.tags.tagsList[tagId];
          if (record) {
            tags.push({
              text: record.name,
              value: record.id,
            });
          }
        });
      }
      return tags;
    },
    surveyPageQuestionsPerPage: (state) => (pageIdentity) => {
      const questions = [];

      if (state.surveyPageQuestionMap.has(pageIdentity)) {
        for (const questionId of state.surveyPageQuestionMap.get(
          pageIdentity
        )) {
          if (state.surveyPageQuestions[questionId]) {
            questions.push({ ...state.surveyPageQuestions[questionId] });
          }
        }
      }

      return questions;
    },
    // Get a survey question based on the given id
    surveyPageQuestion: (state) => (id) => {
      return state.surveyPageQuestions[id] ?? null;
    },
  },
  mutations: {
    updateField,
    [CLEAR_SURVEY_VERSIONS](state) {
      state.surveyVersions = {};
    },
    [CLEAR_SURVEY](state) {
      state.survey = emptySurvey();
      // Clear out data that is related to the survey that we additionally
      // store to represent its structure
      state.surveyPages = {};
      state.surveyPageQuestions = {};
      state.surveyPageQuestionMap = new Map();
      state.surveyPageQuestionTagMap = new Map();
      // Reset the survey configuration secondary language to default
      state.surveyAdditionalLanguages = [];
    },
    [CLEAR_PREVIOUS_APPROVED_SURVEY](state) {
      state.previousApprovedSurvey = emptySurvey();
    },
    [CLEAR_SURVEY_MESSAGES](state) {
      state.surveyMessages = {};
    },
    [SET_IS_EDITING_SURVEY](state, isEditingSurvey) {
      state.isEditingSurvey = isEditingSurvey;
    },
    [SET_SURVEY](state, survey) {
      const surveyData = extractSurveyData(survey);

      state.survey = surveyData;
    },
    [SET_SURVEYS_COUNT](state, meta) {
      state.surveysCount = meta.record_count;
    },
    [SET_PREVIOUS_APPROVED_SURVEY](state, survey) {
      const surveyData = extractSurveyData(survey);

      state.previousApprovedSurvey = surveyData;
    },
    [SET_SURVEY_MESSAGES](
      state,
      { languageTargets = [], messages = [], defaultMessages = [] } = {}
    ) {
      const messageTypes = [
        "completed_contact_form",
        "no_trial_sites_consent",
        "no_trial_sites_contact_form",
        "not_active_consent",
        "not_active_contact_form",
        "not_qualified_consent",
        "not_qualified_contact_form",
        "trial_site_selected_consent",
        "trial_site_selected_contact_form",
      ];
      const messageMap = new Map();
      const defaultMessageMap = new Map();
      const interpretedMessages = {};

      for (const message of messages) {
        messageMap.set(
          message.attributes.messageLanguage +
            "_" +
            message.relationships.oFormMessageType.data.id,
          {
            message: message.attributes.message,
            messageLanguage: message.attributes.messageLanguage,
            oFormMessageTypeId: message.relationships.oFormMessageType.data.id,
            id: message.id,
          }
        );
      }

      for (const message of defaultMessages) {
        defaultMessageMap.set(
          message.attributes.messageLanguage +
            "_" +
            message.relationships.oFormMessageType.data.id,
          { ...message.attributes }
        );
      }

      for (const languageTarget of languageTargets) {
        for (const messageType of messageTypes) {
          const mapIdentifier = languageTarget.value + "_" + messageType;

          if (messageMap.has(mapIdentifier)) {
            interpretedMessages[mapIdentifier] = {
              ...messageMap.get(mapIdentifier),
            };
          } else {
            interpretedMessages[mapIdentifier] = {
              messageLanguage: languageTarget.value,
              oFormMessageTypeId: messageType,
              message: defaultMessageMap.has(mapIdentifier)
                ? defaultMessageMap.get(mapIdentifier).message
                : "",
            };
          }
        }
      }

      state.surveyMessages = interpretedMessages;
    },
    // Set information about various survey versions (generally about the survey(s) in current scope)
    // that outline expresive details about the versions surveys are based on. These items will be key'd
    // by their id so that survey's can relate to which version represents them
    [APPEND_SURVEY_VERSIONS](state, surveyVersions) {
      const newSurveyVersions = {};

      for (const surveyVersion of surveyVersions) {
        newSurveyVersions[surveyVersion.id] = {
          id: surveyVersion.id,
          ...surveyVersion.attributes,
        };
      }

      state.surveyVersions = Object.assign(
        {},
        state.surveyVersions,
        newSurveyVersions
      );
    },
    [APPEND_SURVEY_PAGES](state, surveyPages) {
      const newSurveyPages = {};

      for (const surveyPage of surveyPages) {
        newSurveyPages[surveyPage.attributes.identity] = {
          id: surveyPage.id,
          ...surveyPage.attributes,
        };

        if (
          surveyPage.relationships &&
          surveyPage.relationships.oFormContainerType
        ) {
          newSurveyPages[surveyPage.attributes.identity].oFormContainerTypeId =
            surveyPage.relationships.oFormContainerType.data.id;
        }
      }

      state.surveyPages = Object.assign({}, state.surveyPages, newSurveyPages);

      state.surveyPageCounter = Object.keys(state.surveyPages).length;
      state.surveyPageCounter++;
    },
    [REMOVE_SURVEY_PAGE](state, identity) {
      if (Object.hasOwn(state.surveyPages, identity)) {
        Vue.delete(state.surveyPages, identity);
      }
    },
    [REMOVE_SURVEY](state, id) {
      const index = state.surveys.findIndex((survey) => survey.id === id);
      if (index !== -1) {
        Vue.delete(state.surveys, index);
      }
    },
    [APPEND_SURVEY_PAGE_QUESTIONS](state, surveyPageQuestions) {
      const newSurveyPageQuestions = {};

      for (const surveyPageQuestion of surveyPageQuestions) {
        newSurveyPageQuestions[surveyPageQuestion.id] = {
          id: surveyPageQuestion.id,
          ...surveyPageQuestion.attributes,
        };

        if (
          surveyPageQuestion.relationships &&
          surveyPageQuestion.relationships.oFormFieldType
        ) {
          newSurveyPageQuestions[surveyPageQuestion.id].oFormFieldTypeId =
            surveyPageQuestion.relationships.oFormFieldType.data.id;
        }

        if (
          surveyPageQuestion.relationships &&
          surveyPageQuestion.relationships.oFormFieldCategory
        ) {
          newSurveyPageQuestions[surveyPageQuestion.id].oFormFieldCategoryId =
            surveyPageQuestion.relationships.oFormFieldCategory.data.id;
        }

        if (
          newSurveyPageQuestions[surveyPageQuestion.id].oFormFieldTypeId &&
          newSurveyPageQuestions[surveyPageQuestion.id].oFormFieldTypeId ===
            "placeholder"
        ) {
          // If dealing with a form field that was saved to the server as a placeholder, reset properties
          // that pertain to configuration to force the user to specify non placeholder data. In reality, users should
          // be forced to remove all placeholder details for a question to be valid
          newSurveyPageQuestions[surveyPageQuestion.id].title = "";
          newSurveyPageQuestions[surveyPageQuestion.id].oFormFieldTypeId = "";
          newSurveyPageQuestions[surveyPageQuestion.id].configuration = null;
        }
      }

      state.surveyPageQuestions = Object.assign(
        {},
        state.surveyPageQuestions,
        newSurveyPageQuestions
      );
    },
    // Allow for removing a question from the current set of survey questions
    [REMOVE_SURVEY_PAGE_QUESTION](state, questionId) {
      if (Object.hasOwn(state.surveyPageQuestions, questionId)) {
        Vue.delete(state.surveyPageQuestions, questionId);
      }
    },
    [APPEND_SURVEY_PAGE_QUESTION_MAP](state, surveyPageQuestionMap) {
      surveyPageQuestionMap.forEach((value, key) => {
        if (state.surveyPageQuestionMap.has(key)) {
          const mergedMap = new Set([
            ...state.surveyPageQuestionMap.get(key),
            ...value,
          ]);

          state.surveyPageQuestionMap.set(key, [...mergedMap]);
        } else {
          state.surveyPageQuestionMap.set(key, [...value]);
        }
      });
    },
    [APPEND_SURVEY_PAGE_QUESTION_TAG_MAP](state, surveyPageQuestionTagMap) {
      surveyPageQuestionTagMap.forEach((value, key) => {
        state.surveyPageQuestionTagMap.set(key, [...value]);
      });
    },

    // Allow for removing a survey pahe from the survey page to survey question map
    [REMOVE_SURVEY_PAGE_QUESTION_MAP_PAGE](state, pageIdentity) {
      if (state.surveyPageQuestionMap.has(pageIdentity)) {
        // Since page identities are the key values of the map, we can just delete by key
        state.surveyPageQuestionMap.delete(pageIdentity);
      }
    },
    /*
    Allow for removing a survey question from the survey page to survey question
    association map.

    IMPORTANT: The nature of the API doesn't provide survey questions with the notion
    of which survey page identity they belong to. As a result, we have to iterate
    all survey page identities and ensure the questionId provided is no longer in
    the question ID set for that survey page
    */
    [REMOVE_SURVEY_PAGE_QUESTION_MAP_QUESTION](state, questionId) {
      const mapIterator = state.surveyPageQuestionMap.entries();

      for (const [identity, optionSet] of mapIterator) {
        if (optionSet.includes(questionId)) {
          state.surveyPageQuestionMap.set(
            identity,
            // Remove the questionId from the set of questionIds currently set for the page
            optionSet.filter((id) => id !== questionId)
          );
        }
      }
    },
    [SET_SURVEYS](state, surveys) {
      const surveyData = [];

      for (const survey of surveys) {
        surveyData.push(extractSurveyData(survey));
      }

      state.surveys = surveyData;
    },
    [SET_SURVEY_LIST](state, surveys) {
      const surveyList = [];
      for (const survey of surveys) {
        surveyList.push({
          text: survey.attributes.title,
          value: survey.id,
        });
      }

      state.surveyList = surveyList;
    },
    [SET_ERROR](state, message) {
      state.error = message;
    },
    [SET_SURVEY_ADDITIONAL_LANGUAGES](state, languages) {
      const newSurveyLanguages = [];

      for (const language of languages) {
        //get fallbackCode
        let fallbackCode = language.attributes.isoCode.split("_")[0];

        newSurveyLanguages.push({
          id: language.id,
          text: language.attributes.description,
          value: language.attributes.isoCode,
          fallbackValue: fallbackCode,
          dateLocale: enUS,
        });
      }

      state.surveyAdditionalLanguages = newSurveyLanguages;
    },
  },
  actions: {
    addSurvey({ commit, state }) {
      commit(SET_ERROR, "");

      if (!state.survey.engagementId) {
        // Surveys are required to be created for an engagement. If there is no
        // engagement ID provided, don't allow to attempt to create the survey
        return Promise.resolve([false]);
      }

      return Vue.axios
        .post("/api/o-forms/o-forms", {
          data: {
            type: "oForms",
            attributes: {
              title: state.survey.title,
              engagementId: state.survey.engagementId,
            },
          },
        })
        .then(({ data }) => {
          if (data.data.id) {
            commit(SET_SURVEY, data.data);
          } else if (data.errors) {
            commit(SET_ERROR, data.errors);
          }

          return Promise.resolve([true]);
        })
        .catch((e) => {
          const data = e.response.data;
          if (data.errors) {
            commit(SET_ERROR, data.errors[0].title);
          }

          return Promise.resolve([false]);
        });
    },
    editSurvey({ commit, state }) {
      return patchSurvey(commit, state);
    },
    // For all survey messages that are identified to be managed for the current survey,
    // either create new message records if they aren't expected to already exist, or update
    // existing ones if they are
    persistSurveyMessages({ state, commit }) {
      commit(SET_ERROR, "");

      if (!state.survey.id) {
        // If there isn't currently a survey loaded, we can't save messages since we need context
        // on which survey those messages will be saved for
        return Promise.resolve([false]);
      }

      const promises = [];

      for (const [, message] of Object.entries(state.surveyMessages)) {
        const attributes = {
          message: message.message,
          messageLanguage: message.messageLanguage,
          oFormMessageTypeId: message.oFormMessageTypeId,
          oFormIdentity: state.survey.identity,
          oFormVersionId: state.survey.oFormVersionId,
        };

        if (Object.hasOwn(message, "id") && message.id) {
          promises.push(
            Vue.axios.patch(
              "/api/o-forms/o-form-messages/:id".replace(":id", message.id),
              {
                data: {
                  id: message.id,
                  type: "oFormMessages",
                  attributes,
                },
              }
            )
          );
        } else {
          promises.push(
            Vue.axios.post("/api/o-forms/o-form-messages/", {
              data: {
                type: "oFormMessages",
                attributes,
              },
            })
          );
        }
      }

      return Promise.all(promises)
        .then(() => {
          return Promise.resolve([true]);
        })
        .catch(() => {
          commit(SET_ERROR, "Could Not Save Survey Messages");
          return Promise.resolve([false]);
        });
    },
    approveSurveyVersion({ commit, state, dispatch }) {
      commit(SET_ERROR, "");

      if (!state.survey.oFormVersionId) {
        return Promise.resolve([false]);
      }

      return Vue.axios
        .patch(
          "/api/o-forms/o-form-versions/:id".replace(
            ":id",
            state.survey.oFormVersionId
          ),
          {
            data: {
              type: "oFormVersions",
              id: state.survey.oFormVersionId,
              attributes: { mark_approved: true },
            },
          }
        )
        .then((response) => {
          const success = response.status === 200;

          if (success) {
            commit(APPEND_SURVEY_VERSIONS, [response.data.data]);
            // Once the current survey is approved, the previous version of the survey should no longer be kept in
            // scope because, as per loading hte previous version in the first place, we only want the previous version
            // to be loadable if the current version is not yet approved
            dispatch("clearPreviousApprovedSurvey");
          }

          return Promise.resolve([success]);
        })
        .catch(() => {
          return Promise.resolve([false]);
        });
    },
    toggleSurveyActive({ commit, state }) {
      commit(SET_ERROR, "");

      if (!state.survey.id) {
        return Promise.resolve([false]);
      }

      return Vue.axios
        .patch("/api/o-forms/o-forms/:id".replace(":id", state.survey.id), {
          data: {
            type: "oForms",
            id: state.survey.id,
            attributes: { toggle_active: !state.survey.isActive },
          },
        })
        .then((response) => {
          const success = response.status === 200;

          if (success) {
            // Directly override the isActive property for a survey when toggling
            // it. We do this since we only changed this attribute specifically in the
            // patch, and we don't want to override other survey data (especially parsed
            // related data) because of this toggle
            state.survey.isActive = response.data.data.attributes.isActive;
          }

          return Promise.resolve([success]);
        })
        .catch(() => {
          return Promise.resolve([false]);
        });
    },
    togglePreviousApprovedSurveyActive({ commit, state }) {
      commit(SET_ERROR, "");

      if (!state.previousApprovedSurvey.id) {
        return Promise.resolve([false]);
      }

      return Vue.axios
        .patch(
          "/api/o-forms/o-forms/:id".replace(
            ":id",
            state.previousApprovedSurvey.id
          ),
          {
            data: {
              type: "oForms",
              id: state.previousApprovedSurvey.id,
              attributes: {
                toggle_active: !state.previousApprovedSurvey.isActive,
              },
            },
          }
        )
        .then((response) => {
          const success = response.status === 200;

          if (success) {
            // Directly override the isActive property for a survey when toggling
            // it. We do this since we only changed this attribute specifically in the
            // patch, and we don't want to override other survey data (especially parsed
            // related data) because of this toggle
            state.previousApprovedSurvey.isActive =
              response.data.data.attributes.isActive;
          }

          return Promise.resolve([success]);
        })
        .catch(() => {
          return Promise.resolve([false]);
        });
    },
    createNewSurveyVersion({ commit, state }) {
      commit(SET_ERROR, "");

      if (!state.survey.identity) {
        return Promise.resolve([false]);
      }

      return Vue.axios
        .post("/api/o-forms/o-form-copy", {
          data: {
            type: "oForms",
            attributes: { copy_identity: state.survey.identity },
          },
        })
        .then((response) => {
          const success = response.status === 201;

          if (success) {
            commit(SET_SURVEY, response.data.data);
          }

          return Promise.resolve([success]);
        })
        .catch(() => {
          return Promise.resolve([false]);
        });
    },
    clearSurvey({ commit }) {
      commit(CLEAR_SURVEY);
      // A previous approved survey being loaded is indicative of a previous
      // version existing for the current survey. If the current survey is cleared,
      // we shouldn't persist a previous version
      commit(CLEAR_PREVIOUS_APPROVED_SURVEY);
    },
    clearPreviousApprovedSurvey({ commit }) {
      commit(CLEAR_PREVIOUS_APPROVED_SURVEY);
    },
    clearSurveyMessages({ commit }) {
      // Clear messages in relation to a survey separate from clearing a survey since we
      // want to be able to manage sets of messages separately
      commit(CLEAR_SURVEY_MESSAGES);
    },
    clearSurveys({ commit }) {
      commit(SET_SURVEYS, []);
    },
    clearSurveysList({ commit }) {
      commit(SET_SURVEY_LIST, []);
    },
    clearSurveyVersions({ commit }) {
      commit(CLEAR_SURVEY_VERSIONS);
    },
    getMessages({ state, commit, rootGetters }) {
      if (!state.survey.id) {
        // If a survey hasn't been loaded yet, we can't get messages for the survey
        return Promise.resolve([false]);
      }
      commit(SET_ERROR, "");

      const promises = [];
      const messageParams = {
        o_form_identity: state.survey.identity,
        o_form_version_id: state.survey.oFormVersionId,
      };

      promises.push(Vue.axios.get("/api/o-forms/o-form-default-messages"));
      promises.push(
        Vue.axios.get("/api/o-forms/o-form-messages", { params: messageParams })
      );

      return Promise.all(promises)
        .then((responses) => {
          const defaultMessagesResponse = responses.shift();
          const messagesResponse = responses.shift();

          commit(SET_SURVEY_MESSAGES, {
            languageTargets: rootGetters["systemLanguages/languageTargets"],
            messages: messagesResponse.data.data,
            defaultMessages: defaultMessagesResponse.data.data,
          });

          return Promise.resolve([true]);
        })
        .catch(() => {
          Promise.resolve([false]);
        });
    },
    deleteSurvey({ commit }, id) {
      return Vue.axios
        .delete("/api/o-forms/o-forms/:id".replace(":id", id))
        .then((response) => {
          // A delete request will return a 204 on success from the API
          const success = response.status === 204;

          if (success) {
            commit(REMOVE_SURVEY, id);
          }

          return Promise.resolve([success]);
        });
    },
    getSurvey({ commit, dispatch }, id) {
      dispatch("clearSurvey");
      commit(SET_ERROR, "");
      return Vue.axios
        .get("/api/o-forms/o-forms/:id".replace(":id", id), {
          params: {
            include:
              "oFormContainers.oFormFields.tags,engagement,engagement.sponsor,languages",
          },
        })
        .then((response) => {
          const success = response.status === 200;

          if (success) {
            commit(SET_SURVEY, response.data.data);

            if (response.data.included && response.data.included.length > 0) {
              const containers = [];
              const fields = [];
              const containerFieldAssociationMap = new Map();
              const fieldTagAssociationMap = new Map();
              const languages = [];

              for (const item of response.data.included) {
                switch (item.type) {
                  case "oFormContainers":
                    if (
                      item.relationships.oFormContainerType.data.id !==
                      "root_container"
                    ) {
                      containers.push(item);

                      if (item.relationships.oFormFields.data) {
                        const fieldRelationships = [];
                        for (const oFormField of item.relationships.oFormFields
                          .data) {
                          fieldRelationships.push(oFormField.id);
                        }

                        if (fieldRelationships.length > 0) {
                          containerFieldAssociationMap.set(
                            item.attributes.identity,
                            fieldRelationships
                          );
                        }
                      }
                    }
                    break;
                  case "oFormFields":
                    fields.push(item);
                    if (
                      Object.prototype.hasOwnProperty.call(
                        item.relationships,
                        "tags"
                      )
                    ) {
                      const tagRelationships = [];
                      for (const tag of item.relationships.tags.data) {
                        tagRelationships.push(tag.id);
                      }

                      if (tagRelationships.length > 0) {
                        fieldTagAssociationMap.set(item.id, tagRelationships);
                      }
                    }
                    break;

                  case "languages":
                    languages.push(item);
                    break;
                }
              }

              commit(APPEND_SURVEY_PAGES, containers);
              commit(
                APPEND_SURVEY_PAGE_QUESTION_TAG_MAP,
                fieldTagAssociationMap
              );
              commit(SET_SURVEY_ADDITIONAL_LANGUAGES, languages);
              commit(APPEND_SURVEY_PAGE_QUESTIONS, fields);
              commit(
                APPEND_SURVEY_PAGE_QUESTION_MAP,
                containerFieldAssociationMap
              );
            }
          } else if (response.data.errors) {
            commit(SET_ERROR, response.data.errors);
          }
          return Promise.resolve([true]);
        })
        .catch((error) => {
          commit(SET_ERROR, error);
          return Promise.resolve([false]);
        });
    },
    exportToPDF({ commit }, [id, showApproved]) {
      commit(SET_ERROR, "");

      return Vue.axios
        .post(
          "/api/o-forms/export-to-pdf/:id".replace(":id", id) +
            "/:showApproved".replace(":showApproved", showApproved ? 1 : 0)
        )
        .then((response) => {
          const success = response.status === 201;

          if (success) {
            //get filename and url from first call
            var fileName = response.data.filename;
            var downloadUrl = response.data.url;

            Vue.axios
              .get(downloadUrl, {
                responseType: "blob",
              })
              .then((response) => {
                const success = response.status === 200;
                if (success) {
                  //get pdf and automatically download it with filename
                  const url = window.URL.createObjectURL(
                    new Blob([response.data])
                  );
                  const link = document.createElement("a");
                  link.href = url;
                  link.setAttribute("download", fileName);
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);
                } else if (response.data.errors) {
                  commit(SET_ERROR, response.data.errors);
                  return Promise.resolve([false]);
                }
              })
              .catch((error) => {
                commit(SET_ERROR, error);
                return Promise.resolve([false]);
              });
          } else if (response.data.errors) {
            commit(SET_ERROR, response.data.errors);
            return Promise.resolve([false]);
          }
        })
        .catch((error) => {
          commit(SET_ERROR, error);
          return Promise.resolve([false]);
        });
    },
    getSurveysRequest(context, { params = {} } = {}) {
      return Vue.axios.get("/api/o-forms/o-forms", {
        params,
      });
    },
    getSurveys(
      { commit, dispatch },
      { sortBy = [], page = 1, limit = 20 } = {}
    ) {
      commit(SET_ERROR, "");
      commit(SET_SURVEYS, []);
      const params = {
        sort: sortBy.join(","),
        page,
        limit,
      };
      return dispatch("getSurveysRequest", { params })
        .then(({ data }) => {
          commit(SET_SURVEYS_COUNT, data.meta);
          commit(SET_SURVEYS, data.data);
          return true;
        })
        .catch((response) => {
          commit(SET_ERROR, response);
          return false;
        });
    },
    updateTagMap({ commit }, { questionId, tags }) {
      const fieldTagAssociationMap = new Map();
      fieldTagAssociationMap.set(questionId, tags);
      commit(APPEND_SURVEY_PAGE_QUESTION_TAG_MAP, fieldTagAssociationMap);
    },
    /*
    Provide a means to get a survey based on the passed identity. This will get the
    forward most version of a survey with the given identity
    */
    getSurveyByIdentity({ commit, dispatch }, { identity = null } = {}) {
      if (identity === null) {
        return Promise.resolve([false]);
      }

      commit(SET_ERROR, "");
      dispatch("clearSurvey");

      return dispatch("getSurveysRequest", {
        params: { identity, include: "oFormVersions,engagement.sponsor" },
      })
        .then((response) => {
          let survey = null;

          if (response.status === 200) {
            // We have to query for surveys from the index method since we need to find results based on "identity",
            // but only a singular record should be returned (e.g. the current version of the form with the given identity)
            // we'll want to set focus to only deal with a singular identity
            survey =
              response.data.data.length > 0 ? response.data.data[0] : null;
          }

          if (!survey) {
            return Promise.resolve([false]);
          }

          commit(SET_SURVEY, survey);

          const surveyVersions = [];

          if (response.data.included) {
            for (const include of response.data.included) {
              if (include.type === "oFormVersions") {
                surveyVersions.push(include);
              }
            }
          }

          commit(APPEND_SURVEY_VERSIONS, surveyVersions);

          return Promise.resolve([true]);
        })
        .catch(() => {
          return Promise.resolve([false]);
        });
    },
    /*
    Provide a means to get a previous version of the current survey (if one is loaded).
    This will get the forward most version of a survey that has been approved.

    NOTE: if the current survey has already been approved, this action is mosst since the previous
    approved version would just return the same version. We account for this in action scope by not attempting
    to load a previous version if the current survey is approved
    */
    getPreviousApprovedSurveyByIdentity({ commit, dispatch, state, getters }) {
      if (
        state.survey.identity === null ||
        getters.surveyVersion(state.survey.oFormVersionId)?.isApproved
      ) {
        // If there is not currently a loaded survey, we can't get a previous version of it.
        // Also, we should only use this functionality if the forward most version of a survey isn't
        // approved. Once a version becomes approved, it will be publically accessible and we don't need
        // to manage the previous approved version anymore. The API won't make it accessible anyway since
        // it will only provide access to the most recent approved version
        return Promise.resolve([false]);
      }

      commit(SET_ERROR, "");
      dispatch("clearPreviousApprovedSurvey");

      return dispatch("getSurveysRequest", {
        params: {
          identity: state.survey.identity,
          // If we are loading a previous version of the survey, one should only exist if the previous version
          // was approved and a new version was created afterwards.
          approvedOnly: 1,
          include: "oFormVersions,engagement.sponsor",
        },
      })
        .then((response) => {
          let survey = null;

          if (response.status === 200) {
            // We have to query for surveys from the index method since we need to find results based on "identity",
            // but only a singular record should be returned (e.g. the current version of the form with the given identity)
            // we'll want to set focus to only deal with a singular identity
            survey =
              response.data.data.length > 0 ? response.data.data[0] : null;
          }

          if (!survey) {
            return Promise.resolve([false]);
          }

          commit(SET_PREVIOUS_APPROVED_SURVEY, survey);

          const surveyVersions = [];

          if (response.data.included) {
            for (const include of response.data.included) {
              if (include.type === "oFormVersions") {
                surveyVersions.push(include);
              }
            }
          }

          commit(APPEND_SURVEY_VERSIONS, surveyVersions);

          return Promise.resolve([true]);
        })
        .catch(() => {
          return Promise.resolve([false]);
        });
    },
    getSurveysForEngagement(
      { commit },
      { engagementId, sortBy = [], page = 1, limit = 20 } = {}
    ) {
      commit(SET_ERROR, "");
      commit(SET_SURVEYS, []);

      const params = {
        engagementId,
        include: "oFormVersions",
        sort: sortBy.join(","),
        page,
        limit,
      };

      return Vue.axios
        .get("/api/o-forms/o-forms", {
          params,
        })
        .then(({ data }) => {
          commit(SET_SURVEYS_COUNT, data.meta);
          commit(SET_SURVEYS, data.data);
          const surveyVersions = [];

          if (data.included) {
            for (const include of data.included) {
              if (include.type === "oFormVersions") {
                surveyVersions.push(include);
              }
            }
          }

          commit(APPEND_SURVEY_VERSIONS, surveyVersions);

          return true;
        })
        .catch((response) => {
          commit(SET_ERROR, response);
          return false;
        });
    },
    /**
     * This method will set a survey list to be used for a dropdown select on the Media Channels form.
     * @param commit
     * @param engagementId
     */
    getSurveyListForEngagements({ commit }, engagementId) {
      commit(SET_ERROR, "");
      commit(SET_SURVEY_LIST, []);
      return Vue.axios
        .get("/api/o-forms/o-forms", {
          params: {
            engagementId: engagementId,
            // When finding surveys related to engagements for the sake of Media Channels, we want to consider
            // only approved versions of the survey since media channels can only show approved versions of a
            // survey identity outside of the admin scope, so it is best to present consistent data within
            // the admin scope
            approvedOnly: 1,
          },
        })
        .then(({ data }) => {
          commit(SET_SURVEY_LIST, data.data);
          return true;
        })
        .catch((response) => {
          commit(SET_ERROR, response);
          return false;
        });
    },
    /*
    add a new page to the survey. This requires two steps since the required structure of data to represent pages
    is that each page must belong to a container called a root_container. When a root container is added, an initial
    page is auto added. This page will be used as the added page
    */
    addPage({ commit, state }) {
      commit(SET_ERROR, "");
      commit(SET_IS_EDITING_SURVEY, true);
      const rootContainerPayload = {
        data: {
          type: "oFormContainers",
          attributes: {
            o_form_version_id: state.survey.oFormVersionId,
            o_form_identity: state.survey.identity,
            o_form_container_type_id: "root_container",
            is_active: true,
            title: "New Page " + state.surveyPageCounter,
          },
        },
      };

      return Vue.axios
        .post("/api/o-forms/o-form-containers", rootContainerPayload)
        .then((response) => {
          // The request is successful if an entity is added
          const success = response.status === 201;

          if (success) {
            // If a root container was successfully added, this means that an initial page was successfully added within
            // it. Get the content of that initial page since it is what we'll add survey questions to (i.e. we avoid
            // the concept of managing root containers and just manage containers)
            const containerRequest = Vue.axios
              .get(
                "/api/o-forms/o-form-containers/:id".replace(
                  ":id",
                  response.data.data.relationships.childOFormContainers.data[0]
                    .id
                )
              )
              .then((response) => {
                const success = response.status === 200;

                if (success) {
                  commit(APPEND_SURVEY_PAGES, [response.data.data]);
                }

                commit(SET_IS_EDITING_SURVEY, false);
                return Promise.resolve([success]);
              });

            return Promise.resolve(containerRequest);
          } else {
            commit(
              SET_ERROR,
              Object.prototype.hasOwnProperty.call(response.data, "message") &&
                response.data.message.length > 0
                ? response.data.message
                : "Could not save data"
            );
            commit(SET_IS_EDITING_SURVEY, false);
            return Promise.resolve([false]);
          }
        })
        .catch(() => {
          commit(SET_ERROR, "Could not add Page");
          commit(SET_IS_EDITING_SURVEY, false);
          return Promise.resolve([false]);
        });
    },
    deletePage({ state, commit, getters }, identity) {
      if (Object.hasOwn(state.surveyPages, identity)) {
        commit(SET_IS_EDITING_SURVEY, true);
        // Delete a survey page based on its ID (we reference pages based on identity, so we have to find
        // id based on identity)
        return Vue.axios
          .delete(
            "/api/o-forms/o-form-containers/:id".replace(
              ":id",
              state.surveyPages[identity].id
            )
          )
          .then((response) => {
            // A delete request will return a 204 on success from the API
            const success = response.status === 204;

            if (success) {
              // Before deleting the association of page identities to survey question ids, get
              // all IDs for the questions that were mapped to that page. We want to remove these
              // questions too
              const surveyPageQuestionIds =
                getters.surveyPageQuestionIdsPerPage(identity);

              commit(REMOVE_SURVEY_PAGE_QUESTION_MAP_PAGE, identity);
              commit(REMOVE_SURVEY_PAGE, identity);
              for (const id of surveyPageQuestionIds) {
                commit(REMOVE_SURVEY_PAGE_QUESTION, id);
              }
              state.surveyPageCounter--;
            }

            commit(SET_IS_EDITING_SURVEY, false);
            return Promise.resolve([success]);
          });
      }

      return Promise.resolve([false]);
    },
    changePage({ state, commit }, { identity, data }) {
      if (Object.hasOwn(state.surveyPages, identity)) {
        const newData = {
          identity,
          attributes: { ...state.surveyPages[identity] },
        };

        if (Object.prototype.hasOwnProperty.call(data, "title")) {
          newData.attributes.title = data.title;
        }

        commit(APPEND_SURVEY_PAGES, [newData]);
      }
    },
    /*
    Create a new page question for the given page for the survey
    */
    addPageQuestion({ commit, state }, pageIdentity) {
      commit(SET_ERROR, "");
      commit(SET_IS_EDITING_SURVEY, true);
      const surveyPageIdentity = pageIdentity;
      const payload = {
        data: {
          type: "oFormFields",
          attributes: {
            o_form_version_id: state.survey.oFormVersionId,
            o_form_container_identity: surveyPageIdentity,
            // All fields that are initially created will be "placeholder" fields. These fields won't be expected
            // to do anything until they are changed to a non placeholder type
            o_form_field_type_id: "placeholder",
            is_active: true,
            title: "Placeholder",
          },
        },
      };

      return Vue.axios
        .post("/api/o-forms/o-form-fields", payload)
        .then((response) => {
          // The request is successful if an entity is added
          const success = response.status === 201;

          if (success) {
            commit(APPEND_SURVEY_PAGE_QUESTIONS, [response.data.data]);
            // Track which page the new question was added to
            commit(
              APPEND_SURVEY_PAGE_QUESTION_MAP,
              new Map([[surveyPageIdentity, [response.data.data.id]]])
            );
          } else {
            commit(
              SET_ERROR,
              Object.prototype.hasOwnProperty.call(response.data, "message") &&
                response.data.message.length > 0
                ? response.data.message
                : "Could not save data"
            );
          }

          commit(SET_IS_EDITING_SURVEY, false);
          return Promise.resolve([success]);
        })
        .catch(() => {
          commit(SET_ERROR, "Could not add Question");
          commit(SET_IS_EDITING_SURVEY, false);
          return Promise.resolve([false]);
        });
    },
    /*
    Delete the page question with the given ID. This requires deleting it via the API and, if successful,
    removing it from local scope since it is no longer a valid question
    */
    deletePageQuestion({ commit }, id) {
      commit(SET_IS_EDITING_SURVEY, true);
      return Vue.axios
        .delete("/api/o-forms/o-form-fields/:id".replace(":id", id))
        .then((response) => {
          // A delete request will return a 204 on success from the API
          const success = response.status === 204;

          if (success) {
            commit(REMOVE_SURVEY_PAGE_QUESTION_MAP_QUESTION, id);
            commit(REMOVE_SURVEY_PAGE_QUESTION, id);
          }

          commit(SET_IS_EDITING_SURVEY, false);
          return Promise.resolve([success]);
        });
    },
    /*
    Change the local representation of a page question with whatever new data is
    desired.

    This allows for us to change information about one to many questions locally
    before saving changes to these questions when editing a survey
    */
    changePageQuestion({ state, commit }, { id, data }) {
      if (state.surveyPageQuestions[id]) {
        const newData = {
          id,
          attributes: { ...state.surveyPageQuestions[id] },
        };

        if (Object.prototype.hasOwnProperty.call(data, "oFormFieldTypeId")) {
          newData.attributes.oFormFieldTypeId = data.oFormFieldTypeId;
        }

        if (
          Object.prototype.hasOwnProperty.call(data, "oFormFieldCategoryId")
        ) {
          newData.attributes.oFormFieldCategoryId = data.oFormFieldCategoryId;
        }

        // if (Object.prototype.hasOwnProperty.call(data, "tags")) {
        //   newData.attributes.tags = data;
        // }

        if (Object.prototype.hasOwnProperty.call(data, "title")) {
          newData.attributes.title = data.title;
        }

        if (Object.prototype.hasOwnProperty.call(data, "configuration")) {
          newData.attributes.configuration = data.configuration;
        }

        commit(APPEND_SURVEY_PAGE_QUESTIONS, [newData]);
      }
    },
    /*
    Set page order for pages on the current survey
     */
    changePageOrder({ state, commit }, orderedPageIdentities) {
      const newData = [];

      orderedPageIdentities.forEach((identity, index) => {
        if (state.surveyPages[identity]) {
          const page = {
            attributes: { ...state.surveyPages[identity] },
          };

          page.attributes.displayOrder = index;

          newData.push(page);
        }
      });

      commit(APPEND_SURVEY_PAGES, newData);
    },
    /*
    Add an additional languages for the survey
    */
    getSurveyAdditionalLanguages({ commit, state }) {
      commit(SET_ERROR, "");
      commit(SET_IS_EDITING_SURVEY, true);

      const payload = {
        data: {
          type: "languagesOForms",
          attributes: {
            o_form_id: state.survey.id,
          },
        },
      };

      return Vue.axios
        .get("/api/o-forms/languages-o-forms", payload)
        .then((response) => {
          const success = response.status === 200;

          if (success) {
            commit(SET_SURVEY_ADDITIONAL_LANGUAGES, response.data);
          } else {
            commit(
              SET_ERROR,
              Object.prototype.hasOwnProperty.call(response.data, "message") &&
                response.data.message.length > 0
                ? response.data.message
                : "Could not save data"
            );
          }

          commit(SET_IS_EDITING_SURVEY, false);
          return Promise.resolve([success]);
        })
        .catch(() => {
          commit(SET_ERROR, "Could not get languages");
          commit(SET_IS_EDITING_SURVEY, false);
          return Promise.resolve([false]);
        });
    },
    /*
      add/remove language relationships to surveys
    */
    toggleSurveyAdditionalLanguage({ commit, state }, languages) {
      commit(SET_ERROR, "");
      commit(SET_IS_EDITING_SURVEY, true);

      let languagesData = [];
      if (languages.length) {
        languages.forEach(function (language) {
          let data = {
            type: "languages",
            id: language.id,
          };
          languagesData.push(data);
        });
      }

      const payload = {
        data: {
          type: "oForms",
          id: state.survey.id,
          relationships: {
            languages: {
              data: languagesData,
            },
          },
        },
      };

      return Vue.axios
        .patch(
          "/api/o-forms/o-forms/:id".replace(":id", state.survey.id),
          payload
        )
        .then(() => {
          commit(SET_IS_EDITING_SURVEY, false);
          return Promise.resolve([true]);
        })
        .catch(() => {
          commit(SET_ERROR, "Could not toggle language");
          commit(SET_IS_EDITING_SURVEY, false);
          return Promise.resolve([false]);
        });
    },
    exportMetrics({ commit }, identity) {
      commit(SET_ERROR, "");

      return Vue.axios
        .get("/api/export-survey-metrics/" + identity)
        .then((response) => {
          const success = response.status === 200;
          if (!success) {
            commit(SET_ERROR, "Could not export survey");
            return false;
          }
          return response.data;
        })
        .catch(() => {
          commit(SET_ERROR, "Could not export survey");
          return false;
        });
    },
  },
};
