import { DateTime } from "luxon";

export const formatFullDatetime = (value) => {
  if (!value) return "";
  const date = DateTime.fromISO(value);
  return date.toFormat("D TTT");
};

export const formatDate = (value) => {
  if (!value) return "";
  const date = DateTime.fromISO(value);
  return date.toFormat("D");
};
