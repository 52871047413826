<template>
  <v-container>
    <v-progress-linear indeterminate></v-progress-linear>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "Logout",
  computed: {},
  methods: {
    ...mapActions("auth", ["logout"]),
  },
  mounted() {
    this.logout();
  },
};
</script>

<style scoped></style>
