<template>
  <div v-if="dataLoaded">
    <v-navigation-drawer
      v-model="drawer"
      floating
      fixed
      :mini-variant="rail"
      :permanent="!isSmall"
      app
      color="black"
    >
      <template v-slot:prepend>
        <v-toolbar color="black" dark @click.stop="rail = !rail">
          <v-toolbar-title
            ><v-img src="/img/logo_horizontal.png"></v-img
          ></v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon>
            <v-icon v-if="rail">mdi-chevron-right</v-icon>
            <v-icon v-else>mdi-chevron-left</v-icon>
            <span class="d-none" aria-hidden="true">Collapse</span>
          </v-btn>
        </v-toolbar>
      </template>
      <v-list nav dense dark>
        <v-list-item-group
          v-model="group"
          active-class="primary darken-4 text--accent-4"
          mandatory
        >
          <v-list-item
            dark
            v-for="(link, index) in navigation_links"
            :key="index"
            @click="link.urlName ? navClick(link) : null"
            :class="link.isAdminSection ? 'ms-4' : null"
          >
            <v-list-item-icon>
              <v-icon>{{ link.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              {{ link.label }}
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <navigation-bar></navigation-bar>
    <v-main>
      <v-container fluid>
        <router-view></router-view>
      </v-container>
    </v-main>
    <app-footer></app-footer>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import AppFooter from "@admin/components/AppFooter";
import NavigationBar from "@admin/components/NavigationBar.vue";

export default {
  name: "AppLayout",
  components: { NavigationBar, AppFooter },
  data: () => ({
    dataLoaded: false,
    user_links: [
      {
        label: "Logout",
        url: "/logout",
      },
    ],
    footer_links: [
      {
        label: "Home",
        url: "/",
      },
      {
        label: "About",
        url: "/about",
      },
    ],
    drawer: false,
    rail: false,
    group: null,
  }),
  computed: {
    ...mapGetters({ navigation_links: "getNavigationLinks" }),
    isSmall() {
      return !!this.$vuetify.breakpoint.smAndDown;
    },
  },
  methods: {
    navClick(link) {
      if (this.$route.name !== link.urlName)
        this.$router.push({ name: link.urlName });
      if (Object.hasOwn(link, "rail")) this.rail = !!link.rail;
      else this.rail = false;
    },
  },
  async beforeCreate() {
    await this.$store.dispatch("initialState");
    this.dataLoaded = true;
  },
};
</script>

<style lang="scss" scoped>
.v-application .v-navigation-drawer a {
  color: white;
}
.v-list-item-group {
  a {
    text-decoration: none;
    .v-list-item__title {
      text-decoration: underline;
    }
  }
}
</style>
