import { enUS } from "date-fns/locale";
import { languageCodes } from "@utils/plugins/language_codes";
import Vue from "vue";
import {
  SET_ERROR,
  SET_INCLUDED,
  SET_LANGUAGE,
  SET_LANGUAGES,
  SET_LANGUAGES_LIST,
  SET_LANGUAGES_COUNT,
  CLEAR_LANGUAGE,
  REMOVE_LANGUAGE,
} from "@shared/store/mutation-types";
import { getField, updateField } from "vuex-map-fields";

const emptyLanguage = function () {
  return {
    id: "",
    name: "",
    description: "",
    iso_code: "",
    fallback_value: "",
    is_active: true,
    dateLocale: enUS,
  };
};

/**
 * Provide a store to represent system languages. These languages will be the languages that particpants can
 * see content in when using the participant portal, and also the languages admins can configure content for
 * when configuring data (e.g. surveys) in the admin portal
 *
 * IMORTANT NOTES:
 * - These languages will be utilized using Vue's i18n library
 * - The admin system has the ability to dynamically configure content that will be shown in the participant portal
 *  (e.g. Surveys). When an admin dynamically configures translated content, we want to be sure we interpret
 *  the data for that configuration in the proper locations the translations are occuring.
 * - In other words, we want to provide interpreted translations on a per component basis that doesn't affect the
 *  global translations. In order to do this, the following property must be established in a component that should have
 *  component specific messages:
 *  { i18n: {messages: {}}
 *  As long as this property is defined in a component's object definition, it'll get a unique object instance for Vuei18n.
 *  Without doing this, a component will share the global Vuei18n object, and any messages it provides will be shared with
 *  all other components. Since we have dynamic components for surveys, not having the i18n property on the component
 *  will, for example, share the translated label for one question with all other questions. In other words, all questions would show
 *  the same label
 */
export default {
  namespaced: true,
  state: {
    error: "",
    included: [],
    language: emptyLanguage(),
    languages: [],
    languagesList: [],
    languagesCount: 0,
  },
  getters: {
    getField,
    languages(state) {
      return state.languages;
    },
    languagesList(state) {
      return state.languagesList;
    },
    languageTargets(state) {
      return [
        {
          text: "English",
          value: "en",
          dateLocale: enUS,
        },
      ].concat(state.languagesList);
    },
    localeDateTarget: (state) => (locale) => {
      for (const target of state.languages) {
        if (target.value === locale && Object.hasOwn(target, "dateLocale")) {
          return target.dateLocale;
        }
      }

      // Return the equivalent locale of the default language target if one couldn't
      // be matched for secondary language targets
      return enUS;
    },
    isoCodeList() {
      return languageCodes;
    },
  },
  mutations: {
    updateField,
    [CLEAR_LANGUAGE](state) {
      state.language = emptyLanguage();
    },
    [SET_LANGUAGES_COUNT](state, meta) {
      state.languagesCount = meta.record_count;
    },
    [SET_LANGUAGE](state, language) {
      Object.assign(state.language, language.attributes);
      state.language.name = language.attributes.name;
      state.language.description = language.attributes.description;
      state.language.iso_code = language.attributes.isoCode.replace("_", "-");
      let fallbackCode = language.attributes.isoCode.split("-")[0];
      state.language.fallback_value = fallbackCode;
      state.language.is_active = language.attributes.isActive;
    },
    [SET_INCLUDED](state, included) {
      state.included = included;
    },
    [SET_ERROR](state, errors) {
      if (Array.isArray(errors)) {
        errors.forEach(function (error) {
          state.error = state.error + error.detail;
        });
      } else {
        state.error = errors;
      }
    },
    [REMOVE_LANGUAGE](state, id) {
      const index = state.languages.findIndex((language) => language.id === id);
      Vue.delete(state.languages, index);
    },
    [SET_LANGUAGES](state, secondaryLanguages) {
      const newLanguages = [];

      secondaryLanguages.forEach(function (language) {
        //get fallbackCode
        let fallbackCode = language.attributes.isoCode.split("-")[0];

        let secondaryLanguage = {
          id: language.id,
          name: language.attributes.name,
          description: language.attributes.description,
          iso_code: language.attributes.isoCode,
          fallback_value: fallbackCode,
          is_active: language.attributes.isActive,
          dateLocale: enUS,
        };

        newLanguages.push(secondaryLanguage);
      });

      state.languages = newLanguages;
    },
    [SET_LANGUAGES_LIST](state, secondaryLanguages) {
      const newLanguages = [];

      secondaryLanguages.forEach(function (language) {
        //get fallbackCode
        let fallbackCode = language.attributes.isoCode.split("-")[0];

        newLanguages.push({
          id: language.id,
          text: language.attributes.description,
          value: language.attributes.isoCode,
          fallbackValue: fallbackCode,
          dateLocale: enUS,
        });
      });

      state.languagesList = newLanguages;
    },
  },
  actions: {
    addLanguage({ commit, state }) {
      commit(SET_ERROR, "");
      let data = {
        data: {
          type: "languages",
          attributes: {
            name: state.language.name,
            description: state.language.description,
            isoCode: state.language.iso_code.replace("-", "_"),
            isActive: state.language.is_active,
          },
        },
      };
      return Vue.axios
        .post("/api/languages", data)
        .then(({ data }) => {
          if (data.data.id) {
            commit(SET_LANGUAGE, data.data);
          }
          if (data.errors) {
            commit(SET_ERROR, data.errors);
            return false;
          }
          return data.data.id;
        })
        .catch(({ response }) => {
          if (response.data.errors) {
            commit(SET_ERROR, response.data.errors);
          }
          return false;
        });
    },
    clearLanguage({ commit }) {
      commit(CLEAR_LANGUAGE);
      commit(SET_INCLUDED, []);
    },
    clearLanguages({ commit }) {
      commit(SET_LANGUAGES, []);
      commit(SET_INCLUDED, []);
    },
    deleteLanguage({ commit }, id) {
      return Vue.axios
        .delete("/api/languages/:id".replace(":id", id))
        .then((response) => {
          // A delete request will return a 204 on success from the API
          const success = response.status === 204;

          if (success) {
            commit(REMOVE_LANGUAGE, id);
          }

          return Promise.resolve([success]);
        });
    },
    editLanguage({ commit, state }, id) {
      commit(SET_ERROR, "");
      let data = {
        data: {
          id: id,
          type: "languages",
          attributes: {
            name: state.language.name,
            description: state.language.description,
            iso_code: state.language.iso_code.replace("-", "_"),
            is_active: state.language.is_active,
          },
        },
      };
      return Vue.axios
        .patch("/api/languages/:id".replace(":id", id), data)
        .then(({ data }) => {
          if (data.errors) {
            commit(SET_ERROR, data.errors);
            return false;
          }
          return id;
        })
        .catch(({ response }) => {
          if (response.data.errors) {
            commit(SET_ERROR, response.data.errors);
          }
          return false;
        });
    },
    getLanguage({ commit }, id) {
      commit(CLEAR_LANGUAGE);
      commit(SET_INCLUDED, []);
      commit(SET_ERROR, "");
      return Vue.axios
        .get(`/api/languages/${id}`, {
          params: {
            "fields[languages]": "id,name,isoCode,description,isActive",
          },
        })
        .then(({ data }) => {
          if (data.data.id) {
            commit(SET_LANGUAGE, data.data);
            commit(SET_INCLUDED, data.included);
          } else if (data.errors) {
            commit(SET_ERROR, data.errors[0].title);
          }
          return true;
        })
        .catch(({ data }) => {
          if (data.errors) {
            commit(SET_ERROR, data.errors[0].title);
          }
          return false;
        });
    },
    getLanguages(
      { commit },
      { sortBy = ["description"], page = 1, limit = 1000 } = {}
    ) {
      commit(SET_LANGUAGES, []);
      const fields = ["id", "name", "description", "isoCode", "isActive"];
      return Vue.axios
        .get("/api/languages", {
          params: {
            sort: sortBy.join(","),
            page,
            limit,
            "fields[languages]": fields.join(","),
          },
        })
        .then(({ data }) => {
          commit(SET_LANGUAGES_COUNT, data.meta);
          commit(SET_LANGUAGES, data.data);
          return true;
        });
    },
    getLanguagesList(
      { commit },
      { sortBy = ["description"], page = 1, limit = 1000 } = {}
    ) {
      commit(SET_LANGUAGES_LIST, []);
      const fields = ["id", "name", "description", "isoCode", "isActive"];
      return Vue.axios
        .get("/api/languages", {
          params: {
            sort: sortBy.join(","),
            page,
            limit,
            "fields[languages]": fields.join(","),
          },
        })
        .then(({ data }) => {
          commit(SET_LANGUAGES_LIST, data.data);
          return true;
        })
        .catch((response) => {
          commit(SET_ERROR, response);
          return false;
        });
    },
  },
};
