export default {
  errorMessage: "",
  footerProps: { itemsPerPageOptions: [10, 20, 50, 100, 1000] },
  navigation_links: [
    {
      label: "Sponsors",
      urlName: "SponsorsIndex",
      icon: "mdi-alpha-s-box",
      isAdminSection: false,
      auth: "admin",
    },
    {
      label: "Engagements",
      urlName: "EngagementsIndex",
      icon: "mdi-dna",
      isAdminSection: false,
      auth: "admin",
    },
    {
      label: "Site Locations",
      urlName: "SiteLocationsIndex",
      icon: "mdi-map-marker",
      isAdminSection: false,
      auth: ["Admin", "Super User"],
    },
    {
      label: "System Configuration",
      icon: "mdi-cogs",
      isAdminSection: false,
      auth: ["Admin", "Super User"],
    },
    {
      label: "User Management",
      urlName: "UsersIndex",
      icon: "mdi-account-group",
      auth: ["Admin", "Super User"],
      isAdminSection: true,
    },
    {
      label: "Generic Messages",
      urlName: "GenericMessagesIndex",
      icon: "mdi-cog",
      auth: ["Admin", "Super User"],
      isAdminSection: true,
    },
    /*
    {
      label: "Contact Form",
      urlName: "ContactFieldsIndex",
      icon: "mdi-cog",
      auth: ["Admin", "Super User"],
      isAdminSection: true,
    },
    */
    {
      label: "Languages",
      urlName: "LanguagesIndex",
      icon: "mdi-web",
      auth: ["Admin", "Super User"],
      isAdminSection: true,
    },
    {
      label: "RMS Audits",
      urlName: "RmsAuditsIndex",
      icon: "mdi-message-alert-outline",
      auth: ["Super User"],
      isAdminSection: true,
    },
    {
      label: "Log In",
      urlName: "Login",
      icon: "mdi-account",
      isAdminSection: false,
      auth: false,
    },
  ],
};
