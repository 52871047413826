import { SET_ERROR, SET_COUNTRIES } from "@admin/store/mutation-types";
import Vue from "vue";

export default {
  namespaced: true,
  state: {
    countries: [],
    error: "",
  },
  mutations: {
    [SET_COUNTRIES](state, countries) {
      state.countries = [];
      for (let c = 0; c < countries.length; c++) {
        state.countries.push({
          value: countries[c].id,
          text: countries[c].attributes.name,
          isoCode: countries[c].attributes.isoCode,
        });
      }
    },
    [SET_ERROR](state, message) {
      state.error = message;
    },
  },
  actions: {
    getCountries({ commit, state }) {
      if (state.countries.length > 0) {
        return true;
      }
      commit(SET_COUNTRIES, []);
      return Vue.axios
        .get("/api/countries?fields[countries]=id,name,isoCode")
        .then(({ data }) => {
          commit(SET_COUNTRIES, data.data);
          return true;
        })
        .catch((response) => {
          commit(SET_ERROR, response);
          return false;
        });
    },
  },
};
