import {
  SET_ERROR,
  SET_GENERIC_MESSAGE,
  SET_GENERIC_MESSAGES,
  SET_GENERIC_MESSAGES_COUNT,
  SET_GENERIC_MESSAGES_DEFAULTS_LIST,
  SET_GENERIC_MESSAGES_DEFAULTS_LIST_COUNT,
  SET_GENERIC_MESSAGES_DEFAULTS_TRANSLATIONS_LIST,
  SET_GENERIC_MESSAGES_GLOBALS_LIST,
  SET_GENERIC_MESSAGES_GLOBALS_LIST_COUNT,
} from "@admin/store/mutation-types";
import Vue from "vue";
import { getField, updateField } from "vuex-map-fields";

const emptyGenericMessage = function () {
  return {
    id: "",
    keyName: "",
    message: "",
    translations: [],
  };
};

export default {
  actions: {
    clearGenericMessages({ commit }, messageType = null) {
      switch (messageType) {
        case "default":
          commit(SET_GENERIC_MESSAGES_DEFAULTS_LIST, []);
          commit(SET_GENERIC_MESSAGES_DEFAULTS_LIST_COUNT, 0);
          break;
        case "global":
          commit(SET_GENERIC_MESSAGES_GLOBALS_LIST, []);
          commit(SET_GENERIC_MESSAGES_GLOBALS_LIST_COUNT, 0);
          break;
        default:
          commit(SET_GENERIC_MESSAGES, []);
          commit(SET_GENERIC_MESSAGES_COUNT, 0);
          break;
      }
    },
    clearGenericMessage({ commit }) {
      commit(SET_GENERIC_MESSAGE, []);
    },
    getGenericMessage({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const params = [];
        const apiEndpoint = "/api/generic-messages/" + payload + "?" + params;
        Vue.axios
          .get(apiEndpoint)
          .then((response) => {
            commit(SET_GENERIC_MESSAGE, response.data);
            resolve(response.data);
          })
          .catch((error) => {
            commit(SET_ERROR, error.message);
            reject(error);
          });
      });
    },
    //   in use
    getGenericMessages(
      { commit },
      { sortBy = ["keyName"], page = 1, limit = 50, messageType = null } = {}
    ) {
      const params = {
        page,
        limit,
      };
      if (messageType) {
        params.messageType = messageType;
      }

      const sort = [];
      sortBy.forEach((fieldSort) => {
        sort.push(fieldSort);
      });
      params.sort = sort.join(",");

      return new Promise((resolve, reject) => {
        Vue.axios
          .get("/api/generic-messages", { params })
          .then((response) => {
            switch (messageType) {
              case "default":
                commit(SET_GENERIC_MESSAGES_DEFAULTS_LIST, response.data);
                commit(
                  SET_GENERIC_MESSAGES_DEFAULTS_LIST_COUNT,
                  response.data.meta
                );
                break;
              case "global":
                commit(SET_GENERIC_MESSAGES_GLOBALS_LIST, response.data);
                commit(
                  SET_GENERIC_MESSAGES_GLOBALS_LIST_COUNT,
                  response.data.meta
                );
                break;
              default:
                commit(SET_GENERIC_MESSAGES_COUNT, response.data.meta);
                commit(SET_GENERIC_MESSAGES, response.data);
                break;
            }

            resolve(response.data);
          })
          .catch((error) => {
            commit(SET_ERROR, error.message);
            reject(error);
          });
      });
    },
    getDefaultTranslations({ commit, state }) {
      state.genericMessagesDefaultTranslationsListFieldsLoaded = 0;
      state.genericMessagesDefaultTranslationsListFieldCount = 0;
      let defaultMessageTranslations = {};
      return new Promise((resolve, reject) => {
        const params = {
          messageType: "default",
          limit: 1000,
        };
        Vue.axios
          .get("/api/generic-messages/", { params })
          .then((response) => {
            // get the number of default messages
            state.genericMessagesDefaultTranslationsListFieldCount =
              response.data.data.length;
            // get generic messages with translations for each default messages
            response.data.data.forEach((item) => {
              // set default key data
              if (defaultMessageTranslations["default"] === undefined) {
                Vue.set(defaultMessageTranslations, "default", {});
              }
              if (
                defaultMessageTranslations["default"][
                  item.attributes.keyName
                ] === undefined
              ) {
                Vue.set(
                  defaultMessageTranslations["default"],
                  item.attributes.keyName,
                  item.attributes.message
                );
              }

              // make request for each default message to include translations
              Vue.axios
                .get("/api/generic-messages/" + item.id)
                .then((responseData) => {
                  let keyName = responseData.data.data.attributes.keyName;
                  let translationsData =
                    responseData.data.data.attributes.translations;
                  Object.keys(translationsData).forEach((locale) => {
                    // check if key exist in object
                    if (defaultMessageTranslations[locale] === undefined) {
                      Vue.set(defaultMessageTranslations, locale, {});
                    }
                    if (
                      defaultMessageTranslations[locale][keyName] === undefined
                    ) {
                      Vue.set(
                        defaultMessageTranslations[locale],
                        keyName,
                        translationsData[locale].message
                      );
                    }
                  });
                  state.genericMessagesDefaultTranslationsListFieldsLoaded++;
                  resolve(responseData.data);
                })
                .catch((error) => {
                  commit(SET_ERROR, error.message);
                  reject(error);
                });
            });

            commit(
              SET_GENERIC_MESSAGES_DEFAULTS_TRANSLATIONS_LIST,
              defaultMessageTranslations
            );

            resolve(response.data);
          })
          .catch((error) => {
            commit(SET_ERROR, error.message);
            reject(error);
          });
      });
    },
    editGenericMessage({ commit, state }, id) {
      commit(SET_ERROR, "");
      let data = {
        data: {
          id: id,
          type: "genericMessages",
          attributes: {
            keyName: state.genericMessage.keyName,
            message: state.genericMessage.message,
            translations: state.genericMessage.translations,
          },
        },
      };

      return new Promise((resolve, reject) => {
        Vue.axios
          .patch("/api/generic-messages/" + id, data)
          .then((response) => {
            commit(SET_GENERIC_MESSAGE, response.data);
            resolve(response.data);
          })
          .catch((error) => {
            commit(SET_ERROR, error.message);
            reject(error);
          });
      });
    },
  },
  getters: {
    getField,
    genericMessagesDefaultsList: (state) => state.genericMessagesDefaultsList,
    genericMessagesDefaultsListCount: (state) =>
      state.genericMessagesDefaultsListCount,
    genericMessagesGlobalsList: (state) => state.genericMessagesGlobalsList,
    genericMessagesGlobalsListCount: (state) =>
      state.genericMessagesGlobalsListCount,
    genericMessagesDefaultTranslations: (state) =>
      state.genericMessagesDefaultTranslationsList,
    genericMessagesDefaultTranslationsListFieldsLoaded: (state) => {
      return state.genericMessagesDefaultTranslationsListFieldsLoaded;
    },
    genericMessagesDefaultTranslationsListFieldCount: (state) => {
      return state.genericMessagesDefaultTranslationsListFieldCount;
    },
  },
  mutations: {
    updateField,
    [SET_ERROR](state, message) {
      state.error = message;
    },
    [SET_GENERIC_MESSAGE](state, payload) {
      if (payload.data === undefined) {
        state.genericMessage = emptyGenericMessage();
        return;
      }
      let newMessage = {};
      newMessage = payload.data.attributes;
      newMessage.id = payload.data.id;

      Object.assign(state.genericMessage, newMessage);
    },
    [SET_GENERIC_MESSAGES](state, payload) {
      state.genericMessages = payload.data;
    },
    [SET_GENERIC_MESSAGES_COUNT](state, payload) {
      state.genericMessagesCount = payload.record_count;
    },
    [SET_GENERIC_MESSAGES_DEFAULTS_TRANSLATIONS_LIST](state, payload) {
      state.genericMessagesDefaultTranslationsList = payload;
    },
    [SET_GENERIC_MESSAGES_DEFAULTS_LIST](state, payload) {
      state.genericMessagesDefaultsList = payload.data;
    },
    [SET_GENERIC_MESSAGES_DEFAULTS_LIST_COUNT](state, payload) {
      state.genericMessagesDefaultsListCount = payload.record_count;
    },
    [SET_GENERIC_MESSAGES_GLOBALS_LIST](state, payload) {
      state.genericMessagesGlobalsList = payload.data;
    },
    [SET_GENERIC_MESSAGES_GLOBALS_LIST_COUNT](state, payload) {
      state.genericMessagesGlobalsListCount = payload.record_count;
    },
  },
  namespaced: true,
  state: {
    error: "",
    genericMessage: emptyGenericMessage(),
    genericMessages: [],
    genericMessagesCount: 0,
    genericMessagesDefaultsList: [],
    genericMessagesDefaultsListCount: 0,
    genericMessagesGlobalsList: [],
    genericMessagesGlobalsListCount: 0,
    genericMessagesDefaultTranslationsList: {},

    genericMessagesDefaultTranslationsListFieldsLoaded: 0,
    genericMessagesDefaultTranslationsListFieldCount: 0,
  },
};
