<template>
  <div></div>
</template>

<script>
export default {
  name: "Dashboard",
  components: {},
  computed: {},
  beforeMount() {
    this.$router.push({ name: "SponsorsIndex" });
  },
};
</script>
