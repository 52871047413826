import { getField, updateField } from "vuex-map-fields";
import Vue from "vue";

const defaultState = () => {
  return {
    items: [],
  };
};

export default {
  namespaced: true,
  state: defaultState(),
  getters: {
    getField,
    getFieldTypesList: (state) => {
      const itemsAsList = [];

      state.items.forEach((item) => {
        itemsAsList.push({ value: item.id, text: item.attributes.title });
      });

      return itemsAsList;
    },
    getFieldTypeDisplay: (state) => (typeId) => {
      const foundType = state.items.find((type) => type.id === typeId);

      return foundType !== undefined ? foundType.title : "";
    },
  },
  mutations: {
    updateField,
    RESET_STATE(state) {
      Object.assign(state, defaultState());
    },
    SET_OFORM_FIELD_TYPES(state, types) {
      state.items = types;
    },
    SET_OFORM_FIELD_TYPES_LIST(state, typesList) {
      state.itemsAsList = typesList;
    },
  },
  actions: {
    refreshState({ commit }) {
      commit("RESET_STATE");
    },
    getOFormFieldTypes({ commit }) {
      let route = "/api/o-forms/o-form-field-types";

      return Vue.axios
        .get(route)
        .then((response) => {
          commit("SET_OFORM_FIELD_TYPES", response.data.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
