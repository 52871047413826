import { SET_ERROR, SET_TARGET_GROUPS_LIST } from "@admin/store/mutation-types";
import Vue from "vue";

export default {
  namespaced: true,
  state: {
    targetGroupsList: [],
    error: "",
  },
  mutations: {
    [SET_TARGET_GROUPS_LIST](state, targetGroups) {
      // This is used for select elements in forms.
      targetGroups.forEach(function (targetGroup) {
        state.targetGroupsList.push({
          value: targetGroup.id,
          text: targetGroup.attributes.name,
        });
      });
    },
    [SET_ERROR](state, message) {
      state.error = message;
    },
  },
  actions: {
    getTargetGroupsList({ commit, state }) {
      if (state.targetGroupsList.length > 0) {
        return true;
      }
      commit(SET_TARGET_GROUPS_LIST, []);
      return Vue.axios
        .get("/api/target-groups?fields[targetGroups]=id,name")
        .then(({ data }) => {
          commit(SET_TARGET_GROUPS_LIST, data.data);
          return true;
        })
        .catch((response) => {
          commit(SET_ERROR, response);
          return false;
        });
    },
  },
};
