<template>
  <svg :x="x" :y="y" :data-node-id="id" class="diagram-node">
    <rect
      fill="#00000000"
      stroke="#000000"
      stroke-width="0"
      x="0"
      y="0"
      rx="3"
      ry="3"
      :width="nodeModel.width"
      :height="nodeModel.height"
    />
    <rect
      fill="#000000"
      x="0"
      y="0"
      rx="3"
      ry="3"
      :width="nodeModel.width"
      :height="nodeModel.height"
    />
    <rect
      fill="#000000"
      x="2"
      y="2"
      rx="3"
      ry="3"
      :width="nodeModel.width - 4"
      height="20"
    />
    <text
      x="5"
      y="15"
      font-size="16"
      font-weight="bold"
      fill="#ffffff"
      ref="title"
    >
      {{ nodeModel.title }}
    </text>
    <rect
      fill="#ffffff"
      x="2"
      y="20"
      rx="3"
      ry="3"
      :width="nodeModel.width - 4"
      :height="nodeModel.height - 24"
    />
    <slot />
  </svg>
</template>
<script>
import DiagramNode from "@admin/components/flow_diagram/DiagramNode.js";

export default {
  name: "Node",
  props: {
    id: {
      type: String,
      required: true,
    },
    width: {
      type: Number,
      required: true,
    },
    height: {
      type: Number,
      required: true,
    },
    nodeModel: {
      type: DiagramNode,
      required: true,
    },
  },
  computed: {
    x() {
      return this.nodeModel.x;
    },
    y() {
      return this.nodeModel.y;
    },
  },
};
</script>
<style scoped>
.diagram-node {
  overflow: visible;
}
</style>
