import {
  SET_ERROR,
  SET_SCREENER,
  SET_SCREENERS,
  SET_SCREENERS_COUNT,
  SET_SCREENER_ADDITIONAL_LANGUAGES,
  SET_SCREENER_ENGAGEMENTS,
} from "@admin/store/mutation-types";
import Vue from "vue";
import { enUS } from "date-fns/locale";
import { getField, updateField } from "vuex-map-fields";

const emptyScreener = function () {
  return {
    id: "",
    versionId: "",
    description: "",
    sponsorId: "",
    sponsorName: "",
    title: "",
    type: "super-screener",
    userEmail: "",
    userId: "",
    acceptanceContactFormMessage: "",
    acceptanceConsentMessage: "",
    notActiveContactFormMessage: "",
    notActiveConsentMessage: "",
    notQualifiedContactFormMessage: "",
    notQualifiedConsentMessage: "",
    noTrialSitesContactFormMessage: "",
    noTrialSitesConsentMessage: "",
    qualifiedContactFormMessage: "",
    qualifiedConsentMessage: "",
    trialSiteSelectedContactFormMessage: "",
    trialSiteSelectedConsentMessage: "",
    completedContactFormMessage: "",
    completedConsentMessage: "",
    translations: [],
    engagements: [],
    isActive: true,
    isLocked: false,
    isTest: true,
    styles: {
      back_btn_active: "",
      back_btn_hover: "",
      back_btn_inactive: "",
      back_btn_is_shown: true,

      checkbox_active: "",
      checkbox_error: "",
      checkbox_hover: "",
      checkbox_inactive: "",

      form_field_helper: "",
      form_field_error: "",
      form_field_required: "",

      next_btn_active: "",
      next_btn_error: "",
      next_btn_hover: "",

      response_btn_active: "",
      response_btn_error: "",
      response_btn_hover: "",

      progress_bar_background: "CCCCCC",
      progress_bar_color: "",
    },
    contactFieldsScreeners: [],
  };
};

export default {
  actions: {
    addScreener({ commit, state }, sponsorId) {
      commit(SET_ERROR, "");
      let data = {
        data: {
          type: "screeners",
          attributes: {
            description: state.screener.description,
            sponsorId: sponsorId,
            userId: state.screener.userId,
            isActive: state.screener.isActive,
            isLocked: state.screener.isLocked,
            isTest: state.screener.isTest,
            title: state.screener.title,
            type: state.screener.type,
            isInterestVisible: state.screener.isInterestVisible,
            translations: state.screener.translations,
            styles: state.screener.styles,
          },
          relationships: {
            languages: {
              data: state.screenerAdditionalLanguages.map((language) => {
                return { type: "languages", id: language.id };
              }),
            },
          },
        },
      };

      return new Promise((resolve, reject) => {
        Vue.axios
          .post("/api/screeners", data)
          .then((response) => {
            commit(SET_SCREENER, response.data);
            resolve(response.data);
          })
          .catch((error) => {
            commit(SET_ERROR, error.message);
            reject(error);
          });
      });
    },
    clearScreeners({ commit }) {
      commit(SET_SCREENERS, []);
    },
    clearScreener({ commit }) {
      commit(SET_SCREENER, []);
    },
    deleteScreener({ commit }, screenerId) {
      return new Promise((resolve, reject) => {
        Vue.axios
          .delete("/api/screeners/" + screenerId)
          .then((response) => {
            commit(SET_SCREENER, emptyScreener());
            resolve(response.data);
          })
          .catch((error) => {
            commit(SET_ERROR, error.message);
            reject(error);
          });
      });
    },
    editScreener({ commit, state }, id) {
      commit(SET_ERROR, "");
      let data = {
        data: {
          id: id,
          type: "screeners",
          attributes: {
            description: state.screener.description,
            userId: state.screener.userId,
            isActive: state.screener.isActive,
            isLocked: state.screener.isLocked,
            isTest: state.screener.isTest,
            title: state.screener.title,
            type: state.screener.type,
            acceptanceContactFormMessage:
              state.screener.acceptanceContactFormMessage,
            acceptanceConsentMessage: state.screener.acceptanceConsentMessage,
            notActiveContactFormMessage:
              state.screener.notActiveContactFormMessage,
            notActiveConsentMessage: state.screener.notActiveConsentMessage,
            notQualifiedContactFormMessage:
              state.screener.notQualifiedContactFormMessage,
            notQualifiedConsentMessage:
              state.screener.notQualifiedConsentMessage,
            noTrialSitesContactFormMessage:
              state.screener.noTrialSitesContactFormMessage,
            noTrialSitesConsentMessage:
              state.screener.noTrialSitesConsentMessage,
            qualifiedContactFormMessage:
              state.screener.qualifiedContactFormMessage,
            qualifiedConsentMessage: state.screener.qualifiedConsentMessage,
            trialSiteSelectedContactFormMessage:
              state.screener.trialSiteSelectedContactFormMessage,
            trialSiteSelectedConsentMessage:
              state.screener.trialSiteSelectedConsentMessage,
            completedContactFormMessage:
              state.screener.completedContactFormMessage,
            completedConsentMessage: state.screener.completedConsentMessage,
            translations: state.screener.translations,
            isInterestVisible: state.screener.isInterestVisible,
            styles: state.screener.styles,
          },
        },
      };

      return new Promise((resolve, reject) => {
        Vue.axios
          .patch("/api/screeners/" + id, data)
          .then((response) => {
            commit(SET_SCREENER, response.data);
            resolve(response.data);
          })
          .catch((error) => {
            commit(SET_ERROR, error.message);
            reject(error);
          });
      });
    },
    getScreener({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const params = ["include=users,sponsors,languages,engagements"];
        const apiEndpoint = "/api/screeners/" + payload.id + "?" + params;
        Vue.axios
          .get(apiEndpoint)
          .then((response) => {
            commit(SET_SCREENER, response.data);
            if (response.data.included && response.data.included.length > 0) {
              const languages = [];
              const engagements = [];
              for (const item of response.data.included) {
                switch (item.type) {
                  case "languages":
                    languages.push(item);
                    break;
                  case "engagements":
                    engagements.push(item);
                    break;
                }
              }
              commit(SET_SCREENER_ADDITIONAL_LANGUAGES, languages);
              commit(SET_SCREENER_ENGAGEMENTS, engagements);
            }

            resolve(response.data);
          })
          .catch((error) => {
            commit(SET_ERROR, error.message);
            reject(error);
          });
      });
    },
    getScreeners(
      { commit },
      {
        sortBy = ["-isActive", "title"],
        page = 1,
        limit = 20,
        sponsorId = null,
        include = [],
      } = {}
    ) {
      const params = {
        page,
        limit,
        sponsorId: sponsorId,
      };

      if (include.length > 0) {
        params.include = include.join(",");
      }

      const sort = [];
      const sortAssociated = [];
      sortBy.forEach((fieldSort) => {
        sort.push(fieldSort);
      });

      params.sort = sort.join(",");
      params.sortAssociated = sortAssociated.join(",");

      return new Promise((resolve, reject) => {
        Vue.axios
          .get("/api/screeners", { params })
          .then((response) => {
            commit(SET_SCREENERS_COUNT, response.data.meta);
            commit(SET_SCREENERS, response.data);

            var screenersResponse = response.data;

            // if response has included data, set the engagement list
            if (
              screenersResponse.included &&
              screenersResponse.included.length > 0
            ) {
              const engagements = [];
              for (const item of screenersResponse.included) {
                switch (item.type) {
                  case "engagements":
                    engagements.push(item);
                    break;
                }
              }
              // if engagements were found, loop screeners and add screener.engagements for each screener.relationships.engagements.data.id found
              if (engagements.length > 0) {
                // loop screeners
                screenersResponse.data.forEach((screener) => {
                  screener.engagements = [];
                  // loop screener.relationships.engagements.data if exists
                  if (
                    screener.relationships.engagements &&
                    screener.relationships.engagements.data
                  ) {
                    screener.relationships.engagements.data.forEach(
                      (engagement) => {
                        // loop engagements
                        engagements.forEach((engagementItem) => {
                          // if screener.relationships.engagements.data.id == engagement.id, add engagement to screener.engagements
                          if (engagement.id === engagementItem.id) {
                            screener.engagements.push(engagementItem);
                          }
                        });
                      }
                    );
                  }
                });
              }
              commit(SET_SCREENERS, response.data);
            }
            resolve(response.data);
          })
          .catch((error) => {
            commit(SET_ERROR, error.message);
            reject(error);
          });
      });
    },
    getScreenerAdditionalLanguages({ commit, state }) {
      commit(SET_ERROR, "");

      const payload = {
        data: {
          type: "languagesScreeners",
          attributes: {
            o_form_id: state.survey.id,
          },
        },
      };

      return Vue.axios
        .get("/api/screeners/languages-screeners", payload)
        .then((response) => {
          const success = response.status === 200;

          if (success) {
            commit(SET_SCREENER_ADDITIONAL_LANGUAGES, response.data);
          } else {
            commit(
              SET_ERROR,
              Object.prototype.hasOwnProperty.call(response.data, "message") &&
                response.data.message.length > 0
                ? response.data.message
                : "Could not save data"
            );
          }

          return Promise.resolve([success]);
        })
        .catch(() => {
          commit(SET_ERROR, "Could not get languages");
          return Promise.resolve([false]);
        });
    },
    /*
      add/remove language relationships to screeners
    */
    toggleScreenerAdditionalLanguage({ commit, state }, languages) {
      commit(SET_ERROR, "");

      let languagesData = [];
      if (languages.length) {
        languages.forEach(function (language) {
          let data = {
            type: "languages",
            id: language.id,
          };
          languagesData.push(data);
        });
      }

      const payload = {
        data: {
          type: "screeners",
          id: state.screener.id,
          relationships: {
            languages: {
              data: languagesData,
            },
          },
        },
      };

      return Vue.axios
        .patch("/api/screeners/:id".replace(":id", state.screener.id), payload)
        .then(() => {
          return Promise.resolve([true]);
        })
        .catch(() => {
          commit(SET_ERROR, "Could not toggle language");

          return Promise.resolve([false]);
        });
    },
    updateEngagementScreeners({ commit, state }) {
      const payload = {
        data: {
          type: "engagementsScreeners",
          attributes: {
            engagements: state.screener.engagements,
          },
        },
      };
      return Vue.axios
        .post(`/api/engagements-screeners/update/${state.screener.id}`, payload)
        .then(() => {
          return true;
        })
        .catch(({ data }) => {
          if (data.success === false && data.message) {
            commit(SET_ERROR, data.message);
          }
          return false;
        });
    },
    // Get Sponsor Name when adding new screener
    getSponsor({ commit, state }, id) {
      const fields = ["id", "name"];
      return Vue.axios
        .get(`/api/sponsors/${id}`, {
          params: {
            "fields[sponsors]": fields.join(","),
          },
        })
        .then(({ data }) => {
          state.screener.sponsorName = data.data.attributes.name;
          return true;
        })
        .catch(({ data }) => {
          if (data.errors) {
            commit(SET_ERROR, data.errors);
          }
          return false;
        });
    },

    deleteCloneScreener({ commit }, screenerId) {
      return new Promise((resolve, reject) => {
        Vue.axios
          .delete("/api/screeners/clone/" + screenerId)
          .then((response) => {
            commit(SET_SCREENER, emptyScreener());
            resolve(response.data);
          })
          .catch((error) => {
            commit(SET_ERROR, error.message);
            reject(error);
          });
      });
    },
    updateFromCloneScreener({ commit }, screenerId) {
      return new Promise((resolve, reject) => {
        Vue.axios
          .patch("/api/screeners/update-surveys/" + screenerId)
          .then((response) => {
            commit(SET_SCREENER, emptyScreener());
            resolve(response.data);
          })
          .catch((error) => {
            commit(SET_ERROR, error.message);
            reject(error);
          });
      });
    },
  },
  getters: {
    getField,
    screenerAdditionalLanguagesList(state) {
      return state.screenerAdditionalLanguages;
    },
  },
  mutations: {
    updateField,
    [SET_ERROR](state, message) {
      state.error = message;
    },
    [SET_SCREENER](state, payload) {
      if (payload.data === undefined) {
        // if payload.data is undefined, set screener to empty screener
        state.screener = emptyScreener();
        // clear screener engagements
        state.screenerEngagements = [];
        // clear screener additional languages
        state.screenerAdditionalLanguages = [];
        return;
      }
      let newScreener = {};
      newScreener = payload.data.attributes;
      newScreener.id = payload.data.id;
      newScreener.sponsorId = payload.data.relationships.sponsor.data.id;
      newScreener.userId = payload.data.relationships.user.data.id;
      newScreener.styles = payload.data.attributes.styles
        ? payload.data.attributes.styles
        : emptyScreener().styles;

      Object.assign(state.screener, newScreener);
    },
    [SET_SCREENERS](state, payload) {
      state.screeners = payload.data;
    },
    [SET_SCREENERS_COUNT](state, payload) {
      state.screenersCount = payload.record_count;
    },
    [SET_SCREENER_ADDITIONAL_LANGUAGES](state, languages) {
      const newScreenerLanguages = [];

      for (const language of languages) {
        //get fallbackCode
        let fallbackCode = language.attributes.isoCode.split("_")[0];

        newScreenerLanguages.push({
          id: language.id,
          text: language.attributes.description,
          value: language.attributes.isoCode,
          fallbackValue: fallbackCode,
          dateLocale: enUS,
        });
      }

      // Sort alphabetically by text property
      state.screenerAdditionalLanguages = newScreenerLanguages
        .slice()
        .sort((a, b) => {
          return a.text.localeCompare(b.text);
        });
    },
    [SET_SCREENER_ENGAGEMENTS](state, engagements) {
      const newScreenerEngagements = [];
      for (const engagement of engagements) {
        newScreenerEngagements.push({
          id: engagement.id,
          text: engagement.attributes.name,
          value: engagement.id,
          mediaLiveDate: engagement.attributes.mediaLiveDate,
          mediaEndDate: engagement.attributes.mediaEndDate,
        });
        state.screener.engagements.push({ engagement_id: engagement.id });
      }
      state.screenerEngagements = newScreenerEngagements
        .slice()
        .sort((a, b) => {
          return a.text.localeCompare(b.text);
        });
    },
  },
  namespaced: true,
  state: {
    error: "",
    screener: emptyScreener(),
    screeners: [],
    screenersCount: 0,
    screenerAdditionalLanguages: [],
    screenerEngagements: [],
  },
};
