import Vue from "vue";
import hasIn from "lodash.hasin";
import isEmpty from "lodash.isempty";
import { getField, updateField } from "vuex-map-fields";
import { SET_ERROR, SET_USER_DATA } from "@users/store/mutation-types";

export default {
  namespaced: true,
  state: {
    user: {
      id: null,
      username: "",
      secret_verified: false,
      locale: "",
      identity_provider: null,
    },
    qr_code: "",
    error: "",
  },
  getters: {
    getField,
    user() {
      return Vue.auth.user();
    },
    isLoggedIn() {
      return Vue.auth.check();
    },
  },
  mutations: {
    updateField,
    [SET_ERROR](state, val) {
      state.error = val ? val : false;
    },
    [SET_USER_DATA](state, data) {
      Object.assign(state.user, data);
    },
  },
  actions: {
    fetch(data) {
      return Vue.auth.fetch(data);
    },
    refresh(data) {
      return Vue.auth.refresh(data);
    },
    login(
      { commit },
      { username, password, authService, code = "", locale = "" } = {}
    ) {
      return new Promise((resolve) => {
        const params = {
          data: {
            username: username,
            password: password,
            code: code,
            lang: locale,
          },
        };

        if (authService) {
          // TODO when we anticipate more than just the DUO service (e.g. start using local)
          // we'll need to introduce a new function that identifies how we'd like to affect params
          // (e.g. if withCredentials is included) based on which authService is provided
          params.withCredentials = true;
          // If a user is authenticating by using an auth service, we want to provide a custom auth
          // URL that indicates the service to be used
          params.url = "/api/jwt/login?t=a&auth_service=" + authService;
        }

        Vue.auth
          .login(params)
          .then((res) => {
            commit(SET_ERROR, "");
            resolve(res);
          })
          .catch((e) => {
            console.error("login error", e);
            if (
              e.response &&
              hasIn(e.response, "data.message") &&
              !isEmpty(e.response.data.message)
            ) {
              commit(SET_ERROR, e.response.data.message);
            }
          });
      });
    },
    logout({ state }) {
      const logoutArgs = {};

      if (state.user.identity_provider !== null) {
        // If there is an established identity provider, we need to redirect to the server so we can handle
        // iDP specific unauthentication concerns that the server specifies. E.g. if using DUO, we need to deauth with
        // DUO's SLO
        logoutArgs.redirect =
          "/auth_service_logout?auth_service=" +
          encodeURIComponent(state.user.identity_provider);
      }

      return Vue.auth.logout(logoutArgs);
    },
  },
};
