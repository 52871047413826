import {
  SET_ERROR,
  SET_CONTACT_FIELD,
  SET_CONTACT_FIELDS,
  SET_CONTACT_FIELDS_COUNT,
  REMOVE_CONTACT_FIELD,
} from "@admin/store/mutation-types";
import Vue from "vue";
import { getField, updateField } from "vuex-map-fields";

const emptyContactField = function () {
  return {
    id: "",
    label: "",
    name: "",
    required: false,
    type: "",
    translations: [],
  };
};

export default {
  namespaced: true,
  state: {
    error: "",
    contactField: emptyContactField(),
    contactFields: [],
    contactFieldsCount: 0,
  },
  getters: {
    getField,
  },
  mutations: {
    updateField,
    [SET_ERROR](state, payload) {
      state.error = payload;
    },
    [SET_CONTACT_FIELD](state, payload) {
      if (payload.data === undefined) {
        state.contactField = emptyContactField();
        return;
      }
      let newContactField = {};
      newContactField = payload.data.attributes;
      newContactField.id = payload.data.id;

      Object.assign(state.contactField, newContactField);
    },
    [SET_CONTACT_FIELDS](state, payload) {
      state.contactFields = payload.data;
    },
    [SET_CONTACT_FIELDS_COUNT](state, payload) {
      state.contactFieldsCount = payload.record_count;
    },
    [REMOVE_CONTACT_FIELD](state, id) {
      const index = state.contactFields.findIndex((item) => item.id === id);
      state.contactFields.splice(index, 1);
    },
  },
  actions: {
    clearContactFields({ commit }) {
      commit(SET_CONTACT_FIELDS, []);
    },
    clearContactField({ commit }) {
      commit(SET_CONTACT_FIELD, {});
    },
    getContactField({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const params = [];
        const apiEndpoint = "/api/contact-fields/" + payload + "?" + params;
        Vue.axios
          .get(apiEndpoint)
          .then((response) => {
            commit(SET_CONTACT_FIELD, response.data);
            resolve(response.data);
          })
          .catch((error) => {
            commit(SET_ERROR, error.message);
            reject(error);
          });
      });
    },
    getContactFields({ commit }, { sortBy = ["label"], page = 1, limit = 20 }) {
      const params = {
        page,
        limit,
      };

      const sort = [];
      sortBy.forEach((fieldSort) => {
        sort.push(fieldSort);
      });
      params.sort = sort.join(",");

      return new Promise((resolve, reject) => {
        Vue.axios
          .get("/api/contact-fields", { params })
          .then((response) => {
            commit(SET_CONTACT_FIELDS_COUNT, response.data.meta);
            commit(SET_CONTACT_FIELDS, response.data);
            resolve(response.data);
          })
          .catch((error) => {
            commit(SET_ERROR, error.message);
            reject(error);
          });
      });
    },
    addContactField({ commit, state }) {
      commit(SET_ERROR, "");
      let data = {
        data: {
          type: "contactFields",
          attributes: {
            label: state.contactField.label,
            name: state.contactField.name,
            required: state.contactField.required,
            type: state.contactField.type || "text",
            translations: state.contactField.translations,
          },
        },
      };

      return new Promise((resolve, reject) => {
        Vue.axios
          .post("/api/contact-fields/", data)
          .then((response) => {
            commit(SET_CONTACT_FIELD, response.data);
            resolve(response.data);
          })
          .catch((error) => {
            commit(SET_ERROR, error.message);
            reject(error);
          });
      });
    },
    editContactField({ commit, state }, id) {
      commit(SET_ERROR, "");
      let data = {
        data: {
          id: id,
          type: "contactFields",
          attributes: {
            label: state.contactField.label,
            name: state.contactField.name,
            required: state.contactField.required,
            type: state.contactField.type,
            translations: state.contactField.translations,
          },
        },
      };
      return new Promise((resolve, reject) => {
        Vue.axios
          .patch("/api/contact-fields/" + id, data)
          .then((response) => {
            commit(SET_CONTACT_FIELD, response.data);
            resolve(response.data);
          })
          .catch((error) => {
            commit(SET_ERROR, error.message);
            reject(error);
          });
      });
    },
    deleteContactField({ commit }, id) {
      commit(SET_ERROR, "");
      return new Promise((resolve, reject) => {
        Vue.axios
          .delete("/api/contact-fields/" + id + "?includeDeleted=false")
          .then((response) => {
            // A delete request will return a 204 on success from the API
            const success = response.status === 204;
            if (success) {
              commit(REMOVE_CONTACT_FIELD, id);
            }
            resolve(response.data);
          })
          .catch((error) => {
            commit(SET_ERROR, error.message);
            reject(error);
          });
      });
    },
  },
};
