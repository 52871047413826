import { SET_ERROR, SET_ROLES_LIST } from "@admin/store/mutation-types";
import Vue from "vue";

export default {
  namespaced: true,
  state: {
    rolesList: [],
    error: "",
  },
  mutations: {
    [SET_ROLES_LIST](state, roles) {
      // This is used for select elements in forms.
      roles.forEach(function (role) {
        state.rolesList.push({
          value: role.id,
          text: role.attributes.name,
        });
      });
    },
    [SET_ERROR](state, message) {
      state.error = message;
    },
  },
  actions: {
    getRolesList({ commit }) {
      commit(SET_ROLES_LIST, []);
      return Vue.axios
        .get("/api/roles?fields[roles]=id,name")
        .then(({ data }) => {
          commit(SET_ROLES_LIST, data.data);
          return true;
        })
        .catch((response) => {
          commit(SET_ERROR, response);
          return false;
        });
    },
  },
};
