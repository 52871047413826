export const languageCodes = [
  {
    iso_code: "aa-DJ",
    name: "Afar (Djibouti)",
  },
  {
    iso_code: "aa-ER",
    name: "Afar (Eritrea)",
  },
  {
    iso_code: "aa-ET",
    name: "Afar (Ethiopia)",
  },
  {
    iso_code: "ab",
    name: "Abkhazian",
  },
  {
    iso_code: "af",
    name: "Afrikaans",
  },
  {
    iso_code: "af-NA",
    name: "Afrikaans (Namibia)",
  },
  {
    iso_code: "af-ZA",
    name: "Afrikaans (South Africa)",
  },
  {
    iso_code: "agq",
    name: "Aghem",
  },
  {
    iso_code: "ak",
    name: "Akan",
  },
  {
    iso_code: "ak-GH",
    name: "Akan (Ghana)",
  },
  {
    iso_code: "am",
    name: "Amharic",
  },
  {
    iso_code: "am-ET",
    name: "Amharic (Ethiopia)",
  },
  {
    iso_code: "ar",
    name: "Arabic",
  },
  {
    iso_code: "ar-AE",
    name: "Arabic (UAE)",
  },
  {
    iso_code: "ar-BH",
    name: "Arabic (Bahrain)",
  },
  {
    iso_code: "ar-DJ",
    name: "Arabic (Djibouti)",
  },
  {
    iso_code: "ar-DZ",
    name: "Arabic (Algeria)",
  },
  {
    iso_code: "ar-EG",
    name: "Arabic (Egypt)",
  },
  {
    iso_code: "ar-ER",
    name: "Arabic (Eritrea)",
  },
  {
    iso_code: "ar-IL",
    name: "Arabic (Israel)",
  },
  {
    iso_code: "ar-IQ",
    name: "Arabic (Iraq)",
  },
  {
    iso_code: "ar-JO",
    name: "Arabic (Jordan)",
  },
  {
    iso_code: "ar-KM",
    name: "Arabic (Comoros)",
  },
  {
    iso_code: "ar-KW",
    name: "Arabic (Kuwait)",
  },
  {
    iso_code: "ar-LB",
    name: "Arabic (Lebanon)",
  },
  {
    iso_code: "ar-LY",
    name: "Arabic (Libya)",
  },
  {
    iso_code: "ar-MA",
    name: "Arabic (Morocco)",
  },
  {
    iso_code: "ar-MR",
    name: "Arabic (Mauritania)",
  },
  {
    iso_code: "arn",
    name: "Mapudungun",
  },
  {
    iso_code: "ar-OM",
    name: "Arabic (Oman)",
  },
  {
    iso_code: "ar-PS",
    name: "Arabic (Palestinian Authority)",
  },
  {
    iso_code: "ar-QA",
    name: "Arabic (Qatar)",
  },
  {
    iso_code: "ar-SA",
    name: "Arabic (Saudi Arabia)",
  },
  {
    iso_code: "ar-SD",
    name: "Arabic (Sudan)",
  },
  {
    iso_code: "ar-SO",
    name: "Arabic (Somalia)",
  },
  {
    iso_code: "ar-SS",
    name: "Arabic (South Sudan)",
  },
  {
    iso_code: "ar-SY",
    name: "Arabic (Syria)",
  },
  {
    iso_code: "ar-TD",
    name: "Arabic (Chad)",
  },
  {
    iso_code: "ar-TN",
    name: "Arabic (Tunisia)",
  },
  {
    iso_code: "ar-YE",
    name: "Arabic (Yemen)",
  },
  {
    iso_code: "as",
    name: "Assamese",
  },
  {
    iso_code: "asa",
    name: "Asu",
  },
  {
    iso_code: "as-IN",
    name: "Assamese (India)",
  },
  {
    iso_code: "ast",
    name: "Asturian",
  },
  {
    iso_code: "ay",
    name: "Aymara",
  },
  {
    iso_code: "az",
    name: "Azerbaijani",
  },
  {
    iso_code: "ba",
    name: "Bashkir",
  },
  {
    iso_code: "ba-RU",
    name: "Bashkir (Russia)",
  },
  {
    iso_code: "bas",
    name: "Basaa",
  },
  {
    iso_code: "be",
    name: "Byelorussian",
  },
  {
    iso_code: "be-BY",
    name: "Belarusian (Belarus)",
  },
  {
    iso_code: "bem",
    name: "Bemba",
  },
  {
    iso_code: "bez",
    name: "Bena",
  },
  {
    iso_code: "bg",
    name: "Bulgarian",
  },
  {
    iso_code: "bg-BG",
    name: "Bulgarian (Bulgaria)",
  },
  {
    iso_code: "bh",
    name: "Bihari",
  },
  {
    iso_code: "bi",
    name: "Bislama",
  },
  {
    iso_code: "bin",
    name: "Edo",
  },
  {
    iso_code: "bm",
    name: "Bambara",
  },
  {
    iso_code: "bn",
    name: "Bengali, Bangla",
  },
  {
    iso_code: "bn-BD",
    name: "Bangla (Bangladesh)",
  },
  {
    iso_code: "bn-IN",
    name: "Bangla (India)",
  },
  {
    iso_code: "bo",
    name: "Tibetan",
  },
  {
    iso_code: "bo-CN",
    name: "Tibetan (PRC)",
  },
  {
    iso_code: "bo-IN",
    name: "Tibetan (India)",
  },
  {
    iso_code: "br",
    name: "Breton",
  },
  {
    iso_code: "br-FR",
    name: "Breton (France)",
  },
  {
    iso_code: "brx",
    name: "Bodo",
  },
  {
    iso_code: "bs",
    name: "Bosnian",
  },
  {
    iso_code: "byn",
    name: "Blin",
  },
  {
    iso_code: "ca",
    name: "Catalan",
  },
  {
    iso_code: "ca-AD",
    name: "Catalan (Andorra)",
  },
  {
    iso_code: "ca-ES",
    name: "Catalan (Catalan)",
  },
  {
    iso_code: "ca-FR",
    name: "Catalan (France)",
  },
  {
    iso_code: "ca-IT",
    name: "Catalan (Italy)",
  },
  {
    iso_code: "ce",
    name: "Chechen",
  },
  {
    iso_code: "ce-RU",
    name: "Chechen (Russia)",
  },
  {
    iso_code: "cgg",
    name: "Chiga",
  },
  {
    iso_code: "chr",
    name: "Cherokee",
  },
  {
    iso_code: "co",
    name: "Corsican",
  },
  {
    iso_code: "co-FR",
    name: "Corsican (France)",
  },
  {
    iso_code: "cs",
    name: "Czech",
  },
  {
    iso_code: "cs-CZ",
    name: "Czech (Czech Republic)",
  },
  {
    iso_code: "cu",
    name: "Church Slavic",
  },
  {
    iso_code: "cu-RU",
    name: "Church Slavic (Russia)",
  },
  {
    iso_code: "cy",
    name: "Welsh",
  },
  {
    iso_code: "cy-GB",
    name: "Welsh (United Kingdom)",
  },
  {
    iso_code: "da",
    name: "Danish",
  },
  {
    iso_code: "da-DK",
    name: "Danish (Denmark)",
  },
  {
    iso_code: "da-GL",
    name: "Danish (Greenland)",
  },
  {
    iso_code: "dav",
    name: "Taita",
  },
  {
    iso_code: "de",
    name: "German",
  },
  {
    iso_code: "de-AT",
    name: "German/Austrian",
  },
  {
    iso_code: "de-BE",
    name: "German (Belgium)",
  },
  {
    iso_code: "de-CH",
    name: "German/Swiss",
  },
  {
    iso_code: "de-DE",
    name: "Deutsch German",
  },
  {
    iso_code: "de-LI",
    name: "German (Liechtenstein)",
  },
  {
    iso_code: "de-LU",
    name: "German (Luxembourg)",
  },
  {
    iso_code: "dje",
    name: "Zarma",
  },
  {
    iso_code: "dk",
    name: "Danish",
  },
  {
    iso_code: "dsb",
    name: "Lower Sorbian",
  },
  {
    iso_code: "dua",
    name: "Duala",
  },
  {
    iso_code: "dv",
    name: "Divehi",
  },
  {
    iso_code: "dv-MV",
    name: "Divehi (Maldives)",
  },
  {
    iso_code: "dyo",
    name: "Jola-FOnyi",
  },
  {
    iso_code: "dz",
    name: "Bhutani",
  },
  {
    iso_code: "dz-BT",
    name: "Dzongkha (Bhutan)",
  },
  {
    iso_code: "ebu",
    name: "Embu",
  },
  {
    iso_code: "ee",
    name: "Ewe",
  },
  {
    iso_code: "ee-GH",
    name: "Ewe (Ghana)",
  },
  {
    iso_code: "ee-TG",
    name: "Ewe (Togo)",
  },
  {
    iso_code: "el",
    name: "Greek",
  },
  {
    iso_code: "el-CY",
    name: "Greek (Cyprus)",
  },
  {
    iso_code: "el-GR",
    name: "Greek (Greece)",
  },
  {
    iso_code: "en-AU",
    name: "English/Australia",
  },
  {
    iso_code: "en-GB",
    name: "English (UK)",
  },
  {
    iso_code: "en-US",
    name: "English (US)",
  },
  {
    iso_code: "en-AG",
    name: "English (Antigua and Barbuda)",
  },
  {
    iso_code: "en-AI",
    name: "English (Anguilla)",
  },
  {
    iso_code: "en-AS",
    name: "English (American Samoa)",
  },
  {
    iso_code: "en-AT",
    name: "English (Austria)",
  },
  {
    iso_code: "en-BB",
    name: "English (Barbados)",
  },
  {
    iso_code: "en-BE",
    name: "English (Belgium)",
  },
  {
    iso_code: "en-BI",
    name: "English (Burundi)",
  },
  {
    iso_code: "en-BM",
    name: "English (Bermuda)",
  },
  {
    iso_code: "en-BS",
    name: "English (Bahamas)",
  },
  {
    iso_code: "en-BW",
    name: "English (Botswana)",
  },
  {
    iso_code: "en-BZ",
    name: "English (Belize)",
  },
  {
    iso_code: "en-CA",
    name: "English (Canada)",
  },
  {
    iso_code: "en-CC",
    name: "English (Cocos [Keeling] Islands)",
  },
  {
    iso_code: "en-CH",
    name: "English (Switzerland)",
  },
  {
    iso_code: "en-CK",
    name: "English (Cook Islands)",
  },
  {
    iso_code: "en-CM",
    name: "English (Cameroon)",
  },
  {
    iso_code: "en-CX",
    name: "English (Christmas Island)",
  },
  {
    iso_code: "en-CY",
    name: "English (Cyprus)",
  },
  {
    iso_code: "en-DE",
    name: "English (Germany)",
  },
  {
    iso_code: "en-DK",
    name: "English (Denmark)",
  },
  {
    iso_code: "en-DM",
    name: "English (Dominica)",
  },
  {
    iso_code: "en-ER",
    name: "English (Eritrea)",
  },
  {
    iso_code: "en-FI",
    name: "English (Finland)",
  },
  {
    iso_code: "en-FJ",
    name: "English (Fiji)",
  },
  {
    iso_code: "en-FK",
    name: "English (Falkland Islands)",
  },
  {
    iso_code: "en-FM",
    name: "English (Micronesia)",
  },
  {
    iso_code: "en-GD",
    name: "English(Grenada)",
  },
  {
    iso_code: "en-GG",
    name: "English (Guernsey)",
  },
  {
    iso_code: "en-GH",
    name: "English (Ghana)",
  },
  {
    iso_code: "en-GI",
    name: "English (Gibraltar)",
  },
  {
    iso_code: "en-GM",
    name: "English (Gambia)",
  },
  {
    iso_code: "en-GU",
    name: "English (Guam)",
  },
  {
    iso_code: "en-GY",
    name: "English (Guyana)",
  },
  {
    iso_code: "en-HK",
    name: "English (Hong Kong SAR)",
  },
  {
    iso_code: "en-ID",
    name: "English (Indonesia)",
  },
  {
    iso_code: "en-IE",
    name: "English (Ireland)",
  },
  {
    iso_code: "en-IL",
    name: "English (Israel)",
  },
  {
    iso_code: "en-IM",
    name: "English (Isle of Man)",
  },
  {
    iso_code: "en-IN",
    name: "English (India)",
  },
  {
    iso_code: "en-IO",
    name: "English (British Indian Ocean Territory)",
  },
  {
    iso_code: "en-JE",
    name: "English (Jersey)",
  },
  {
    iso_code: "en-JM",
    name: "English (Jamaica)",
  },
  {
    iso_code: "en-KE",
    name: "English (Kenya)",
  },
  {
    iso_code: "en-KI",
    name: "English (Kiribati)",
  },
  {
    iso_code: "en-KN",
    name: "English (Saint Kitts and Nevis)",
  },
  {
    iso_code: "en-KY",
    name: "English (Cayman Islands)",
  },
  {
    iso_code: "en-LC",
    name: "English (Saint Lucia)",
  },
  {
    iso_code: "en-LR",
    name: "English (Liberia)",
  },
  {
    iso_code: "en-LS",
    name: "English (Lesotho)",
  },
  {
    iso_code: "en-MG",
    name: "English (Madagascar)",
  },
  {
    iso_code: "en-MH",
    name: "English (Marshall Islands)",
  },
  {
    iso_code: "en-MO",
    name: "English (Macao SAR)",
  },
  {
    iso_code: "en-MP",
    name: "English (Northern Mariana Islands)",
  },
  {
    iso_code: "en-MS",
    name: "English (Montserrat)",
  },
  {
    iso_code: "en-MT",
    name: "English (Malta)",
  },
  {
    iso_code: "en-MU",
    name: "English (Mauritius)",
  },
  {
    iso_code: "en-MW",
    name: "English (Malawi)",
  },
  {
    iso_code: "en-MY",
    name: "English (Malaysia)",
  },
  {
    iso_code: "en-NA",
    name: "English (Namibia)",
  },
  {
    iso_code: "en-NF",
    name: "English (Norfolk Island)",
  },
  {
    iso_code: "en-NG",
    name: "English (Nigeria)",
  },
  {
    iso_code: "en-NL",
    name: "English (Netherlands)",
  },
  {
    iso_code: "en-NR",
    name: "English (Nauru)",
  },
  {
    iso_code: "en-NU",
    name: "English (Niue)",
  },
  {
    iso_code: "en-NZ",
    name: "English (New Zealand)",
  },
  {
    iso_code: "en-PG",
    name: "English (Papua New Guinea)",
  },
  {
    iso_code: "en-PH",
    name: "English (Republic of the Philippines)",
  },
  {
    iso_code: "en-PK",
    name: "English (Pakistan)",
  },
  {
    iso_code: "en-PN",
    name: "English (Pitcairn Islands)",
  },
  {
    iso_code: "en-PR",
    name: "English (Puerto Rico)",
  },
  {
    iso_code: "en-PW",
    name: "English (Palau)",
  },
  {
    iso_code: "en-RW",
    name: "English (Rwanda)",
  },
  {
    iso_code: "en-SB",
    name: "English (Solomon Islands)",
  },
  {
    iso_code: "en-SC",
    name: "English (Seychelles)",
  },
  {
    iso_code: "en-SD",
    name: "English (Sudan)",
  },
  {
    iso_code: "en-SE",
    name: "English (Sweden)",
  },
  {
    iso_code: "en-SG",
    name: "English/Singapore",
  },
  {
    iso_code: "en-SH",
    name: "English (St Helena Ascension Tristan da Cunha)",
  },
  {
    iso_code: "en-SI",
    name: "English (Slovenia)",
  },
  {
    iso_code: "en-SL",
    name: "English (Sierra Leone)",
  },
  {
    iso_code: "en-SS",
    name: "English (South Sudan)",
  },
  {
    iso_code: "en-SX",
    name: "English (Sint Maarten)",
  },
  {
    iso_code: "en-SZ",
    name: "English (Swaziland)",
  },
  {
    iso_code: "en-TC",
    name: "English (Turks and Caicos Islands)",
  },
  {
    iso_code: "en-TK",
    name: "English (Tokelau)",
  },
  {
    iso_code: "en-TO",
    name: "English (Tonga)",
  },
  {
    iso_code: "en-TT",
    name: "English (Trinidad and Tobago)",
  },
  {
    iso_code: "en-TV",
    name: "English (Tuvalu)",
  },
  {
    iso_code: "en-TZ",
    name: "English (Tanzania)",
  },
  {
    iso_code: "en-UG",
    name: "English (Uganda)",
  },
  {
    iso_code: "en-UM",
    name: "English (US Minor Outlying Islands)",
  },
  {
    iso_code: "en-VC",
    name: "English (Saint Vincent and the Grenadines)",
  },
  {
    iso_code: "en-VG",
    name: "English (British Virgin Islands)",
  },
  {
    iso_code: "en-VI",
    name: "English (US Virgin Islands)",
  },
  {
    iso_code: "en-VU",
    name: "English (Vanuatu)",
  },
  {
    iso_code: "en-WS",
    name: "English (Samoa)",
  },
  {
    iso_code: "en-ZA",
    name: "English (South Africa)",
  },
  {
    iso_code: "en-ZM",
    name: "English (Zambia)",
  },
  {
    iso_code: "en-ZW",
    name: "English (Zimbabwe)",
  },
  {
    iso_code: "eo",
    name: "Esperanto",
  },
  {
    iso_code: "es",
    name: "Spanish",
  },
  {
    iso_code: "es-AR",
    name: "Spanish (Argentina)",
  },
  {
    iso_code: "es-BO",
    name: "Spanish (Bolivia)",
  },
  {
    iso_code: "es-CL",
    name: "Spanish (Chile)",
  },
  {
    iso_code: "es-CO",
    name: "Spanish (Colombia)",
  },
  {
    iso_code: "es-CR",
    name: "Spanish (Costa Rica)",
  },
  {
    iso_code: "es-CU",
    name: "Spanish (Cuba)",
  },
  {
    iso_code: "es-DO",
    name: "Spanish (Dominican Republic)",
  },
  {
    iso_code: "es-EC",
    name: "Spanish (Ecuador)",
  },
  {
    iso_code: "es-ES",
    name: "Spanish (Spain)",
  },
  {
    iso_code: "es-GQ",
    name: "Spanish (Equatorial Guinea)",
  },
  {
    iso_code: "es-GT",
    name: "Spanish (Guatemala)",
  },
  {
    iso_code: "es-HN",
    name: "Spanish (Honduras)",
  },
  {
    iso_code: "es-MX",
    name: "Spanish (Mexico)",
  },
  {
    iso_code: "es-NI",
    name: "Spanish (Nicaragua)",
  },
  {
    iso_code: "es-PA",
    name: "Spanish (Panama)",
  },
  {
    iso_code: "es-PE",
    name: "Spanish (Peru)",
  },
  {
    iso_code: "es-PH",
    name: "Spanish (Philippines)",
  },
  {
    iso_code: "es-PR",
    name: "Spanish (Puerto Rico)",
  },
  {
    iso_code: "es-PY",
    name: "Spanish (Paraguay)",
  },
  {
    iso_code: "es-SV",
    name: "Spanish (El Salvador)",
  },
  {
    iso_code: "es-US",
    name: "Spanish (United States)",
  },
  {
    iso_code: "es-UY",
    name: "Spanish (Uruguay)",
  },
  {
    iso_code: "es-VE",
    name: "Spanish (Bolivarian Republic of Venezuela)",
  },
  {
    iso_code: "et",
    name: "Estonian",
  },
  {
    iso_code: "et-EE",
    name: "Estonian(Estonia)",
  },
  {
    iso_code: "eu",
    name: "Basque",
  },
  {
    iso_code: "eu-ES",
    name: "Basque (Basque)",
  },
  {
    iso_code: "ewo",
    name: "Ewondo",
  },
  {
    iso_code: "fa",
    name: "Persian",
  },
  {
    iso_code: "fa-IR",
    name: "Persian (Iran)",
  },
  {
    iso_code: "ff",
    name: "Fulah",
  },
  {
    iso_code: "ff-CM",
    name: "Fulah (Cameroon)",
  },
  {
    iso_code: "ff-GN",
    name: "Fulah (Guinea)",
  },
  {
    iso_code: "ff-MR",
    name: "Fulah (Mauritania)",
  },
  {
    iso_code: "ff-NG",
    name: "Fulah (Nigeria)",
  },
  {
    iso_code: "fi",
    name: "Finnish",
  },
  {
    iso_code: "fi-FI",
    name: "Finnish (Finland)",
  },
  {
    iso_code: "fil",
    name: "Filipino",
  },
  {
    iso_code: "fj",
    name: "Fiji",
  },
  {
    iso_code: "fo",
    name: "Faeroese",
  },
  {
    iso_code: "fo-DK",
    name: "Faroese (Denmark)",
  },
  {
    iso_code: "fo-FO",
    name: "Faroese (Faroe Islands)",
  },
  {
    iso_code: "fr",
    name: "French",
  },
  {
    iso_code: "fr-BE",
    name: "Belgian/French",
  },
  {
    iso_code: "fr-BF",
    name: "French (Burkina Faso)",
  },
  {
    iso_code: "fr-BI",
    name: "French (Burundi)",
  },
  {
    iso_code: "fr-BJ",
    name: "French (Benin)",
  },
  {
    iso_code: "fr-BL",
    name: "French (Saint BarthE2809Alemy)",
  },
  {
    iso_code: "fr-CA",
    name: "French/Canada",
  },
  {
    iso_code: "fr-CD",
    name: "French (Congo DRC)",
  },
  {
    iso_code: "fr-CF",
    name: "French (Central African Republic)",
  },
  {
    iso_code: "fr-CG",
    name: "French (Congo)",
  },
  {
    iso_code: "fr-CH",
    name: "French (Switzerland)",
  },
  {
    iso_code: "fr-CI",
    name: "French (CE2809Cte dIvoire)",
  },
  {
    iso_code: "fr-CM",
    name: "French (Cameroon)",
  },
  {
    iso_code: "fr-DJ",
    name: "French (Djibouti)",
  },
  {
    iso_code: "fr-DZ",
    name: "French (Algeria)",
  },
  {
    iso_code: "fr-FR",
    name: "French/France",
  },
  {
    iso_code: "fr-GA",
    name: "French (Gabon)",
  },
  {
    iso_code: "fr-GF",
    name: "French (French Guiana)",
  },
  {
    iso_code: "fr-GN",
    name: "French (Guinea)",
  },
  {
    iso_code: "fr-GP",
    name: "French (Guadeloupe)",
  },
  {
    iso_code: "fr-GQ",
    name: "French (EquatorialGuinea)",
  },
  {
    iso_code: "fr-HT",
    name: "French (Haiti)",
  },
  {
    iso_code: "fr-KM",
    name: "French (Comoros)",
  },
  {
    iso_code: "fr-LU",
    name: "French (Luxembourg)",
  },
  {
    iso_code: "fr-MA",
    name: "French (Morocco)",
  },
  {
    iso_code: "fr-MC",
    name: "French (Monaco)",
  },
  {
    iso_code: "fr-MF",
    name: "French (Saint Martin)",
  },
  {
    iso_code: "fr-MG",
    name: "French (Madagascar)",
  },
  {
    iso_code: "fr-ML",
    name: "French (Mali)",
  },
  {
    iso_code: "fr-MQ",
    name: "French (Martinique)",
  },
  {
    iso_code: "fr-MR",
    name: "French (Mauritania)",
  },
  {
    iso_code: "fr-MU",
    name: "French (Mauritius)",
  },
  {
    iso_code: "fr-NC",
    name: "French (New Caledonia)",
  },
  {
    iso_code: "fr-NE",
    name: "French (Niger)",
  },
  {
    iso_code: "fr-PF",
    name: "French (French Polynesia)",
  },
  {
    iso_code: "fr-PM",
    name: "French (Saint Pierre and Miquelon)",
  },
  {
    iso_code: "fr-RE",
    name: "French (Reunion)",
  },
  {
    iso_code: "fr-RW",
    name: "French (Rwanda)",
  },
  {
    iso_code: "fr-SC",
    name: "French (Seychelles)",
  },
  {
    iso_code: "fr-SN",
    name: "French (Senegal)",
  },
  {
    iso_code: "fr-SY",
    name: "French (Syria)",
  },
  {
    iso_code: "fr-TD",
    name: "French (Chad)",
  },
  {
    iso_code: "fr-TG",
    name: "French (Togo)",
  },
  {
    iso_code: "fr-TN",
    name: "French (Tunisia)",
  },
  {
    iso_code: "fr-VU",
    name: "French (Vanuatu)",
  },
  {
    iso_code: "fr-WF",
    name: "French (Wallis and Futuna)",
  },
  {
    iso_code: "fr-YT",
    name: "French (Mayotte)",
  },
  {
    iso_code: "fur",
    name: "Friulian",
  },
  {
    iso_code: "fy",
    name: "Frisian",
  },
  {
    iso_code: "fy-NL",
    name: "Frisian (Netherlands)",
  },
  {
    iso_code: "ga",
    name: "Irish",
  },
  {
    iso_code: "ga-IE",
    name: "Irish (Ireland)",
  },
  {
    iso_code: "gd",
    name: "Gaelic (Scots Gaelic)",
  },
  {
    iso_code: "gd-GB",
    name: "Scottish Gaelic (United Kingdom)",
  },
  {
    iso_code: "gl",
    name: "Galician",
  },
  {
    iso_code: "gl-ES",
    name: "Galician (Galician)",
  },
  {
    iso_code: "gn",
    name: "Guarani",
  },
  {
    iso_code: "gn-PY",
    name: "Guarani (Paraguay)",
  },
  {
    iso_code: "gsw",
    name: "Alsatian",
  },
  {
    iso_code: "gu",
    name: "Gujarati",
  },
  {
    iso_code: "gu-IN",
    name: "Gujarati (India)",
  },
  {
    iso_code: "guz",
    name: "Gusii",
  },
  {
    iso_code: "gv",
    name: "Manx",
  },
  {
    iso_code: "gv-IM",
    name: "Manx (Isle of Man)",
  },
  {
    iso_code: "ha",
    name: "Hausa",
  },
  {
    iso_code: "haw",
    name: "Hawaiian",
  },
  {
    iso_code: "he",
    name: "Hebrew",
  },
  {
    iso_code: "he-IL",
    name: "Hebrew (Israel)",
  },
  {
    iso_code: "hi",
    name: "Hindi",
  },
  {
    iso_code: "hi-IN",
    name: "Hindi (India)",
  },
  {
    iso_code: "hr",
    name: "Croatian",
  },
  {
    iso_code: "hr-BA",
    name: "Croatian (Latin Bosnia and Herzegovina)",
  },
  {
    iso_code: "hr-HR",
    name: "Croatian (Croatia)",
  },
  {
    iso_code: "hsb",
    name: "Upper Sorbian",
  },
  {
    iso_code: "hu",
    name: "Hungarian",
  },
  {
    iso_code: "hu-HU",
    name: "Hungarian (Hungary)",
  },
  {
    iso_code: "hy",
    name: "Armenian",
  },
  {
    iso_code: "hy-AM",
    name: "Armenian (Armenia)",
  },
  {
    iso_code: "ia",
    name: "Interlingua",
  },
  {
    iso_code: "ia-FR",
    name: "Interlingua (France)",
  },
  {
    iso_code: "ibb",
    name: "Ibibio",
  },
  {
    iso_code: "id",
    name: "Indonesian",
  },
  {
    iso_code: "id-ID",
    name: "Indonesian (Indonesia)",
  },
  {
    iso_code: "ie",
    name: "Interlingue",
  },
  {
    iso_code: "ig",
    name: "Igbo",
  },
  {
    iso_code: "ig-NG",
    name: "Igbo (Nigeria)",
  },
  {
    iso_code: "ii",
    name: "Yi",
  },
  {
    iso_code: "ii-CN",
    name: "Yi (PRC)",
  },
  {
    iso_code: "ik",
    name: "Inupiak",
  },
  {
    iso_code: "in",
    name: "Indonesian",
  },
  {
    iso_code: "is",
    name: "Icelandic",
  },
  {
    iso_code: "is-IS",
    name: "Icelandic (Iceland)",
  },
  {
    iso_code: "it",
    name: "Italian",
  },
  {
    iso_code: "it-CH",
    name: "Italian (Switzerland)",
  },
  {
    iso_code: "it-IT",
    name: "Italian (Italy)",
  },
  {
    iso_code: "it-SM",
    name: "Italian (San Marino)",
  },
  {
    iso_code: "iu",
    name: "Inuktitut",
  },
  {
    iso_code: "iw",
    name: "Hebrew",
  },
  {
    iso_code: "ja",
    name: "Japanese",
  },
  {
    iso_code: "ja-JP",
    name: "Japanese (Japan)",
  },
  {
    iso_code: "jgo",
    name: "Ngomba",
  },
  {
    iso_code: "ji",
    name: "Yiddish",
  },
  {
    iso_code: "jmc",
    name: "Machame",
  },
  {
    iso_code: "jv",
    name: "Javanese",
  },
  {
    iso_code: "ka",
    name: "Georgian",
  },
  {
    iso_code: "kab",
    name: "Kabyle",
  },
  {
    iso_code: "ka-GE",
    name: "Georgian (Georgia)",
  },
  {
    iso_code: "kam",
    name: "Kamba",
  },
  {
    iso_code: "kde",
    name: "Makonde",
  },
  {
    iso_code: "kea",
    name: "Kabuverdianu",
  },
  {
    iso_code: "khq",
    name: "Koyra Chiini",
  },
  {
    iso_code: "ki",
    name: "Kikuyu",
  },
  {
    iso_code: "ki-KE",
    name: "Kikuyu (Kenya)",
  },
  {
    iso_code: "kk",
    name: "Kazakh",
  },
  {
    iso_code: "kkj",
    name: "Kako",
  },
  {
    iso_code: "kk-KZ",
    name: "Kazakh (Kazakhstan)",
  },
  {
    iso_code: "kl",
    name: "Greenlandic",
  },
  {
    iso_code: "kl-GL",
    name: "Greenlandic (Greenland)",
  },
  {
    iso_code: "kln",
    name: "Kalenjin",
  },
  {
    iso_code: "km",
    name: "Cambodian",
  },
  {
    iso_code: "km-KH",
    name: "Khmer (Cambodia)",
  },
  {
    iso_code: "kn",
    name: "Kannada",
  },
  {
    iso_code: "kn-IN",
    name: "Kannada (India)",
  },
  {
    iso_code: "ko",
    name: "Korean",
  },
  {
    iso_code: "kok",
    name: "Konkani",
  },
  {
    iso_code: "ko-KP",
    name: "Korean (North Korea)",
  },
  {
    iso_code: "ko-KR",
    name: "Korean (Korea)",
  },
  {
    iso_code: "kr",
    name: "Kanuri",
  },
  {
    iso_code: "kr-NG",
    name: "Kanuri (Nigeria)",
  },
  {
    iso_code: "ks",
    name: "Kashmiri",
  },
  {
    iso_code: "ksb",
    name: "Shambala",
  },
  {
    iso_code: "ksf",
    name: "Bafia",
  },
  {
    iso_code: "ksh",
    name: "Colognian",
  },
  {
    iso_code: "ku",
    name: "Kurdish",
  },
  {
    iso_code: "kw",
    name: "Cornish",
  },
  {
    iso_code: "kw-GB",
    name: "Cornish (United Kingdom)",
  },
  {
    iso_code: "ky",
    name: "Kirghiz",
  },
  {
    iso_code: "ky-KG",
    name: "Kyrgyz (Kyrgyzstan)",
  },
  {
    iso_code: "la",
    name: "Latin",
  },
  {
    iso_code: "lag",
    name: "Langi",
  },
  {
    iso_code: "lb",
    name: "Luxembourgish",
  },
  {
    iso_code: "lb-LU",
    name: "Luxembourgish (Luxembourg)",
  },
  {
    iso_code: "lg",
    name: "Ganda",
  },
  {
    iso_code: "lg-UG",
    name: "Ganda (Uganda)",
  },
  {
    iso_code: "lkt",
    name: "Lakota",
  },
  {
    iso_code: "ln",
    name: "Lingala",
  },
  {
    iso_code: "ln-AO",
    name: "Lingala (Angola)",
  },
  {
    iso_code: "ln-CD",
    name: "Lingala (Congo DRC)",
  },
  {
    iso_code: "ln-CF",
    name: "Lingala (Central African Republic)",
  },
  {
    iso_code: "ln-CG",
    name: "Lingala (Congo)",
  },
  {
    iso_code: "lo",
    name: "Laothian",
  },
  {
    iso_code: "lo-LA",
    name: "Lao (Lao PDR)",
  },
  {
    iso_code: "lrc",
    name: "Northern Luri",
  },
  {
    iso_code: "lt",
    name: "Lithuanian",
  },
  {
    iso_code: "lt-LT",
    name: "Lithuanian (Lithuania)",
  },
  {
    iso_code: "lu",
    name: "Luba-KAtanga",
  },
  {
    iso_code: "lu-CD",
    name: "Luba-KAtanga (Congo DRC)",
  },
  {
    iso_code: "luo",
    name: "Luo",
  },
  {
    iso_code: "luy",
    name: "Luyia",
  },
  {
    iso_code: "lv",
    name: "Latvian, Lettish",
  },
  {
    iso_code: "lv-LV",
    name: "Latvian (Latvia)",
  },
  {
    iso_code: "mas",
    name: "Masai",
  },
  {
    iso_code: "mer",
    name: "Meru",
  },
  {
    iso_code: "mfe",
    name: "Morisyen",
  },
  {
    iso_code: "mg",
    name: "Malagasy",
  },
  {
    iso_code: "mgh",
    name: "Makhuwa-MEetto",
  },
  {
    iso_code: "mg-MG",
    name: "Malagasy(Madagascar)",
  },
  {
    iso_code: "mgo",
    name: "Meta",
  },
  {
    iso_code: "mi",
    name: "Maori",
  },
  {
    iso_code: "mi-NZ",
    name: "Maori (New Zealand)",
  },
  {
    iso_code: "mk",
    name: "Macedonian",
  },
  {
    iso_code: "mk-MK",
    name: "Macedonian (Former Yugoslav Republic of Macedonia)",
  },
  {
    iso_code: "ml",
    name: "Malayalam",
  },
  {
    iso_code: "ml-IN",
    name: "Malayalam (India)",
  },
  {
    iso_code: "mn",
    name: "Mongolian",
  },
  {
    iso_code: "mni",
    name: "Manipuri",
  },
  {
    iso_code: "mn-MN",
    name: "Mongolian (Cyrillic Mongolia)",
  },
  {
    iso_code: "mo",
    name: "Moldavian",
  },
  {
    iso_code: "moh",
    name: "Mohawk",
  },
  {
    iso_code: "mr",
    name: "Marathi",
  },
  {
    iso_code: "mr-IN",
    name: "Marathi (India)",
  },
  {
    iso_code: "ms",
    name: "Malay",
  },
  {
    iso_code: "ms-BN",
    name: "Malay (Brunei Darussalam)",
  },
  {
    iso_code: "ms-MY",
    name: "Malay (Malaysia)",
  },
  {
    iso_code: "ms-SG",
    name: "Malay (Latin Singapore)",
  },
  {
    iso_code: "mt",
    name: "Maltese",
  },
  {
    iso_code: "mt-MT",
    name: "Maltese (Malta)",
  },
  {
    iso_code: "mua",
    name: "Mundang",
  },
  {
    iso_code: "my",
    name: "Burmese",
  },
  {
    iso_code: "my-MM",
    name: "Burmese (Myanmar)",
  },
  {
    iso_code: "mzn",
    name: "Mazanderani",
  },
  {
    iso_code: "na",
    name: "Nauru",
  },
  {
    iso_code: "naq",
    name: "Nama",
  },
  {
    iso_code: "nb",
    name: "Norwegian",
  },
  {
    iso_code: "nb-NO",
    name: "Norwegian BokmE280A0l (Norway)",
  },
  {
    iso_code: "nb-SJ",
    name: "Norwegian BokmE280A0l (Svalbard and JanMayen)",
  },
  {
    iso_code: "nd",
    name: "North Ndebele",
  },
  {
    iso_code: "nd-ZW",
    name: "North Ndebele (Zimbabwe)",
  },
  {
    iso_code: "ne",
    name: "Nepali",
  },
  {
    iso_code: "ne-IN",
    name: "Nepali (India)",
  },
  {
    iso_code: "ne-NP",
    name: "Nepali (Nepal)",
  },
  {
    iso_code: "nl",
    name: "Dutch",
  },
  {
    iso_code: "nl-AW",
    name: "Dutch (Aruba)",
  },
  {
    iso_code: "nl-BE",
    name: "Belgian/Dutch",
  },
  {
    iso_code: "nl-BQ",
    name: "Dutch (Bonaire Sint Eustatius and Saba)",
  },
  {
    iso_code: "nl-CW",
    name: "Dutch (CuraE280A1ao)",
  },
  {
    iso_code: "nl-NL",
    name: "Dutch",
  },
  {
    iso_code: "nl-SR",
    name: "Dutch (Suriname)",
  },
  {
    iso_code: "nl-SX",
    name: "Dutch(Sint Maarten)",
  },
  {
    iso_code: "nmg",
    name: "Kwasio",
  },
  {
    iso_code: "nn",
    name: "Norwegian (Nynorsk)",
  },
  {
    iso_code: "nnh",
    name: "Ngiemboon",
  },
  {
    iso_code: "nn-NO",
    name: "Norwegian Nynorsk (Norway)",
  },
  {
    iso_code: "no",
    name: "Norwegian",
  },
  {
    iso_code: "nqo",
    name: "Nko",
  },
  {
    iso_code: "nr",
    name: "South Ndebele",
  },
  {
    iso_code: "nr-ZA",
    name: "South Ndebele (South Africa)",
  },
  {
    iso_code: "nso",
    name: "Sesotho sa Leboa",
  },
  {
    iso_code: "nus",
    name: "Nuer",
  },
  {
    iso_code: "nyn",
    name: "Nyankole",
  },
  {
    iso_code: "oc",
    name: "Occitan",
  },
  {
    iso_code: "oc-FR",
    name: "Occitan (France)",
  },
  {
    iso_code: "om",
    name: "Oromo, Afan",
  },
  {
    iso_code: "om-ET",
    name: "Oromo (Ethiopia)",
  },
  {
    iso_code: "om-KE",
    name: "Oromo (Kenya)",
  },
  {
    iso_code: "or",
    name: "Oriya",
  },
  {
    iso_code: "or-IN",
    name: "Odia (India)",
  },
  {
    iso_code: "os",
    name: "Ossetic",
  },
  {
    iso_code: "os-GE",
    name: "Ossetian (Cyrillic Georgia)",
  },
  {
    iso_code: "os-RU",
    name: "Ossetian (Cyrillic Russia)",
  },
  {
    iso_code: "pa",
    name: "Punjabi",
  },
  {
    iso_code: "pa-IN",
    name: "Punjabi (India)",
  },
  {
    iso_code: "pap",
    name: "Papiamento",
  },
  {
    iso_code: "pl",
    name: "Polish",
  },
  {
    iso_code: "pl-PL",
    name: "Polish (Poland)",
  },
  {
    iso_code: "prg",
    name: "Prussian",
  },
  {
    iso_code: "prs",
    name: "Dari",
  },
  {
    iso_code: "ps",
    name: "Pashto, Pushto",
  },
  {
    iso_code: "ps-AF",
    name: "Pashto (Afghanistan)",
  },
  {
    iso_code: "pt",
    name: "Portuguese",
  },
  {
    iso_code: "pt-AO",
    name: "Portuguese (Angola)",
  },
  {
    iso_code: "pt-BR",
    name: "Portuguese (Brazil)",
  },
  {
    iso_code: "pt-CV",
    name: "Portuguese(Cabo Verde)",
  },
  {
    iso_code: "pt-GW",
    name: "Portuguese (Guinea-BIssau)",
  },
  {
    iso_code: "pt-MO",
    name: "Portuguese (Macao SAR)",
  },
  {
    iso_code: "pt-MZ",
    name: "Portuguese (Mozambique)",
  },
  {
    iso_code: "pt-PT",
    name: "Portuguese (Portugal)",
  },
  {
    iso_code: "pt-ST",
    name: "Portuguese (SC386o TomE2809A and PrC2A1ncipe)",
  },
  {
    iso_code: "pt-TL",
    name: "Portuguese (Timor-LEste)",
  },
  {
    iso_code: "qu",
    name: "Quechua",
  },
  {
    iso_code: "quc",
    name: "Kiche",
  },
  {
    iso_code: "quz",
    name: "Quechua",
  },
  {
    iso_code: "rm",
    name: "Rhaeto-ROmance",
  },
  {
    iso_code: "rm-CH",
    name: "Romansh (Switzerland)",
  },
  {
    iso_code: "rn",
    name: "Kirundi",
  },
  {
    iso_code: "rn-BI",
    name: "Rundi(Burundi)",
  },
  {
    iso_code: "ro",
    name: "Romanian",
  },
  {
    iso_code: "ro-MD",
    name: "Romanian (Moldova)",
  },
  {
    iso_code: "ro-RO",
    name: "Romanian (Romania)",
  },
  {
    iso_code: "ru",
    name: "Russian",
  },
  {
    iso_code: "ru-BY",
    name: "Russian (Belarus)",
  },
  {
    iso_code: "ru-KG",
    name: "Russian (Kyrgyzstan)",
  },
  {
    iso_code: "ru-KZ",
    name: "Russian (Kazakhstan)",
  },
  {
    iso_code: "ru-MD",
    name: "Russian (Moldova)",
  },
  {
    iso_code: "ru-RU",
    name: "Russian (Russia)",
  },
  {
    iso_code: "ru-UA",
    name: "Russian (Ukraine)",
  },
  {
    iso_code: "rw",
    name: "Kinyarwanda",
  },
  {
    iso_code: "rwk",
    name: "Rwa",
  },
  {
    iso_code: "rw-RW",
    name: "Kinyarwanda (Rwanda)",
  },
  {
    iso_code: "sa",
    name: "Sanskrit",
  },
  {
    iso_code: "sah",
    name: "Sakha",
  },
  {
    iso_code: "sa-IN",
    name: "Sanskrit (India)",
  },
  {
    iso_code: "saq",
    name: "Samburu",
  },
  {
    iso_code: "sbp",
    name: "Sangu",
  },
  {
    iso_code: "sd",
    name: "Sindhi",
  },
  {
    iso_code: "se",
    name: "Sami (Northern)",
  },
  {
    iso_code: "se-FI",
    name: "Sami Northern (Finland)",
  },
  {
    iso_code: "seh",
    name: "Sena",
  },
  {
    iso_code: "se-NO",
    name: "Sami Northern (Norway)",
  },
  {
    iso_code: "ses",
    name: "Koyraboro Senni",
  },
  {
    iso_code: "se-SE",
    name: "Sami Northern (Sweden)",
  },
  {
    iso_code: "sg",
    name: "Sangro",
  },
  {
    iso_code: "sh",
    name: "Serbo-CRoatian",
  },
  {
    iso_code: "si",
    name: "Singhalese",
  },
  {
    iso_code: "si-LK",
    name: "Sinhala (Sri Lanka)",
  },
  {
    iso_code: "sk",
    name: "Slovak",
  },
  {
    iso_code: "sk-SK",
    name: "Slovak (Slovakia)",
  },
  {
    iso_code: "sl",
    name: "Slovenian",
  },
  {
    iso_code: "sl-SI",
    name: "Slovenian (Slovenia)",
  },
  {
    iso_code: "sm",
    name: "Samoan",
  },
  {
    iso_code: "sma",
    name: "Sami (Southern)",
  },
  {
    iso_code: "smj",
    name: "Sami (Lule)",
  },
  {
    iso_code: "smn",
    name: "Sami (Inari)",
  },
  {
    iso_code: "sms",
    name: "Sami (Skolt)",
  },
  {
    iso_code: "sn",
    name: "Shona",
  },
  {
    iso_code: "so",
    name: "Somali",
  },
  {
    iso_code: "so-DJ",
    name: "Somali (Djibouti)",
  },
  {
    iso_code: "so-ET",
    name: "Somali (Ethiopia)",
  },
  {
    iso_code: "so-KE",
    name: "Somali (Kenya)",
  },
  {
    iso_code: "so-SO",
    name: "Somali (Somalia)",
  },
  {
    iso_code: "sq",
    name: "Albanian",
  },
  {
    iso_code: "sq-AL",
    name: "Albanian (Albania)",
  },
  {
    iso_code: "sq-MK",
    name: "Albanian (Macedonia FYRO)",
  },
  {
    iso_code: "sq-XK",
    name: "Albanian (Kosovo)",
  },
  {
    iso_code: "sr",
    name: "Serbian",
  },
  {
    iso_code: "ss",
    name: "Siswati",
  },
  {
    iso_code: "ss-SZ",
    name: "Swati (Swaziland)",
  },
  {
    iso_code: "ssy",
    name: "Saho",
  },
  {
    iso_code: "ss-ZA",
    name: "Swati (South Africa)",
  },
  {
    iso_code: "st",
    name: "Sesotho",
  },
  {
    iso_code: "st-LS",
    name: "Sesotho (Lesotho)",
  },
  {
    iso_code: "st-ZA",
    name: "Southern Sotho (South Africa)",
  },
  {
    iso_code: "su",
    name: "Sudanese",
  },
  {
    iso_code: "sv",
    name: "Swedish",
  },
  {
    iso_code: "sv-AX",
    name: "Swedish (?land Islands)",
  },
  {
    iso_code: "sv-FI",
    name: "Swedish (Finland)",
  },
  {
    iso_code: "sv-SE",
    name: "Swedish (Sweden)",
  },
  {
    iso_code: "sw",
    name: "Swahili",
  },
  {
    iso_code: "sw-CD",
    name: "Kiswahili (Congo DRC)",
  },
  {
    iso_code: "sw-KE",
    name: "Kiswahili (Kenya)",
  },
  {
    iso_code: "sw-TZ",
    name: "Kiswahili (Tanzania)",
  },
  {
    iso_code: "sw-UG",
    name: "Kiswahili (Uganda)",
  },
  {
    iso_code: "syr",
    name: "Syriac",
  },
  {
    iso_code: "ta",
    name: "Tamil",
  },
  {
    iso_code: "ta-IN",
    name: "Tamil (India)",
  },
  {
    iso_code: "ta-LK",
    name: "Tamil (Sri Lanka)",
  },
  {
    iso_code: "ta-MY",
    name: "Tamil (Malaysia)",
  },
  {
    iso_code: "ta-SG",
    name: "Tamil (Singapore)",
  },
  {
    iso_code: "te",
    name: "Tegulu",
  },
  {
    iso_code: "te-IN",
    name: "Telugu (India)",
  },
  {
    iso_code: "teo",
    name: "Teso",
  },
  {
    iso_code: "tg",
    name: "Tajik",
  },
  {
    iso_code: "th",
    name: "Thai",
  },
  {
    iso_code: "th-TH",
    name: "Thai (Thailand)",
  },
  {
    iso_code: "ti",
    name: "Tigrinya",
  },
  {
    iso_code: "ti-ER",
    name: "Tigrinya (Eritrea)",
  },
  {
    iso_code: "ti-ET",
    name: "Tigrinya (Ethiopia)",
  },
  {
    iso_code: "tig",
    name: "Tigre",
  },
  {
    iso_code: "tk",
    name: "Turkmen",
  },
  {
    iso_code: "tk-TM",
    name: "Turkmen (Turkmenistan)",
  },
  {
    iso_code: "tl",
    name: "Tagalog",
  },
  {
    iso_code: "tn",
    name: "Setswana",
  },
  {
    iso_code: "tn-BW",
    name: "Setswana (Botswana)",
  },
  {
    iso_code: "tn-ZA",
    name: "Setswana (South Africa)",
  },
  {
    iso_code: "to",
    name: "Tonga",
  },
  {
    iso_code: "to-TO",
    name: "Tongan (Tonga)",
  },
  {
    iso_code: "tr",
    name: "Turkish",
  },
  {
    iso_code: "tr-CY",
    name: "Turkish (Cyprus)",
  },
  {
    iso_code: "tr-TR",
    name: "Turkish (Turkey)",
  },
  {
    iso_code: "ts",
    name: "Tsonga",
  },
  {
    iso_code: "ts-ZA",
    name: "Tsonga (South Africa)",
  },
  {
    iso_code: "tt",
    name: "Tatar",
  },
  {
    iso_code: "tt-RU",
    name: "Tatar (Russia)",
  },
  {
    iso_code: "tw",
    name: "Twi",
  },
  {
    iso_code: "twq",
    name: "Tasawaq",
  },
  {
    iso_code: "tzm",
    name: "Tamazight",
  },
  {
    iso_code: "ug",
    name: "Uyghur",
  },
  {
    iso_code: "ug-CN",
    name: "Uyghur (PRC)",
  },
  {
    iso_code: "uk",
    name: "Ukrainian",
  },
  {
    iso_code: "uk-UA",
    name: "Ukrainian (Ukraine)",
  },
  {
    iso_code: "ur",
    name: "Urdu",
  },
  {
    iso_code: "ur-IN",
    name: "Urdu (India)",
  },
  {
    iso_code: "ur-PK",
    name: "Urdu (Islamic Republic of Pakistan)",
  },
  {
    iso_code: "uz",
    name: "Uzbek",
  },
  {
    iso_code: "vai",
    name: "Vai",
  },
  {
    iso_code: "ve",
    name: "Venda",
  },
  {
    iso_code: "ve-ZA",
    name: "Venda (South Africa)",
  },
  {
    iso_code: "vi",
    name: "Vietnamese",
  },
  {
    iso_code: "vi-VN",
    name: "Vietnamese (Vietnam)",
  },
  {
    iso_code: "vo",
    name: "Volapuk",
  },
  {
    iso_code: "vun",
    name: "Vunjo",
  },
  {
    iso_code: "wae",
    name: "Walser",
  },
  {
    iso_code: "wal",
    name: "Wolaytta",
  },
  {
    iso_code: "wo",
    name: "Wolof",
  },
  {
    iso_code: "xh",
    name: "Xhosa",
  },
  {
    iso_code: "xh-ZA",
    name: "isiXhosa (South Africa)",
  },
  {
    iso_code: "xog",
    name: "Soga",
  },
  {
    iso_code: "yav",
    name: "Yangben",
  },
  {
    iso_code: "yi",
    name: "Yiddish",
  },
  {
    iso_code: "yo",
    name: "Yoruba",
  },
  {
    iso_code: "yo-BJ",
    name: "Yoruba (Benin)",
  },
  {
    iso_code: "yo-NG",
    name: "Yoruba (Nigeria)",
  },
  {
    iso_code: "zgh",
    name: "Standard Moroccan Tamazight",
  },
  {
    iso_code: "zh",
    name: "Chinese",
  },
  {
    iso_code: "zh-CN",
    name: "Chinese (Simplified PRC)",
  },
  {
    iso_code: "zh-HK",
    name: "Chinese (Traditional Hong Kong SAR)",
  },
  {
    iso_code: "zh-MO",
    name: "Chinese (Traditional Macao SAR)",
  },
  {
    iso_code: "zh-SG",
    name: "Chinese (Simplified Singapore)",
  },
  {
    iso_code: "zh-TW",
    name: "Chinese (Traditional Taiwan)",
  },
  {
    iso_code: "zu",
    name: "Zulu",
  },
  {
    iso_code: "zu-ZA",
    name: "isiZulu (South Africa)",
  },
];
