<template>
  <div class="vue-diagrams">
    <SvgPanZoom
      ref="svgpanzoom"
      :style="{
        width: '100%',
        height: height + 'px',
        border: '1px solid black',
      }"
      :zoomEnabled="zoomEnabled"
      :panEnabled="true"
      :controlIconsEnabled="true"
      :fit="false"
      :center="true"
      viewportSelector="#svg-root"
      :preventMouseEventsDefault="true"
    >
      <svg
        id="svg-root"
        xmlns="http://www.w3.org/2000/svg"
        :viewBox="'0 0 ' + width + ' ' + height"
        width="100%"
        :height="height"
        preserveAspectRatio="xMinYMin meet"
        class="svg-content"
        ref="diagramRoot"
      >
        <defs>
          <pattern
            id="smallGrid"
            width="16"
            height="16"
            patternUnits="userSpaceOnUse"
          >
            <path
              d="M 16 0 L 0 0 0 16"
              fill="none"
              stroke="#ccc"
              stroke-width="1"
            />
          </pattern>
          <pattern
            id="grid"
            width="80"
            height="80"
            patternUnits="userSpaceOnUse"
          >
            <rect width="80" height="80" fill="url(#smallGrid)" />
            <path
              d="M 80 0 L 0 0 0 80"
              fill="none"
              stroke="gray"
              stroke-width="2"
            />
          </pattern>
        </defs>

        <rect
          ref="grid"
          class="svg-pan-zoom_viewport"
          x="-5000px"
          y="-5000px"
          width="10000px"
          height="10000px"
          fill="url(#grid)"
        />
        <g ref="viewPort" id="viewport" x="50" y="50">
          <Link
            v-for="(link, linkIndex) in model._model.links"
            :id="link.id"
            :key="'link-' + linkIndex"
            :options="link.options"
            :positionFrom="link.positionFrom"
            :positionTo="link.positionTo"
            :ref="'link-' + link.id"
          />
          <Node
            v-for="node in model._model.nodes"
            :height="node.height"
            :id="node.id"
            :key="'node-' + node.id"
            :nodeModel="node"
            :ref="'node-' + node.id"
            :title="node.title"
            :width="node.width"
            :x="node.x"
            :y="node.y"
          >
            <Question
              v-for="(question, questionIndex) in node.questions"
              :index="questionIndex"
              :key="questionIndex"
              :title="question.title"
            >
              <Port
                v-for="(port, portIndex) in questionPorts(
                  node,
                  question.id,
                  questionIndex
                )"
                :key="portIndex"
                :id="port.id"
                :name="port.name"
                :node="node"
                :type="port.type"
                :ref="'port-' + port.id"
                :y="portIndex * 30 + questionIndex * 130"
              />
            </Question>
          </Node>
        </g>
      </svg>
    </SvgPanZoom>
  </div>
</template>
<script>
import SvgPanZoom from "vue-svg-pan-zoom";
import DiagramModel from "./DiagramModel";
import Node from "./Node.vue";
import Link from "./Link.vue";
import Port from "./Port.vue";
import Question from "./Question.vue";

export default {
  name: "Diagram",
  Model: DiagramModel,
  props: {
    model: {
      required: true,
    },
    width: {
      default: 500,
    },
    height: {
      default: 500,
    },
  },
  data() {
    return {
      document,
      initialDragX: 0,
      initialDragY: 0,
      linksUpdated: false,
      mode: "move",
      mouseButtonIsPressed: false,
      mouseDownViewportPos: {},
      mouseX: 0,
      mouseY: 0,
      viewportMousePos: {},
      viewPosition: undefined,
      zoomEnabled: true,
    };
  },
  components: {
    Link,
    Node,
    Port,
    Question,
    SvgPanZoom,
  },
  computed: {
    querySelector() {
      return document.querySelector("#viewport");
    },
  },
  methods: {
    getPortHandlePosition(portId) {
      let portComponent = this.$refs["port-" + portId];
      if (portComponent !== undefined) {
        portComponent = portComponent[0];
      } else {
        console.error("Port not found: ", portId);
        return;
      }
      const node = this.$refs["node-" + portComponent.node.id][0];
      let x;
      let y;
      x = node.x + node.width + 5;
      y = node.y + portComponent.displayedY + 9;
      if (portComponent.type === "in") {
        x = node.x + 5;
      }

      return { x, y };
    },
    questionPorts(node, questionId, questionIndex) {
      let ports = [];
      node.ports.forEach((port) => {
        if (
          port.qid === questionId ||
          (port.type === "in" && questionIndex === 0)
        ) {
          ports.push(port);
        }
      });
      return ports;
    },
  },
  updated() {
    let links = this.model._model.links;
    if (!this.linksUpdated) {
      this.linksUpdated = true;

      for (let i = 0; i < links.length; i++) {
        let coords;
        coords = this.getPortHandlePosition(links[i].from);
        links[i].positionFrom = { x: coords.x, y: coords.y };
        coords = this.getPortHandlePosition(links[i].to);
        links[i].positionTo = { x: coords.x, y: coords.y };
      }
    }
  },
  watch: {
    mode: {
      handler(action) {
        this.$nextTick(() => {
          if (action === "move") {
            this.$refs.svgpanzoom.spz.enablePan();
          } else {
            this.$refs.svgpanzoom.spz.disablePan();
          }
        });
      },
      immediate: true,
    },
  },
};
</script>
