import { find } from "lodash";

/**
 * Extracts belongsToMany relational records fron the API response 'included' data
 * @param {*} relationships The 'relationships' value of the main record
 * @param {*} relationName Which relation to extract (as specified in the table model)
 * @param {*} includedData The 'included' data returned from the API
 * @returns array
 */
export const getBelongsToManyByType = (
  relationships,
  relationName,
  includedData
) => {
  const associations = {};
  if (
    relationships !== undefined &&
    relationships[relationName] !== undefined
  ) {
    relationships[relationName].data.forEach((val) => {
      if (associations[val.id] === undefined) {
        associations[val.id] = find(includedData, (assoc) => {
          return assoc.type === val.type && assoc.id === val.id;
        });
      }
    });
  }
  return Object.values(associations);
};

/**
 * Extracts a belongsTo relational record fron the API response 'included' data
 * @param {*} relationships The 'relationships' value of the main record
 * @param {*} relationName Which relation to extract (as specified in the table model)
 * @param {*} includedData The 'included' data returned from the API
 * @returns {*}|undefined
 */
export const getBelongsToByType = (
  relationships,
  relationName,
  includedData
) => {
  if (
    relationships !== undefined &&
    relationships[relationName] !== undefined
  ) {
    const id = relationships[relationName].data.id;
    const type = relationships[relationName].data.type;
    return find(includedData, (assoc) => {
      return assoc.type === type && assoc.id === id;
    });
  }
};
