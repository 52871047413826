import Vue from "vue";
import VueRouter from "vue-router";
import AppLayout from "@admin/layouts/App";
import Dashboard from "@admin/views/Dashboard.vue";
import ErrorForbidden from "@admin/views/ErrorForbidden";
import ErrorNotFound from "@admin/views/ErrorNotFound";
import GenericLayoutWithCard from "@admin/layouts/GenericWithCard.vue";
import Login from "@admin/views/Login";
import Logout from "@admin/views/Logout";
import PublicLayout from "@admin/layouts/Public";
import store from "@admin/store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    component: PublicLayout,
    children: [
      {
        path: "",
        name: "Login",
        component: Login,
        meta: { auth: false },
        props: (route) => {
          // Allow login to be initiated when the login view is mounted. This is
          // important when using authentication services since services can require
          // preparing necessary session info on the server in an initial request and issue
          // a subsequent request to actually complete JWT auth
          let isSubmitOnMount = false;
          // identify which authentication service (if applicable) to use when submitting a login request
          let submitAuthService = null;

          if (route.query.submit) {
            isSubmitOnMount = true;
          }

          if (route.query.auth_service) {
            submitAuthService = route.query.auth_service;
          }

          return {
            isSubmitOnMount,
            submitAuthService,
          };
        },
      },
    ],
  },
  {
    path: "/",
    component: AppLayout,
    children: [
      {
        path: "",
        name: "Dashboard",
        component: Dashboard,
        meta: { auth: true },
      },
      {
        path: "/logout",
        name: "Logout",
        component: Logout,
        meta: { auth: true },
      },
      {
        // Provide an endpoint to allow for initiating logout functionality for DUO on the server.
        // this is necessary since we need to delete cookie based information as well as direct the user
        // to DUO's SLO so that the user can be deauthenticated from DUO. This should be done after utilizing the
        // logout route
        path: "/auth_service_logout",
        name: "AuthServiceLogout",
        component: null,
        meta: { auth: false },
        beforeEnter: (to) => {
          let authService = null;
          if (to.query.auth_service && to.query.auth_service.length > 0) {
            authService = to.query.auth_service;
          }

          if (authService === null) {
            return false;
          }

          window.location.href =
            process.env.VUE_APP_BASE_API_URL +
            "/auth_service_logout?auth_service=" +
            encodeURIComponent(authService);
        },
      },
      {
        path: "/engagements",
        component: GenericLayoutWithCard,
        meta: {
          auth: { roles: ["admin"] },
        },
        children: [
          {
            path: "",
            name: "EngagementsIndex",
            component: () => import("@admin/views/Engagements/Index.vue"),
            meta: { auth: true, titleAction: "Browse" },
          },
          {
            path: "add/:selectedSponsorId?",
            name: "EngagementAdd",
            component: () => import("@admin/views/Engagements/Form.vue"),
            meta: { auth: true, titleAction: "Add" },
            props: true,
          },
          {
            path: "edit/:engagementId",
            name: "EngagementEdit",
            component: () => import("@admin/views/Engagements/Form.vue"),
            meta: { auth: true, titleAction: "Edit" },
            props: true,
          },
          {
            path: "view/:engagementId",
            name: "EngagementView",
            component: () => import("@admin/views/Engagements/View.vue"),
            meta: { auth: true, titleAction: "View" },
            props: true,
          },
          {
            path: "site-locations/:engagementId",
            name: "SiteAssociations",
            component: () =>
              import("@admin/views/Engagements/SiteAssociation.vue"),
            meta: { auth: true, titleAction: "Edit" },
            props: true,
          },
        ],
      },
      {
        path: "/media-channels",
        component: GenericLayoutWithCard,
        meta: {
          auth: { roles: ["admin"] },
        },
        children: [
          {
            path: "add/:screenerId",
            name: "MediaChannelAdd",
            component: () => import("@admin/views/MediaChannels/Form.vue"),
            meta: { auth: true, titleAction: "Add" },
          },
          {
            path: "edit/:id",
            name: "MediaChannelEdit",
            component: () => import("@admin/views/MediaChannels/Form.vue"),
            meta: { auth: true, titleAction: "Edit" },
            props: true,
          },
          {
            path: "view/:id",
            name: "MediaChannelView",
            component: () => import("@admin/views/MediaChannels/View.vue"),
            meta: { auth: true, titleAction: "View" },
            props: true,
          },
        ],
      },
      {
        path: "/screeners",
        component: GenericLayoutWithCard,
        meta: {
          auth: { roles: ["admin"] },
        },
        children: [
          {
            path: "",
            name: "ScreenersIndex",
            component: () => import("@admin/views/Screeners/Index.vue"),
            meta: { auth: true, titleAction: "Browse" },
          },
          {
            path: "add/:selectedSponsorId",
            name: "ScreenerAdd",
            component: () => import("@admin/views/Screeners/Form.vue"),
            meta: { auth: true, titleAction: "Add" },
            props: true,
          },
          {
            path: "edit/:screenerId",
            name: "ScreenerEdit",
            component: () => import("@admin/views/Screeners/Form.vue"),
            meta: {
              auth: true,
              titleAction: "Edit",
            },
            props: true,
            // restrict access to the screener edit page to only Admin or Super User roles
            beforeEnter: (to, from, next) => {
              const user = store.getters["auth/user"];
              if (user.roles.find((role) => role.name === "Admin" || role.name === "Super User")) {
                next();
              } else {
                next({ name: "Forbidden" });
              }
            },
          },
          {
            path: "view/:screenerId",
            name: "ScreenerView",
            component: () => import("@admin/views/Screeners/View.vue"),
            meta: { auth: true, titleAction: "View" },
            props: true,
          },
          {
            path: "associate-surveys/:screenerId",
            name: "SurveyAssociations",
            component: () =>
              import("@admin/views/Screeners/SurveyAssociation.vue"),
            meta: { auth: true, titleAction: "Edit" },
            props: true,
          },
        ],
      },
      {
        path: "/surveys-new",
        component: GenericLayoutWithCard,
        meta: {
          auth: { roles: ["admin"] },
        },
        children: [
          {
            path: "",
            name: "SurveysNewIndex",
            component: () => import("@admin/views/SurveysNew/Index.vue"),
            meta: { auth: true, titleAction: "Browse" },
          },
          {
            path: "add/:screenerId?",
            name: "SurveysNewAdd",
            component: () => import("@admin/views/SurveysNew/AddForm.vue"),
            meta: { auth: true, titleAction: "Add" },
            props: true,
          },
          {
            path: "edit/:id",
            name: "SurveysNewEdit",
            component: () => import("@admin/views/SurveysNew/Form.vue"),
            meta: { auth: true, titleAction: "Edit" },
            props: true,
          },
          {
            path: "view/:id",
            name: "SurveysNewView",
            component: () => import("@admin/views/SurveysNew/View.vue"),
            meta: { auth: true, titleAction: "View" },
            props: true,
          },
          {
            path: "edit-screener/:id",
            name: "ScreenerSurveysEdit",
            component: () => import("@admin/views/SurveysNew/screenerForm.vue"),
            meta: { auth: true, titleAction: "Edit" },
            props: true,
          },
        ],
      },
      {
        path: "/site-locations",
        component: GenericLayoutWithCard,
        meta: {
          auth: { roles: ["admin"] },
        },
        children: [
          {
            path: "add",
            name: "SiteLocationsAdd",
            component: () => import("@admin/views/SiteLocations/Form.vue"),
            meta: { auth: true, titleAction: "Add" },
          },
          {
            path: "edit/:id",
            name: "SiteLocationsEdit",
            component: () => import("@admin/views/SiteLocations/Form.vue"),
            meta: { auth: true, titleAction: "Edit" },
            props: true,
          },
          {
            path: "",
            name: "SiteLocationsIndex",
            component: () => import("@admin/views/SiteLocations/Index.vue"),
            meta: { auth: true, titleAction: "Browse" },
          },
          {
            path: "upload/:engagementId",
            name: "SiteLocationsUpload",
            component: () => import("@admin/views/SiteLocations/Upload.vue"),
            meta: { auth: true, titleAction: "Upload" },
            props: true,
          },
          {
            path: "view/:id/:engagementId?",
            name: "SiteLocationsView",
            component: () => import("@admin/views/SiteLocations/View.vue"),
            meta: { auth: true, titleAction: "View" },
            props: true,
          },
        ],
      },
      {
        path: "/sponsors",
        component: GenericLayoutWithCard,
        meta: {
          auth: { roles: ["admin"] },
        },
        children: [
          {
            path: "",
            name: "SponsorsIndex",
            component: () => import("@admin/views/Sponsors/Index.vue"),
            meta: { auth: true, titleAction: "Browse" },
          },
          {
            path: "view/:id",
            name: "SponsorsView",
            component: () => import("@admin/views/Sponsors/View.vue"),
            meta: { auth: true, titleAction: "View" },
            props: true,
          },
          {
            path: "add",
            name: "SponsorsAdd",
            component: () => import("@admin/views/Sponsors/Form.vue"),
            meta: { auth: true, titleAction: "Add" },
          },
          {
            path: "edit/:id",
            name: "SponsorsEdit",
            component: () => import("@admin/views/Sponsors/Form.vue"),
            meta: { auth: true, titleAction: "Edit" },
            props: true,
          },
        ],
      },
      {
        path: "/surveys",
        component: GenericLayoutWithCard,
        meta: {
          auth: { roles: ["admin"] },
        },
        children: [
          {
            path: "",
            name: "SurveysIndex",
            component: () => import("@admin/views/Surveys/Index.vue"),
            meta: { auth: true, titleAction: "Browse" },
          },
          {
            path: "add/:engagementId",
            name: "SurveysAdd",
            component: () => import("@admin/views/Surveys/AddForm.vue"),
            meta: { auth: true, titleAction: "Add" },
            props: true,
          },
          {
            path: "edit/:id",
            name: "SurveysEdit",
            component: () => import("@admin/views/Surveys/Form.vue"),
            meta: { auth: true, titleAction: "Edit" },
            props: true,
          },
          {
            path: "view/:id",
            name: "SurveysView",
            component: () => import("@admin/views/Surveys/View.vue"),
            meta: { auth: true, titleAction: "View" },
            props: true,
          },
        ],
      },
      {
        path: "/users",
        component: GenericLayoutWithCard,
        meta: {
          auth: { roles: ["admin"] },
        },
        children: [
          {
            path: "",
            name: "UsersIndex",
            component: () => import("@admin/views/Users/Index.vue"),
            meta: { auth: true, titleAction: "Browse" },
          },
          {
            path: "add",
            name: "UsersAdd",
            component: () => import("@admin/views/Users/Form.vue"),
            meta: { auth: true, titleAction: "Add" },
          },
          {
            path: "edit/:id",
            name: "UsersEdit",
            component: () => import("@admin/views/Users/Form.vue"),
            meta: { auth: true, titleAction: "Edit" },
            props: true,
          },
        ],
      },
      {
        path: "/generic-messages",
        component: GenericLayoutWithCard,
        meta: {
          auth: { roles: ["admin"] },
        },
        children: [
          {
            path: "",
            name: "GenericMessagesIndex",
            component: () => import("@admin/views/GenericMessages/Index.vue"),
            meta: { auth: true, titleAction: "Browse" },
          },
          {
            path: "edit/:id",
            name: "GenericMessagesEdit",
            component: () => import("@admin/views/GenericMessages/Form.vue"),
            meta: { auth: true, titleAction: "Edit" },
            props: true,
          },
        ],
      },
      {
        path: "/contact-fields",
        component: GenericLayoutWithCard,
        meta: {
          auth: { roles: ["admin"] },
        },
        children: [
          {
            path: "",
            name: "ContactFieldsIndex",
            component: () => import("@admin/views/ContactFields/Index.vue"),
            meta: { auth: true, titleAction: "Browse" },
          },
          {
            path: "add/:screenerId?",
            name: "ContactFieldsAdd",
            component: () => import("@admin/views/ContactFields/Form.vue"),
            meta: { auth: true, titleAction: "Add" },
          },
          {
            path: "edit/:id",
            name: "ContactFieldsEdit",
            component: () => import("@admin/views/ContactFields/Form.vue"),
            meta: { auth: true, titleAction: "Edit" },
            props: true,
          },
        ],
      },
      {
        path: "/languages",
        component: GenericLayoutWithCard,
        meta: {
          auth: { roles: ["admin"] },
        },
        children: [
          {
            path: "",
            name: "LanguagesIndex",
            component: () => import("@admin/views/Languages/Index.vue"),
            meta: { auth: true, titleAction: "Browse" },
          },
          {
            path: "view/:id",
            name: "LanguagesView",
            component: () => import("@admin/views/Languages/View.vue"),
            meta: { auth: true, titleAction: "View" },
            props: true,
          },
          {
            path: "add",
            name: "LanguagesAdd",
            component: () => import("@admin/views/Languages/Form.vue"),
            meta: { auth: true, titleAction: "Add" },
          },
          {
            path: "edit/:id",
            name: "LanguagesEdit",
            component: () => import("@admin/views/Languages/Form.vue"),
            meta: { auth: true, titleAction: "Edit" },
            props: true,
          },
        ],
      },
      {
        path: "/rms-audits",
        component: GenericLayoutWithCard,
        meta: {
          auth: { roles: ["admin"] },
        },
        children: [
          {
            path: "",
            name: "RmsAuditsIndex",
            component: () => import("@admin/views/RmsAudits/Index.vue"),
            meta: { auth: true, titleAction: "Browse" },
          },
        ],
      },
      {
        path: "/403",
        component: ErrorForbidden,
        name: "Forbidden",
      },
      // Ensure the catch-all route is last to be the default page if no other route is found
      {
        path: "/:catchAll(.*)",
        component: ErrorNotFound,
        name: "NotFound",
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to) {
    if (to.hash !== undefined && to.hash != "") {
      setTimeout(function () {
        let element = document.querySelector(to.hash);
        if (element !== null) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      }, 1500);
    }
  },
});

export default router;
