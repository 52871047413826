import {
  CLEAR_ENGAGEMENT,
  REMOVE_ENGAGEMENT,
  SET_ERROR,
  SET_ENGAGEMENTS_COUNT,
  SET_ENGAGEMENT,
  SET_ENGAGEMENTS,
  SET_INCLUDED,
  SET_ENGAGEMENT_INDICATIONS,
  SET_ENGAGEMENT_RELATION_STRINGS,
  SET_ENGAGEMENTS_LIST,
} from "@admin/store/mutation-types";
import { getField, updateField } from "vuex-map-fields";
import { getBelongsToManyByType } from "@shared/util/Relationships";
import { map } from "lodash";
import Vue from "vue";

const emptyEngagement = function () {
  return {
    id: "",
    name: "",
    description: "",
    sponsorContact: "",
    sponsor_id: "",
    sponsorName: "",
    startDate: "",
    endDate: "",
    isActive: true,
    radius: "25",
    mediaLiveDate: "",
    mediaEndDate: "",
    g6Url: "",
    protocolIdentifier: "",
    studySubjectIdentifier: "",
    protocolVersion: "",
    phase: "",
    amendmentDate: "",
    firstPatientInDate: "",
    lastPatientInDate: "",
    rareDisease: false,
    cellGeneTherapy: false,
    deiFocus: false,
    deiTarget: 0,
    identification: "",
    campaignTypes: [],
    campaignTypesString: "",
    indications: [],
    indicationsString: "",
    targetGroups: [],
    targetGroupsString: "",
    therapeuticAreas: [],
    therapeuticAreasString: "",
    attachments: [],
    targetParticipantCount: "",
    rms_provider_id: "",
    rmsSponsorIdentifier: "",
    rmsProviderName: "",
  };
};

const patchEngagement = function (commit, state, id) {
  commit(SET_ERROR, "");
  let relationships = {};
  let relationshipTypes = [
    "campaignTypes",
    "indications",
    "targetGroups",
    "therapeuticAreas",
  ];
  relationshipTypes.forEach(function (relationshipType) {
    relationships[relationshipType] = { data: [] };
    state.engagement[relationshipType].forEach(function (relationshipId) {
      // Conditional handling for indications since the form data is formatted differently
      if (relationshipType === "indications") {
        relationshipId = relationshipId.value;
      }
      relationships[relationshipType].data.push({
        type: relationshipType,
        id: relationshipId,
      });
    });
  });
  let data = setData(state);
  data.data.relationships = relationships;
  data.data.id = id;

  return Vue.axios
    .patch("/api/engagements/:id".replace(":id", id), data)
    .then(({ data }) => {
      if (data.errors) {
        commit(SET_ERROR, data.errors);
        return false;
      }
      return id;
    })
    .catch(({ response }) => {
      if (response.data.errors) {
        commit(SET_ERROR, response.data.errors);
      }
      return false;
    });
};

const setData = function (state) {
  return {
    data: {
      type: "engagements",
      attributes: {
        name: state.engagement.name,
        description: state.engagement.description,
        sponsor_id: state.engagement.sponsor_id,
        sponsorContact: state.engagement.sponsorContact,
        startDate: state.engagement.startDate,
        endDate: state.engagement.endDate,
        isActive: state.engagement.isActive,
        radius: state.engagement.radius,
        mediaLiveDate: state.engagement.mediaLiveDate,
        mediaEndDate: state.engagement.mediaEndDate,
        g6Url: state.engagement.g6Url,
        protocolIdentifier: state.engagement.protocolIdentifier,
        studySubjectIdentifier: state.engagement.studySubjectIdentifier,
        protocolVersion: state.engagement.protocolVersion,
        phase: state.engagement.phase,
        amendmentDate: state.engagement.amendmentDate,
        firstPatientInDate: state.engagement.firstPatientInDate,
        lastPatientInDate: state.engagement.lastPatientInDate,
        rareDisease: state.engagement.rareDisease,
        cellGeneTherapy: state.engagement.cellGeneTherapy,
        deiFocus: state.engagement.deiFocus,
        deiTarget: state.engagement.deiTarget,
        targetParticipantCount: state.engagement.targetParticipantCount,
        rms_provider_id: state.engagement.rms_provider_id,
        rms_sponsor_identifier: state.engagement.rmsSponsorIdentifier,
        rmsProviderName: state.engagement.rmsProviderName,
      },
    },
  };
};

/**
 * A helper method to facilitate setting the relationship ids of an engagement
 * @param engagement the engagement object
 * @param state The store state
 */
const setRelationshipIds = function (engagement, state) {
  let relationshipTypes = [
    "campaignTypes",
    "sponsors",
    "targetGroups",
    "therapeuticAreas",
  ];
  relationshipTypes.forEach(function (relationshipType) {
    let collectionIds = [];
    if (engagement.relationships[relationshipType] !== undefined) {
      engagement.relationships[relationshipType].data.forEach(function (
        relationship
      ) {
        collectionIds.push(relationship.id);
      });
      state.engagement[relationshipType] = collectionIds;
    }
  });
};

export default {
  namespaced: true,
  state: {
    engagementsCount: 0,
    engagement: emptyEngagement(),
    engagements: [],
    error: "",
    included: [],
    phases: ["I", "II", "III", "IV", "N/A"],
    engagementsList: [],
  },
  getters: {
    getField,
  },
  mutations: {
    updateField,
    [CLEAR_ENGAGEMENT](state) {
      state.engagement = emptyEngagement();
    },
    [SET_ENGAGEMENTS_COUNT](state, meta) {
      state.engagementsCount = meta.record_count;
    },
    [SET_ENGAGEMENT](state, engagement) {
      Object.assign(state.engagement, engagement.attributes);
      state.engagement.identification =
        engagement.attributes.sponsorIdentification +
        "-" +
        engagement.attributes.identification;
      state.engagement.sponsor_id = engagement.relationships.sponsor.data.id;
      state.engagement.rms_provider_id =
        engagement.relationships.rmsProvider.data.id;
      setRelationshipIds(engagement, state);
    },
    [SET_ENGAGEMENTS](state, engagements) {
      state.engagements = engagements;
    },
    [SET_INCLUDED](state, included) {
      state.included = included;
    },
    [SET_ERROR](state, errors) {
      if (Array.isArray(errors)) {
        errors.forEach(function (error) {
          state.error = state.error + error.detail;
        });
      } else {
        state.error = errors;
      }
    },
    [SET_ENGAGEMENT_INDICATIONS](state, engagement) {
      let indications = [];
      engagement.included.forEach(function (record) {
        if (record.type === "indications") {
          let indication = { text: record.attributes.name, value: record.id };
          indications.push(indication);
        }
      });
      state.engagement.indications = indications;
    },
    [SET_ENGAGEMENT_RELATION_STRINGS](state, engagement) {
      let relationshipTypes = [
        "campaignTypes",
        "indications",
        "targetGroups",
        "therapeuticAreas",
      ];
      relationshipTypes.forEach(function (relationshipType) {
        let key = relationshipType + "String";
        state.engagement[key] = map(
          getBelongsToManyByType(
            engagement.data.relationships,
            relationshipType,
            engagement.included
          ),
          (obj) => obj.attributes?.name
        )
          .sort()
          .join(", ");
      });
      engagement.included.forEach(function (include) {
        if (include.type === "sponsors") {
          state.engagement.sponsorsString = include.attributes.name;
        }
      });
    },
    [REMOVE_ENGAGEMENT](state, id) {
      const index = state.engagements.findIndex(
        (engagement) => engagement.id === id
      );
      if (index !== -1) {
        Vue.delete(state.engagements, index);
      }
    },
    [SET_ENGAGEMENTS_LIST](state, engagements) {
      // This is used for select elements in forms.
      state.engagementsList = [];
      engagements.forEach(function (engagement) {
        state.engagementsList.push({
          value: engagement.id,
          text: engagement.attributes.name,
        });
      });
    },
  },
  actions: {
    async addEngagement({ commit, state }) {
      commit(SET_ERROR, "");
      let data = setData(state);

      let success = false;
      const response = await Vue.axios
        .post("/api/engagements", data)
        .then(({ data }) => {
          if (data.data.id) {
            commit(SET_ENGAGEMENT, data.data);
          } else if (data.errors) {
            commit(SET_ERROR, data.errors);
          }
          return data;
        })
        .catch((e) => {
          const data = e.response.data;
          if (data.errors) {
            commit(SET_ERROR, data.errors[0].detail);
          }
          return [];
        });

      if (response.length !== 0) {
        success = patchEngagement(commit, state, response.data.id);
      }

      return success;
    },
    clearEngagement({ commit }) {
      commit(CLEAR_ENGAGEMENT);
    },
    clearEngagements({ commit }) {
      commit(SET_ENGAGEMENTS, []);
    },
    deleteEngagement({ commit }, id) {
      return Vue.axios
        .delete("/api/engagements/:id".replace(":id", id))
        .then((response) => {
          // A delete request will return a 204 on success from the API
          const success = response.status === 204;

          if (success) {
            commit(REMOVE_ENGAGEMENT, id);
          }

          return Promise.resolve([success]);
        });
    },
    editEngagement({ commit, state }, id) {
      return patchEngagement(commit, state, id);
    },
    getEngagement({ commit }, id) {
      commit(CLEAR_ENGAGEMENT);
      commit(SET_ERROR, "");
      return Vue.axios
        .get(
          "/api/engagements/:id?include=campaignTypes,indications,sponsor,targetGroups,therapeuticAreas,rmsProviders".replace(
            ":id",
            id
          )
        )
        .then(({ data }) => {
          if (data.data.id) {
            commit(SET_ENGAGEMENT, data.data);
            commit(SET_ENGAGEMENT_INDICATIONS, data);
            commit(SET_ENGAGEMENT_RELATION_STRINGS, data);
          } else if (data.errors) {
            commit(SET_ERROR, data.errors[0].title);
          }
          return true;
        })
        .catch((error) => {
          commit(SET_ERROR, error);
          return false;
        });
    },
    /**
     * GET an Engagement Collection
     * @param commit
     * @param sortBy
     * @param page
     * @param limit
     * @returns {Promise<boolean>}
     */
    getEngagements(
      { commit },
      { sortBy = ["-isActive", "endDate", "name"], page = 1, limit = 20 } = {}
    ) {
      commit(SET_ENGAGEMENTS, []);

      const params = {
        include:
          "siteLocations,campaignTypes,targetGroups,therapeuticAreas,indications,sponsors",
        page,
        limit,
        "fields[campaignTypes]": "id,name",
        "fields[targetGroups]": "id,name",
        "fields[therapeuticAreas]": "id,name",
        "fields[indications]": "id,name",
      };

      const sort = [];
      const sortAssociated = [];
      sortBy.forEach((fieldSort) => {
        if (fieldSort.includes("sponsorName")) {
          sortAssociated.push(fieldSort);
        } else {
          sort.push(fieldSort);
        }
      });

      params.sort = sort.join(",");
      params.sortAssociated = sortAssociated.join(",");

      return Vue.axios
        .get("/api/engagements", {
          params,
        })
        .then(({ data }) => {
          commit(SET_ENGAGEMENTS_COUNT, data.meta);
          commit(SET_ENGAGEMENTS, data.data);
          commit(SET_INCLUDED, data.included);
          return true;
        })
        .catch((response) => {
          commit(SET_ERROR, response);
          return false;
        });
    },
    /**
     * API Call to check uniqueness.
     * @param state
     * @param identification
     * @returns {Promise<boolean>}
     */
    async identificationIsUnique(state, identification) {
      let isUnique = true;
      await Vue.axios
        .get(`/api/engagements?uniqueIdentification=${identification}`)
        .then(({ data }) => {
          isUnique = data.data.length === 0;
        });
      return isUnique;
    },
    // Get a list of engagements for a select element
    getEngagementsList({ commit }, sponsorId = null) {
      commit(SET_ENGAGEMENTS_LIST, []);
      let endpoint = "/api/engagements?fields[engagements]=id,name&sort=name";
      // If a sponsorId is passed in, filter the engagements by that sponsor
      if (sponsorId) {
        endpoint += `&sponsorId=${sponsorId}`;
      }
      return Vue.axios
        .get(endpoint)
        .then(({ data }) => {
          commit(SET_ENGAGEMENTS_LIST, data.data);
          return true;
        })
        .catch((response) => {
          commit(SET_ERROR, response);
          return false;
        });
    },
  },
};
