<template>
  <span>
    <confirm-dialog ref="confirmDelete"></confirm-dialog>
    <v-btn @click="openConfirm" class="mx-1">
      Delete
      <v-icon aria-label="Delete Icon" class="ml-1">mdi-delete</v-icon>
    </v-btn>
  </span>
</template>

<script>
import ConfirmDialog from "@utils/components/ConfirmDialog.vue";
export default {
  name: "DeleteButton",
  components: {
    ConfirmDialog,
  },
  methods: {
    openConfirm() {
      this.$refs.confirmDelete
        .open("Confirm", "Are you sure you want to delete this ?")
        .then((confirm) => {
          if (confirm) {
            this.attributes.onConfirm();
          }
        });
    },
  },
  props: {
    /**
     * Attributes for the button
     * onConfirm: {
     *   type: function,
     *   required: true,
     *}
     */

    attributes: {},
  },
};
</script>

<style scoped>
.v-btn {
  background-color: #ad330f !important;
  color: white;
}
</style>
