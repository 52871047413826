<template>
  <v-footer
    padless
    inset
    app
    :color="isSmall || isPublic ? 'black' : 'white'"
    :dark="!!isSmall"
    :absolute="true"
  >
    <v-layout justify-center wrap>
      <v-flex py-4 text-center xs12>
        <img
          class="spectrum-logo"
          src="/img/spectrum-logo.png"
          alt="Spectrum Science Logo"
        />
        <br />
        <small :style="contentColor"
          >Copyright Spectrum Science &copy; {{ currentYear }}</small
        >
      </v-flex>
    </v-layout>
  </v-footer>
</template>

<script>
export default {
  name: "AppFooter",
  computed: {
    contentColor: function () {
      return this.isSmall || this.isPublic ? "color: white" : "color: black";
    },
    currentYear: function () {
      return new Date().getFullYear();
    },
    isSmall() {
      return !!this.$vuetify.breakpoint.smAndDown;
    },
  },
  props: {
    isPublic: Boolean,
  },
};
</script>

<style scoped>
.spectrum-logo {
  height: 30px;
  width: auto;
}
</style>
