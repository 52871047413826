export const parsedErrors = (errorSet) => {
  const parsedErrors = {};
  for (const error of errorSet) {
    Object.assign(parsedErrors, {
      [error.id]: error.detail,
    });
  }

  return parsedErrors;
};

export const resolveWithErrors = (errors) => {
  return Promise.resolve([false, parsedErrors(errors)]);
};
