import {
  CLEAR_MEDIA_CHANNEL,
  SET_ERROR,
  SET_MEDIA_CHANNEL,
  SET_MEDIA_CHANNEL_SUBMISSIONS_DATA,
  SET_MEDIA_CHANNELS,
  SET_MEDIA_CHANNEL_TYPES,
  SET_MEDIA_CHANNELS_COUNT,
} from "@admin/store/mutation-types";
import Vue from "vue";
import { getField, updateField } from "vuex-map-fields";

const emptyMediaChannel = function () {
  return {
    submissionsCompleted: 0,
    domain: "",
    screenerId: "",
    screenerName: "",
    id: "",
    isActive: true,
    submissionsQualified: 0,
    submissionsStarted: 0,
    share: 0,
    snippet: "",
    sponsorName: "",
    sponsorId: "",
    title: "",
    type: "",
    views: 0,
  };
};
export default {
  namespaced: true,
  state: {
    error: "",
    isActiveOptions: [
      {
        text: "Yes",
        value: true,
      },
      {
        text: "No",
        value: false,
      },
    ],
    mediaChannel: emptyMediaChannel(),
    mediaChannels: [],
    mediaChannelTypes: [],
    mediaChannelsCount: 0,
  },
  getters: { getField },
  mutations: {
    updateField,
    [CLEAR_MEDIA_CHANNEL](state) {
      state.mediaChannel = emptyMediaChannel();
    },
    [SET_MEDIA_CHANNELS_COUNT](state, meta) {
      state.mediaChannelsCount = meta.record_count;
    },
    [SET_MEDIA_CHANNEL](state, mediaChannel) {
      let portalUrl = process.env.VUE_APP_PORTAL_URL;
      Object.assign(state.mediaChannel, mediaChannel.data.attributes);
      state.mediaChannel.id = mediaChannel.data.id;

      state.mediaChannel.screenerId =
        mediaChannel.data.relationships.screener.data.id;

      if (mediaChannel.included) {
        for (const included of mediaChannel.included) {
          switch (included.type) {
            case "screeners":
              state.mediaChannel.screenerName = included.attributes.title;
              break;
            case "sponsors":
              state.mediaChannel.sponsorId = included.id;
              state.mediaChannel.sponsorName = included.attributes.name;
              break;
          }
        }
      }

      state.mediaChannel.snippet =
        '<div id="trialblazer"' +
        '\n\tdata-mc="' +
        mediaChannel.data.id +
        '"' +
        '\n\tdata-si="' +
          state.mediaChannel.screenerId +
        '">' +
        '\n\t<div id="participant-portal"></div>' +
        "\n</div>" +
        '\n<script id="portal-script" src="' +
        portalUrl +
        '/js/portal.js"></script>';
    },
    [SET_MEDIA_CHANNEL_SUBMISSIONS_DATA](state, mediaChannel) {
      state.mediaChannel.submissionsStarted =
        mediaChannel.attributes.submissionsStarted;
      state.mediaChannel.submissionsCompleted =
        mediaChannel.attributes.submissionsCompleted;
      state.mediaChannel.submissionsQualified =
        mediaChannel.attributes.submissionsQualified;
    },
    [SET_MEDIA_CHANNELS](state, mediaChannels) {
      state.mediaChannels = mediaChannels;
    },
    [SET_MEDIA_CHANNEL_TYPES](state) {
      const types = [
        "Spectrum Website",
        "Partner Website",
        "Social Media",
        "Other",
      ];

      types.forEach(function (type) {
        state.mediaChannelTypes.push({
          text: type,
          value: type,
        });
      });
    },
    [SET_ERROR](state, errors) {
      if (Array.isArray(errors)) {
        errors.forEach(function (error) {
          state.error =
            state.error + error.detail + '"' + error.source.pointer + '"';
        });
      } else {
        state.error = errors;
      }
    },
  },
  actions: {
    async addMediaChannel({ commit, state }, screenerId) {
      commit(SET_ERROR, "");
      let data = {
        data: {
          type: "media-channels",
          attributes: {
            domain: state.mediaChannel.domain,
            screenerId: screenerId,
            isActive: state.mediaChannel.isActive,
            title: state.mediaChannel.title,
            type: state.mediaChannel.type,
          },
        },
      };
      let success = false;
      success = await Vue.axios
        .post("/api/media-channels", data)
        .then(({ data }) => {
          if (data.data.id) {
            commit(SET_MEDIA_CHANNEL, data);
            return true;
          }
        })
        .catch(({ response }) => {
          if (response && response.data.errors) {
            commit(SET_ERROR, response.data.errors);

            return false;
          }
          return true;
        });

      return success;
    },
    clearMediaChannel({ commit }) {
      commit(CLEAR_MEDIA_CHANNEL);
    },
    clearMediaChannels({ commit }) {
      commit(SET_MEDIA_CHANNELS, []);
    },
    async editMediaChannel({ commit, state }, id) {
      commit(SET_ERROR, "");
      let data = {
        data: {
          id: id,
          type: "media-channels",
          attributes: {
            domain: state.mediaChannel.domain,
            isActive: state.mediaChannel.isActive,
            title: state.mediaChannel.title,
            type: state.mediaChannel.type,
          },
        },
      };
      let success = false;
      success = await Vue.axios
        .patch(`/api/media-channels/${id}`, data)
        .then(({ data }) => {
          if (data.data.id) {
            commit(SET_MEDIA_CHANNEL, data.data);
            return true;
          }
        })
        .catch(({ response }) => {
          if (response && response.data.errors) {
            commit(SET_ERROR, response.data.errors);

            return false;
          }

          return true;
        });

      return success;
    },
    getMediaChannels(
      { commit },
      { screenerId, sortBy = ["title"], page = 1, limit = 20 } = {}
    ) {
      commit(SET_MEDIA_CHANNELS, []);

      let requestParams = {
        sort: sortBy.join(","),
        page,
        limit,
        screener_id: screenerId,
      };

      return Vue.axios
        .get("/api/media-channels", { params: requestParams })
        .then(({ data }) => {
          commit(SET_MEDIA_CHANNELS_COUNT, data.meta);
          commit(SET_MEDIA_CHANNELS, data.data);
          return true;
        })
        .catch((response) => {
          commit(SET_ERROR, response);
          return false;
        });
    },
    getMediaChannel({ commit }, id) {
      commit(CLEAR_MEDIA_CHANNEL);
      commit(SET_ERROR, "");

      return Vue.axios
          .get(`/api/media-channels/${id}`, {
            params: {
                include: [
                    "screener",
                    "screener.sponsors",
                ].join(","),
            },
          })
        .then(({ data }) => {
          if (data.data.id) {
            commit(SET_MEDIA_CHANNEL, data);
          }
          return true;
        })
        .catch(({ response }) => {
          if (response !== undefined) {
            commit(SET_ERROR, response.errors);
          }
          return false;
        });
    },
    getMediaChannelTypes({ commit }) {
      commit(SET_MEDIA_CHANNEL_TYPES);
    },
  },
};
