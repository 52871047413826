import { SET_ERROR, SET_REFERRAL_COUNTS } from "@admin/store/mutation-types";
import Vue from "vue";
import { getField } from "vuex-map-fields";

export default {
  namespaced: true,
  getters: {
    getField,
  },
  state: {
    error: "",
    randomized: 0,
    screened: 0,
    total: 0,
  },
  mutations: {
    [SET_REFERRAL_COUNTS](state, referralCounts) {
      state.randomized = referralCounts.attributes.randomized;
      state.screened = referralCounts.attributes.screened;
      state.total = referralCounts.attributes.total;
    },
    [SET_ERROR](state, message) {
      state.error = message;
    },
  },
  actions: {
    getReferralCounts({ commit }, sponsorId) {
      return Vue.axios
        .get("/api/referrals/counts/:id".replace(":id", sponsorId))
        .then(({ data }) => {
          commit(SET_REFERRAL_COUNTS, data.data);
          return true;
        })
        .catch((response) => {
          commit(SET_ERROR, response);
          return false;
        });
    },
  },
};
