<template>
  <v-btn @click="handleClick" class="mx-1">
    Cancel
    <v-icon aria-label="Cancel Icon">mdi-cancel</v-icon>
  </v-btn>
</template>

<script>
export default {
  name: "CancelButton",
  props: {
    /**
     * Attributes for the button
     * to: {
     *   type: String,
     *   required: true,
     *}
     */
    attributes: {},
  },
  methods: {
    handleClick() {
      // Check if an onCancel callback is provided
      if (this.attributes.onCancel && typeof this.attributes.onCancel === "function") {
        // Call the onCancel callback
        this.attributes.onCancel();
      }

      // Perform the redirect
      this.$router.push(this.attributes.to);
    },
  },
};
</script>

<style scoped>
.v-btn {
  background-color: #ad330f !important;
  color: white;
}
</style>
