import {
  CLEAR_USER,
  SET_ERROR,
  SET_INCLUDED,
  SET_USERS_COUNT,
  SET_USER,
  SET_USERS,
  SET_USERS_LIST,
} from "@admin/store/mutation-types";
import { getField, updateField } from "vuex-map-fields";
import Vue from "vue";

const emptyUser = function () {
  return {
    id: "",
    email: "",
    isActive: true,
    roles: [],
    sponsors: [],
  };
};

/**
 *
 * @param commit
 * @param state
 * @param id
 * @returns {Promise<boolean>}
 */
const saveUser = function (commit, state, id) {
  commit(SET_ERROR, "");
  let relationships = {};
  let relationshipTypes = ["roles", "sponsors"];
  relationshipTypes.forEach((relationshipType) => {
    relationships[relationshipType] = { data: [] };
    state.user[relationshipType].forEach(function (relationshipId) {
      // Conditional handling for sponsors since the form data is formatted differently
      if (relationshipType === "sponsors") {
        relationshipId = relationshipId.value;
      }
      relationships[relationshipType].data.push({
        type: relationshipType,
        id: relationshipId,
      });
    });
  });
  let data = setData(state);
  data.data.relationships = relationships;
  data.data.id = id;

  return id
    ? Vue.axios.patch(`/api/users/${id}`, data)
    : Vue.axios
        .post("/api/users", data)
        .then(({ data }) => {
          if (data.data.id) {
            commit(SET_USER, data.data);
          } else if (data.errors) {
            commit(SET_ERROR, data.errors);
          }
          return true;
        })
        .catch(({ response }) => {
          if (response.data.errors) {
            commit(SET_ERROR, response.data.errors);
          }
          return false;
        });
};

/**
 * A utility to set the data for form submission ajax call.
 * @param state
 * @returns {{data: {attributes: {isActive, email}, type: string}}}
 */
const setData = function (state) {
  return {
    data: {
      type: "users",
      attributes: {
        email: state.user.email,
        isActive: state.user.isActive,
      },
    },
  };
};

/**
 * A helper method to facilitate setting the relationship ids of a user
 * @param user the user object
 * @param state The store state
 */
const setRelationshipIds = function (user, state) {
  let relationshipTypes = ["roles", "sponsors"];
  relationshipTypes.forEach(function (relationshipType) {
    let collectionIds = [];
    if (
      user.relationships !== undefined &&
      user.relationships[relationshipType] !== undefined
    ) {
      user.relationships[relationshipType].data.forEach((relationship) => {
        collectionIds.push(relationship.id);
      });
    }
    state.user[relationshipType] = collectionIds;
  });
};

export default {
  namespaced: true,
  state: {
    includedRelations: [],
    user: emptyUser(),
    users: [],
    usersList: [],
    error: "",
    usersCount: 0,
  },
  getters: {
    getField,
  },
  mutations: {
    updateField,
    [CLEAR_USER](state) {
      state.user = emptyUser();
    },
    [SET_USERS_COUNT](state, meta) {
      state.usersCount = meta.record_count;
    },
    [SET_INCLUDED](state, includedRelations) {
      state.includedRelations = includedRelations;
    },
    [SET_USER](state, user) {
      Object.assign(state.user, user.attributes);
      setRelationshipIds(user, state);
    },
    [SET_USERS](state, users) {
      state.users = users;
    },
    [SET_USERS_LIST](state, users) {
      // This is used for select elements in forms.
      users.forEach(function (user) {
        state.usersList.push({
          value: user.id,
          text: user.attributes.email,
        });
      });
    },
    [SET_ERROR](state, errors) {
      if (Array.isArray(errors)) {
        errors.forEach(function (error) {
          state.error = state.error + error.detail;
        });
      } else {
        state.error = errors;
      }
    },
  },
  actions: {
    async addUser({ commit, state }) {
      commit(SET_ERROR, "");
      return saveUser(commit, state);
    },
    clearUser({ commit }) {
      commit(CLEAR_USER);
      commit(SET_INCLUDED, []);
    },
    clearUsers({ commit }) {
      commit(SET_USERS, []);
      commit(SET_INCLUDED, []);
    },
    editUser({ commit, state }, id) {
      commit(SET_ERROR, "");
      return saveUser(commit, state, id);
    },
    getUser({ commit }, id) {
      commit(CLEAR_USER);
      commit(SET_INCLUDED, []);
      commit(SET_ERROR, "");
      return Vue.axios
        .get(`/api/users/${id}`, {
          params: {
            include: ["roles", "sponsors"].join(","),
            "fields[roles]": "id,name",
            "fields[sponsors]": "id,name",
          },
        })
        .then(({ data }) => {
          if (data.data.id) {
            commit(SET_USER, data.data);
            commit(SET_INCLUDED, data.included);
          } else if (data.errors) {
            commit(SET_ERROR, data.errors[0].title);
          }
          return true;
        })
        .catch(({ data }) => {
          if (data.errors) {
            commit(SET_ERROR, data.errors[0].title);
          }
          return false;
        });
    },
    getUsers({ commit }, { sortBy = [], page = 1, limit = 20 } = {}) {
      commit(SET_USERS, []);

      const params = {
        include: "roles",
        page,
        limit,
        sort: sortBy.join(","),
        "fields[roles]": "id,name",
      };

      return Vue.axios
        .get("/api/users", {
          params,
        })
        .then(({ data }) => {
          commit(SET_USERS_COUNT, data.meta);
          commit(SET_USERS, data.data);
          commit(SET_INCLUDED, data.included);
          return true;
        })
        .catch((response) => {
          commit(SET_ERROR, response);
          return false;
        });
    },
    getUsersList({ commit }) {
      commit(SET_USERS_LIST, []);
      return Vue.axios
        .get("/api/users?fields[users]=id,email")
        .then(({ data }) => {
          commit(SET_USERS_LIST, data.data);
          return true;
        })
        .catch((response) => {
          commit(SET_ERROR, response);
          return false;
        });
    },
    validateEmail({ commit, state }) {
      commit(SET_ERROR, "");
      let data = {
        data: {
          type: "users",
          attributes: {
            email: state.user.email,
          },
        },
      };
      return Vue.axios
        .post("/api/users/validate-email", data)
        .then(({ data }) => {
          return data.data.is_valid;
        })
        .catch((e) => {
          const data = e.response.data;
          if (data.errors) {
            commit(SET_ERROR, data.errors[0].title);
          }
          return false;
        });
    },
  },
};
