<template>
  <v-dialog
    v-model="dialog"
    @click:outside="cancel"
    @keydown.esc="cancel"
    max-width="600"
  >
    <v-card>
      <v-card-title>
        {{ title }}
      </v-card-title>
      <v-card-text
        v-show="message"
        class="pa-4 black--text"
        v-html="message"
      ></v-card-text>
      <v-card-actions class="pt-3">
        <v-btn color="secondary" @click.native="cancel"> Cancel </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click.native="agree"> Confirm </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "ConfirmDialog",
  data() {
    return {
      dialog: false,
      resolve: null,
      reject: null,
      message: null,
      title: null,
    };
  },
  methods: {
    open(title, message) {
      this.dialog = true;
      this.title = title;
      this.message = message;

      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    agree() {
      this.resolve(true);
      this.dialog = false;
    },
    cancel() {
      this.resolve(false);
      this.dialog = false;
    },
  },
};
</script>
