<template>
  <v-btn :to="attributes.to" color="blue" class="mx-1">
    Edit
    <v-icon aria-label="Edit Icon" class="ml-1">mdi-pencil</v-icon>
  </v-btn>
</template>

<script>
export default {
  name: "EditButton",
  props: {
    /**
     * Attributes for the button
     * to: {
     *   type: String,
     *   required: true,
     *}
     */
    attributes: {},
  },
};
</script>

<style scoped>
.v-btn {
  background-color: #146e98 !important;
}
</style>
