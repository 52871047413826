<template>
  <v-app :style="{ backgroundColor: isLoginPage ? 'black' : '' }">
    <router-view></router-view>
  </v-app>
</template>

<script>
export default {
  name: "App",
  computed: {
    isLoginPage() {
      return this.$route.path === "/login";
    },
  },
};
</script>

<style scoped></style>
