export const ADD_BREADCRUMB = "ADD_BREADCRUMB";
export const SET_ENGAGEMENT_BREADCRUMB = "SET_ENGAGEMENT_BREADCRUMB";
export const SET_SPONSOR_BREADCRUMB = "SET_SPONSOR_BREADCRUMB";
export const RESET_BREADCRUMBS = "RESET_BREADCRUMBS";

export const SET_INCLUDED = "SET_INCLUDED";
export const SET_COUNTRY = "SET_COUNTRY";
export const SET_COUNTRIES = "SET_COUNTRIES";

export const SET_CAMPAIGN_TYPES_LIST = "SET_CAMPAIGN_TYPES_LIST";

export const CLEAR_ENGAGEMENT = "CLEAR_ENGAGEMENT";
export const REMOVE_ENGAGEMENT = "REMOVE_ENGAGEMENT";
export const SET_ENGAGEMENT = "SET_ENGAGEMENT";
export const SET_ENGAGEMENTS = "SET_ENGAGEMENTS";
export const SET_ENGAGEMENTS_FILES = "SET_ENGAGEMENTS_FILES";
export const SET_ENGAGEMENTS_FILES_UPLOAD_RESULT =
  "SET_ENGAGEMENTS_FILES_UPLOAD_RESULT";
export const SET_INCLUDED_FILES = "SET_INCLUDED_FILES";
export const SET_ENGAGEMENTS_COUNT = "SET_ENGAGEMENTS_COUNT";
export const SET_ENGAGEMENT_INDICATIONS = "SET_ENGAGEMENT_INDICATIONS";
export const SET_ENGAGEMENT_RELATION_STRINGS =
  "SET_ENGAGEMENT_RELATION_STRINGS";

export const CLEAR_SITE_LOCATION = "CLEAR_SITE_LOCATION";
export const SET_ENGAGEMENTS_SITE_LOCATIONS = "SET_ENGAGEMENTS_SITE_LOCATIONS";
export const SET_ENGAGEMENTS_SITE_LOCATIONS_UPLOAD_RESULT =
  "SET_ENGAGEMENTS_SITE_LOCATIONS_UPLOAD_RESULT";
export const SET_ENGAGEMENTS_SITE_LOCATIONS_COUNT =
  "SET_ENGAGEMENT_SITE_LOCATIONS_COUNT";

export const ADD_INDICATION_TO_LIST = "ADD_INDICATION_TO_LIST";
export const SET_INDICATION = "SET_INDICATION";
export const SET_INDICATIONS_LIST = "SET_INDICATIONS_LIST";

export const APPEND_TAGS_LIST = "APPEND_TAGS_LIST";

export const CLEAR_MEDIA_CHANNEL = "MEDIA_CHANNEL";
export const SET_MEDIA_CHANNEL = "SET_MEDIA_CHANNEL";
export const SET_MEDIA_CHANNEL_SUBMISSIONS_DATA =
  "SET_MEDIA_CHANNEL_SUBMISSIONS_DATA";
export const SET_MEDIA_CHANNELS = "SET_MEDIA_CHANNELS";
export const SET_MEDIA_CHANNEL_TYPES = "SET_MEDIA_CHANNEL_TYPES";
export const SET_MEDIA_CHANNELS_COUNT = "SET_MEDIA_CHANNELS_COUNT";

export const CLEAR_NAVIGATION_BAR_ACTION_BUTTONS =
  "CLEAR_NAVIGATION_BAR_ACTION_BUTTONS";
export const CLEAR_NAVIGATION_BAR_TITLE_OBJECT =
  "CLEAR_NAVIGATION_BAR_TITLE_OBJECT";
export const SET_NAVIGATION_BAR_ACTION_BUTTONS =
  "SET_NAVIGATION_BAR_ACTION_BUTTONS";
export const SET_NAVIGATION_BAR_TITLE_OBJECT =
  "SET_NAVIGATION_BAR_TITLE_OBJECT";

export const SET_REFERRAL_COUNTS = "SET_REFERRAL_COUNTS";
export const SET_ROLES_LIST = "SET_ROLES_LIST";

export const CLEAR_SPONSOR = "CLEAR_SPONSOR";
export const REMOVE_SPONSOR = "REMOVE_SPONSOR";
export const SET_SPONSOR = "SET_SPONSOR";
export const SET_SPONSORS = "SET_SPONSORS";
export const SET_SPONSORS_LIST = "SET_SPONSORS_LIST";
export const SET_SPONSORS_COUNT = "SET_SPONSORS_COUNT";

export const SET_TARGET_GROUPS_LIST = "SET_TARGET_GROUPS_LIST";

export const SET_THERAPEUTIC_AREAS_LIST = "SET_THERAPEUTIC_AREAS_LIST";

export const SET_SITE_LOCATION = "SET_SITE_LOCATION";
export const SET_SITE_LOCATIONS = "SET_SITE_LOCATIONS";
export const SET_SITE_LOCATION_ASSOCIATIONS = "SET_SITE_LOCATION_ASSOCIATIONS";
export const SET_SITE_LOCATIONS_COUNT = "SET_SITE_LOCATIONS_COUNT";
export const REMOVE_SITE_LOCATION = "REMOVE_SITE_LOCATION";

export const CLEAR_USER = "CLEAR_USER";
export const SET_USERS_COUNT = "SET_USERS_COUNT";
export const SET_USER = "SET_USER";
export const SET_USERS = "SET_USERS";
export const SET_USERS_LIST = "SET_USERS_LIST";
export const SET_ERROR = "SET_ERROR";

export const CLEAR_SURVEY = "CLEAR_SURVEY";
export const CLEAR_PREVIOUS_APPROVED_SURVEY = "CLEAR_PREVIOUS_APPROVED_SURVEY";
export const CLEAR_SURVEY_VERSIONS = "CLEAR_SURVEY_VERSIONS";
export const CLEAR_SURVEY_MESSAGES = "CLEAR_SURVEY_MESSAGES";
export const SET_IS_EDITING_SURVEY = "SET_IS_EDITING_SURVEY";
export const SET_SURVEY = "SET_SURVEY";
export const SET_SURVEY_ASSOCIATIONS = "SET_SURVEY_ASSOCIATIONS";
export const SET_PREVIOUS_APPROVED_SURVEY = "SET_PREVIOUS_APPROVED_SURVEY";
export const SET_SURVEYS = "SET_SURVEYS";
export const SET_SURVEY_MESSAGES = "SET_SURVEY_MESSAGES";
export const APPEND_SURVEY_VERSIONS = "APPEND_SURVEY_VERSIONS";
export const SET_SURVEY_LIST = "SET_SURVEY_LIST";
export const APPEND_SURVEY_PAGES = "APPEND_SURVEY_PAGE";
export const REMOVE_SURVEY_PAGE = "REMOVE_SURVEY_PAGE";
export const APPEND_SURVEY_PAGE_QUESTIONS = "APPEND_SURVEY_PAGE_QUESTION";
export const APPEND_SURVEY_PAGE_QUESTION_TAG_MAP =
  "APPEND_SURVEY_PAGE_QUESTION_TAG_MAP";
export const APPEND_SURVEY_PAGE_QUESTION_OPTIONS =
  "APPEND_SURVEY_PAGE_QUESTION_OPTIONS";
export const REMOVE_SURVEY_PAGE_QUESTION_OPTION =
  "REMOVE_SURVEY_PAGE_QUESTION_OPTION";
export const APPEND_QUESTION_OPTION_MAP = "APPEND_QUESTION_OPTION_MAP";
export const REMOVE_SURVEY_PAGE_QUESTION = "REMOVE_SURVEY_PAGE_QUESTION";
export const REMOVE_SURVEY = "REMOVE_SURVEY";
export const APPEND_SURVEY_PAGE_QUESTION_MAP =
  "APPEND_SURVEY_PAGE_QUESTION_MAP";
export const REMOVE_SURVEY_PAGE_QUESTION_MAP_PAGE =
  "REMOVE_SURVEY_PAGE_QUESTION_MAP_PAGE";
export const REMOVE_SURVEY_PAGE_QUESTION_MAP_QUESTION =
  "REMOVE_SURVEY_PAGE_QUESTION_MAP_QUESTION";
export const SET_SURVEYS_COUNT = "SET_SURVEYS_COUNT";
export const SET_SURVEY_ADDITIONAL_LANGUAGES =
  "SET_SURVEY_ADDITIONAL_LANGUAGES";
export const SET_QUESTION_TYPES = "SET_QUESTION_TYPES";
export const SET_SPONSOR_SURVEYS = "SET_SPONSOR_SURVEYS";

export const SET_RMS_PROVIDERS_LIST = "SET_RMS_PROVIDERS_LIST";
export const SET_RMS_AUDITS = "SET_RMS_AUDITS";
export const SET_RMS_AUDITS_COUNT = "SET_RMS_AUDITS_COUNT";

export const SET_SCREENER = "SET_SCREENER";
export const SET_SCREENERS = "SET_SCREENERS";
export const SET_SCREENERS_COUNT = "SET_SCREENERS_COUNT";
export const SET_SCREENER_FLOW = "SET_SCREENER_FLOW";
export const SET_SCREENER_SURVEYS = "SET_SCREENER_SURVEYS";
export const SET_SCREENER_SURVEYS_COUNT = "SET_SCREENER_SURVEYS_COUNT";
export const SET_SCREENER_ADDITIONAL_LANGUAGES =
  "SET_SCREENER_ADDITIONAL_LANGUAGES";

export const SET_OPTION_ENGAGEMENTS = "SET_OPTION_ENGAGEMENTS";
export const APPEND_QUESTIONNAIRE_VERSIONS = "APPEND_QUESTIONNAIRE_VERSIONS";

export const SET_GENERIC_MESSAGE = "SET_GENERIC_MESSAGE";
export const SET_GENERIC_MESSAGES = "SET_GENERIC_MESSAGES";
export const SET_GENERIC_MESSAGES_COUNT = "SET_GENERIC_MESSAGES_COUNT";
export const SET_GENERIC_MESSAGES_DEFAULTS_LIST =
  "SET_GENERIC_MESSAGES_DEFAULTS_LIST";
export const SET_GENERIC_MESSAGES_DEFAULTS_TRANSLATIONS_LIST =
  "SET_GENERIC_MESSAGES_DEFAULTS_TRANSLATIONS_LIST";
export const SET_GENERIC_MESSAGES_DEFAULTS_LIST_COUNT =
  "SET_GENERIC_MESSAGES_DEFAULTS_LIST_COUNT";
export const SET_GENERIC_MESSAGES_GLOBALS_LIST =
  "SET_GENERIC_MESSAGES_GLOBALS_LIST";
export const SET_GENERIC_MESSAGES_GLOBALS_LIST_COUNT =
  "SET_GENERIC_MESSAGES_GLOBALS_LIST_COUNT";

export const SET_ENGAGEMENTS_LIST = "SET_ENGAGEMENTS_LIST";
export const SET_SCREENER_ENGAGEMENTS = "SET_SCREENER_ENGAGEMENTS";

export const SET_SCREENER_SURVEYS_LIST = "SET_SCREENER_SURVEYS_LIST";
export const ADD_NEW_SCREENER_SURVEY = "ADD_NEW_SCREENER_SURVEY";
export const UPDATE_SCREENER_SURVEY = "UPDATE_SCREENER_SURVEY";
export const APPEND_SCREENER_SURVEY_PAGE = "APPEND_SCREENER_SURVEY_PAGE";
export const APPEND_SCREENER_SURVEY_PAGE_QUESTION =
  "APPEND_SCREENER_SURVEY_PAGE_QUESTION";
export const APPEND_SCREENER_SURVEY_PAGE_QUESTION_MAP =
  "APPEND_SCREENER_SURVEY_PAGE_QUESTION_MAP";
export const SET_SURVEY_INDEX = "SET_SURVEY_INDEX";
export const CLEAR_SCREENER_SURVEYS = "CLEAR_SCREENER_SURVEYS";

export const SET_SCREENER_BREADCRUMB = "SET_SCREENER_BREADCRUMB";

export const APPEND_SCREENER_SURVEY_TRANSITION_LIST =
  "APPEND_SCREENER_SURVEY_TRANSITION_LIST";

export const REMOVE_CONTACT_FIELD = "REMOVE_CONTACT_FIELD";
export const SET_CONTACT_FIELD = "SET_CONTACT_FIELD";
export const SET_CONTACT_FIELDS = "SET_CONTACT_FIELDS";
export const SET_CONTACT_FIELDS_COUNT = "SET_CONTACT_FIELDS_COUNT";

export const SET_CONTACT_FIELDS_SCREENERS = "SET_CONTACT_FIELDS_SCREENERS";
export const SET_CONTACT_FIELDS_SCREENERS_COUNT =
  "SET_CONTACT_FIELDS_SCREENERS_COUNT";
