import Vue from "vue";
import Vuetify from "vuetify/lib";
import colors from "vuetify/lib/util/colors";
Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    default: "participant",
    themes: {
      light: {
        primary: "#001571",
        accent: colors.indigo.base, // #3F51B5
        darkBlue: "#001A6F",
        orangeBrown: "#AD3410",
      },
      participant: {
        primary: "#00aea9",
        secondary: "#001A6F",
        tertiary: "#AD3410",
        accent: "#e6ffff",
        warning: "#ad3410",
        success: "#002150",
        error: "#ad3410",
      },
    },
  },
});
