<template>
  <div class="text-center">
    <img
      id="title-logo"
      src="/img/logo_horizontal.png"
      alt="Trial Blazer Logo"
      class="mt-5"
    />
    <router-view></router-view>
    <app-footer :isPublic="true"></app-footer>
  </div>
</template>

<script>
import AppFooter from "@admin/components/AppFooter";
export default {
  name: "PublicLayout",
  components: { AppFooter },
};
</script>

<style scoped>
#app {
  background-color: black;
}
#title-logo {
  height: auto;
  width: 200px;
}
</style>
