import auth from "@users/store/index";
import campaign_types from "@admin/store/modules/campaign_types/index.js";
import contactFields from "@admin/store/modules/contact_fields";
import contactFieldsScreeners from "@admin/store/modules/contact_fields_screeners";
import countries from "@admin/store/modules/countries/index.js";
import engagements from "@admin/store/modules/engagements/index.js";
import engagementsFiles from "@admin/store/modules/engaements_files";
import engagementsSiteLocations from "./engagements_site_locations/index";
import indications from "@admin/store/modules/indications/index.js";
import mediaChannels from "@admin/store/modules/media_channels/index.js";
import navigationBar from "@admin/store/modules/navigation_bar";
import oFormFieldTypes from "@oForms/store/o_form_field_types/index";
import oFormFieldCategories from "@oForms/store/o_form_field_categories/index";
import screeners from "@admin/store/modules/screeners";
import screenerFlow from "@admin/store/modules/screener_flow";
import screenerSurveys from "@admin/store/modules/screener_surveys";
import questionCategories from "@admin/store/modules/question_categories";
import referrals from "./referrals/index";
import roles from "./roles/index";
import sponsors from "./sponsors/index";
import siteLocations from "./site_locations/index";
import surveysNew from "@admin/store/modules/surveys_new/index.js";
import surveys from "@admin/store/modules/surveys/index.js";
import surveySubmissions from "@shared/store/modules/survey_submissions/index.js";
import systemLanguages from "@shared/store/modules/system_languages/index.js";
import target_groups from "@admin/store/modules/target_groups/index.js";
import tags from "@admin/store/modules/tags/index.js";
import therapeutic_areas from "@admin/store/modules/therapeutic_areas/index.js";
import rms_providers from "@admin/store/modules/rms_providers/index.js";
import genericMessages from "@admin/store/modules/generic_messages/index.js";
import users from "./users/index";

export default {
  auth,
  campaign_types,
  contactFields,
  contactFieldsScreeners,
  countries,
  engagements,
  engagementsFiles,
  engagementsSiteLocations,
  indications,
  mediaChannels,
  navigationBar,
  oFormFieldTypes,
  oFormFieldCategories,
  screeners,
  screenerFlow,
  screenerSurveys,
  questionCategories,
  referrals,
  roles,
  sponsors,
  siteLocations,
  surveys,
  surveysNew,
  surveySubmissions,
  systemLanguages,
  target_groups,
  tags,
  therapeutic_areas,
  rms_providers,
  users,
  genericMessages,
};
