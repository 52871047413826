import Vue from "vue";
import VueI18n from "vue-i18n";
import LocaleService from "../services/LocaleService";

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: "en", // set locale
  fallbackLocale: "en",
});

// TODO need to implement a cache busting system that checks against a checksum or something to determine if there
// is a new language file that needs to be obtained for a language that has already had a file
const loadedLanguages = []; // our default language that is preloaded

export function setI18nLanguage(locale, { changeDocumentLang = true } = {}) {
  i18n.locale = locale;

  /**
   * NOTE:
   * If you need to specify the language setting for headers, such as the `fetch` API, set it here.
   * The following is an example for axios.
   *
   * axios.defaults.headers.common['Accept-Language'] = locale
   */

  if (changeDocumentLang) {
    document.querySelector("html").setAttribute("lang", locale);
  }
}

export async function loadLocaleMessages(locale) {
  // If the language was already loaded
  if (loadedLanguages.includes(locale)) {
    return Promise.resolve(setI18nLanguage(locale));
  }

  // load locale messages with dynamic import
  const messages = await LocaleService.getLocale(locale).then((response) => {
    return response.data;
  });

  // set locale and locale message
  i18n.setLocaleMessage(locale, messages);
  loadedLanguages.push(locale);
  return setI18nLanguage(locale);
}

export default i18n;
