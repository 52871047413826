import {
  SET_ERROR,
  SET_RMS_PROVIDERS_LIST,
  SET_RMS_AUDITS,
  SET_RMS_AUDITS_COUNT,
} from "@admin/store/mutation-types";
import Vue from "vue";

export default {
  namespaced: true,
  state: {
    rmsProvidersList: [],
    rmsAudits: [],
    rmsAuditsCount: 0,
    error: "",
  },
  mutations: {
    [SET_RMS_PROVIDERS_LIST](state, rmsProviders) {
      // This is used for select elements in forms.
      rmsProviders.forEach(function (rmsProvider) {
        state.rmsProvidersList.push({
          value: rmsProvider.id,
          text: rmsProvider.attributes.name,
        });
      });
    },
    [SET_RMS_AUDITS](state, data) {
      // This is used for select elements in forms.
      state.rmsAudits = data.data;
    },
    [SET_RMS_AUDITS_COUNT](state, meta) {
      state.rmsAuditsCount = meta.record_count;
    },
    [SET_ERROR](state, message) {
      state.error = message;
    },
  },
  actions: {
    getRmsProvidersList({ commit, state }) {
      if (state.rmsProvidersList.length > 0) {
        return true;
      }
      commit(SET_RMS_PROVIDERS_LIST, []);
      return Vue.axios
        .get("/api/rms-providers", {
          params: {
            sort: "-name",
          },
        })
        .then(({ data }) => {
          commit(SET_RMS_PROVIDERS_LIST, data.data);
          return true;
        })
        .catch((response) => {
          commit(SET_ERROR, response);
          return false;
        });
    },
    getRmsAudits({ commit }, { sort = "-created", page = 1, limit = 20 } = {}) {
      const params = {
        include: "rmsProviders",
        page,
        sort,
        limit,
        "fields[rmsAudits]":
          "id,contactId,created,rmsProviderName,responseMessage,responseBody",
        "fields[rmsProviders]": "id,name",
      };

      return Vue.axios
        .get("/api/rms-audits", { params })
        .then(({ data }) => {
          commit(SET_RMS_AUDITS_COUNT, data.meta);
          commit(SET_RMS_AUDITS, data);
          return true;
        })
        .catch((response) => {
          commit(SET_ERROR, response);
          return false;
        });
    },
    resendRmsAuditRecord({ commit }, contactId) {
      commit(SET_ERROR, "");

      return Vue.axios
        .post(
          "/api/resend-rms-audit/:contactId".replace(":contactId", contactId)
        )
        .then(({ data }) => {
          if (data.errors) {
            commit(SET_ERROR, data.errors);
            return false;
          }
          return data.data.id;
        })
        .catch(({ response }) => {
          if (response.data.errors) {
            commit(SET_ERROR, response.data.errors);
          }
          return false;
        });
    },
  },
};
