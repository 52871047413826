<template>
  <v-btn :to="attributes.to" class="mx-1"> Back </v-btn>
</template>

<script>
export default {
  name: "BackButton",
  props: {
    /**
     * Attributes for the button
     * to: {
     *   type: String,
     *   required: true,
     *}
     */
    attributes: {},
  },
};
</script>

<style scoped>
.v-btn {
  background-color: #f5f5f5 !important;
  color: black;
}
</style>
