import Vue from "vue";

const defaultState = () => {
  return {
    items: [],
  };
};

export default {
  namespaced: true,
  state: defaultState(),
  getters: {
    getQuestionCategoriesList: (state) => {
      const itemsAsList = [];

      state.items.forEach((item) => {
        itemsAsList.push({ value: item.id, text: item.attributes.category });
      });

      return itemsAsList;
    },
  },
  mutations: {
    RESET_STATE(state) {
      Object.assign(state, defaultState());
    },
    SET_QUESTION_CATEGORIES(state, types) {
      state.items = types;
    },
  },
  actions: {
    refreshState({ commit }) {
      commit("RESET_STATE");
    },
    getQuestionCategories({ commit }) {
      let route = "/api/question-categories";

      return Vue.axios
        .get(route)
        .then((response) => {
          commit("SET_QUESTION_CATEGORIES", response.data.data);
        })
        .catch(() => {
          commit("SET_QUESTION_CATEGORIES", []);
        });
    },
  },
};
