<template>
  <svg :y="displayedY" style="overflow: visible">
    <rect
      class="port-handle"
      fill="#000"
      height="10"
      width="10"
      :x="x"
      :y="renderedY"
    ></rect>
    <text
      v-if="isOutPort"
      font-size="12pt"
      fill="#000000"
      text-anchor="end"
      :x="width - 10"
      :y="12"
    >
      {{ name }}
    </text>
  </svg>
</template>
<script>
export default {
  name: "Port",
  props: ["id", "name", "node", "type", "y"],
  computed: {
    displayedY() {
      return this.isOutPort ? this.y + 30 : this.y + 40;
    },
    isOutPort() {
      return this.type === "out";
    },
    renderedY() {
      return this.isOutPort ? 0 : 25;
    },
    width() {
      return this.node.width;
    },
    x() {
      return this.isOutPort ? this.width - 5 : -4;
    },
  },
};
</script>
<style scoped>
.port-handle:hover {
  fill: #f00;
}
</style>
