import Vue from "vue";
import VueCompositionApi from "@vue/composition-api";
import App from "./App.vue";
import http from "@users/plugins/http";
import router from "./router";
import store from "./store";
import i18n from "@utils/plugins/i18n";
import vuetify from "./plugins/vuetify";
import "./plugins/v-tooltip";
import auth from "./plugins/auth";
import { TiptapVuetifyPlugin } from "tiptap-vuetify";
import "tiptap-vuetify/dist/main.css";
import { formatFullDatetime, formatDate } from "@shared/util/FormatDatetime";
import "vue-draggable-resizable/dist/VueDraggableResizable.css";
import "@shared/plugins/vee-validate";
import wysiwyg from "vue-wysiwyg";

Vue.config.productionTip = false;
Vue.prototype.formatDatetime = formatFullDatetime;
Vue.prototype.formatDate = formatDate;

Vue.use(VueCompositionApi);

Vue.use(TiptapVuetifyPlugin, {
  // the next line is important! You need to provide the Vuetify Object to this place.
  vuetify, // same as "vuetify: vuetify"
  iconsGroup: "mdi",
});

Vue.use(wysiwyg, {
  hideModules: {
    code: true,
    orderedList: true,
    unorderedList: true,
    image: true,
    table: true,
    separator: true,
  },
});

// Access the Axios instance and update or add more headers
http.instance.defaults.headers = {
  ...http.instance.defaults.headers,
  // Add more headers as needed
  "Accept-Language": "en",
};

new Vue({
  http,
  router,
  store,
  config: { auth },
  i18n,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
