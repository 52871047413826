import {
  SET_ERROR,
  SET_ENGAGEMENTS_SITE_LOCATIONS,
  SET_INCLUDED,
  SET_ENGAGEMENTS_SITE_LOCATIONS_UPLOAD_RESULT,
  SET_ENGAGEMENTS_SITE_LOCATIONS_COUNT,
} from "@admin/store/mutation-types";
import { getBelongsToByType } from "@shared/util/Relationships";
import Vue from "vue";
import { findIndex } from "lodash";

const emptyEngagementSiteLocation = function () {
  return {
    id: "",
    engagementId: "",
    siteLocationId: "",
    radius: "",
    rmsSiteIdentification: "",
    isActive: true,
  };
};

export default {
  namespaced: true,
  state: {
    engagementsSiteLocation: emptyEngagementSiteLocation(),
    engagementsSiteLocations: [],
    included: {},
    uploadResult: undefined,
    error: "",
    engagements_site_locations_count: 0,
  },
  mutations: {
    [SET_ENGAGEMENTS_SITE_LOCATIONS](state, engagementsSiteLocations) {
      state.engagementsSiteLocations = engagementsSiteLocations;
    },
    [SET_INCLUDED](state, included) {
      state.included = included;
    },
    [SET_ERROR](state, errors) {
      if (Array.isArray(errors)) {
        errors.forEach(function (error) {
          state.error = state.error + error.detail;
        });
      } else {
        state.error = errors;
      }
    },
    [SET_ENGAGEMENTS_SITE_LOCATIONS_UPLOAD_RESULT](state, uploadResult) {
      state.uploadResult = uploadResult;
    },
    [SET_ENGAGEMENTS_SITE_LOCATIONS_COUNT](state, meta) {
      state.engagements_site_locations_count = meta.record_count;
    },
  },
  getters: {
    /**
     * Provides an array of data to be used in the SiteLocationsTable component
     * Each object is modified with engagementsSiteLocations data with a radius and isActive
     * and engagementSiteLocation's isActive status is set to isActiveForEngagement property
     * The engagementsSiteLocations object is assigned to the sitelocation.engagementSiteLocation
     * property to be used as needed.
     * @example
     * {
     *  type: "siteLocations",
     *  id: {string},
     *  attributes: {
     *    isActiveForEngagement: {boolean}, <-- Equals engagementSiteLocation.attributes.isActive
     *    radius: {integer|string|null}, <-- Equals engagementSiteLocation.attributes.radius
     *    ... <-- remaining siteLocations attributes
     *  },
     *  engagementSiteLocation: {
     *    type: "engagementsSiteLocations",
     *    id: {string},
     *    attributes: {...} <-- original data
     *  }
     * }
     * @param {*} state
     * @returns []
     */
    siteLocationsTableData(state) {
      const siteLocations = [];
      state.engagementsSiteLocations.forEach((engagementSiteLocation) => {
        const siteLocation = getBelongsToByType(
          engagementSiteLocation.relationships,
          "siteLocation",
          state.included
        );
        if (siteLocation !== undefined) {
          siteLocation.engagementSiteLocation = engagementSiteLocation;
          siteLocation.attributes.isActiveForEngagement =
            engagementSiteLocation.attributes.isActive;
          siteLocation.attributes.radius =
            engagementSiteLocation.attributes.radius;
          siteLocation.attributes.rmsSiteIdentification =
            engagementSiteLocation.attributes.rmsSiteIdentification;
          siteLocations.push(siteLocation);
        }
      });
      return siteLocations;
    },
  },
  actions: {
    associateEngagementSiteLocation(
      { commit },
      { engagementId, siteLocationId, isActive, radius, rmsSiteIdentification }
    ) {
      commit(SET_ERROR, "");
      return Vue.axios
        .post(`/api/engagements-site-locations/`, {
          data: {
            type: "engagementsSiteLocations",
            attributes: {
              engagementId: engagementId,
              siteLocationId: siteLocationId,
              isActive: isActive,
              radius: radius,
              rmsSiteIdentification: rmsSiteIdentification,
            },
          },
        })
        .then((data) => {
          return data;
        })
        .catch((e) => {
          const data = e.response.data;
          commit(SET_ERROR, data.errors);
          return false;
        });
    },
    clearEngagementsSiteLocations({ commit }) {
      commit(SET_ENGAGEMENTS_SITE_LOCATIONS, []);
    },
    clearEngagementsSiteLocationsUploadResult({ commit }) {
      commit(SET_ENGAGEMENTS_SITE_LOCATIONS_UPLOAD_RESULT, undefined);
    },
    deleteEngagementSiteLocation({ commit }, engagementSiteLocation) {
      commit(SET_ERROR, "");
      return Vue.axios
        .delete(
          `/api/engagements-site-locations/${engagementSiteLocation[0].id}`
        )
        .then((data) => {
          return data;
        })
        .catch((response) => {
          commit(SET_ERROR, response);
          return false;
        });
    },
    getEngagementsSiteLocations(
      { commit },
      {
        engagement_id,
        sortBy = ["siteLocation.name"],
        page = 1,
        limit = 20,
      } = {}
    ) {
      commit(SET_ERROR, "");
      commit(SET_ENGAGEMENTS_SITE_LOCATIONS, []);

      let requestParams = {
        engagement_id,
        sort: sortBy.join(","),
        page,
        limit,
        include: "siteLocation",
      };

      return Vue.axios
        .get("/api/engagements-site-locations", { params: requestParams })
        .then(({ data }) => {
          commit(SET_ENGAGEMENTS_SITE_LOCATIONS, data.data);
          commit(SET_INCLUDED, data.included);
          commit(SET_ENGAGEMENTS_SITE_LOCATIONS_COUNT, data.meta);
          return true;
        })
        .catch((response) => {
          commit(SET_ERROR, response);
          return false;
        });
    },
    async updateEngagementSiteLocation({ commit }, { id, attributes } = {}) {
      commit(SET_ERROR, "");
      let requestParams = {
        include: ["siteLocation", "siteLocation.country"].join(","),
        "fields[siteLocation.country]": ["id", "name", "iso_code"].join(","),
      };
      const data = {
        type: "engagementsSiteLocations",
        id: id,
        attributes: attributes,
      };
      return Vue.axios
        .patch(
          `/api/engagements-site-locations/${id}`,
          { data: data },
          { params: requestParams }
        )
        .then(({ data }) => {
          const idx = findIndex(
            this.engagementsSiteLocations,
            (engagementSiteLocation) => engagementSiteLocation.id === id
          );
          if (idx > -1) {
            this.engagementsSiteLocations[idx] = data.data;
            commit(
              SET_ENGAGEMENTS_SITE_LOCATIONS,
              this.engagementsSiteLocations
            );
          }
          return true;
        })
        .catch((response) => {
          commit(SET_ERROR, response);
          return false;
        });
    },
    uploadTrialSites({ commit }, { formData, engagementId }) {
      commit(SET_ERROR, "");
      commit(SET_ENGAGEMENTS_SITE_LOCATIONS_UPLOAD_RESULT, undefined);
      Vue.axios.interceptors.response.use(
        function (response) {
          return response;
        },
        function (error) {
          // get the error message returned from the api response
          let errorString = "";
          switch (error.response.status) {
            case 500:
              // if the error is a 500 return a custom error message
              errorString =
                "There was a server side error. Please contact your Administrator.";
              break;
            default:
              // otherwise return the error message returned from the api response
              errorString = error.response.data.errors[0].detail;
              break;
          }
          return Promise.reject(errorString);
        }
      );
      return Vue.axios
        .post(
          `/api/engagements-site-locations/upload/${engagementId}`,
          formData
        )
        .then(({ data }) => {
          commit(SET_ENGAGEMENTS_SITE_LOCATIONS_UPLOAD_RESULT, data.data);
          return true;
        })
        .catch((response) => {
          commit(SET_ERROR, response);
          return false;
        });
    },
  },
};
