import {
  SET_ERROR,
  SET_CONTACT_FIELDS_SCREENERS,
  SET_CONTACT_FIELDS_SCREENERS_COUNT,
} from "@admin/store/mutation-types";
import Vue from "vue";

export default {
  namespaced: true,
  state: {
    error: "",
    contactFieldsScreeners: [],
    contactFieldsScreenersCount: 0,
  },
  getters: {
    contactFieldsScreeners: (state) => {
      return state.contactFieldsScreeners;
    },
  },
  mutations: {
    [SET_ERROR](state, payload) {
      state.error = payload;
    },
    [SET_CONTACT_FIELDS_SCREENERS](state, payload) {
      state.contactFieldsScreeners = payload.data;
    },
    [SET_CONTACT_FIELDS_SCREENERS_COUNT](state, payload) {
      state.contactFieldsScreenersCount = payload;
    },
  },
  actions: {
    clearContactFieldsScreeners({ commit }) {
      commit(SET_CONTACT_FIELDS_SCREENERS, []);
    },
    setContactFieldsScreeners({ commit }, payload) {
      commit(SET_CONTACT_FIELDS_SCREENERS, payload);
    },
    getContactFieldsScreeners(
      { commit },
      { sortBy = ["weight"], limit = 100, screenerId = null }
    ) {
      const params = {
        limit,
        screenerId,
      };

      const sort = [];
      sortBy.forEach((fieldSort) => {
        sort.push(fieldSort);
      });
      params.sort = sort.join(",");

      return new Promise((resolve, reject) => {
        Vue.axios
          .get("/api/contact-fields-screeners", { params })
          .then((response) => {
            commit(SET_CONTACT_FIELDS_SCREENERS_COUNT, response.data.meta);
            let convertedData = response.data.data.map((item) => {
              return {
                id: item.id,
                type: item.type,
                attributes: {
                  contact_field_id: item.relationships.contactField.data.id,
                  screener_id: item.relationships.screener.data.id,
                  weight: item.attributes.weight,
                },
              };
            });

            commit(SET_CONTACT_FIELDS_SCREENERS, { data: convertedData });
            resolve(response.data);
          })
          .catch((error) => {
            commit(SET_ERROR, error.message);
            reject(error);
          });
      });
    },

    updateContactFieldsScreeners(
      { commit },
      { screenerId = "", contactFieldScreeners = [] }
    ) {
      const payload = {
        data: {
          type: "contactFieldsScreeners",
          attributes: {
            contactFieldScreeners: contactFieldScreeners,
          },
        },
      };
      return Vue.axios
        .post(`/api/contact-fields-screeners/update/${screenerId}`, payload)
        .then(() => {
          return true;
        })
        .catch(({ data }) => {
          if (data.success === false && data.message) {
            commit(SET_ERROR, data.message);
          }
          return false;
        });
    },
  },
};
