<template>
  <v-container>
    <v-card :dark="true">
      <v-card-title><h1>Log in with an existing account</h1></v-card-title>
      <v-form
        ref="form"
        lazy-validation
        @submit.prevent="is2faEnabled ? submitWithCode() : submit()"
      >
        <v-card-text v-if="is2faEnabled">
          <div class="ma-auto otp-div">
            <v-row>
              <v-col class="text-left">
                <label for="login-code"> Google Authenticator Code </label>
              </v-col>
              <v-col v-if="errorMsg" class="text-right">
                <div class="error--text">{{ errorMsg }}</div>
              </v-col>
            </v-row>
            <v-otp-input
              ref="code"
              id="login-code"
              v-model="code"
              :error="!!errorMsg"
              @input="errorMsg = ''"
              @finish="submitWithCode()"
            ></v-otp-input>
          </div>
        </v-card-text>
        <v-card-text v-else>
          <v-text-field
            autofocus
            ref="username"
            label="Username"
            id="login-username"
            v-model="username"
            :error-messages="errorMsg"
            @change="errorMsg = ''"
          ></v-text-field>
          <v-text-field
            label="Password"
            id="login-pw"
            v-model="password"
            type="password"
            :error-messages="errorMsg"
            @change="errorMsg = ''"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn v-if="is2faEnabled" color="secondary" @click="reset">
            Reset
          </v-btn>
          <v-btn
            type="submit"
            color="success"
            :href="duoAuthUrl"
            :loading="isSubmitting"
          >
            Log In with DUO
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn type="submit" color="primary" :loading="isSubmitting">
            Log In
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
  name: "Login",
  components: {},
  props: {
    isSubmitOnMount: {
      type: Boolean,
      default: false,
    },
    submitAuthService: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isSubmitting: false,
      username: "",
      password: "",
      code: "",
    };
  },
  computed: {
    ...mapGetters("auth", ["isLoggedIn"]),
    ...mapFields("auth", {
      error: "error",
      is2faEnabled: "user.secret_verified",
    }),
    errorMsg: {
      get: function () {
        return this.error || "";
      },
      set: function (val) {
        this.error = val;
      },
    },
    duoAuthUrl() {
      // Identify a URL from the API server that should be used to initiate authentication with DUO
      const url =
        process.env.VUE_APP_BASE_API_URL +
        "/jwt/saml?auth_service=duo&auth_target=" +
        // Indicate that the user should be redirected back to the current application with an attempt to auto authenticate.
        // This is done since the initial request to the API server doesn't actually authenticate to get a JWT. It just prepares
        // a session for a user identity to be able to consider authentication when trying to get a JWT
        encodeURIComponent(
          window.location.origin + "/login?submit=1&auth_service=duo"
        );

      return url;
    },
  },
  watch: {
    isLoggedIn: {
      handler(isLoggedIn) {
        if (isLoggedIn) {
          this.$router.push({ name: "SponsorsIndex" });
        }
      },
      immediate: true,
    },
    is2faEnabled(val) {
      if (val) {
        this.$refs.form.resetValidation();
        this.isSubmitting = false;
        this.$nextTick(() => {
          this.$refs.code.focus();
        });
      }
    },
    error(val) {
      this.isSubmitting = false;
      if (val && this.is2faEnabled) {
        this.$refs.code.focus();
      }
    },
  },
  methods: {
    ...mapActions("auth", ["login"]),
    reset() {
      this.errorMsg = "";
      this.is2faEnabled = false;
      this.$nextTick(() => {
        this.$refs.username.focus();
      });
    },
    async submit() {
      if (this.isSubmitting) return;
      this.errorMsg = "";
      this.isSubmitting = true;

      const loginArgs = {};

      if (this.submitAuthService) {
        // If using an authentication service, we don't want to attempt to login via username
        // and password since authentication will happen outside of basic auth on the server.
        // TODO: this will change once local authentication is introduced since local authentication
        // will actually use an authentication service that provides basic auth
        loginArgs.authService = this.submitAuthService;
      } else {
        loginArgs.username = this.username;
        loginArgs.password = this.password;
      }

      await this.login(loginArgs);
      this.isSubmitting = false;
    },
    async submitWithCode() {
      if (!this.$refs.form.validate() || this.isSubmitting) return;
      this.errorMsg = "";
      this.isSubmitting = true;
      await this.login({
        username: this.username,
        password: this.password,
        code: this.code,
      });
      this.isSubmitting = false;
    },
  },
  beforeMount() {
    this.reset();
  },
  mounted() {
    if (this.isSubmitOnMount) {
      this.submit();
    }
  },
};
</script>

<style scoped>
h1 {
  font-size: 1.5rem;
}
.otp-div {
  max-width: 400px;
}
</style>
