import {
  ADD_BREADCRUMB,
  CLEAR_NAVIGATION_BAR_ACTION_BUTTONS,
  CLEAR_NAVIGATION_BAR_TITLE_OBJECT,
  RESET_BREADCRUMBS,
  SET_ENGAGEMENT_BREADCRUMB,
  SET_SPONSOR_BREADCRUMB,
  SET_NAVIGATION_BAR_ACTION_BUTTONS,
  SET_NAVIGATION_BAR_TITLE_OBJECT,
  SET_ERROR,
  SET_SCREENER_BREADCRUMB,
} from "@admin/store/mutation-types";
import router from "@admin/router";
import Vue from "vue";

// This is used on the edit form view to have a quick link back to the model view
function modelViewCrumb(model, href = null) {
  let modelHref = router.history.current.path.replace("edit", "view");
  let modelIcon = "";
  switch (model) {
    case "Engagement":
      modelIcon = "mdi-dna";
      break;
    case "Media Channel":
      modelIcon = "mdi-alpha-m-box";
      break;
    case "Site Location":
      modelIcon = "mdi-map-marker";
      break;
    case "Survey":
      modelHref = href;
      modelIcon = "mdi-format-list-text";
      break;
    case "Sponsor":
      modelIcon = "mdi-alpha-s-box";
      break;
    case "Language":
      modelIcon = "mdi-web";
      break;
  }
  return {
    href: modelHref,
    icon: modelIcon,
    text: "View " + model,
  };
}

export default {
  namespaced: true,
  state: {
    actionButtons: [],
    currentBreadcrumbs: [],
    errors: [],
    engagementBreadcrumb: {
      id: "",
      name: "",
    },
    sponsorBreadcrumb: {
      id: "",
      name: "",
    },
    screenerBreadcrumb: {
        id: "",
        name: "",
    },
    titleAction: "",
    titleObject: "",
  },
  mutations: {
    [ADD_BREADCRUMB](state, breadcrumb) {
      state.currentBreadcrumbs.push(breadcrumb);
    },
    [CLEAR_NAVIGATION_BAR_ACTION_BUTTONS](state) {
      state.actionButtons = [];
    },
    [CLEAR_NAVIGATION_BAR_TITLE_OBJECT](state) {
      state.titleObject = "";
    },
    [SET_ENGAGEMENT_BREADCRUMB](state, engagement) {
      state.engagementBreadcrumb = engagement;
    },
    [SET_SCREENER_BREADCRUMB](state, screener) {
      state.screenerBreadcrumb = screener;
    },
    [SET_SPONSOR_BREADCRUMB](state, sponsor) {
      state.sponsorBreadcrumb = sponsor;
    },
    [SET_NAVIGATION_BAR_TITLE_OBJECT](state, titleObject) {
      state.titleObject = titleObject;
    },
    [SET_NAVIGATION_BAR_ACTION_BUTTONS](state, actionButtons) {
      state.actionButtons = actionButtons;
    },
    [RESET_BREADCRUMBS](state) {
      state.currentBreadcrumbs = [
        {
          href: "/",
          icon: "mdi-home",
          text: "Home",
        },
      ];
    },
  },
  actions: {
    clearActionButtons({ commit }) {
      commit(CLEAR_NAVIGATION_BAR_ACTION_BUTTONS);
    },
    clearTitleObject({ commit }) {
      commit(CLEAR_NAVIGATION_BAR_TITLE_OBJECT);
    },
    setActionButtons({ commit }, actionButtons) {
      commit(SET_NAVIGATION_BAR_ACTION_BUTTONS, actionButtons);
    },
    setBreadcrumbs({ commit, state }, params) {
      commit(RESET_BREADCRUMBS);

      const engagementViewCrumb = {
        href:
          "/engagements/view/" +
          this.state.navigationBar.engagementBreadcrumb.id,
        icon: "mdi-dna",
        text: this.state.navigationBar.engagementBreadcrumb.name,
      };
      const siteLocationRootCrumb = {
        href: "/site-locations",
        icon: "mdi-map-marker-radius-outline",
        text: "Site Locations",
      };

      const screenerViewCrumb = {
        href:
            "/screeners/view/" +
            state.screenerBreadcrumb.id,
        icon: "mdi-dna",
        text: state.screenerBreadcrumb.name,
      };
      const sponsorRootCrumb = {
        href: "/sponsors",
        icon: "mdi-alpha-s-box",
        text: "Sponsors",
      };
      const sponsorViewCrumb = {
        href: "/sponsors/view/" + this.state.navigationBar.sponsorBreadcrumb.id,
        icon: "mdi-alpha-s-box",
        text: this.state.navigationBar.sponsorBreadcrumb.name,
      };
      const languagesRootCrumb = {
        href: "/languages",
        icon: "mdi-web",
        text: "Languages",
      };
      const genericMessageRootCrumb = {
        href: "/generic-messages",
        icon: "mdi-cog",
        text: "Generic Messages",
      };
      switch (router.history.current.name) {
        case "EngagementsAdd":
          commit(ADD_BREADCRUMB, sponsorViewCrumb);
          break;
        case "EngagementEdit":
          commit(ADD_BREADCRUMB, sponsorViewCrumb);
          commit(ADD_BREADCRUMB, modelViewCrumb("Engagement"));
          break;
        case "EngagementsIndex":
          commit(ADD_BREADCRUMB, sponsorRootCrumb);
          break;
        case "EngagementView":
          commit(ADD_BREADCRUMB, sponsorViewCrumb);
          break;
        case "MediaChannelAdd":
          commit(ADD_BREADCRUMB, sponsorViewCrumb);
          commit(ADD_BREADCRUMB, screenerViewCrumb);
          break;
        case "MediaChannelEdit":
          commit(ADD_BREADCRUMB, sponsorViewCrumb);
          commit(ADD_BREADCRUMB, screenerViewCrumb);
          commit(ADD_BREADCRUMB, modelViewCrumb("Media Channel"));
          break;
        case "MediaChannelView":
          commit(ADD_BREADCRUMB, sponsorViewCrumb);
          commit(ADD_BREADCRUMB, screenerViewCrumb);
          break;
        case "ScreenerAdd":
          commit(ADD_BREADCRUMB, sponsorViewCrumb);
          break;
        case "ScreenerEdit":
          commit(ADD_BREADCRUMB, sponsorViewCrumb);
          commit(ADD_BREADCRUMB, modelViewCrumb("Screener"));
          break;
        case "ScreenerIndex":
          commit(ADD_BREADCRUMB, sponsorViewCrumb);
          break;
        case "ScreenerView":
          commit(ADD_BREADCRUMB, sponsorViewCrumb);
          break;
        case "SiteAssociations":
          commit(ADD_BREADCRUMB, sponsorViewCrumb);
          commit(ADD_BREADCRUMB, engagementViewCrumb);
          break;
        case "SiteLocationsAdd":
          commit(ADD_BREADCRUMB, siteLocationRootCrumb);
          break;
        case "SiteLocationsEdit":
          commit(ADD_BREADCRUMB, siteLocationRootCrumb);
          commit(ADD_BREADCRUMB, modelViewCrumb("Site Location"));
          break;
        case "SiteLocationsView":
          router.history.current.params.engagementId !== undefined
            ? (commit(ADD_BREADCRUMB, sponsorViewCrumb),
              commit(ADD_BREADCRUMB, engagementViewCrumb))
            : commit(ADD_BREADCRUMB, siteLocationRootCrumb);
          break;
        case "SiteLocationsUpload":
          commit(ADD_BREADCRUMB, sponsorViewCrumb);
          commit(ADD_BREADCRUMB, engagementViewCrumb);
          break;
        case "SponsorsAdd":
          commit(ADD_BREADCRUMB, sponsorRootCrumb);
          break;
        case "SponsorsEdit":
          commit(ADD_BREADCRUMB, sponsorRootCrumb);
          commit(ADD_BREADCRUMB, modelViewCrumb("Sponsor"));
          break;
        case "SponsorsView":
          commit(ADD_BREADCRUMB, sponsorRootCrumb);
          break;
        case "SurveysAdd":
          commit(ADD_BREADCRUMB, sponsorViewCrumb);
          commit(ADD_BREADCRUMB, engagementViewCrumb);
          break;
        case "SurveysEdit":
          commit(ADD_BREADCRUMB, sponsorViewCrumb);
          commit(ADD_BREADCRUMB, engagementViewCrumb);
          commit(
            ADD_BREADCRUMB,
            modelViewCrumb("Survey", "/surveys/view/" + params.self.id)
          );
          break;
        case "SurveysView":
          commit(ADD_BREADCRUMB, sponsorViewCrumb);
          commit(ADD_BREADCRUMB, engagementViewCrumb);
          break;
        case "UsersAdd":
        case "UsersEdit":
          commit(ADD_BREADCRUMB, {
            href: "/users",
            icon: "mdi-account-group",
            text: "Users",
          });
          break;
        case "LanguagesAdd":
          commit(ADD_BREADCRUMB, languagesRootCrumb);
          break;
        case "LanguagesEdit":
          commit(ADD_BREADCRUMB, languagesRootCrumb);
          commit(ADD_BREADCRUMB, modelViewCrumb("Language"));
          break;
        case "LanguagesView":
          commit(ADD_BREADCRUMB, languagesRootCrumb);
          break;
        case "GenericMessagesIndex":
          commit(ADD_BREADCRUMB, genericMessageRootCrumb);
          break;
        case "GenericMessagesEdit":
          commit(ADD_BREADCRUMB, genericMessageRootCrumb);
          break;
      }
    },
    setEngagementBreadcrumb({ commit }, engagement) {
      return Vue.axios
        .get("/api/engagements/" + engagement.id, {
          params: {
            include: "sponsor",
          },
        })
        .then(({ data }) => {
          if (data.data.id) {
            commit(SET_ENGAGEMENT_BREADCRUMB, {
              id: engagement.id,
              name: data.data.attributes.name,
            });
            commit(SET_SPONSOR_BREADCRUMB, {
              id: data.data.relationships.sponsor.data.id,
              name: data.data.attributes.sponsorName,
            });
          }
        })
        .catch(({ response }) => {
          if (response && response.data.errors) {
            commit(SET_ERROR, response.data.errors);
          }
        });
    },
    setSponsorBreadcrumb({ commit }, sponsor) {
      commit(SET_SPONSOR_BREADCRUMB, sponsor);
    },
    setTitleObject({ commit }, text) {
      commit(SET_NAVIGATION_BAR_TITLE_OBJECT, text);
    },

    setScreenerBreadcrumb({ commit }, screenerIdOrObject) {
      // default value for id and name
      let screenerId = null;

      // If the screenerIdOrObject is an object, we can assume it is a screener object
      if (typeof screenerIdOrObject === 'object') {
        // Set the screenerId
        screenerId = screenerIdOrObject.id;
        commit(SET_SCREENER_BREADCRUMB, {
          id: screenerIdOrObject.id,
          name: screenerIdOrObject.name,
        });
      } else {
        // Otherwise, we can assume it is a screenerId
        screenerId = screenerIdOrObject;
        return Vue.axios
            .get("/api/screeners/" + screenerId, {
              params: {
                include: "sponsor",
              },
            })
            .then(({data}) => {
              if (data.data.id) {
                commit(SET_SCREENER_BREADCRUMB, {
                  id: screenerId,
                  name: data.data.attributes.title,
                });
                commit(SET_SPONSOR_BREADCRUMB, {
                  id: data.data.relationships.sponsor.data.id,
                  name: data.data.attributes.sponsorName,
                });
              }
            })
            .catch(({response}) => {
              if (response && response.data.errors) {
                commit(SET_ERROR, response.data.errors);
              }
            });
      }
    },
  },
};
