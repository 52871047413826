import {
  ADD_INDICATION_TO_LIST,
  SET_ERROR,
  SET_INDICATION,
  SET_INDICATIONS_LIST,
} from "@admin/store/mutation-types";
import Vue from "vue";

const emptyIndication = function () {
  return {
    id: "",
    name: "",
  };
};

export default {
  namespaced: true,
  state: {
    error: "",
    indication: emptyIndication(),
    indicationsList: [],
  },
  mutations: {
    [ADD_INDICATION_TO_LIST](state, indication) {
      state.indicationsList.push({
        value: indication.id,
        text: indication.attributes.name,
      });
    },
    [SET_INDICATION](state, indication) {
      this.state.indication = indication.attributes;
    },
    [SET_INDICATIONS_LIST](state, indications) {
      // This is used for select elements in forms.
      indications.forEach(function (indication) {
        state.indicationsList.push({
          value: indication.id,
          text: indication.attributes.name,
        });
      });
    },
    [SET_ERROR](state, message) {
      state.error = message;
    },
  },
  actions: {
    addIndication({ commit }, name) {
      commit(SET_ERROR, "");
      let data = {
        data: {
          type: "indications",
          attributes: {
            name: name,
            is_active: true,
          },
        },
      };
      return Vue.axios
        .post("/api/indications?fields[indications]=id,name", data)
        .then(({ data }) => {
          if (data.data.id) {
            commit(SET_INDICATION, data.data);
            commit(ADD_INDICATION_TO_LIST, data.data);
          } else if (data.errors) {
            commit(SET_ERROR, data.errors);
          }
          return true;
        })
        .catch((e) => {
          const data = e.response.data;
          if (data.errors) {
            commit(SET_ERROR, data.errors[0].title);
          }

          return false;
        });
    },
    getIndicationsList({ commit, state }) {
      if (state.indicationsList.length > 0) {
        return true;
      }
      commit(SET_INDICATIONS_LIST, []);
      return Vue.axios
        .get("/api/indications", {
          params: {
            limit: 1000,
            sort: "name",
            "fields[indications]": "id,name",
          },
        })
        .then(({ data }) => {
          commit(SET_INDICATIONS_LIST, data.data);
          return true;
        })
        .catch((response) => {
          commit(SET_ERROR, response);
          return false;
        });
    },
  },
};
