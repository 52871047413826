import Vue from "vue";
import { SET_ERROR, APPEND_TAGS_LIST } from "@admin/store/mutation-types";

const defaultState = () => {
  return {
    tagsList: {},
  };
};

export default {
  namespaced: true,
  state: defaultState(),
  mutations: {
    RESET_STATE(state) {
      Object.assign(state, defaultState());
    },
    [APPEND_TAGS_LIST](state, tags) {
      const newTags = {};
      tags.forEach(function (tag) {
        newTags[tag.id] = {
          id: tag.id,
          name: tag.attributes.name,
        };
      });

      state.tagsList = Object.assign({}, state.tagsList, newTags);
    },
    [SET_ERROR](state, message) {
      state.error = message;
    },
  },
  getters: {
    getTagsList(state) {
      const tagsList = [];
      for (const [, tag] of Object.entries(state.tagsList)) {
        tagsList.push({
          text: tag.name,
          value: tag.id,
        });
      }
      return tagsList;
    },
  },
  actions: {
    refreshState({ commit }) {
      commit("RESET_STATE");
    },
    addTag({ commit }, name) {
      commit(SET_ERROR, "");
      let data = {
        data: {
          type: "tags",
          attributes: {
            name: name,
          },
        },
      };

      return Vue.axios
        .post("/api/tags?fields[tags]=id,name", data)
        .then(({ data }) => {
          if (data.data.id) {
            commit(APPEND_TAGS_LIST, [data.data]);
          } else if (data.errors) {
            commit(SET_ERROR, data.errors);
          }
          return [true, [], { id: data.data.id }];
        })
        .catch((e) => {
          const data = e.response.data;
          if (data.errors) {
            commit(SET_ERROR, data.errors[0].title);
          }

          return [false];
        });
    },
    refreshTagsList({ state }) {
      state.tagsList = {};
    },
    getTags({ commit, state, dispatch }) {
      if (state.tagsList.length > 0) {
        return true;
      }

      dispatch("refreshTagsList");

      return Vue.axios
        .get("/api/tags", {
          params: {
            limit: 1000,
            sort: "name",
            "fields[tags]": "id,name",
          },
        })
        .then(({ data }) => {
          commit(APPEND_TAGS_LIST, data.data);
          return true;
        })
        .catch((response) => {
          commit(SET_ERROR, response);
          return false;
        });
    },
  },
};
