/**
 * @class DiagramNode
 */
class DiagramNode {
  /**
   * @param id
   * @param  {String} title
   * @param  {Integer} x
   * @param  {Integer} y
   * @param  {Boolean} [isStart=false]
   */
  constructor(id, title, x, y, isStart = false) {
    this.id = id;
    this.title = title;
    this.x = x;
    this.y = y;
    this.width = 200;
    this.ports = [];
    this.questions = [];

    // Add the "in" port if it's not the starting node
    if (!isStart) {
      this.ports.push({
        id: this.id,
        type: "in",
      });
    }
    this.setHeight();
  }

  /**
   * Adds a new "out" port into the node.
   * @param {String} id
   * @param {String} qid
   * @param {String} name
   * @return {Integer} The port id
   */
  addOutPort(id, qid, name) {
    let newPort = {
      id: id,
      qid: qid,
      type: "out",
      name,
    };
    this.ports.push(newPort);
    this.setHeight();
  }

  addQuestion(id, title) {
    let newQuestion = {
      id: id,
      title: title,
    };
    this.questions.push(newQuestion);
  }

  setHeight() {
    const factor = this.ports.length > 1 ? this.ports.length : 1;
    const height = factor * 35 + 50;
    this.height = Math.max(height, 85);
  }
}

export default DiagramNode;
