import DiagramNode from "./DiagramNode";

/**
 * @class DiagramModel
 */
class DiagramModel {
  /**
   */
  constructor() {
    this._model = {
      nodes: [],
      links: [],
    };
  }

  /**
   * Adds a node to the diagram
   * @param {String} id The id of the node
   * @param {String} title The title of the node
   * @param {Integer} x X coordinate
   * @param {Integer} y Y Coordinate
   * @param  {Boolean} [isStart=false] If the node is the starting node
   * @return {DiagramNode} The node created
   */
  addNode(id, title, x, y, isStart = false) {
    const newNode = new DiagramNode(id, title, x, y, isStart);
    this._model.nodes.push(newNode);

    return newNode;
  }

  /**
   * Adds a link between two ports
   * @param {String} from   Port id. Must be an out port
   * @param {String} to     Port id. Must be an in port
   */
  addLink(from, to) {
    this._model.links.push({
      id: Math.trunc(Math.random() * 1000),
      from: from,
      to: to,
      positionFrom: {},
      positionTo: {},
    });
  }
}

export default DiagramModel;
