import {
  ADD_NEW_SCREENER_SURVEY,
  APPEND_SCREENER_SURVEY_PAGE,
  APPEND_SCREENER_SURVEY_PAGE_QUESTION,
  APPEND_SCREENER_SURVEY_PAGE_QUESTION_MAP,
  APPEND_SCREENER_SURVEY_TRANSITION_LIST,
  APPEND_SURVEY_PAGE_QUESTION_OPTIONS,
  APPEND_SURVEY_PAGE_QUESTION_TAG_MAP,
  CLEAR_SCREENER_SURVEYS,
  REMOVE_SURVEY_PAGE,
  REMOVE_SURVEY_PAGE_QUESTION,
  REMOVE_SURVEY_PAGE_QUESTION_MAP_QUESTION,
  REMOVE_SURVEY_PAGE_QUESTION_OPTION,
  SET_ERROR,
  SET_INCLUDED,
  SET_IS_EDITING_SURVEY,
  SET_SCREENER_SURVEYS,
  SET_SCREENER_SURVEYS_COUNT,
  SET_SCREENER_SURVEYS_LIST,
  SET_SPONSOR_SURVEYS,
  SET_SURVEY_INDEX,
  UPDATE_SCREENER_SURVEY,
} from "@admin/store/mutation-types";

import Vue from "vue";
import { findIndex } from "lodash";
import camelCase from "lodash.camelcase";

const emptyScreenerSurvey = function () {
  return {
    id: "",
    screenerId: "",
    surveyId: "",
  };
};

const patchSurvey = function (commit, state, id) {
  commit(SET_ERROR, "");
  commit(SET_IS_EDITING_SURVEY, true);

  const requests = [];
  const surveyData = setSurveyData(state.screenerSurveys[id]);

  let data = surveyData;

  data.data.relationships = {
    screener: {
      data: {
        type: "screeners",
        id: state.screenerSurveys[id].screenerId,
      },
    },
  };
  data.data.id = id;

  // Establish request to save the survey
  requests.push(
    Vue.axios
      .patch("/api/surveys/:id".replace(":id", id), data)
      .then(({ data }) => {
        commit(SET_IS_EDITING_SURVEY, false);
        if (data.errors) {
          commit(SET_ERROR, data.errors);
          return false;
        }
        return id;
      })
      .catch(() => {
        // Indicate false to show that we couldn't save the data, but omit interpreting
        // any errors and attempting to apply them. Data is too complex to worry about for now
        commit(SET_IS_EDITING_SURVEY, false);
        return Promise.resolve(false);
      })
  );

  // Establish requests to save survey pages
  for (const [, surveyPage] of Object.entries(
    state.screenerSurveys[id].surveyData.pages
  )) {
    const pageData = setSurveyPageData(surveyPage);

    requests.push(
      Vue.axios
        .patch("/api/pages/:id".replace(":id", surveyPage.id), pageData)
        .catch(() => {
          // Indicate false to show that we couldn't save the data, but omit interpreting
          // any errors and attempting to apply them. Data is too complex to worry about for now
          return Promise.resolve(false);
        })
    );
  }

  // Establish requests to save survey questions
  for (const [, surveyPageQuestion] of Object.entries(
    state.screenerSurveys[id].surveyData.questions
  )) {
    const questionData = setSurveyPageQuestionData(surveyPageQuestion);

    const relationshipId = questionData.data.id;
    const relationships = {};

    relationships["tags"] = { data: [] };
    if (state.surveyPageQuestionTagMap.has(relationshipId)) {
      state.surveyPageQuestionTagMap
        .get(relationshipId)
        .forEach(function (tagId) {
          relationships["tags"].data.push({
            type: "tags",
            id: tagId,
          });
        });
    }

    questionData.data.relationships = relationships;

    requests.push(
      Vue.axios
        .patch(
          "/api/questions/:id".replace(":id", surveyPageQuestion.id),
          questionData
        )
        .catch(() => {
          // Indicate false to show that we couldn't save the data, but omit interpreting
          // any errors and attempting to apply them. Data is too complex to worry about for now
          return Promise.resolve(false);
        })
    );

    //for each question options lets
    if (surveyPageQuestion.options.length > 0) {
      for (const [, surveyPageQuestionOption] of Object.entries(
        surveyPageQuestion.options
      )) {
        const optionData = setSurveyPageQuestionOptionData(
          surveyPageQuestion,
          surveyPageQuestionOption
        );

        requests.push(
          Vue.axios
            .patch(
              "/api/options/:id".replace(":id", surveyPageQuestionOption.id),
              optionData
            )
            .catch(() => {
              // Indicate false to show that we couldn't save the data, but omit interpreting
              // any errors and attempting to apply them. Data is too complex to worry about for now
              return Promise.resolve(false);
            })
        );

        // for each engagement option lets
        if (Object.keys(surveyPageQuestionOption.engagementOption).length > 0) {
          for (const [key, engagementOption] of Object.entries(
            surveyPageQuestionOption.engagementOption
          )) {
            if (engagementOption.id === null) {
              const engagementOptionData = setEngagementsOptionAddData(
                surveyPageQuestionOption.id,
                key,
                engagementOption
              );
              requests.push(
                Vue.axios
                  .post("/api/engagements-options/", engagementOptionData)
                  .catch(() => {
                    // Indicate false to show that we couldn't save the data, but omit interpreting
                    // any errors and attempting to apply them. Data is too complex to worry about for now
                    return Promise.resolve(false);
                  })
              );
            } else {
              const engagementOptionData =
                setEngagementsOptionUpdateData(engagementOption);
              requests.push(
                Vue.axios
                  .patch(
                    "/api/engagements-options/:id".replace(
                      ":id",
                      engagementOption.id
                    ),
                    engagementOptionData
                  )
                  .catch(() => {
                    // Indicate false to show that we couldn't save the data, but omit interpreting
                    // any errors and attempting to apply them. Data is too complex to worry about for now
                    return Promise.resolve(false);
                  })
              );
            }
          }
        }
      }
    }
  }

  // NOTE: we don't catch errors on the requests level because we'd only get the error that fails first
  // (if there are many) when in reality we can errors for each datum we are trying to save.
  // We need to catch on every individual request to handle this properly
  return Promise.all(requests).then((responses) => {
    let success = true;

    for (const response of responses) {
      switch (typeof response) {
        case "object":
          if (response.status !== 200) {
            success = false;

            // Log whatever error occurred for the first response that was found to fail.
            // We can only handle these errors one at a time
            commit(
              SET_ERROR,
              Object.prototype.hasOwnProperty.call(response.data, "message") &&
                response.data.message.length > 0
                ? response.data.message
                : "Could not save data"
            );
          }
          break;
        case "boolean":
          if (!response) {
            success = false;
            commit(SET_ERROR, "Could not save data");
          }
          break;
      }

      if (!success) {
        break;
      }
    }

    commit(SET_IS_EDITING_SURVEY, false);

    return Promise.resolve([success]);
  });
};

const setSurveyData = function (survey) {
  return {
    data: {
      type: "surveys",
      id: survey.id,
      attributes: {
        title: survey.title,
        globalId: survey.globalId,
      },
    },
  };
};

const setSurveyPageData = (surveyPage) => {
  return {
    data: {
      type: "pages",
      id: surveyPage.id,
      attributes: {
        display_order: surveyPage.displayOrder,
        title: surveyPage.title,
        is_active: surveyPage.isActive,
        is_deleted: surveyPage.isDeleted,
      },
    },
  };
};

const setSurveyPageQuestionData = (surveyPageQuestion) => {
  return {
    data: {
      type: "questions",
      id: surveyPageQuestion.id,
      attributes: {
        question_category_id: surveyPageQuestion.questionCategoryId,
        type: surveyPageQuestion.type,
        title: surveyPageQuestion.title,
        helper_text: surveyPageQuestion.helperText,
        deleted: surveyPageQuestion.deleted,
        created: surveyPageQuestion.created,
        modified: surveyPageQuestion.modified,
        translations: surveyPageQuestion.translations,
      },
    },
  };
};

const setSurveyPageQuestionOptionData = (question, option) => {
  return {
    data: {
      type: "options",
      id: option.id,
      attributes: {
        question_id: question.id,
        label: option.label,
        value: option.value,
        is_disqualified: option.isDisqualified,
        transition_to: option.transitionTo,
        transition_target: option.transitionTarget,
        deleted: option.deleted,
        created: option.created,
        modified: option.modified,
        translations: option.translations,
      },
    },
  };
};

const setEngagementsOptionAddData = (
  optionId,
  engagementId,
  engagementOption
) => {
  return {
    data: {
      type: "engagementsOptions",
      attributes: {
        engagementId: engagementId,
        optionId: optionId,
        isDisqualified: engagementOption.isDisqualified,
        weight: engagementOption.weight,
      },
    },
  };
};
const setEngagementsOptionUpdateData = (engagementOption) => {
  return {
    data: {
      type: "engagementsOptions",
      id: engagementOption.id,
      attributes: {
        isDisqualified: engagementOption.isDisqualified,
        weight: engagementOption.weight,
      },
    },
  };
};

export default {
  namespaced: true,
  state: {
    screenerSurvey: emptyScreenerSurvey(),
    screenerSurveys: [],
    screenerSurveyList: [],
    included: {},
    error: "",
    screenerSurveysCount: 0,
    surveyPageQuestionMap: new Map(),
    surveyPageQuestionTagMap: new Map(),
    isEditingSurvey: false,
    surveyIndex: null,
    sponsorSurveys: [],
    sponsorSurveyPages: [],
    uniqueSponsorSurveys: [],
    screenerSurveyTransitionList: [
      {
        text: "End",
        value: "end",
      },
    ],
    screenerId: null,
  },
  mutations: {
    [ADD_NEW_SCREENER_SURVEY](state, newSurvey) {
      const newSurveyCopy = {};
      newSurveyCopy[newSurvey.id] = {
        id: newSurvey.id,
        screenerSurveyId: newSurvey.screenerSurveyId,
        surveyData: {
          pages: {},
          questions: {},
          options: {},
          pageQuestionAssociationMap: new Map(),
          questionTagAssociationMap: new Map(),
        },
        ...newSurvey.attributes,
      };

      state.screenerSurveys = Object.assign(
        {},
        state.screenerSurveys,
        newSurveyCopy
      );
    },
    [APPEND_SCREENER_SURVEY_PAGE](state, { surveyIndex, newPages }) {
      const newSurveyPages = {};
      for (const surveyPage of newPages) {
        newSurveyPages[surveyPage.id] = {
          id: surveyPage.id,
          ...surveyPage.attributes,
        };
      }

      let pages = state.screenerSurveys[surveyIndex].surveyData.pages;

      let mergedPages = Object.assign({}, pages, newSurveyPages);

      // Convert the object into an array of objects
      const pageArray = Object.keys(mergedPages).map((key) => ({
        key,
        ...mergedPages[key],
      }));

      // Sort the array based on the displayOrder property in ascending order
      pageArray.sort((a, b) => a.displayOrder - b.displayOrder);

      // Convert the sorted array back into an object with the same keys
      const sortedSurveyPages = pageArray.reduce((acc, page) => {
        acc[page.key] = page;
        return acc;
      }, {});

      state.screenerSurveys[surveyIndex].surveyData.pages = sortedSurveyPages;
    },
    [APPEND_SCREENER_SURVEY_PAGE_QUESTION](
      state,
      { newQuestions, additionalLanguages }
    ) {
      const newSurveyPageQuestions = {};

      for (const surveyPageQuestion of newQuestions) {
        if (surveyPageQuestion.options === undefined) {
          surveyPageQuestion.options = [];
        }

        newSurveyPageQuestions[surveyPageQuestion.id] = {
          id: surveyPageQuestion.id,
          ...surveyPageQuestion.attributes,
          options: surveyPageQuestion.options,
        };

        if (
          surveyPageQuestion.relationships &&
          surveyPageQuestion.relationships.questionCategory
        ) {
          newSurveyPageQuestions[surveyPageQuestion.id].questionCategoryId =
            surveyPageQuestion.relationships.questionCategory.data.id;
        } else {
          newSurveyPageQuestions[surveyPageQuestion.id].questionCategoryId =
            null;
        }

        if (
          surveyPageQuestion.relationships &&
          surveyPageQuestion.relationships.page
        ) {
          newSurveyPageQuestions[surveyPageQuestion.id].pageId =
            surveyPageQuestion.relationships.page.data.id;
        }

        if (surveyPageQuestion.translations === undefined) {
          let tmpTranslations = [];

          if (additionalLanguages.length > 0) {
            additionalLanguages.forEach(function (language) {
              tmpTranslations[language.value] = {
                locale: language.value,
                title: "",
              };
            });
          }
          newSurveyPageQuestions[surveyPageQuestion.id].translations = {};
          newSurveyPageQuestions[surveyPageQuestion.id].translations =
            Object.assign(
              {},
              newSurveyPageQuestions[surveyPageQuestion.id].translations,
              tmpTranslations
            );
        }

        newSurveyPageQuestions[surveyPageQuestion.id].deleted = null;
        newSurveyPageQuestions[surveyPageQuestion.id].type = null;
      }

      let questions =
        state.screenerSurveys[state.surveyIndex].surveyData.questions;
      state.screenerSurveys[state.surveyIndex].surveyData.questions =
        Object.assign({}, questions, newSurveyPageQuestions);
    },
    [APPEND_SCREENER_SURVEY_PAGE_QUESTION_MAP](state, surveyPageQuestionMap) {
      let pageQuestionsMap = state.surveyPageQuestionMap;

      surveyPageQuestionMap.forEach((value, key) => {
        if (pageQuestionsMap.has(key)) {
          const mergedMap = new Set([...pageQuestionsMap.get(key), ...value]);

          pageQuestionsMap.set(key, [...mergedMap]);
        } else {
          pageQuestionsMap.set(key, [...value]);
        }
      });
      state.surveyPageQuestionMap = pageQuestionsMap;
    },
    [APPEND_SCREENER_SURVEY_TRANSITION_LIST](state) {
      let transitionList = [
        {
          value: "end",
          text: "End",
        },
      ];

      if (
        typeof state.screenerSurveys === "object" &&
        state.screenerSurveys !== null
      ) {
        // Check if it's an object
        for (let key in state.screenerSurveys) {
          if (
            Object.prototype.hasOwnProperty.call(state.screenerSurveys, key)
          ) {
            const survey = state.screenerSurveys[key];

            // Check if survey.surveyData.pages is defined and not empty
            if (
              survey.surveyData &&
              survey.surveyData.pages &&
              Object.keys(survey.surveyData.pages).length > 0
            ) {
              // Iterate over the values of survey.surveyData.pages
              Object.values(survey.surveyData.pages).forEach(function (page) {
                // Check if this page has any questions
                const pageHasQuestions = Object.values(
                  survey.surveyData.questions
                ).some(function (question) {
                  return question.pageId === page.id;
                });

                // If the page doesn't have questions, add it to transitionList
                if (!pageHasQuestions) {
                  transitionList.push({
                    text: page.title,
                    value: page.id,
                  });
                } else {
                  // If the page has questions, check their types
                  Object.values(survey.surveyData.questions).forEach(function (
                    question
                  ) {
                    if (
                      question.pageId === page.id &&
                      question.type !== "dob"
                    ) {
                      transitionList.push({
                        text: page.title,
                        value: page.id,
                      });
                    }
                  });
                }
              });
            }
          }
        }
      }

      state.screenerSurveyTransitionList = transitionList;
    },
    [APPEND_SURVEY_PAGE_QUESTION_OPTIONS](
      state,
      { questionOptions, additionalLanguages }
    ) {
      let newQuestionOption = {};
      let surveyIndex = state.surveyIndex;

      for (const questionOption of questionOptions) {
        newQuestionOption = {
          id: questionOption.id,
          ...questionOption.attributes,
        };

        if (
          questionOption.relationships &&
          questionOption.relationships.question !== undefined
        ) {
          newQuestionOption.questionId =
            questionOption.relationships.question.data.id;
        }

        if (
          state.screenerSurveys[surveyIndex].surveyData.questions[
            newQuestionOption.questionId
          ] !== undefined
        ) {
          newQuestionOption.pageId =
            state.screenerSurveys[surveyIndex].surveyData.questions[
              newQuestionOption.questionId
            ].pageId;

          if (
            state.screenerSurveys[surveyIndex].surveyData.questions[
              newQuestionOption.questionId
            ].options === undefined
          ) {
            state.screenerSurveys[surveyIndex].surveyData.questions[
              newQuestionOption.questionId
            ].options = [];
          }

          if (newQuestionOption.translations === undefined) {
            let tmpTranslations = [];

            if (additionalLanguages.length > 0) {
              additionalLanguages.forEach(function (language) {
                tmpTranslations[language.value] = {
                  locale: language.value,
                  label: "",
                };
              });
            }

            newQuestionOption.translations = {};
            newQuestionOption.translations = Object.assign(
              {},
              newQuestionOption.translations,
              tmpTranslations
            );
          }

          if (newQuestionOption.engagementOption === undefined) {
            newQuestionOption.engagementOption = {};
          }

          state.screenerSurveys[surveyIndex].surveyData.questions[
            newQuestionOption.questionId
          ].options.push(newQuestionOption);
        }
      }
    },
    [APPEND_SURVEY_PAGE_QUESTION_TAG_MAP](state, surveyPageQuestionTagMap) {
      surveyPageQuestionTagMap.forEach((value, key) => {
        state.surveyPageQuestionTagMap.set(key, [...value]);
      });
    },
    [CLEAR_SCREENER_SURVEYS](state) {
      state.screenerSurveys = [];
      state.screenerSurveysCount = 0;
      state.included = {};
      state.surveyPages = {};
      state.surveyPageQuestions = {};
      state.surveyPageQuestionMap = new Map();
      state.surveyPageQuestionTagMap = new Map();
      state.sponsorSurveys = [];
      state.sponsorSurveyPages = [];
      state.uniqueSponsorSurveys = [];
      state.screenerId = null;
    },
    // Allow for removing a survey page from the survey page to survey question map
    [REMOVE_SURVEY_PAGE](state, { surveyIndex, pageId }) {
      if (
        Object.hasOwn(
          state.screenerSurveys[surveyIndex].surveyData.pages,
          pageId
        )
      ) {
        Vue.delete(state.screenerSurveys[surveyIndex].surveyData.pages, pageId);
      }
      if (state.surveyPageQuestionMap.has(pageId)) {
        // Since page ids are the key values of the map, we can just delete by key
        state.surveyPageQuestionMap.delete(pageId);
      }
    },
    // Allow for removing a survey page from the survey page to survey question map
    [REMOVE_SURVEY_PAGE_QUESTION](state, { surveyIndex, questionId }) {
      if (
        Object.hasOwn(
          state.screenerSurveys[surveyIndex].surveyData.questions,
          questionId
        )
      ) {
        Vue.delete(
          state.screenerSurveys[surveyIndex].surveyData.questions,
          questionId
        );
      }
    },
    [REMOVE_SURVEY_PAGE_QUESTION_MAP_QUESTION](state, questionId) {
      const mapIterator = state.surveyPageQuestionMap.entries();

      for (const [id, optionSet] of mapIterator) {
        if (optionSet.includes(questionId)) {
          state.surveyPageQuestionMap.set(
            id,
            // Remove the questionId from the set of questionIds currently set for the page
            optionSet.filter((id) => id !== questionId)
          );
        }
      }
    },
    [REMOVE_SURVEY_PAGE_QUESTION_OPTION](state, data) {
      if (
        Object.hasOwn(
          state.screenerSurveys[state.surveyIndex].surveyData.questions,
          data.questionId
        )
      ) {
        let newOptions = state.screenerSurveys[
          state.surveyIndex
        ].surveyData.questions[data.questionId].options.filter(function (
          option
        ) {
          return option.id !== data.optionId;
        });

        state.screenerSurveys[state.surveyIndex].surveyData.questions[
          data.questionId
        ].options = newOptions;
      }
    },
    [SET_ERROR](state, errors) {
      if (Array.isArray(errors)) {
        errors.forEach(function (error) {
          state.error = state.error + error.detail;
        });
      } else {
        state.error = errors;
      }
    },
    [SET_INCLUDED](state, included) {
      state.included = included;
    },
    [SET_IS_EDITING_SURVEY](state, isEditingSurvey) {
      state.isEditingSurvey = isEditingSurvey;
    },
    [SET_SCREENER_SURVEYS](state, screenerSurveys) {
      const pages = [];
      const questions = [];
      const options = [];
      const engagementsOptions = [];
      const pageQuestionAssociationMap = new Map();
      const questionTagAssociationMap = new Map();

      // Populate the data using your existing logic
      for (const item of state.included) {
        switch (item.type) {
          case "pages":
            pages.push(item);

            if (item.relationships.questions !== undefined) {
              const questionRelationships = [];
              for (const question of item.relationships.questions.data) {
                questionRelationships.push(question.id);
              }

              if (questionRelationships.length > 0) {
                pageQuestionAssociationMap.set(item.id, questionRelationships);
              }
            }
            break;
          case "questions":
            if (item.options === undefined) {
              item.options = [];
            }
            questions.push(item);

            if (
              Object.prototype.hasOwnProperty.call(item.relationships, "tags")
            ) {
              const tagRelationships = [];
              for (const tag of item.relationships.tags.data) {
                tagRelationships.push(tag.id);
              }

              if (tagRelationships.length > 0) {
                questionTagAssociationMap.set(item.id, tagRelationships);
              }
            }

            break;
          case "options":
            options.push(item);
            break;
          case "engagementsOptions":
            engagementsOptions.push(item);
            break;
        }
      }

      let surveyList = [];
      let surveyData = {};
      screenerSurveys.forEach(function (screenerSurvey) {
        // Find the survey in the included data
        let tmpIncludedSurvey = state.included.find(function (includeData) {
          return screenerSurvey.relationships.survey.data.id === includeData.id;
        });

        // If the survey is found
        if (tmpIncludedSurvey) {
          let surveyId = screenerSurvey.relationships.survey.data.id;
          surveyData[surveyId] = {
            id: screenerSurvey.relationships.survey.data.id,
            title: tmpIncludedSurvey.attributes.title,
            modified: tmpIncludedSurvey.attributes.modified,
            created: tmpIncludedSurvey.attributes.created,
            isActive: tmpIncludedSurvey.attributes.isActive,
            globalId: tmpIncludedSurvey.attributes.globalId,
            screenerId: screenerSurvey.relationships.screener.data.id,
            screenerSurveyId: screenerSurvey.id,
            sponsorName: null,
            surveyData: {},
          };
          state.screenerId = screenerSurvey.relationships.screener.data.id;

          if (state.included && state.included.length > 0) {
            // PAGES CODE STARTS HERE
            let newSurveyPages = {};
            let newSurveyPageQuestions = {};
            let newSurveyPageQuestionOptions = {};

            // PAGES
            for (const surveyPage of pages) {
              if (surveyId === surveyPage.relationships.survey.data.id) {
                // Set Page Data
                newSurveyPages[surveyPage.id] = {
                  id: surveyPage.id,
                  ...surveyPage.attributes,
                };

                // QUESTIONS
                if (
                  surveyPage.relationships.questions &&
                  surveyPage.relationships.questions.length !== 0
                ) {
                  for (const pageQuestion of surveyPage.relationships.questions
                    .data) {
                    let tmpQuestion = questions.find(function (includeData) {
                      return pageQuestion.id === includeData.id;
                    });
                    if (tmpQuestion) {
                      newSurveyPageQuestions[tmpQuestion.id] = {
                        id: tmpQuestion.id,
                        ...tmpQuestion.attributes,
                        options: tmpQuestion.options,
                      };

                      if (tmpQuestion.relationships) {
                        if (tmpQuestion.relationships.questionCategory) {
                          newSurveyPageQuestions[
                            tmpQuestion.id
                          ].questionCategoryId =
                            tmpQuestion.relationships.questionCategory.data.id;
                        } else {
                          newSurveyPageQuestions[
                            tmpQuestion.id
                          ].questionCategoryId = null;
                        }

                        if (tmpQuestion.relationships.page) {
                          newSurveyPageQuestions[tmpQuestion.id].pageId =
                            tmpQuestion.relationships.page.data.id;
                        }

                        if (
                          tmpQuestion.relationships.options &&
                          tmpQuestion.relationships.options.length !== 0
                        ) {
                          for (const questionOption of tmpQuestion.relationships
                            .options.data) {
                            let tmpOption = options.find(function (
                              includeData
                            ) {
                              return questionOption.id === includeData.id;
                            });

                            let tmpEngagementOption = [];
                            for (const engagementOption of engagementsOptions) {
                              if (
                                engagementOption.relationships &&
                                engagementOption.relationships.option &&
                                engagementOption.relationships.option.data
                                  .id === tmpOption.id
                              ) {
                                let engagementId =
                                  engagementOption.relationships.engagement.data
                                    .id;
                                tmpEngagementOption[engagementId] = {
                                  id: engagementOption.id,
                                  ...engagementOption.attributes,
                                };
                              }
                            }

                            if (tmpOption) {
                              newSurveyPageQuestionOptions[tmpOption.id] = {
                                id: tmpOption.id,
                                questionId: tmpQuestion.id,
                                pageId: surveyPage.id,
                                engagementOption: Object.assign(
                                  {},
                                  tmpEngagementOption
                                ),
                                ...tmpOption.attributes,
                              };
                              if (
                                newSurveyPageQuestions[tmpQuestion.id]
                                  .options === undefined
                              ) {
                                newSurveyPageQuestions[tmpQuestion.id].options =
                                  [];
                              }
                              newSurveyPageQuestions[
                                tmpQuestion.id
                              ].options.push(
                                newSurveyPageQuestionOptions[tmpOption.id]
                              );
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            // Convert the object into an array of objects
            const pageArray = Object.keys(newSurveyPages).map((key) => ({
              key,
              ...newSurveyPages[key],
            }));

            // Sort the array based on the displayOrder property in ascending order
            pageArray.sort((a, b) => a.displayOrder - b.displayOrder);

            // Convert the sorted array back into an object with the same keys
            const sortedSurveyPages = pageArray.reduce((acc, page) => {
              acc[page.key] = page;
              return acc;
            }, {});

            // Set the survey pages
            surveyData[surveyId].surveyData.pages = sortedSurveyPages;
            // Set Questions
            surveyData[surveyId].surveyData.questions = newSurveyPageQuestions;
            // Set Options
            surveyData[surveyId].surveyData.options =
              newSurveyPageQuestionOptions;
            // Set Page Question Association Map
            pageQuestionAssociationMap.forEach((value, key) => {
              if (state.surveyPageQuestionMap.has(key)) {
                const mergedMap = new Set([
                  ...state.surveyPageQuestionMap.get(key),
                  ...value,
                ]);

                state.surveyPageQuestionMap.set(key, [...mergedMap]);
              } else {
                state.surveyPageQuestionMap.set(key, [...value]);
              }
            });

            // Set Question Tag Association Map
            questionTagAssociationMap.forEach((value, key) => {
              state.surveyPageQuestionTagMap.set(key, [...value]);
            });

            surveyData[surveyId].surveyData.pageQuestionAssociationMap =
              pageQuestionAssociationMap;
            surveyData[surveyId].surveyData.questionTagAssociationMap =
              questionTagAssociationMap;
          }

          surveyList.push(surveyData[surveyId]);
        }
      });

      state.screenerSurveys = surveyData;
    },
    [SET_SCREENER_SURVEYS_COUNT](state, meta) {
      state.screenerSurveysCount = meta.record_count;
    },
    [SET_SCREENER_SURVEYS_LIST](state, screenerSurveys) {
      let surveyTableData = [];
      let surveyDataIndex = 0;
      // For each screener survey
      screenerSurveys.forEach(function (screenerSurvey) {
        let survey = {};
        // Set the survey id
        survey.id = screenerSurvey.relationships.survey.data.id;
        // Find the survey in the included data
        let includedSurvey = state.included.find(function (includeData) {
          return survey.id === includeData.id;
        });

        // If the survey is found
        if (includedSurvey) {
          survey.title = includedSurvey.attributes.title;
          survey.modified = includedSurvey.attributes.modified;
          survey.created = includedSurvey.attributes.created;
        }

        surveyTableData[surveyDataIndex] = {
          surveyId: survey.id,
          title: survey.title,
          page: "",
          question: "",
          displayOrder: "",
          modified: survey.modified,
          id: survey.id,
          globalId: includedSurvey.attributes.globalId,
          screenerSurveyId: screenerSurvey.id,
          relationships: screenerSurvey.relationships,
          created: survey.created,
        };
        // If the survey has pages
        if (
          includedSurvey.relationships &&
          includedSurvey.relationships.pages &&
          includedSurvey.relationships.pages.data.length > 0
        ) {
          // For each page in the survey
          let firstPage = true;
          // Loop through the pages
          includedSurvey.relationships.pages.data.forEach(function (page) {
            // Find the page in the included data
            let includedPage = state.included.find(function (includeData) {
              return page.id === includeData.id;
            });

            // If the page is found
            if (includedPage) {
              // If this is not the first page, clone the previous row
              if (!firstPage) {
                let previousSurveyDataIndex = surveyDataIndex;
                surveyDataIndex++;
                // Clone the previous row
                surveyTableData[surveyDataIndex] = {
                  ...surveyTableData[previousSurveyDataIndex],
                };

                // Set the Page Title and Question Title to blank
                surveyTableData[surveyDataIndex].page = "";
                surveyTableData[surveyDataIndex].question = "";
                surveyTableData[surveyDataIndex].displayOrder = 0;
              } else {
                firstPage = false;
              }
              // Set the page title and display order
              surveyTableData[surveyDataIndex].page =
                includedPage.attributes.title;
              surveyTableData[surveyDataIndex].displayOrder =
                includedPage.attributes.displayOrder;
              // For each question in the page
              // If the page has questions
              if (
                includedPage.relationships &&
                includedPage.relationships.questions &&
                includedPage.relationships.questions.data.length > 0
              ) {
                let firstQuestion = true;
                // Loop through the questions
                includedPage.relationships.questions.data.forEach(function (
                  question
                ) {
                  // Find the question in the included data
                  let includedQuestion = state.included.find(function (
                    includeData
                  ) {
                    return question.id === includeData.id;
                  });

                  // If the question is found
                  if (includedQuestion) {
                    // If this is not the first question, clone the previous row
                    if (!firstQuestion) {
                      // set the previous row index
                      let previousSurveyDataIndex = surveyDataIndex;
                      surveyDataIndex++;
                      // Clone the previous row
                      surveyTableData[surveyDataIndex] = {
                        ...surveyTableData[previousSurveyDataIndex],
                      };
                      // Set the Question Title to blank
                      surveyTableData[surveyDataIndex].question = "";
                    } else {
                      firstQuestion = false;
                    }
                    // Set the question title
                    surveyTableData[surveyDataIndex].question =
                      includedQuestion.attributes.title;
                  }
                });
              }
            }
          });
        }

        surveyDataIndex++;
      });

      // Sort the table data by created first, then by displayOrder
      surveyTableData.sort((a, b) => {
        // Compare by created first
        const modifiedComparison =
          new Date(a.created).getTime() - new Date(b.created).getTime();
        if (modifiedComparison !== 0) {
          return modifiedComparison; // If modified values are not equal, return the comparison result
        }
        // If modified values are equal, compare by displayOrder
        const displayOrderA = a.displayOrder || 0; // Handle cases where displayOrder is null
        const displayOrderB = b.displayOrder || 0;

        // Sort by ascending displayOrder
        return displayOrderA - displayOrderB;
      });

      // Set the title and page to blank if they are the same as the previous row
      let previousSurveyID,
        previousPageName = null;
      // For each row in the table
      surveyTableData.forEach(function (surveyData, index) {
        // If the title or page is the same as the previous row, set it to blank
        if (surveyData.surveyId === previousSurveyID) {
          surveyTableData[index].title = "";
        } else {
          previousSurveyID = surveyData.surveyId;
        }
        // If the page is the same as the previous row, set it to blank
        if (surveyData.page === previousPageName) {
          surveyTableData[index].page = "";
        } else {
          previousPageName = surveyData.page;
        }
      });
      // Set Survey Data
      state.screenerSurveyList = surveyTableData;
    },
    [SET_SPONSOR_SURVEYS](state, surveys) {
      const surveyData = surveys;
      let sponsorSurveyPages = [];
      let uniqueSponsorSurveys = [];

      // lets set unique sponsor surveys which does not include DOB,EXIT, or the current state survey
      Object.keys(surveyData).forEach((key) => {
        surveyData[key].pagesList = [];
        Object.keys(surveyData[key].pages).forEach((pageKey) => {
          sponsorSurveyPages[pageKey] = surveyData[key].pages[pageKey];
          surveyData[key].pagesList.push(surveyData[key].pages[pageKey]);
        });
        //we don't want the user to transition to DOB survey or to this same survey
        if (surveyData[key].title !== "DOB") {
          uniqueSponsorSurveys.push(surveyData[key]);
        }
      });

      //now add current survey's pages as it might not be included above (ex: DOB,END, etc)
      Object.keys(
        state.screenerSurveys[state.surveyIndex].surveyData.pages
      ).forEach((key) => {
        sponsorSurveyPages[key] =
          state.screenerSurveys[state.surveyIndex].surveyData.pages[key];
      });
      state.sponsorSurveyPages = Object.assign(
        {},
        state.sponsorSurveyPages,
        sponsorSurveyPages
      );

      state.uniqueSponsorSurveys = uniqueSponsorSurveys;
      state.sponsorSurveys = Object.assign(
        {},
        state.sponsorSurveys,
        surveyData
      );
    },
    [SET_SURVEY_INDEX](state, surveyIndex) {
      state.surveyIndex = surveyIndex;
    },
    [UPDATE_SCREENER_SURVEY](state, updatedSurveys) {
      state.screenerSurveys = updatedSurveys;
    },
  },
  getters: {
    isEditingSurvey(state) {
      return state.isEditingSurvey;
    },
    // For a page, identify all question Ids that are associated to that id
    surveyPageQuestionIds: (state) => (pageId) => {
      if (state.surveyPageQuestionMap.has(pageId)) {
        return state.surveyPageQuestionMap.get(pageId);
      }

      return [];
    },

    // double check below
    tagsPerQuestion: (state, getters, rootState) => (questionId) => {
      const tags = [];
      if (state.surveyPageQuestionTagMap.has(questionId)) {
        const tagIds = state.surveyPageQuestionTagMap.get(questionId);
        tagIds.forEach((tagId) => {
          const record = rootState.tags.tagsList[tagId];
          if (record) {
            tags.push({
              text: record.name,
              value: record.id,
            });
          }
        });
      }
      return tags;
    },

    surveyPageIds(state) {
      const orderIndexedIds = {};
      const unorderedIds = [];

      for (const [id, page] of Object.entries(
        state.screenerSurveys[state.surveyIndex].surveyData.pages
      )) {
        if (Number.isInteger(page.displayOrder)) {
          orderIndexedIds[page.displayOrder] = id;
        } else {
          // Include when the unordered item was created so that we can still sort
          // all unordered items by when they were created and give a consistent expectation
          // to the user
          unorderedIds.push({ id, created: page.created });
        }
      }

      return Object.values(orderIndexedIds).concat(
        unorderedIds
          .sort((a, b) => {
            // Sort by created date ascending
            if (a.created < b.created) {
              return -1;
            }

            return 1;
          })
          // Get the actual ids of the unordered items
          .map((page) => page.id)
      );
    },

    screenerAdditionalLanguages: (state, getters, rootState) => {
      return rootState.screeners.screenerAdditionalLanguages;
    },
  },
  actions: {
    setSurveyIndex({ commit }, surveyIndex) {
      commit(SET_SURVEY_INDEX, surveyIndex);
    },
    alterScreenerSurvey({ commit }, updatedSurveys) {
      commit(UPDATE_SCREENER_SURVEY, updatedSurveys);
      commit(APPEND_SCREENER_SURVEY_TRANSITION_LIST);
    },
    addScreenerSurveys({ commit }, { newSurvey, newPage }) {
      commit(ADD_NEW_SCREENER_SURVEY, { newSurvey, newPage });
    },
    alterPageQuestion({ state, commit, dispatch }, { id, data }) {
      let surveyIndex = state.surveyIndex;
      if (state.screenerSurveys[surveyIndex].surveyData.questions[id]) {
        let updateSurvey = state.screenerSurveys;
        const newData = updateSurvey[surveyIndex].surveyData.questions[id];

        if (Object.prototype.hasOwnProperty.call(data, "type")) {
          newData.type = data.type;
        }

        if (Object.prototype.hasOwnProperty.call(data, "questionCategoryId")) {
          newData.questionCategoryId = data.questionCategoryId;
        }

        if (Object.prototype.hasOwnProperty.call(data, "tags")) {
          newData.tags = data;
        }

        if (Object.prototype.hasOwnProperty.call(data, "title")) {
          newData.title = data.title;
        }

        if (Object.prototype.hasOwnProperty.call(data, "helperText")) {
          newData.helperText = data.helperText;
        }

        updateSurvey[surveyIndex].surveyData.questions[id] = newData;

        commit(UPDATE_SCREENER_SURVEY, updateSurvey);

        // If the question type is changed to multiple, we need to add a default option
        if (
          state.screenerSurveys[surveyIndex].surveyData.questions[id].type ===
            "multiple" &&
          state.screenerSurveys[surveyIndex].surveyData.questions[id].options
            .length === 0
        ) {
          // Add a default option if none exist
          dispatch("addSurveyPageQuestionOption", {
            questionId: newData.id,
            label: "None of the Above",
          });
        }
      }
    },

    addSurvey({ commit, dispatch }, { screenerId = null, title = "" }) {
      commit(SET_ERROR, "");
      return Vue.axios
        .post("/api/surveys", {
          data: {
            type: "surveys",
            attributes: {
              title: title,
              screenerId: screenerId,
            },
          },
        })
        .then(async ({ data }) => {
          if (data.data.id) {
            let screenerSurvey = await dispatch("associateScreenerSurvey", {
              screenerId: screenerId,
              surveyId: data.data.id,
              cloneSurvey: false,
            });
            data.data.screenerId = screenerId;
            data.data.attributes.screenerId = screenerId;
            data.data.screenerSurveyId = screenerSurvey.data.data.id;
            await commit(ADD_NEW_SCREENER_SURVEY, data.data);
            await dispatch("addSurveyPage", data.data.id);
          } else if (data.errors) {
            commit(SET_ERROR, data.errors);
          }

          return Promise.resolve([true]);
        })
        .catch((e) => {
          const data = e.response.data;
          if (data.errors) {
            commit(SET_ERROR, data.errors[0].title);
          }

          return Promise.resolve([false]);
        });
    },
    addSurveyPage({ commit, state }, surveyIndex = null) {
      commit(SET_ERROR, "");
      commit(SET_IS_EDITING_SURVEY, true);

      // Use the provided surveyIndex if not null, otherwise, use state.surveyIndex
      let targetSurveyIndex =
        surveyIndex !== null ? surveyIndex : state.surveyIndex;

      let displayOrder = 0;
      if (state.screenerSurveys[targetSurveyIndex].surveyData !== undefined) {
        displayOrder =
          state.screenerSurveys[targetSurveyIndex].surveyData.pages.length + 1;
      }
      const pagesPayload = {
        data: {
          type: "pages",
          attributes: {
            survey_id: state.screenerSurveys[targetSurveyIndex].id,
            is_active: true,
            display_order: displayOrder,
            title: "New Page 1",
          },
        },
      };

      return Vue.axios
        .post("/api/pages", pagesPayload)
        .then((response) => {
          // The request is successful if an entity is added
          const success = response.status === 201;

          if (success) {
            let newPages = [response.data.data];
            commit(APPEND_SCREENER_SURVEY_PAGE, {
              surveyIndex: targetSurveyIndex,
              newPages,
            });
            commit(APPEND_SCREENER_SURVEY_TRANSITION_LIST);
            commit(SET_IS_EDITING_SURVEY, false);
            return Promise.resolve([success]);
          } else {
            commit(
              SET_ERROR,
              Object.prototype.hasOwnProperty.call(response.data, "message") &&
                response.data.message.length > 0
                ? response.data.message
                : "Could not save data"
            );
            commit(SET_IS_EDITING_SURVEY, false);
            return Promise.resolve([false]);
          }
        })
        .catch(() => {
          commit(SET_ERROR, "Could not add Page");
          commit(SET_IS_EDITING_SURVEY, false);
          return Promise.resolve([false]);
        });
    },
    deleteSurveyPage({ state, commit, getters }, { surveyId, id }) {
      // Check if the page exists in the survey
      if (Object.hasOwn(state.screenerSurveys[surveyId].surveyData.pages, id)) {
        commit(SET_IS_EDITING_SURVEY, true);
        // Delete a survey page based on its ID
        return Vue.axios
          .delete(
            "/api/pages/:id".replace(
              ":id",
              state.screenerSurveys[surveyId].surveyData.pages[id].id
            )
          )
          .then((response) => {
            // A delete request will return a 204 on success from the API
            const success = response.status === 204;

            if (success) {
              // Before deleting the association of page ids to survey question ids, get
              // all IDs for the questions that were mapped to that page. We want to remove these
              // questions too
              const surveyPageQuestionIds = getters.surveyPageQuestionIds(id);

              commit(REMOVE_SURVEY_PAGE, { surveyIndex: surveyId, pageId: id });
              for (const id of surveyPageQuestionIds) {
                commit(REMOVE_SURVEY_PAGE_QUESTION, {
                  surveyIndex: surveyId,
                  questionId: id,
                });
              }
            }

            commit(APPEND_SCREENER_SURVEY_TRANSITION_LIST);
            commit(SET_IS_EDITING_SURVEY, false);
            return Promise.resolve([success]);
          });
      }

      return Promise.resolve([false]);
    },
    /*
    Create a new page question for the given page for the survey
    */
    async addSurveyPageQuestion({ commit, getters }, pageId) {
      commit(SET_ERROR, "");
      commit(SET_IS_EDITING_SURVEY, true);
      const surveyPageId = pageId;
      const payload = {
        data: {
          type: "questions",
          attributes: {
            question_category_id: null,
            page_id: surveyPageId,
            is_active: true,
            title: "",
            helper_text: null,
          },
        },
      };

      return Vue.axios
        .post("/api/questions", payload)
        .then((response) => {
          // The request is successful if an entity is added
          const success = response.status === 201;

          if (success) {
            let newQuestions = [response.data.data];
            let additionalLanguages = getters.screenerAdditionalLanguages;
            commit(APPEND_SCREENER_SURVEY_PAGE_QUESTION, {
              newQuestions: newQuestions,
              additionalLanguages: additionalLanguages,
            });

            // Track which page the new question was added to
            commit(
              APPEND_SCREENER_SURVEY_PAGE_QUESTION_MAP,
              new Map([[surveyPageId, [response.data.data.id]]])
            );
          } else {
            commit(
              SET_ERROR,
              Object.prototype.hasOwnProperty.call(response.data, "message") &&
                response.data.message.length > 0
                ? response.data.message
                : "Could not save data"
            );
          }

          commit(SET_IS_EDITING_SURVEY, false);
          return Promise.resolve([success]);
        })
        .catch(() => {
          commit(SET_ERROR, "Could not add Question");
          commit(SET_IS_EDITING_SURVEY, false);
          return Promise.resolve([false]);
        });
    },

    /*
    Delete the page question with the given ID. This requires deleting it via the API and, if successful,
    removing it from local scope since it is no longer a valid question
    */
    deleteSurveyPageQuestion({ commit }, { surveyIndex, id }) {
      commit(SET_IS_EDITING_SURVEY, true);
      return Vue.axios
        .delete("/api/questions/:id".replace(":id", id))
        .then((response) => {
          // A delete request will return a 204 on success from the API
          const success = response.status === 204;

          if (success) {
            commit(REMOVE_SURVEY_PAGE_QUESTION_MAP_QUESTION, id);
            commit(REMOVE_SURVEY_PAGE_QUESTION, {
              surveyIndex: surveyIndex,
              questionId: id,
            });
          }

          commit(SET_IS_EDITING_SURVEY, false);
          return Promise.resolve([success]);
        });
    },

    addSurveyPageQuestionOption({ commit, getters }, questionIdOrObject) {
      // default value for label and value
      let questionId;
      let label = null;
      let value = null;
      let transitionTo = null;
      let transitionTarget = null;

      // If the questionIdOrObject is an object, we can assume it is a question object
      if (typeof questionIdOrObject === "object") {
        // Set the questionId to the questionId of the object
        questionId = questionIdOrObject.questionId;
        if (questionIdOrObject.label !== undefined) {
          // Set the label to the label of the object
          label = questionIdOrObject.label;
          // Set the value to the value of the object
          // Set the value to the camelCase version of the label
          value = camelCase(questionIdOrObject.label);
        }
        if (questionIdOrObject.transitionTo !== undefined) {
          // Set the transitionTo to the transitionTo of the object
          transitionTo = questionIdOrObject.transitionTo;
        }
        if (questionIdOrObject.transitionTarget !== undefined) {
            // Set the transitionTarget to the transitionTarget of the object
            transitionTarget = questionIdOrObject.transitionTarget;
        }
      } else {
        // Otherwise, we can assume it is a questionId
        questionId = questionIdOrObject;
      }

      commit(SET_ERROR, "");
      commit(SET_IS_EDITING_SURVEY, true);
      const payload = {
        data: {
          type: "options",
          attributes: {
            question_id: questionId,
            is_active: true,
            label: label,
            value: value,
            is_disqualified: 0,
            transition_to: transitionTo,
            transition_target: transitionTarget,
          },
        },
      };

      return Vue.axios
        .post("/api/options", payload)
        .then((response) => {
          // The request is successful if an entity is added
          const success = response.status === 201;

          if (success) {
            response.data.data.attributes.isNew = true;
            let questionIdOrObject = [response.data.data];
            let additionalLanguages = getters.screenerAdditionalLanguages;
            commit(APPEND_SURVEY_PAGE_QUESTION_OPTIONS, {
              questionOptions: questionIdOrObject,
              additionalLanguages: additionalLanguages,
            });
          } else {
            commit(
              SET_ERROR,
              Object.prototype.hasOwnProperty.call(response.data, "message") &&
                response.data.message.length > 0
                ? response.data.message
                : "Could not save data"
            );
          }

          commit(SET_IS_EDITING_SURVEY, false);
          return Promise.resolve([success]);
        })
        .catch(() => {
          commit(SET_ERROR, "Could not add Option");
          commit(SET_IS_EDITING_SURVEY, false);
          return Promise.resolve([false]);
        });
    },

    deleteSurveyPageQuestionOption({ commit }, { questionId, optionId }) {
      commit(SET_IS_EDITING_SURVEY, true);
      return Vue.axios
        .delete("/api/options/:id".replace(":id", optionId))
        .then((response) => {
          // A delete request will return a 204 on success from the API
          const success = response.status === 204;

          if (success) {
            commit(REMOVE_SURVEY_PAGE_QUESTION_OPTION, {
              questionId,
              optionId,
            });
          }

          commit(SET_IS_EDITING_SURVEY, false);
          return Promise.resolve([success]);
        });
    },

    updateTagMap({ commit }, { questionId, tags }) {
      const fieldTagAssociationMap = new Map();
      fieldTagAssociationMap.set(questionId, tags);
      commit(APPEND_SURVEY_PAGE_QUESTION_TAG_MAP, fieldTagAssociationMap);
    },

    associateScreenerSurvey(
      { commit },
      { screenerId, surveyId, cloneSurvey = true }
    ) {
      commit(SET_ERROR, "");
      return Vue.axios
        .post(`/api/screener-surveys?cloneSurvey=` + cloneSurvey, {
          data: {
            type: "screenerSurveys",
            attributes: {
              screenerId: screenerId,
              surveyId: surveyId,
            },
          },
        })
        .then((data) => {
          return data;
        })
        .catch((e) => {
          const data = e.response.data;
          commit(SET_ERROR, data.errors);
          return false;
        });
    },
    clearScreenerSurveys({ commit }) {
      commit(SET_SCREENER_SURVEYS, []);
    },
    deleteScreenerSurvey({ commit }, screenerSurveyId) {
      commit(SET_ERROR, "");
      return Vue.axios
        .delete(`/api/screener-surveys/${screenerSurveyId}`)
        .then((data) => {
          return data;
        })
        .catch((response) => {
          commit(SET_ERROR, response);
          return false;
        });
    },
    getScreenerSurveys(
      { commit },
      {
        screenerId,
        sortBy = ["created", "survey.title", "survey.pages.display_order"],
        page = 1,
        limit = 20,
      } = {}
    ) {
      commit(SET_ERROR, "");
      commit(SET_SCREENER_SURVEYS_LIST, []);

      let requestParams = {
        screenerId,
        sort: sortBy.join(","),
        page,
        limit,
        include: "survey,survey.pages,survey.pages.questions",
      };

      return Vue.axios
        .get("/api/screener-surveys", { params: requestParams })
        .then(({ data }) => {
          commit(SET_INCLUDED, data.included);
          commit(SET_SCREENER_SURVEYS_LIST, data.data);
          commit(SET_SCREENER_SURVEYS_COUNT, data.meta);
          return true;
        })
        .catch((response) => {
          commit(SET_ERROR, response);
          return false;
        });
    },

    getScreenerSurveyWithData(
      { commit },
      { screenerId, sortBy = ["created", "survey.title"] } = {}
    ) {
      commit(SET_ERROR, "");
      commit(SET_SCREENER_SURVEYS_LIST, []);

      let requestParams = {
        screenerId,
        sort: sortBy.join(","),
        isEdit: true,
        include:
          "survey,survey.pages,survey.pages.questions,survey.pages.questions.tags,survey.pages.questions.options,survey.pages.questions.options.engagementsOptions",
      };

      return Vue.axios
        .get("/api/screener-surveys", { params: requestParams })
        .then(({ data }) => {
          commit(SET_INCLUDED, data.included);
          commit(SET_SCREENER_SURVEYS, data.data);
          commit(SET_SCREENER_SURVEYS_COUNT, data.meta);
          commit(APPEND_SCREENER_SURVEY_TRANSITION_LIST);
          return true;
        })
        .catch((response) => {
          commit(SET_ERROR, response);
          return false;
        });
    },

    getSponsorSurveys({ commit }, { sponsorId }) {
      commit(SET_SPONSOR_SURVEYS, []);
      let params = {
        sponsorId,
        include: "screenerSurveys.surveys.pages.questions.options",
      };
      return Vue.axios
        .get("/api/screeners/", { params })
        .then((response) => {
          const success = response.status === 200;
          //if success is true, what we want to do is create an array of all sponsor surveys and set their pages->questions->options data
          if (success) {
            let surveys = [];
            let surveyId;
            let pageId;
            let questionId;
            if (response.data.included && response.data.included.length > 0) {
              for (const item of response.data.included) {
                switch (item.type) {
                  case "surveys":
                    var survey = item.attributes;
                    survey.id = item.id;
                    surveyId = survey.id;
                    surveys[item.id] = survey;
                    surveys[item.id]["pages"] = [];
                    surveys[item.id]["pagesList"] = [];

                    break;
                  case "pages":
                    var page = item.attributes;
                    page.id = item.id;
                    page.survey_id = surveyId;

                    surveys[surveyId]["pages"][item.id] = page;

                    surveys[surveyId]["pagesList"].push(page);

                    surveys[surveyId]["pages"][item.id]["questions"] = [];
                    surveys[surveyId]["pages"][item.id]["questionsList"] = [];
                    if (item.relationships.questions !== undefined) {
                      if (item.relationships.questions.data.length > 0) {
                        for (const question of item.relationships.questions
                          .data) {
                          surveys[surveyId]["pages"][item.id]["questions"][
                            question.id
                          ] = null;
                        }
                      }
                    }

                    break;
                  case "questions":
                    if (item.relationships.page !== undefined) {
                      pageId = item.relationships.page.data.id;

                      let question = item.attributes;
                      question.id = item.id;

                      surveys[surveyId]["pages"][pageId]["questionsList"].push(
                        question
                      );
                      surveys[surveyId]["pages"][pageId]["questions"][item.id] =
                        question;
                      surveys[surveyId]["pages"][pageId]["questions"][item.id][
                        "options"
                      ] = [];
                    }
                    break;
                  case "options":
                    if (item.relationships.question !== undefined) {
                      questionId = item.relationships.question.data.id;

                      let option = item.attributes;
                      option.id = item.id;

                      surveys[surveyId]["pages"][pageId]["questions"][
                        questionId
                      ]["options"].push(option);
                    }

                    break;
                }

                commit(SET_SPONSOR_SURVEYS, surveys);
              }
            } else if (response.data.errors) {
              commit(SET_ERROR, response.data.errors);
            }

            commit(SET_SPONSOR_SURVEYS, surveys);
          } else if (response.data.errors) {
            commit(SET_ERROR, response.data.errors);
          }

          return Promise.resolve([true]);
        })
        .catch(() => {
          return Promise.resolve([false]);
        });
    },

    editSurvey({ commit, state }, id) {
      commit(SET_ERROR, "");
      return patchSurvey(commit, state, id);
    },

    /*
    Set page order for pages on the current survey
     */
    changePageOrder({ state, commit }, orderedPageIds) {
      const newData = [];

      orderedPageIds.forEach((id, index) => {
        if (state.screenerSurveys[state.surveyIndex].surveyData.pages[id]) {
          const page = {
            id: id,
            attributes: {
              ...state.screenerSurveys[state.surveyIndex].surveyData.pages[id],
            },
          };

          page.attributes.displayOrder = index;

          newData.push(page);
        }
      });

      commit(APPEND_SCREENER_SURVEY_PAGE, {
        surveyIndex: state.surveyIndex,
        newPages: newData,
      });
    },

    clearSurvey({ commit }) {
      commit(CLEAR_SCREENER_SURVEYS);
    },

    updateQuestionTitleTranslation(
      { state, commit },
      { questionId, languageTarget, title }
    ) {
      if (
        state.screenerSurveys[state.surveyIndex].surveyData.questions[
          questionId
        ]
      ) {
        let screenerSurvey = state.screenerSurveys;
        if (
          screenerSurvey[state.surveyIndex].surveyData.questions[questionId]
            .translations[languageTarget] !== undefined
        ) {
          screenerSurvey[state.surveyIndex].surveyData.questions[
            questionId
          ].translations[languageTarget].title = title;
        }

        commit(UPDATE_SCREENER_SURVEY, screenerSurvey);
      }
    },

    updateQuestionOptionLabelTranslation(
      { state, commit },
      { questionId, optionId, languageTarget, label }
    ) {
      // not is use
      if (
        state.screenerSurveys[state.surveyIndex].surveyData.questions[
          questionId
        ]
      ) {
        let screenerSurvey = state.screenerSurveys;
        let question =
          screenerSurvey[state.surveyIndex].surveyData.questions[questionId];

        if (question) {
          const optionIndex = question.options.findIndex(
            (option) => option.id === optionId
          );
          if (optionIndex !== -1) {
            const option = question.options[optionIndex];
            if (option.translations && option.translations[languageTarget]) {
              option.translations[languageTarget].label = label;
              question.options[optionIndex] = option;
              screenerSurvey[state.surveyIndex].surveyData.questions[
                questionId
              ] = question;
              commit(UPDATE_SCREENER_SURVEY, screenerSurvey);
            }
          }
        }
      }
    },

    async updateScreenerSurvey({ commit }, { id, attributes } = {}) {
      commit(SET_ERROR, "");
      let requestParams = {
        include: ["survey"].join(","),
      };
      const data = {
        type: "screenerSurveys",
        id: id,
        attributes: attributes,
      };
      return Vue.axios
        .patch(
          `/api/screener-surveys/${id}`,
          { data: data },
          { params: requestParams }
        )
        .then(({ data }) => {
          const idx = findIndex(
            this.screenerSurveys,
            (screenerSurvey) => screenerSurvey.id === id
          );
          if (idx > -1) {
            this.screenerSurveys[idx] = data.data;
            commit(SET_SCREENER_SURVEYS, this.screenerSurveys);
          }
          return true;
        })
        .catch((response) => {
          commit(SET_ERROR, response);
          return false;
        });
    },

    createScreenerSurveyFromSurvey({ commit, rootState }) {
      // Assign surveysNew and survey from rootState for convenience
      let surveysNew = rootState.surveysNew;
      let survey = surveysNew.survey;

      // Create a new survey copy
      let newSurveyCopy = {};
      newSurveyCopy[survey.id] = {
        id: survey.id,
        created: survey.created,
        modified: survey.modified,
        globalId: survey.globalId,
        screenerId: survey.screenerId,
        sponsorName: survey.sponsorName,
        title: survey.title,
        isActive: survey.isActive,
        surveyData: {
          pages: {},
          questions: {},
          options: {},
          pageQuestionAssociationMap: new Map(),
          questionTagAssociationMap: new Map(),
        },
      };
      // Copy survey pages
      newSurveyCopy[survey.id].surveyData.pages = Object.assign(
        {},
        surveysNew.surveyPages
      );
      // Copy survey questions
      newSurveyCopy[survey.id].surveyData.questions = Object.assign(
        {},
        surveysNew.surveyPageQuestions
      );
      // Commit the new screener survey
      commit(UPDATE_SCREENER_SURVEY, newSurveyCopy);

      // Create a map to associate page IDs with question IDs
      const questionPageQuestionMap = new Map();
      Object.keys(surveysNew.surveyPageQuestions).forEach((questionId) => {
        const pageId = surveysNew.surveyPageQuestions[questionId].pageId;
        if (questionPageQuestionMap.has(pageId)) {
          questionPageQuestionMap.get(pageId).push(questionId);
        } else {
          questionPageQuestionMap.set(pageId, [questionId]);
        }
      });
      // Commit the map to associate page IDs with question IDs
      commit(APPEND_SCREENER_SURVEY_PAGE_QUESTION_MAP, questionPageQuestionMap);
    },

    async deleteSurvey({ commit, state, dispatch }, surveyId) {
      let screenerSurvey = state.screenerSurveys;

      // Find the next survey to transition to
      // Get all survey IDs
      const surveyIds = Object.keys(state.screenerSurveys);
      // Get the current index of the survey ID
      const currentIndex = surveyIds.indexOf(surveyId);
      // Initialize next and previous survey IDs to null
      let nextSurveyId = null;
      let previousSurveyId = null;
      let nextSurveyIndex = null;
      // If the current index is valid
      if (currentIndex > -1) {
        // If there's a next survey
        if (currentIndex < surveyIds.length - 1) {
          // There's a next survey
          nextSurveyId = surveyIds[currentIndex + 1];
        }
        // If there's a previous survey
        if (currentIndex > 0) {
          // There's a previous survey
          previousSurveyId = surveyIds[currentIndex - 1];
        }
      }

      // If there's a next survey, set the next survey index to the next survey ID
      if (nextSurveyId) {
        nextSurveyIndex = nextSurveyId;
      } else if (previousSurveyId) {
        nextSurveyIndex = previousSurveyId;
      }

      let deletedData = await dispatch(
        "deleteScreenerSurvey",
        state.screenerSurveys[surveyId].screenerSurveyId
      );

      if (deletedData) {
        delete screenerSurvey[surveyId];

        commit(UPDATE_SCREENER_SURVEY, screenerSurvey);
        commit(SET_SURVEY_INDEX, nextSurveyIndex);
      }
    },
  },
};
