import axios from "axios";

export const getBaseUrl = () => {
  return process.env.VUE_APP_BASE_API_URL;
};

export const getDownloadUrl = (filename) => {
  return [window.location.origin, "downloads", filename].join("/");
};

export const apiClient = axios.create({
  baseURL: getBaseUrl(),
  withCredentials: false,
  headers: {
    Accept: "application/vnd.api+json",
    "Content-Type": "application/vnd.api+json",
  },
});

export default apiClient;
