import Vue from "vue";
import { SET_ERROR, SET_SCREENER_FLOW } from "@admin/store/mutation-types";
import Diagram from "@admin/components/flow_diagram/Diagram.vue";

const defaultState = () => {
  return {
    flowNodes: new Diagram.Model(),
  };
};

export default {
  namespaced: true,
  state: defaultState(),
  mutations: {
    RESET_STATE(state) {
      Object.assign(state, defaultState());
    },
    [SET_SCREENER_FLOW](state, screenerFlow) {
      const diagramModel = new Diagram.Model();
      // We pop off the first 2 elements because they are the start and dob nodes
      const start = screenerFlow.shift();
      // Manually create the start node
      const startNode = diagramModel.addNode(
        start.id,
        start.title,
        0,
        200,
        true
      );
      startNode.addQuestion("start", "");
      startNode.addOutPort("start", "start", "Terms and Conditions");

      const dob = screenerFlow.shift();
      const dobNode = diagramModel.addNode(dob.id, dob.title, 300, 175);
      dobNode.addQuestion("dob", "DOB");
      dobNode.addOutPort(
        dob.questions[0].options[0].id,
        "dob",
        "BirthDate input"
      );

      // Add a link between the start and dob nodes
      diagramModel.addLink("start", dob.id);

      // Add a link between the dob node and the first question
      diagramModel.addLink(
        dob.questions[0].options[0].id,
        dob.questions[0].options[0].transition_to
      );

      // Loop through the rest of the nodes and add them to the diagram
      screenerFlow.forEach((node, index) => {
        let yFactor = index + 1;
        if (node.id === "end") {
          yFactor = 1;
        }
        const newNode = diagramModel.addNode(
          node.id,
          node.title,
          300 * (index + 2),
          175 * yFactor
        );
        node.questions.forEach((question) => {
          newNode.addQuestion(question.id, question.title);
          question.options.forEach((option) => {
            newNode.addOutPort(option.id, question.id, option.label);
            diagramModel.addLink(option.id, option.transition_to);
          });
        });
      });
      state.flowNodes = diagramModel;
    },
    [SET_ERROR](state, message) {
      state.error = message;
    },
  },
  actions: {
    getFlow({ commit }, screenerId) {
      return new Promise((resolve, reject) => {
        const apiEndpoint = "/api/screener-surveys/flow/" + screenerId;
        Vue.axios
          .get(apiEndpoint)
          .then((response) => {
            commit(SET_SCREENER_FLOW, response.data);
            resolve(response.data);
          })
          .catch((error) => {
            commit(SET_ERROR, error.message);
            reject(error);
          });
      });
    },
  },
};
