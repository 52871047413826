import { getField } from "vuex-map-fields";
//import Vue from "vue";

export default {
  getField,
  getNavigationLinks: (state, getters) => {
    const isLoggedIn = getters["auth/isLoggedIn"];
    return state.navigation_links.filter((navLink) => {
      if (navLink.auth === true) {
        return isLoggedIn;
      }
      if (navLink.auth === false) {
        return !isLoggedIn;
      }
      if (Array.isArray(navLink.auth)) {
        return getters.acceptRoles(navLink.auth);
      }
      return true;
    });
  },
  // For a set of given roles, check to see if current authenticated user has at least one of them
  acceptRoles: (state) => (roles) => {
    if (
      Array.isArray(roles) &&
      Object.hasOwn(state.auth.user, "roles") &&
      Array.isArray(state.auth.user.roles)
    ) {
      const userRoles = state.auth.user.roles.map(({ name }) => name);
      return userRoles.some((role) => {
        if (roles.includes(role)) {
          return true;
        }
      });
    }
    return false;
  },
};
