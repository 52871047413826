import {
  SET_ERROR,
  SET_ENGAGEMENTS_FILES,
  SET_ENGAGEMENTS_FILES_UPLOAD_RESULT,
  SET_INCLUDED_FILES,
} from "@admin/store/mutation-types";
import Vue from "vue";

export default {
  namespaced: true,
  state: {
    engagementsFiles: [],
    error: "",
    includedFiles: [],
    filesUploadResult: {},
  },
  mutations: {
    [SET_ENGAGEMENTS_FILES](state) {
      state.engagementsFiles = [];
      state.includedFiles.forEach((engagementFile) => {
        let file = {};
        const createdDate = new Date(engagementFile.attributes.created);
        file.created =
          createdDate.toLocaleDateString() +
          " " +
          createdDate.toLocaleTimeString();
        file.downloadUrl = engagementFile.attributes.downloadUrl;
        file.filename = engagementFile.attributes.filename;
        file.id = engagementFile.id;
        file.mime = engagementFile.attributes.mime;
        file.size = engagementFile.attributes.size;
        state.engagementsFiles.push(file);
      });
    },
    [SET_ERROR](state, message) {
      state.error = message;
    },
    [SET_ENGAGEMENTS_FILES_UPLOAD_RESULT](state, uploadResult) {
      state.uploadResult = uploadResult;
    },
    [SET_INCLUDED_FILES](state, included) {
      state.includedFiles = included;
    },
  },
  actions: {
    clearEngagementsFiles({ commit }) {
      commit(SET_ENGAGEMENTS_FILES, []);
    },
    clearEngagementsFilesUploadResult({ commit }) {
      commit(SET_ENGAGEMENTS_FILES_UPLOAD_RESULT, {});
    },
    getEngagementsFiles({ commit }, { params } = {}) {
      commit(SET_ERROR, "");
      commit(SET_ENGAGEMENTS_FILES, []);
      let requestParams = {
        sort: "o_file.filename",
        include: "o_file",
        "fields[o_file]": ["id", "created", "filename", "mime", "size"].join(
          ","
        ),
      };
      if (params !== undefined) {
        requestParams = Object.assign(requestParams, params);
      }
      return Vue.axios
        .get("/api/engagements-o-files", { params: requestParams })
        .then(({ data }) => {
          if (data.included !== undefined) {
            commit(SET_INCLUDED_FILES, data.included);
          }
          commit(SET_ENGAGEMENTS_FILES, data.data);
          return true;
        })
        .catch((response) => {
          commit(SET_ERROR, response);
          return false;
        });
    },
    uploadFile({ commit }, { formData, engagementId }) {
      commit(SET_ERROR, "");
      commit(SET_ENGAGEMENTS_FILES_UPLOAD_RESULT, {});
      return Vue.axios
        .post(`/api/engagements-o-files/${engagementId}`, formData)
        .then(({ data }) => {
          commit(SET_ENGAGEMENTS_FILES_UPLOAD_RESULT, data.data);
          return true;
        })
        .catch((response) => {
          commit(SET_ERROR, response);
          return false;
        });
    },
  },
};
