import Vue from "vue";

import auth from "@websanova/vue-auth/src/v2.js";
import driverHttpAxios from "@websanova/vue-auth/src/drivers/http/axios.1.x.js";
import driverRouterVueRouter from "@websanova/vue-auth/src/drivers/router/vue-router.2.x.js";
import driverJwt from "./drivers/jwt";
import router from "@admin/router";

Vue.use(auth, {
  plugins: {
    http: Vue.axios,
    router: router,
  },
  drivers: {
    auth: driverJwt,
    http: driverHttpAxios,
    router: driverRouterVueRouter,
  },
  options: {
    stores: ["cookie"],
    rolesKey: "type",
    loginData: {
      url: "/api/jwt/login?t=a",
      staySignedIn: false,
    },
    refreshData: { url: "/api/jwt/refresh", interval: 5 },
    logoutData: { url: "/api/jwt/logout", redirect: "/login" },
    fetchData: { url: "/api/users/me" },
    tokenDefaultKey: "auth_token_admin",
    refreshTokenName: "auth_token_admin_refresh",
    forbiddenRedirect: { name: "Forbidden" },
  },
});

export default {};
