import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import { getBaseUrl } from "@utils/http/client";

// We can't use the apiClient generated in @utils/http/client because it doesn't
// leverage using API credentials. Instead, we must create a user specific API client
// based on the same details (e.g. baseURL) as we would in the client

// Create an instance of Axios with default configuration
const instance = axios.create({
  baseURL: getBaseUrl(),
  headers: {
    Accept: "application/vnd.api+json",
    "Content-type": "application/vnd.api+json",
  },
});

Vue.use(VueAxios, instance);

export default {
  instance,
  root: instance.defaults.baseURL,
};
