<template>
  <v-app-bar dark class="flex-grow-0" app>
    <v-toolbar-title
      >{{ titleAction }}<span class="nav-divider mx-1">|</span
      ><b>{{ titleObject }}</b>
      <v-breadcrumbs
        class="p-0"
        v-if="currentBreadcrumbs.length > 0"
        :items="currentBreadcrumbs"
      >
        <template v-slot:divider>></template>
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item ripple :href="item.href">
            <v-icon class="breadcrumb__icon" v-if="item.icon" size="14">{{
              item.icon
            }}</v-icon>
            {{ item.text }}
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
    </v-toolbar-title>
    <v-spacer></v-spacer>
    <template v-for="button in actionButtons">
      <component
        :is="button.component"
        :key="button.index"
        :attributes="button.attributes"
        :text="button.text"
      />
    </template>

    <v-menu v-if="isLoggedIn" left bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" v-on="on">
          <v-icon>mdi-account</v-icon>
          <span class="d-none" aria-hidden="true">Profile</span>
        </v-btn>
      </template>
      <v-list>
        <v-list-item
          v-for="(link, index) in user_links"
          :key="index"
          @click="() => {}"
        >
          <v-list-item-title>
            <router-link :to="link.url">{{ link.label }}</router-link>
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import AddButton from "@admin/components/action_buttons/AddButton.vue";
import CancelButton from "@admin/components/action_buttons/CancelButton.vue";
import BackButton from "@admin/components/action_buttons/BackButton.vue";
import DoneButton from "@admin/components/action_buttons/DoneButton.vue";
import DeleteButton from "@admin/components/action_buttons/DeleteButton.vue";
import EditButton from "@admin/components/action_buttons/EditButton.vue";
import SaveButton from "@admin/components/action_buttons/SaveButton.vue";
import SearchBar from "@admin/components/action_buttons/SearchBar.vue";
export default {
  name: "NavigationBar",
  components: {
    AddButton,
    CancelButton,
    BackButton,
    DoneButton,
    DeleteButton,
    EditButton,
    SaveButton,
    SearchBar,
  },
  computed: {
    ...mapGetters("auth", ["isLoggedIn"]),
    ...mapState("navigationBar", [
      "actionButtons",
      "currentBreadcrumbs",
      "titleObject",
    ]),
    titleAction() {
      return this.$route.meta.titleAction;
    },
  },
  data: () => ({
    dataLoaded: false,
    user_links: [
      {
        label: "Logout",
        url: "/logout",
      },
    ],
  }),
};
</script>

<style>
.nav-divider {
  color: #faaf1c !important;
}
.v-breadcrumbs__item {
  color: #faaf1c !important;
  text-decoration: underline;
}
.v-breadcrumbs {
  color: #faaf1c !important;
  padding: 0 !important;
}
.breadcrumb__icon {
  color: #faaf1c !important;
}
</style>
