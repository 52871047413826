<script>
export default {
  name: "Question",
  props: ["index", "title"],
};
</script>

<template>
  <g>
    <text
      x="5"
      :y="45 + index * 100"
      font-size="16"
      font-weight="bold"
      fill="#000000"
      ref="question-title"
    >
      {{ title }}
    </text>
    <slot />
  </g>
</template>

<style scoped></style>
