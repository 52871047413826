import store from "@admin/store";
import {
  SET_SECRET_VERIFIED,
  SET_USER_DATA,
  SET_ERROR,
} from "@users/store/mutation-types";

export default {
  request: function (req, token) {
    if (req.url === this.options.refreshData.url) {
      // Use Refresh token for refresh requests
      token = this.token(this.options.refreshTokenName);
      req.data = { refresh_token: token };
    }

    let accept = "application/vnd.api+json";
    let contentType = "application/vnd.api+json";
    // Allow override of header
    if (req.transformHeader) {
      accept = req.transformHeader;
      contentType = req.transformHeader;
    }

    this.drivers.http.setHeaders.call(this, req, {
      "Content-Type": contentType,
      Accept: accept,
      Authorization: token,
    });
  },
  response: function (res) {
    const data = res.data || {};

    this.token(this.options.refreshTokenName, data.refresh);

    if (res.config.url === this.options.loginData.url) {
      // check on whether the login requires 2fa
      if (data.success && data.data && data.data.secret_verified) {
        store.commit("auth/" + SET_SECRET_VERIFIED, true);
        return data.success;
      } else if (!data.success && data.data && data.data.message) {
        store.commit("auth/" + SET_ERROR, data.data.message);
        return data.success;
      }
    }

    if (res.config.url === this.options.fetchData.url) {
      /**
       * This is a auth.fetch() (get user) response
       * This runs on page load, right after initial authentication (including page reloads)
       */
      // Set user data from response
      if (data.data) {
        store.commit("auth/" + SET_USER_DATA, data.data);
      }
    }
    return data.access;
  },
};
