<template>
  <g class="diagram-link" @mouseenter="mouseEnter" @mouseleave="mouseLeave">
    <path :d="curve" :style="largeStrokeStyle" stroke-width="12" fill="none" />
    <path
      :d="curve"
      ref="curvePath"
      style="stroke: rgb(0, 0, 0)"
      stroke-width="5"
      fill="none"
    />
  </g>
</template>
<script>
export default {
  name: "Link",
  props: ["positionFrom", "positionTo", "id", "index"],
  data() {
    return {
      largeStrokeStyle: "stroke:rgba(255,0,0,0.0);",
    };
  },
  computed: {
    x1() {
      return this.positionFrom.x;
    },
    y1() {
      return this.positionFrom.y - 4;
    },
    x2() {
      return this.positionTo.x - 4;
    },
    y2() {
      return this.positionTo.y - 4;
    },
    curve() {
      if (
        this.positionFrom.x &&
        this.positionFrom.y &&
        this.positionTo.x &&
        this.positionTo.y
      ) {
        const x1 = Math.trunc(this.positionFrom.x),
          y1 = Math.trunc(this.positionFrom.y - 4),
          x2 = Math.trunc(this.positionTo.x - 4),
          y2 = Math.trunc(this.positionTo.y + 22);

        const distance = Math.trunc(4 * Math.sqrt(Math.abs(x1 - x2)));
        return `M${x1},${y1} C${x1 + distance},${y1} ${
          x2 - distance
        },${y2} ${x2},${y2}`;
      }

      return "";
    },
  },
  methods: {
    mouseEnter() {
      this.largeStrokeStyle = "stroke:rgba(255,0,0,0.5);";
    },
    mouseLeave() {
      this.largeStrokeStyle = "stroke:rgba(255,0,0,0.0);";
    },
  },
};
</script>
