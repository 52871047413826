import { parse, parseISO, isValid, isMatch, differenceInYears } from "date-fns";

/*
 Given a value, parse the value into a Javascript Date.

 params: {
    value: String
    {
        locale: date-fns/locale based object
        isUTC: boolean to determine if, instead of parsing the value based on a given locale, parse it as an
          ISO based string instead
    }
 }
 */
const translateDate = (value, { locale, isUTC = false } = {}) => {
  if (isUTC) {
    if (!isMatch(value, "y-MM-dd")) {
      return false;
    }
  } else {
    if (!isMatch(value, locale.formatLong.date({ width: "short" }))) {
      return false;
    }
  }

  return isUTC ? parseISO(value) : parse(value, "P", new Date(), { locale });
};

// Determine if a passed Javascript Date can be considered valid
const validateDate = (date) => {
  if (!isValid(date)) {
    return false;
  }

  // date-fns has an unfortunate behavior where it only requires a provided year to match the format 'y'.
  // This works where, if a year fewer than four digits is provided, the year will be prefixed with 0's up to four
  // digits. We want to always ensure that at least four digits are provided, so we provide an additional check that can
  // force the provided year to be numerically large enough that it has at least four digits because it must be a year greater
  // than 1000.
  // NOTE: This will technically prevent the providing of years from 0000 - 0999, but, for this application's purposes,
  // such an old year should never be considered
  return differenceInYears(date, new Date("1000-01-01")) > 0;
};

// Provide a Vee-Validate compatible means of validating if a date value is a valid date for a given
// locale. This will allow to validate date based inputs in a way that is compatible with the user's expected
// date format for their locale
const date_format = {
  validate(value, params) {
    const date = translateDate(value, params);

    if (date === false) {
      return false;
    }

    return validateDate(date);
  },
  params: ["locale"],
};

export { translateDate, validateDate, date_format };
