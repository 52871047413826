export const SET_PREVIEW_PAGE = "SET_PREVIEW_PAGE";
export const DECREMENT_PROGRESS_BAR_VALUE = "DECREMENT_PROGRESS_BAR_VALUE";
export const INCREMENT_PROGRESS_BAR_VALUE = "INCREMENT_PROGRESS_BAR_VALUE";
export const SET_PROGRESS_BAR_MAX = "SET_PROGRESS_BAR_MAX";
export const RESET_PROGRESS_BAR = "RESET_PROGRESS_BAR";
export const SET_SURVEY_VERSION = "SET_SURVEY_VERSION";
export const SET_SURVEY = "SET_SURVEY";
export const SET_SURVEY_MESSAGES = "SET_SURVEY_MESSAGES";
export const SET_SURVEY_SUBMISSION = "SET_SURVEY_SUBMISSION";
export const SET_SURVEY_SUBMISSION_ACCESS_TOKEN =
  "SET_SURVEY_SUBMISSION_ACCESS_TOKEN";
export const SET_SURVEY_MEDIA_CHANNEL = "SET_SURVEY_MEDIA_CHANNEL";
export const SET_SURVEY_PAGE = "SET_SURVEY_PAGE";
export const SET_SURVEY_PAGE_ENTRY = "SET_SURVEY_PAGE_ENTRY";
export const SET_SURVEY_PAGE_QUESTIONS = "SET_SURVEY_PAGE_QUESTIONS";
export const SNAPSHOT_SURVEY_PAGE_HISTORY = "SNAPSHOT_SURVEY_PAGE_HISTORY";
export const POP_SURVEY_PAGE_HISTORY = "POP_SURVEY_PAGE_HISTORY";
export const SANITIZE_SURVEY_PAGE_HISTORY = "SANITIZE_SURVEY_PAGE_HISTORY";
export const SET_SURVEY_TEMP_METRIC_DATA = "SET_SURVEY_TEMP_METRIC_DATA";
export const SET_ERROR = "SET_ERROR";
export const SET_INCLUDED = "SET_INCLUDED";
export const SET_LANGUAGE = "SET_LANGUAGE";
export const SET_LANGUAGES = "SET_LANGUAGES";
export const SET_LANGUAGES_LIST = "SET_LANGUAGES_LIST";
export const SET_LANGUAGES_COUNT = "SET_LANGUAGES_COUNT";
export const CLEAR_LANGUAGE = "CLEAR_LANGUAGE";
export const REMOVE_LANGUAGE = "REMOVE_LANGUAGE";
