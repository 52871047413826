import {
  CLEAR_SITE_LOCATION,
  SET_ERROR,
  SET_SITE_LOCATION,
  SET_SITE_LOCATIONS,
  SET_SITE_LOCATION_ASSOCIATIONS,
  SET_SITE_LOCATIONS_COUNT,
  REMOVE_SITE_LOCATION,
} from "@admin/store/mutation-types";
import { getField, updateField } from "vuex-map-fields";
import Vue from "vue";

const emptySiteLocation = function () {
  return {
    id: "",
    city: "",
    country: "",
    countryName: "",
    countryIsoCode: "",
    description: "",
    isActive: true,
    latitude: 0,
    longitude: 0,
    name: "",
    piName: "",
    siteId: "",
    siteLocationIrbType: "",
    siteLocationType: "",
    state: "",
    streetAddress: "",
    unitNumber: "",
    zipCode: "",
    isVirtual: false,
  };
};

const setData = function (state) {
  const location = {
    city: state.siteLocation.city,
    state: state.siteLocation.state,
    street_address: state.siteLocation.streetAddress,
    unit_number: state.siteLocation.unitNumber,
    zip_code: state.siteLocation.zipCode,
  };
  return {
    data: {
      type: "site_locations",
      attributes: {
        site_id: state.siteLocation.siteId,
        name: state.siteLocation.name,
        description: state.siteLocation.description,
        country_id: state.siteLocation.country,
        location: location,
        is_active: state.siteLocation.isActive,
        is_virtual: state.siteLocation.isVirtual,
        pi_name: state.siteLocation.piName,
        site_location_type_id: state.siteLocation.siteLocationType,
        site_location_irb_type_id: state.siteLocation.siteLocationIrbType,
      },
    },
  };
};

export default {
  namespaced: true,
  state: {
    siteLocation: emptySiteLocation(),
    siteLocations: [],
    error: "",
    siteLocationsCount: 0,
    siteLocationTypes: [
      { text: "Academic Hospital", value: "academic_hospital" },
      { text: "Clinical Research Center", value: "clinical_research_center" },
      { text: "Neurology Disease Center", value: "neurology_disease_center" },
    ],
    siteLocationIrbTypes: [
      { text: "Local", value: "local" },
      { text: "Single", value: "single" },
    ],
    siteLocationId: "",
  },
  getters: {
    getField,
  },
  mutations: {
    updateField,
    [CLEAR_SITE_LOCATION](state) {
      state.siteLocation = emptySiteLocation();
    },
    [SET_SITE_LOCATION](state, siteLocation) {
      const included = siteLocation.included;
      siteLocation = siteLocation.data;
      Object.assign(state.siteLocation, siteLocation.attributes);
      state.siteLocation.country = siteLocation.relationships.country.data.id;

      let relationshipTypes = ["siteLocationType", "siteLocationIrbType"];
      relationshipTypes.forEach(function (relationshipType) {
        state.siteLocation[relationshipType] =
          siteLocation.relationships[relationshipType].data.id;
      });

      included.forEach(function (included) {
        if (
          included.type === "engagementsSiteLocations" &&
          included.relationships.engagement.data.id ===
            siteLocation.engagementId
        ) {
          state.siteLocationId = included.attributes.rmsSiteIdentification;
        } else {
          state.siteLocationId = siteLocation.attributes.siteId;
        }
      });
      state.siteLocation.city = siteLocation.attributes.location.city;
      state.siteLocation.streetAddress =
        siteLocation.attributes.location.street_address;
      state.siteLocation.state = siteLocation.attributes.location.state;
      state.siteLocation.unitNumber =
        siteLocation.attributes.location.unit_number;
      state.siteLocation.zipCode = siteLocation.attributes.location.zip_code;
    },
    [SET_SITE_LOCATIONS](state, siteLocations) {
      state.siteLocations = siteLocations;
    },
    [SET_SITE_LOCATION_ASSOCIATIONS](state, engagementSiteLocations) {
      if (state.siteLocations.length > 0) {
        state.siteLocations.forEach((siteLocation) => {
          siteLocation.associated = false;
          engagementSiteLocations.forEach((engagementSiteLocation) => {
            if (
              siteLocation.id ===
              engagementSiteLocation.relationships.siteLocation.data.id
            ) {
              siteLocation.associated = true;
            }
          });
        });
      }
    },
    [SET_SITE_LOCATIONS_COUNT](state, meta) {
      state.siteLocationsCount = meta.record_count;
    },
    [REMOVE_SITE_LOCATION](state, id) {
      const index = state.siteLocations.findIndex(
        (siteLocation) => siteLocation.id === id
      );
      if (index !== -1) {
        Vue.delete(state.siteLocations, index);
      }
    },
    [SET_ERROR](state, errors) {
      if (Array.isArray(errors)) {
        errors.forEach(function (error) {
          state.error = state.error + error.detail;
        });
      } else {
        state.error = errors;
      }
    },
  },
  actions: {
    async addSiteLocation({ commit, state }) {
      commit(SET_ERROR, "");
      let data = setData(state);
      return await Vue.axios
        .post("/api/site-locations", data)
        .then(({ data }) => {
          if (data.errors) {
            commit(SET_ERROR, data.errors);
            return false;
          }
          return data.data.id;
        })
        .catch((e) => {
          if (e.response) {
            const data = e.response.data;
            if (data.errors) {
              commit(SET_ERROR, data.errors[0].detail);
            }
          } else {
            commit(SET_ERROR, e.message);
          }
          return false;
        });
    },
    clearSiteLocation({ commit }) {
      commit(CLEAR_SITE_LOCATION);
    },
    clearSiteLocations({ commit }) {
      commit(SET_SITE_LOCATIONS, []);
    },
    editSiteLocation({ commit, state }, id) {
      commit(SET_ERROR, "");

      let data = setData(state);
      data.data.id = id;

      return Vue.axios
        .patch("/api/site-locations/:id".replace(":id", id), data)
        .then(({ data }) => {
          if (data.errors) {
            commit(SET_ERROR, data.errors);
          }
          return id;
        })
        .catch(({ response }) => {
          if (response.data.errors) {
            commit(SET_ERROR, response.data.errors);
          }
          return false;
        });
    },
    getSiteLocation({ commit }, { id, engagementId }) {
      commit(CLEAR_SITE_LOCATION);
      commit(SET_ERROR, "");
      const params = {
        include: ["countries", "engagements_site_locations"].join(","),
        "fields[country]": "id,name,iso_code",
        "fields[engagements_site_locations]":
          "id,engagement_id,site_location_id,rms_site_identification",
      };

      return Vue.axios
        .get("/api/site-locations/:id".replace(":id", id), { params })
        .then(({ data }) => {
          if (data.data.id) {
            data.data.engagementId = engagementId;
            commit(SET_SITE_LOCATION, data);
          } else if (data.errors) {
            commit(SET_ERROR, data.errors[0].title);
          }
          return true;
        })
        .catch((error) => {
          commit(SET_ERROR, error);
          return false;
        });
    },
    getSiteLocations(
      { commit },
      { sortBy = ["-isActive", "name"], page = 1, limit = 20 } = {}
    ) {
      commit(SET_SITE_LOCATIONS, []);
      let requestParams = {
        page,
        limit,
        sort: sortBy.join(","),
        "fields[country]": "id,name,iso_code",
        "fields[engagements]": "id,name,isActive",
      };
      return Vue.axios
        .get("/api/site-locations", { params: requestParams })
        .then(({ data }) => {
          commit(SET_SITE_LOCATIONS_COUNT, data.meta);
          commit(SET_SITE_LOCATIONS, data.data);
          return true;
        })
        .catch((response) => {
          commit(SET_ERROR, response);
          return false;
        });
    },
    deleteSiteLocation({ commit }, id) {
      return Vue.axios
        .delete("/api/site-locations/:id".replace(":id", id))
        .then((response) => {
          // A delete request will return a 204 on success from the API
          const success = response.status === 204;

          if (success) {
            commit(REMOVE_SITE_LOCATION, id);
          }

          return Promise.resolve([success]);
        });
    },
    searchSiteLocations({ commit }, search) {
      commit(SET_SITE_LOCATIONS, []);
      let requestParams = {
        sort: "-isActive,name",
        include: "country",
        "fields[country]": "id,name,iso_code",
        searchQuery: search,
      };
      return Vue.axios
        .get("/api/site-locations", { params: requestParams })
        .then(({ data }) => {
          commit(SET_SITE_LOCATIONS, data.data);
          return true;
        })
        .catch((response) => {
          commit(SET_ERROR, response);
          return false;
        });
    },
    setAssociations({ commit }, engagementSiteLocations) {
      commit(SET_SITE_LOCATION_ASSOCIATIONS, engagementSiteLocations);
    },
  },
};
