import {
  SET_ERROR,
  SET_CAMPAIGN_TYPES_LIST,
} from "@admin/store/mutation-types";
import Vue from "vue";

export default {
  namespaced: true,
  state: {
    campaignTypesList: [],
    error: "",
  },
  mutations: {
    [SET_CAMPAIGN_TYPES_LIST](state, campaignTypes) {
      // This is used for select elements in forms.
      campaignTypes.forEach(function (campaignType) {
        state.campaignTypesList.push({
          value: campaignType.id,
          text: campaignType.attributes.name,
        });
      });
    },
    [SET_ERROR](state, message) {
      state.error = message;
    },
  },
  actions: {
    getCampaignTypesList({ commit, state }) {
      if (state.campaignTypesList.length > 0) {
        return true;
      }
      commit(SET_CAMPAIGN_TYPES_LIST, []);
      return Vue.axios
        .get("/api/campaign-types?fields[campaignTypes]=id,name")
        .then(({ data }) => {
          commit(SET_CAMPAIGN_TYPES_LIST, data.data);
          return true;
        })
        .catch((response) => {
          commit(SET_ERROR, response);
          return false;
        });
    },
  },
};
