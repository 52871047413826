import {
  CLEAR_SURVEY,
  SET_ERROR,
  SET_IS_EDITING_SURVEY,
  SET_SURVEY,
  SET_SURVEY_ASSOCIATIONS,
  SET_SURVEYS_COUNT,
  APPEND_SURVEY_PAGES,
  REMOVE_SURVEY_PAGE,
  REMOVE_SURVEY,
  APPEND_SURVEY_PAGE_QUESTIONS,
  REMOVE_SURVEY_PAGE_QUESTION,
  APPEND_SURVEY_PAGE_QUESTION_MAP,
  APPEND_SURVEY_PAGE_QUESTION_TAG_MAP,
  APPEND_SURVEY_PAGE_QUESTION_OPTIONS,
  REMOVE_SURVEY_PAGE_QUESTION_OPTION,
  APPEND_QUESTION_OPTION_MAP,
  REMOVE_SURVEY_PAGE_QUESTION_MAP_PAGE,
  REMOVE_SURVEY_PAGE_QUESTION_MAP_QUESTION,
  SET_SURVEYS,
  SET_SURVEY_LIST,
  SET_SPONSOR_SURVEYS,
  SET_OPTION_ENGAGEMENTS,
} from "@admin/store/mutation-types";

import { getField, updateField } from "vuex-map-fields";
import Vue from "vue";
import camelCase from "lodash.camelcase";

const emptySurvey = function () {
  return {
    id: "",
    globalId: "",
    title: "",
    screenerId: "",
    modified: "",
  };
};

const patchSurvey = function (commit, state, id) {
  commit(SET_ERROR, "");
  commit(SET_IS_EDITING_SURVEY, true);

  const requests = [];
  const surveyData = setSurveyData(state.survey);

  let data = surveyData;

  data.data.relationships = {
    screener: {
      data: {
        type: "screeners",
        id: state.survey.screenerId,
      },
    },
  };
  data.data.id = id;

  // Establish request to save the survey
  requests.push(
    Vue.axios
      .patch("/api/surveys/:id".replace(":id", id), data)
      .then(({ data }) => {
        commit(SET_IS_EDITING_SURVEY, false);
        if (data.errors) {
          commit(SET_ERROR, data.errors);
          return false;
        }
        return id;
      })
      .catch(() => {
        // Indicate false to show that we couldn't save the data, but omit interpreting
        // any errors and attempting to apply them. Data is too complex to worry about for now
        commit(SET_IS_EDITING_SURVEY, false);
        return Promise.resolve(false);
      })
  );

  // Establish requests to save survey pages
  for (const [, surveyPage] of Object.entries(state.surveyPages)) {
    const pageData = setSurveyPageData(surveyPage);

    requests.push(
      Vue.axios
        .patch("/api/pages/:id".replace(":id", surveyPage.id), pageData)
        .catch(() => {
          // Indicate false to show that we couldn't save the data, but omit interpreting
          // any errors and attempting to apply them. Data is too complex to worry about for now
          return Promise.resolve(false);
        })
    );
  }

  // Establish requests to save survey questions
  for (const [, surveyPageQuestion] of Object.entries(
    state.surveyPageQuestions
  )) {
    const questionData = setSurveyPageQuestionData(surveyPageQuestion);

    const relationshipId = questionData.data.id;
    const relationships = {};

    relationships["tags"] = { data: [] };
    if (state.surveyPageQuestionTagMap.has(relationshipId)) {
      state.surveyPageQuestionTagMap
        .get(relationshipId)
        .forEach(function (tagId) {
          relationships["tags"].data.push({
            type: "tags",
            id: tagId,
          });
        });
    }

    questionData.data.relationships = relationships;

    requests.push(
      Vue.axios
        .patch(
          "/api/questions/:id".replace(":id", surveyPageQuestion.id),
          questionData
        )
        .catch(() => {
          // Indicate false to show that we couldn't save the data, but omit interpreting
          // any errors and attempting to apply them. Data is too complex to worry about for now
          return Promise.resolve(false);
        })
    );

    //for each question options lets
    if (surveyPageQuestion.options.length > 0) {
      for (const [, surveyPageQuestionOption] of Object.entries(
        surveyPageQuestion.options
      )) {
        const optionData = setSurveyPageQuestionOptionData(
          surveyPageQuestion,
          surveyPageQuestionOption
        );

        requests.push(
          Vue.axios
            .patch(
              "/api/options/:id".replace(":id", surveyPageQuestionOption.id),
              optionData
            )
            .catch(() => {
              // Indicate false to show that we couldn't save the data, but omit interpreting
              // any errors and attempting to apply them. Data is too complex to worry about for now
              return Promise.resolve(false);
            })
        );
      }
    }
  }

  // NOTE: we don't catch errors on the requests level because we'd only get the error that fails first
  // (if there are many) when in reality we can errors for each datum we are trying to save.
  // We need to catch on every individual request to handle this properly
  return Promise.all(requests).then((responses) => {
    let success = true;

    for (const response of responses) {
      switch (typeof response) {
        case "object":
          if (response.status !== 200) {
            success = false;

            // Log whatever error occurred for the first response that was found to fail.
            // We can only handle these errors one at a time
            commit(
              SET_ERROR,
              Object.prototype.hasOwnProperty.call(response.data, "message") &&
                response.data.message.length > 0
                ? response.data.message
                : "Could not save data"
            );
          }
          break;
        case "boolean":
          if (!response) {
            success = false;
            commit(SET_ERROR, "Could not save data");
          }
          break;
      }

      if (!success) {
        break;
      }
    }

    commit(SET_IS_EDITING_SURVEY, false);

    return Promise.resolve([success]);
  });
};

const extractSurveyData = (survey) => {
  const surveyData = {
    id: survey.id,
    globalId: survey.attributes.globalId,
    title: survey.attributes.title,
    screenerId: survey.attributes.screenerId ?? null,
    sponsorName: survey.attributes.sponsorName ?? null,
    isActive: survey.attributes.isActive ?? null,
  };

  return surveyData;
};

const setSurveyPageQuestionOptionData = (question, option) => {
  return {
    data: {
      type: "options",
      id: option.id,
      attributes: {
        question_id: question.id,
        label: option.label,
        value: option.value,
        is_disqualified: option.isDisqualified,
        transition_to: option.transitionTo,
        transition_target: option.transitionTarget,
        deleted: option.deleted,
        created: option.created,
        modified: option.modified,
      },
    },
  };
};

const setSurveyData = function (survey) {
  return {
    data: {
      type: "surveys",
      id: survey.id,
      attributes: {
        title: survey.title,
        globalId: survey.globalId,
      },
    },
  };
};

const setSurveyPageData = (surveyPage) => {
  return {
    data: {
      type: "pages",
      id: surveyPage.id,
      attributes: {
        display_order: surveyPage.displayOrder,
        title: surveyPage.title,
        is_active: surveyPage.isActive,
        is_deleted: surveyPage.isDeleted,
      },
    },
  };
};

const setSurveyPageQuestionData = (surveyPageQuestion) => {
  return {
    data: {
      type: "questions",
      id: surveyPageQuestion.id,
      attributes: {
        question_category_id: surveyPageQuestion.questionCategoryId,
        type: surveyPageQuestion.type,
        title: surveyPageQuestion.title,
        helper_text: surveyPageQuestion.helperText,
        deleted: surveyPageQuestion.deleted,
        created: surveyPageQuestion.created,
        modified: surveyPageQuestion.modified,
      },
    },
  };
};

export default {
  namespaced: true,
  state: {
    // Provide an indication of if the survey is being edited in the context of the API. Editing a survey is a complex process where all loaded questions
    // and pages are saved. We want to be able to indicate to all areas that allow for editing these items that saving is happening
    // to allow for those areas to prevent edits during the save. Additionally, when new parts of the survey are being created (e.g.
    // adding pages or questions), we want to prevent the editing of the survey since we want all data to load locally so this application can provide
    // validation rules for that new data before allowing a final save to the server
    isEditingSurvey: false,
    survey: emptySurvey(),
    surveyPages: {},
    surveyPageQuestions: {},
    surveyPageQuestionMap: new Map(),
    surveyPageQuestionTagMap: new Map(),
    questionOptionMap: new Map(),
    surveys: [],
    surveyList: [],
    sponsorSurveys: [],
    sponsorSurveyPages: [],
    uniqueSponsorSurveys: [],
    included: [],
    error: "",
    surveyPageCounter: 1,
    surveysCount: 0,
    screenerSurveys: [],
    questionTypes: [],
    optionEngagements: [],
  },
  getters: {
    getField,
    isEditingSurvey(state) {
      return state.isEditingSurvey;
    },
    sortedSurveys(state) {
      return state.surveys.sort((a, b) => a.name.localeCompare(b.name));
    },
    getQuestionTypesList(state) {
      return state.questionTypes;
    },
    // Identify the ids for all pages belonging to a survey in their
    // given display order. Add all pages that don't have a display order to
    // the end of the set in whatever order they are encountered
    surveyPageIds(state) {
      const orderIndexedIds = {};
      const unorderedIds = [];

      for (const [id, page] of Object.entries(state.surveyPages)) {
        if (Number.isInteger(page.displayOrder)) {
          orderIndexedIds[page.displayOrder] = id;
        } else {
          // Include when the unordered item was created so that we can still sort
          // all unordered items by when they were created and give a consistent expectation
          // to the user
          unorderedIds.push({ id, created: page.created });
        }
      }

      return Object.values(orderIndexedIds).concat(
        unorderedIds
          .sort((a, b) => {
            // Sort by created date ascending
            if (a.created < b.created) {
              return -1;
            }

            return 1;
          })
          // Get the actual ids of the unordered items
          .map((page) => page.id)
      );
    },
    // Identify a list of survey pages based on the structure of [id => page title]
    surveyPagesList(state) {
      const pages = [];

      for (const [id, page] of Object.entries(state.surveyPages)) {
        pages.push({ value: id, text: page.title });
      }

      return pages.sort((a, b) => {
        // Sort by text i.e. order by the title of a page
        if (a.text < b.text) {
          return -1;
        }

        return 1;
      });
    },

    // Identify a survey page based on the given id
    surveyPage: (state) => (id) => {
      return state.surveyPages[id] ?? null;
    },
    // For a page, identify all question Ids that are associated to that id
    surveyPageQuestionIdsPerPage: (state) => (pageId) => {
      if (state.surveyPageQuestionMap.has(pageId)) {
        return state.surveyPageQuestionMap.get(pageId);
      }

      return [];
    },
    tagsPerQuestion: (state, getters, rootState) => (questionId) => {
      const tags = [];
      if (state.surveyPageQuestionTagMap.has(questionId)) {
        const tagIds = state.surveyPageQuestionTagMap.get(questionId);
        tagIds.forEach((tagId) => {
          const record = rootState.tags.tagsList[tagId];
          if (record) {
            tags.push({
              text: record.name,
              value: record.id,
            });
          }
        });
      }
      return tags;
    },
    surveyPageQuestionsPerPage: (state) => (pageId) => {
      const questions = [];

      if (state.surveyPageQuestionMap.has(pageId)) {
        for (const questionId of state.surveyPageQuestionMap.get(pageId)) {
          if (state.surveyPageQuestions[questionId]) {
            questions.push({
              ...state.surveyPageQuestions[questionId],
            });
          }
        }
      }

      return questions;
    },
    // Get a survey question based on the given id
    surveyPageQuestion: (state) => (id) => {
      return state.surveyPageQuestions[id] ?? null;
    },
  },
  mutations: {
    updateField,
    [CLEAR_SURVEY](state) {
      state.survey = emptySurvey();
      // Clear out data that is related to the survey that we additionally
      // store to represent its structure
      state.surveyPages = {};
      state.surveyPageQuestions = {};
      state.surveyPageQuestionMap = new Map();
      state.surveyPageQuestionTagMap = new Map();
    },
    [SET_IS_EDITING_SURVEY](state, isEditingSurvey) {
      state.isEditingSurvey = isEditingSurvey;
    },
    [SET_SURVEY](state, survey) {
      const surveyData = extractSurveyData(survey);
      state.survey = surveyData;
    },
    [SET_SURVEYS_COUNT](state, meta) {
      state.surveysCount = meta.record_count;
    },
    [SET_SURVEY_ASSOCIATIONS](state, screenerSurveys) {
      if (state.surveys.length > 0) {
        state.surveys.forEach((survey) => {
          survey.associated = false;
          if (screenerSurveys.length > 0) {
              screenerSurveys.forEach((screenerSurvey) => {
                if (
                    survey.id === screenerSurvey.globalId
                ) {
                  survey.associated = true;
                  survey.clonedId = screenerSurvey.surveyId;
                  if (screenerSurvey.title !== '') {
                    survey.title = screenerSurvey.title;
                  }
                }
              });
          }
        });
      }
    },
    [APPEND_SURVEY_PAGES](state, surveyPages) {
      const newSurveyPages = {};

      for (const surveyPage of surveyPages) {
        newSurveyPages[surveyPage.id] = {
          id: surveyPage.id,
          ...surveyPage.attributes,
        };
      }
      state.surveyPages = Object.assign({}, state.surveyPages, newSurveyPages);
      state.surveyPageCounter = Object.keys(state.surveyPages).length;
    },
    [REMOVE_SURVEY_PAGE](state, id) {
      if (Object.hasOwn(state.surveyPages, id)) {
        Vue.delete(state.surveyPages, id);
      }
    },
    [REMOVE_SURVEY](state, id) {
      const index = state.surveys.findIndex((survey) => survey.id === id);
      if (index !== -1) {
        Vue.delete(state.surveys, index);
      }
    },
    [APPEND_SURVEY_PAGE_QUESTIONS](state, surveyPageQuestions) {
      const newSurveyPageQuestions = {};

      for (const surveyPageQuestion of surveyPageQuestions) {
        if (surveyPageQuestion.options === undefined) {
          surveyPageQuestion.options = [];
        }

        newSurveyPageQuestions[surveyPageQuestion.id] = {
          id: surveyPageQuestion.id,
          ...surveyPageQuestion.attributes,
          options: surveyPageQuestion.options,
        };

        if (
          surveyPageQuestion.relationships &&
          surveyPageQuestion.relationships.questionCategory
        ) {
          newSurveyPageQuestions[surveyPageQuestion.id].questionCategoryId =
            surveyPageQuestion.relationships.questionCategory.data.id;
        }

        if (
          surveyPageQuestion.relationships &&
          surveyPageQuestion.relationships.page
        ) {
          newSurveyPageQuestions[surveyPageQuestion.id].pageId =
            surveyPageQuestion.relationships.page.data.id;
        }
      }

      state.surveyPageQuestions = Object.assign(
        {},
        state.surveyPageQuestions,
        newSurveyPageQuestions
      );
    },
    [APPEND_SURVEY_PAGE_QUESTION_OPTIONS](state, questionOptions) {
      let newQuestionOption = {};

      for (const questionOption of questionOptions) {
        newQuestionOption = {
          id: questionOption.id,
          ...questionOption.attributes,
        };

        if (
          questionOption.relationships &&
          questionOption.relationships.question !== undefined
        ) {
          newQuestionOption.questionId =
            questionOption.relationships.question.data.id;
        }

        if (
          state.surveyPageQuestions[newQuestionOption.questionId] !== undefined
        ) {
          newQuestionOption.pageId =
            state.surveyPageQuestions[newQuestionOption.questionId].pageId;

          if (
            state.surveyPageQuestions[newQuestionOption.questionId].options ===
            undefined
          ) {
            state.surveyPageQuestions[newQuestionOption.questionId].options =
              [];
          }
          state.surveyPageQuestions[newQuestionOption.questionId].options.push(
            newQuestionOption
          );
        }
      }
    },
    [REMOVE_SURVEY_PAGE_QUESTION_OPTION](state, data) {
      if (Object.hasOwn(state.surveyPageQuestions, data.questionId)) {
        let newOptions = state.surveyPageQuestions[
          data.questionId
        ].options.filter(function (option) {
          return option.id !== data.optionId;
        });

        state.surveyPageQuestions[data.questionId].options = newOptions;
      }
    },
    // Allow for removing a question from the current set of survey questions
    [REMOVE_SURVEY_PAGE_QUESTION](state, questionId) {
      if (Object.hasOwn(state.surveyPageQuestions, questionId)) {
        Vue.delete(state.surveyPageQuestions, questionId);
      }
    },
    [APPEND_SURVEY_PAGE_QUESTION_MAP](state, surveyPageQuestionMap) {
      surveyPageQuestionMap.forEach((value, key) => {
        if (state.surveyPageQuestionMap.has(key)) {
          const mergedMap = new Set([
            ...state.surveyPageQuestionMap.get(key),
            ...value,
          ]);

          state.surveyPageQuestionMap.set(key, [...mergedMap]);
        } else {
          state.surveyPageQuestionMap.set(key, [...value]);
        }
      });
    },
    [APPEND_QUESTION_OPTION_MAP](state, questionOptionMap) {
      questionOptionMap.forEach((value, key) => {
        if (state.questionOptionMap.has(key)) {
          const mergedMap = new Set([
            ...state.questionOptionMap.get(key),
            ...value,
          ]);

          state.questionOptionMap.set(key, [...mergedMap]);
        } else {
          state.questionOptionMap.set(key, [...value]);
        }
      });
    },

    [APPEND_SURVEY_PAGE_QUESTION_TAG_MAP](state, surveyPageQuestionTagMap) {
      surveyPageQuestionTagMap.forEach((value, key) => {
        state.surveyPageQuestionTagMap.set(key, [...value]);
      });
    },

    // Allow for removing a survey pahe from the survey page to survey question map
    [REMOVE_SURVEY_PAGE_QUESTION_MAP_PAGE](state, pageId) {
      if (state.surveyPageQuestionMap.has(pageId)) {
        // Since page ids are the key values of the map, we can just delete by key
        state.surveyPageQuestionMap.delete(pageId);
      }
    },
    /*
    Allow for removing a survey question from the survey page to survey question
    association map.

    IMPORTANT: The nature of the API doesn't provide survey questions with the notion
    of which survey page id they belong to. As a result, we have to iterate
    all survey page ids and ensure the questionId provided is no longer in
    the question ID set for that survey page
    */
    [REMOVE_SURVEY_PAGE_QUESTION_MAP_QUESTION](state, questionId) {
      const mapIterator = state.surveyPageQuestionMap.entries();

      for (const [id, optionSet] of mapIterator) {
        if (optionSet.includes(questionId)) {
          state.surveyPageQuestionMap.set(
            id,
            // Remove the questionId from the set of questionIds currently set for the page
            optionSet.filter((id) => id !== questionId)
          );
        }
      }
    },
    [SET_SURVEYS](state, surveys) {
      const surveyData = [];

      for (const survey of surveys) {
        surveyData.push(extractSurveyData(survey));
      }

      state.surveys = surveyData;
    },
    [SET_SURVEY_LIST](state, surveys) {
      const surveyList = [];
      for (const survey of surveys) {
        surveyList.push({
          text: survey.attributes.title,
          value: survey.id,
        });
      }

      state.surveyList = surveyList;
    },
    [SET_SPONSOR_SURVEYS](state, surveys) {
      const surveyData = surveys;
      let sponsorSurveyPages = [];
      let uniqueSponsorSurveys = [];

      //lets set unique sponsor surveys which does not include DOB,EXIT, or the current state survey
      Object.keys(surveyData).forEach((key) => {
        surveyData[key].pagesList = [];
        Object.keys(surveyData[key].pages).forEach((pageKey) => {
          sponsorSurveyPages[pageKey] = surveyData[key].pages[pageKey];
          surveyData[key].pagesList.push(surveyData[key].pages[pageKey]);
        });

        //we dont want the user to transition to DOB survey or to this same survey
        if (surveyData[key].title !== "DOB" && key !== state.survey.id) {
          uniqueSponsorSurveys.push(surveyData[key]);
        }
      });

      //now add current survey's pages as it might not be included above (ex: DOB,END, etc)
      Object.keys(state.surveyPages).forEach((key) => {
        sponsorSurveyPages[key] = state.surveyPages[key];
      });
      state.sponsorSurveyPages = Object.assign(
        {},
        state.sponsorSurveyPages,
        sponsorSurveyPages
      );

      state.uniqueSponsorSurveys = uniqueSponsorSurveys;

      state.sponsorSurveys = Object.assign(
        {},
        state.sponsorSurveys,
        surveyData
      );
    },
    [SET_OPTION_ENGAGEMENTS](state, data) {
      const optionEngagementsData = data.optionEngagements;

      let newSponsorEngagements = [];
      if (data.sponsorEngagements.length > 0) {
        data.sponsorEngagements.forEach((sponsorEngagement) => {
          let newSponsorEngagement = {
            id: sponsorEngagement.id,
            name: sponsorEngagement.attributes.name,
            engagementOptionId: null,
            selected: false,
            weight: null,
          };

          Object.keys(optionEngagementsData).forEach((key) => {
            if (
              optionEngagementsData[key].relationships.engagement.data.id ===
              newSponsorEngagement.id
            ) {
              newSponsorEngagement.selected = true;
              newSponsorEngagement.engagementOptionId =
                optionEngagementsData[key].id;
              newSponsorEngagement.weight =
                optionEngagementsData[key].attributes.weight;
            }
          });

          newSponsorEngagements.push(newSponsorEngagement);
        });
      }

      state.optionEngagements = Object.assign(
        {},
        state.optionEngagements,
        newSponsorEngagements
      );
    },
    [SET_ERROR](state, message) {
      state.error = message;
    },
  },
  actions: {
    addSurvey({ commit, state }, { screenerId = null }) {
      commit(SET_ERROR, "");

      return Vue.axios
        .post("/api/surveys", {
          data: {
            type: "surveys",
            attributes: {
              title: state.survey.title,
              screenerId: screenerId,
            },
          },
        })
        .then(({ data }) => {
          if (data.data.id) {
            data.data.screenerId = screenerId;
            commit(SET_SURVEY, data.data);
          } else if (data.errors) {
            commit(SET_ERROR, data.errors);
          }

          return Promise.resolve([true]);
        })
        .catch((e) => {
          const data = e.response.data;
          if (data.errors) {
            commit(SET_ERROR, data.errors[0].title);
          }

          return Promise.resolve([false]);
        });
    },
    editSurvey({ commit, state }, id) {
      return patchSurvey(commit, state, id);
    },
    toggleSurveyActive({ commit, state }) {
      commit(SET_ERROR, "");

      if (!state.survey.id) {
        return Promise.resolve([false]);
      }

      return Vue.axios
        .patch(
          "/api/screeners/surveys/:id".replace(":id", state.survey.id),
          {
            data: {
              type: "surveys",
              id: state.survey.id,
              attributes: {
                toggle_active: !state.screenersurvey.isActive,
              },
            },
          }
        )
        .then((response) => {
          const success = response.status === 200;

          if (success) {
            // Directly override the isActive property for a survey when toggling
            // it. We do this since we only changed this attribute specifically in the
            // patch, and we don't want to override other survey data (especially parsed
            // related data) because of this toggle
            state.survey.isActive = response.data.data.attributes.isActive;
          }

          return Promise.resolve([success]);
        })
        .catch(() => {
          return Promise.resolve([false]);
        });
    },
    clearSurvey({ commit }) {
      commit(CLEAR_SURVEY);
    },
    clearSurveys({ commit }) {
      commit(SET_SURVEYS, []);
    },
    clearSurveysList({ commit }) {
      commit(SET_SURVEY_LIST, []);
    },
    deleteSurvey({ commit }, id) {
      return Vue.axios
        .delete("/api/surveys/:id".replace(":id", id))
        .then((response) => {
          // A delete request will return a 204 on success from the API
          const success = response.status === 204;

          if (success) {
            commit(REMOVE_SURVEY, id);
          }

          return Promise.resolve([success]);
        });
    },
    getSurvey({ commit, dispatch }, id) {
      dispatch("clearSurvey");
      commit(SET_ERROR, "");
      return Vue.axios
        .get("/api/surveys/:id".replace(":id", id), {
          params: {
            include:
              "pages.questions.tags,pages.questions.options,screener.sponsor",
          },
        })
        .then((response) => {
          const success = response.status === 200;
          if (success) {
            if (response.data.included && response.data.included.length > 0) {
              const pages = [];
              const questions = [];
              const options = [];
              const pageQuestionAssociationMap = new Map();
              const questionTagAssociationMap = new Map();
              const questionOptionAssociationMap = new Map();

              for (const item of response.data.included) {
                switch (item.type) {
                  case "screeners":
                    response.data.data.attributes.screenerId = item.id;
                    break;
                  case "pages":
                    item.attributes.survey_id = id;
                    pages.push(item);

                    if (item.relationships.questions !== undefined) {
                      const questionRelationships = [];
                      for (const question of item.relationships.questions
                        .data) {
                        questionRelationships.push(question.id);
                      }

                      if (questionRelationships.length > 0) {
                        pageQuestionAssociationMap.set(
                          item.id,
                          questionRelationships
                        );
                      }
                    }

                    break;
                  case "questions":
                    if (item.options === undefined) {
                      item.options = [];
                    }
                    questions.push(item);

                    if (
                      Object.prototype.hasOwnProperty.call(
                        item.relationships,
                        "tags"
                      )
                    ) {
                      const tagRelationships = [];
                      for (const tag of item.relationships.tags.data) {
                        tagRelationships.push(tag.id);
                      }

                      if (tagRelationships.length > 0) {
                        questionTagAssociationMap.set(
                          item.id,
                          tagRelationships
                        );
                      }
                    }

                    break;
                  case "options":
                    options.push(item);
                    break;
                  case "sponsors":
                    response.data.data.attributes.sponsorName =
                      item.attributes.name;
                    break;
                }
              }

              commit(APPEND_SURVEY_PAGES, pages);
              commit(
                APPEND_SURVEY_PAGE_QUESTION_TAG_MAP,
                questionTagAssociationMap
              );
              commit(APPEND_SURVEY_PAGE_QUESTIONS, questions);
              commit(APPEND_SURVEY_PAGE_QUESTION_OPTIONS, options);
              commit(
                APPEND_SURVEY_PAGE_QUESTION_MAP,
                pageQuestionAssociationMap
              );
              commit(APPEND_QUESTION_OPTION_MAP, questionOptionAssociationMap);
            }
            commit(SET_SURVEY, response.data.data);
          } else if (response.data.errors) {
            commit(SET_ERROR, response.data.errors);
          }
          return Promise.resolve([true]);
        })
        .catch((error) => {
          commit(SET_ERROR, error);
          return Promise.resolve([false]);
        });
    },
    getSurveysRequest(context, { params = {} } = {}) {
      return Vue.axios.get("/api/surveys", {
        params,
      });
    },
    getSurveys(
      { commit, dispatch },
      { sortBy = [], page = 1, limit = 20 } = {}
    ) {
      commit(SET_ERROR, "");
      commit(SET_SURVEYS, []);
      const params = {
        sort: sortBy.join(","),
        page,
        limit,
      };
      return dispatch("getSurveysRequest", { params })
        .then(({ data }) => {
          commit(SET_SURVEYS_COUNT, data.meta);
          commit(SET_SURVEYS, data.data);
          return true;
        })
        .catch((response) => {
          commit(SET_ERROR, response);
          return false;
        });
    },
    getSponsorSurveys({ commit }, { sponsorId }) {
      commit(SET_SPONSOR_SURVEYS, []);
      let params = {
        sponsorId,
        include: "screenerSurveys.surveys.pages.questions.options",
      };
      return Vue.axios
        .get("/api/screeners/", { params })
        .then((response) => {
          const success = response.status === 200;
          //if success is true, what we want to do is create an array of all sponsor surveys and set their pages->questions->options data
          if (success) {
            let surveys = [];
            let surveyId;
            let pageId;
            let questionId;
            if (response.data.included && response.data.included.length > 0) {
              for (const item of response.data.included) {
                switch (item.type) {
                  case "surveys":
                    var survey = item.attributes;
                    survey.id = item.id;
                    surveyId = survey.id;
                    surveys[item.id] = survey;
                    surveys[item.id]["pages"] = [];
                    surveys[item.id]["pagesList"] = [];

                    break;
                  case "pages":
                    var page = item.attributes;
                    page.id = item.id;
                    page.survey_id = surveyId;

                    surveys[surveyId]["pages"][item.id] = page;

                    surveys[surveyId]["pagesList"].push(page);

                    surveys[surveyId]["pages"][item.id]["questions"] = [];
                    surveys[surveyId]["pages"][item.id]["questionsList"] = [];
                    if (item.relationships.questions !== undefined) {
                      if (item.relationships.questions.data.length > 0) {
                        for (const question of item.relationships.questions
                          .data) {
                          surveys[surveyId]["pages"][item.id]["questions"][
                            question.id
                          ] = null;
                        }
                      }
                    }

                    break;
                  case "questions":
                    if (item.relationships.page !== undefined) {
                      pageId = item.relationships.page.data.id;

                      let question = item.attributes;
                      question.id = item.id;

                      surveys[surveyId]["pages"][pageId]["questionsList"].push(
                        question
                      );
                      surveys[surveyId]["pages"][pageId]["questions"][item.id] =
                        question;
                      surveys[surveyId]["pages"][pageId]["questions"][item.id][
                        "options"
                      ] = [];
                    }
                    break;
                  case "options":
                    if (item.relationships.question !== undefined) {
                      questionId = item.relationships.question.data.id;

                      let option = item.attributes;
                      option.id = item.id;

                      surveys[surveyId]["pages"][pageId]["questions"][
                        questionId
                      ]["options"].push(option);
                    }

                    break;
                }
              }

              commit(SET_SPONSOR_SURVEYS, surveys);
            }
          } else if (response.data.errors) {
            commit(SET_ERROR, response.data.errors);
          }

          return Promise.resolve([true]);
        })
        .catch(() => {
          return Promise.resolve([false]);
        });
    },
    updateTagMap({ commit }, { questionId, tags }) {
      const fieldTagAssociationMap = new Map();
      fieldTagAssociationMap.set(questionId, tags);
      commit(APPEND_SURVEY_PAGE_QUESTION_TAG_MAP, fieldTagAssociationMap);
    },
    getSurveyListForScreener({ commit }, screenerId) {
      commit(SET_ERROR, "");
      commit(SET_SURVEY_LIST, []);
      return Vue.axios
        .get("/api/surveys", {
          params: {
            screenerId: screenerId,
            approvedOnly: 1,
          },
        })
        .then(({ data }) => {
          commit(SET_SURVEY_LIST, data.data);
          return true;
        })
        .catch((response) => {
          commit(SET_ERROR, response);
          return false;
        });
    },
    getOptionEngagements({ commit, rootGetters }, option_id) {
      commit(SET_ERROR, "");
      commit(SET_OPTION_ENGAGEMENTS, {
        sponsorEngagements: [],
        optionEngagements: [],
      });

      let params = {
        option_id,
        include: "engagements",
      };
      return Vue.axios
        .get("/api/engagements-options", { params })
        .then(({ data }) => {
          commit(SET_OPTION_ENGAGEMENTS, {
            sponsorEngagements: rootGetters["sponsors/sponsorEngagements"],
            optionEngagements: data.data,
          });
          return true;
        })
        .catch((response) => {
          commit(SET_ERROR, response);
          return false;
        });
    },
    updateOptionEngagements({ commit }, { optionId, optionEngagements }) {
      commit(SET_ERROR, "");
      commit(SET_IS_EDITING_SURVEY, true);

      return Vue.axios
        .post(
          "/api/update-engagements-options/:optionId".replace(
            ":optionId",
            optionId
          ),
          {
            engagements: optionEngagements,
          }
        )
        .then(async () => {
          //let's refetch the option engagements' values
          await this.getOptionEngagements(optionId);
        })
        .catch(() => {
          commit(SET_ERROR, "Could not update option enagagements");
          commit(SET_IS_EDITING_SURVEY, false);
          return Promise.resolve([false]);
        });
    },
    addPage({ commit, state }) {
      commit(SET_ERROR, "");
      commit(SET_IS_EDITING_SURVEY, true);
      let displayOrder = state.surveyPages.length + 1;
      const pagesPayload = {
        data: {
          type: "pages",
          attributes: {
            survey_id: state.survey.id,
            is_active: true,
            display_order: displayOrder,
            title: "New Page " + (state.surveyPageCounter + 1),
          },
        },
      };

      return Vue.axios
        .post("/api/pages", pagesPayload)
        .then((response) => {
          // The request is successful if an entity is added
          const success = response.status === 201;

          if (success) {
            commit(APPEND_SURVEY_PAGES, [response.data.data]);

            commit(SET_IS_EDITING_SURVEY, false);
            return Promise.resolve([success]);
          } else {
            commit(
              SET_ERROR,
              Object.prototype.hasOwnProperty.call(response.data, "message") &&
                response.data.message.length > 0
                ? response.data.message
                : "Could not save data"
            );
            commit(SET_IS_EDITING_SURVEY, false);
            return Promise.resolve([false]);
          }
        })
        .catch(() => {
          commit(SET_ERROR, "Could not add Page");
          commit(SET_IS_EDITING_SURVEY, false);
          return Promise.resolve([false]);
        });
    },
    deletePage({ state, commit, getters }, id) {
      if (Object.hasOwn(state.surveyPages, id)) {
        commit(SET_IS_EDITING_SURVEY, true);
        // Delete a survey page based on its ID
        return Vue.axios
          .delete("/api/pages/:id".replace(":id", state.surveyPages[id].id))
          .then((response) => {
            // A delete request will return a 204 on success from the API
            const success = response.status === 204;

            if (success) {
              // Before deleting the association of page ids to survey question ids, get
              // all IDs for the questions that were mapped to that page. We want to remove these
              // questions too
              const surveyPageQuestionIds =
                getters.surveyPageQuestionIdsPerPage(id);

              commit(REMOVE_SURVEY_PAGE_QUESTION_MAP_PAGE, id);
              commit(REMOVE_SURVEY_PAGE, id);
              for (const id of surveyPageQuestionIds) {
                commit(REMOVE_SURVEY_PAGE_QUESTION, id);
              }
              state.surveyPageCounter--;
            }

            commit(SET_IS_EDITING_SURVEY, false);
            return Promise.resolve([success]);
          });
      }

      return Promise.resolve([false]);
    },
    changePage({ state, commit }, { id, data }) {
      if (Object.hasOwn(state.surveyPages, id)) {
        const newData = {
          id,
          attributes: { ...state.surveyPages[id] },
        };

        if (Object.prototype.hasOwnProperty.call(data, "title")) {
          newData.attributes.title = data.title;
        }
        commit(APPEND_SURVEY_PAGES, [newData]);
      }
    },
    /*
    Create a new page question for the given page for the survey
    */
    async addPageQuestion({ commit }, pageId) {
      commit(SET_ERROR, "");
      commit(SET_IS_EDITING_SURVEY, true);
      const surveyPageId = pageId;
      const payload = {
        data: {
          type: "questions",
          attributes: {
            question_category_id: null,
            page_id: surveyPageId,
            is_active: true,
            title: "Placeholder",
            helper_text: null,
          },
        },
      };

      return Vue.axios
        .post("/api/questions", payload)
        .then((response) => {
          // The request is successful if an entity is added
          const success = response.status === 201;

          if (success) {
            commit(APPEND_SURVEY_PAGE_QUESTIONS, [response.data.data]);
            // Track which page the new question was added to
            commit(
              APPEND_SURVEY_PAGE_QUESTION_MAP,
              new Map([[surveyPageId, [response.data.data.id]]])
            );
          } else {
            commit(
              SET_ERROR,
              Object.prototype.hasOwnProperty.call(response.data, "message") &&
                response.data.message.length > 0
                ? response.data.message
                : "Could not save data"
            );
          }

          commit(SET_IS_EDITING_SURVEY, false);
          return Promise.resolve([success]);
        })
        .catch(() => {
          commit(SET_ERROR, "Could not add Question");
          commit(SET_IS_EDITING_SURVEY, false);
          return Promise.resolve([false]);
        });
    },
    /*
    Delete the page question with the given ID. This requires deleting it via the API and, if successful,
    removing it from local scope since it is no longer a valid question
    */
    deletePageQuestion({ commit }, id) {
      commit(SET_IS_EDITING_SURVEY, true);
      return Vue.axios
        .delete("/api/questions/:id".replace(":id", id))
        .then((response) => {
          // A delete request will return a 204 on success from the API
          const success = response.status === 204;

          if (success) {
            commit(REMOVE_SURVEY_PAGE_QUESTION_MAP_QUESTION, id);
            commit(REMOVE_SURVEY_PAGE_QUESTION, id);
          }

          commit(SET_IS_EDITING_SURVEY, false);
          return Promise.resolve([success]);
        });
    },
    /*
    Change the local representation of a page question with whatever new data is
    desired.

    This allows for us to change information about one to many questions locally
    before saving changes to these questions when editing a survey
    */
    changePageQuestion({ state, commit, dispatch }, { id, data }) {
      if (state.surveyPageQuestions[id]) {
        const newData = {
          id,
          attributes: { ...state.surveyPageQuestions[id] },
          options: state.surveyPageQuestions[id].options,
        };

        if (Object.prototype.hasOwnProperty.call(data, "type")) {
          newData.attributes.type = data.type;
        }

        if (Object.prototype.hasOwnProperty.call(data, "questionCategoryId")) {
          newData.attributes.questionCategoryId = data.questionCategoryId;
        }

        if (Object.prototype.hasOwnProperty.call(data, "tags")) {
          newData.attributes.tags = data;
        }

        if (Object.prototype.hasOwnProperty.call(data, "title")) {
          newData.attributes.title = data.title;
        }

        if (Object.prototype.hasOwnProperty.call(data, "helperText")) {
          newData.attributes.helperText = data.helperText;
        }

        // If the question type is changed to multiple, we need to add a default option
        if (state.surveyPageQuestions[id].type === 'multiple' && newData.options.length === 0) {
          // Add a default option if none exist
          dispatch('addQuestionOption', { questionId: newData.id, label: 'None of the Above' });
        }

        commit(APPEND_SURVEY_PAGE_QUESTIONS, [newData]);
      }
    },
    /*
    Set page order for pages on the current survey
     */
    changePageOrder({ state, commit }, orderedPageIds) {
      const newData = [];

      orderedPageIds.forEach((id, index) => {
        if (state.surveyPages[id]) {
          const page = {
            id: id,
            attributes: { ...state.surveyPages[id] },
          };

          page.attributes.displayOrder = index;

          newData.push(page);
        }
      });

      commit(APPEND_SURVEY_PAGES, newData);
    },
    /*
    Create a new question option for the given question for the survey
    */
    addQuestionOption({ commit }, questionIdOrObject) {
      // default value for label and value
      let questionId;
      let label = null;
      let value = null;

      // If the questionIdOrObject is an object, we can assume it is a question object
      if (typeof questionIdOrObject === 'object') {
        // Set the questionId to the questionId of the object
        questionId = questionIdOrObject.questionId;
        label = questionIdOrObject.label;
        // Set the value to the camelCase version of the label
        value = camelCase(questionIdOrObject.label);
      } else {
        // Otherwise, we can assume it is a questionId
        questionId = questionIdOrObject;
      }

      commit(SET_ERROR, "");
      commit(SET_IS_EDITING_SURVEY, true);
      const payload = {
        data: {
          type: "options",
          attributes: {
            question_id: questionId,
            is_active: true,
            label: label,
            value: value,
            is_disqualified: 0,
            transition_to: null,
            transition_target: null,
          },
        },
      };

      return Vue.axios
        .post("/api/options", payload)
        .then((response) => {
          // The request is successful if an entity is added
          const success = response.status === 201;

          if (success) {
            response.data.data.attributes.isNew = true;
            commit(APPEND_SURVEY_PAGE_QUESTION_OPTIONS, [response.data.data]);
          } else {
            commit(
              SET_ERROR,
              Object.prototype.hasOwnProperty.call(response.data, "message") &&
                response.data.message.length > 0
                ? response.data.message
                : "Could not save data"
            );
          }

          commit(SET_IS_EDITING_SURVEY, false);
          return Promise.resolve([success]);
        })
        .catch(() => {
          commit(SET_ERROR, "Could not add Option");
          commit(SET_IS_EDITING_SURVEY, false);
          return Promise.resolve([false]);
        });
    },
    /*
    Delete the question option with the given ID. This requires deleting it via the API and, if successful,
    removing it from local scope since it is no longer a valid option
    */
    deleteQuestionOption({ commit }, { questionId, optionId }) {
      commit(SET_IS_EDITING_SURVEY, true);
      return Vue.axios
        .delete("/api/options/:id".replace(":id", optionId))
        .then((response) => {
          // A delete request will return a 204 on success from the API
          const success = response.status === 204;

          if (success) {
            commit(REMOVE_SURVEY_PAGE_QUESTION_OPTION, {
              questionId,
              optionId,
            });
          }

          commit(SET_IS_EDITING_SURVEY, false);
          return Promise.resolve([success]);
        });
    },
    setAssociations({ commit }, screenerSurveys) {
      commit(SET_SURVEY_ASSOCIATIONS, screenerSurveys);
    },
  },
};
