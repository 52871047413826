import {
  SET_ERROR,
  SET_THERAPEUTIC_AREAS_LIST,
} from "@admin/store/mutation-types";
import Vue from "vue";

export default {
  namespaced: true,
  state: {
    therapeuticAreasList: [],
    error: "",
  },
  mutations: {
    [SET_THERAPEUTIC_AREAS_LIST](state, therapeuticAreas) {
      // This is used for select elements in forms.
      therapeuticAreas.forEach(function (therapeuticArea) {
        state.therapeuticAreasList.push({
          value: therapeuticArea.id,
          text: therapeuticArea.attributes.name,
        });
      });
    },
    [SET_ERROR](state, message) {
      state.error = message;
    },
  },
  actions: {
    getTherapeuticAreasList({ commit, state }) {
      if (state.therapeuticAreasList.length > 0) {
        return true;
      }
      commit(SET_THERAPEUTIC_AREAS_LIST, []);
      return Vue.axios
        .get("/api/therapeutic-areas", {
          params: {
            sort: "name",
            "fields[therapeuticAreas]": "id,name",
          },
        })
        .then(({ data }) => {
          commit(SET_THERAPEUTIC_AREAS_LIST, data.data);
          return true;
        })
        .catch((response) => {
          commit(SET_ERROR, response);
          return false;
        });
    },
  },
};
