<template>
  <v-btn :to="attributes.to">
    <slot>{{ text }}</slot>
    <v-icon aria-label="Add Icon" class="ml-1">mdi-plus</v-icon>
  </v-btn>
</template>

<script>
export default {
  name: "AddButton",
  props: {
    /**
     * Attributes for the button
     * to: {
     *   type: String,
     *   required: true,
     *}
     */
    attributes: {},
    text: {
      type: String,
      default: "Add",
    },
  },
};
</script>

<style scoped>
.v-btn {
  background-color: #00afaa !important;
}
</style>
