<template>
  <v-text-field
    class="mt-3 mr-12"
    label="Search"
    v-model="search"
    append-outer-icon="mdi-magnify"
    @click:append-outer="searchClicked"
    @click:clear="searchClicked"
    clearable
    full-width
    dense
  ></v-text-field>
</template>

<script>
export default {
  name: "SearchBar",
  data: () => ({
    search: "",
  }),
  methods: {
    searchClicked() {
      this.attributes.searchCallback(this.search);
    },
  },
  props: {
    /**
     * Attributes for the button
     * searchCallback: {
     *   type: function,
     *   required: true,
     *}
     */
    attributes: {},
  },
};
</script>

<style scoped></style>
